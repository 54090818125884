import ActionTypes from "../constants";
import GeneralUtils from "../utils/GeneralUtils";

/**
 * Actions related to fetching and managing zone wellness and utilization data. This class interfaces with the API
 * to retrieve data and dispatches actions to update the application state.
 *
 * @class
 * @example
 * const zoneActions = new ZoneDetailsActions();
 * zoneActions.getZoneWellness('zone123', new Timeframe(1577836800, 1580515200), 'DAY'); // Unix epoch timestamps
 * zoneActions.getZoneUtilization('zone123', new Timeframe(1577836800, 1580515200), 'DAY'); // Unix epoch timestamps
 */
class ZoneDetailsActions {
  /**
   * Fetches wellness data for a specific zone from the API.
   *
   * @param {string} zoneId - The unique identifier of the zone
   * @param {Timeframe} timeframe - Timeframe instance containing start and end dates
   * @param {string} bucketType - The granularity of the data aggregation ('DAY', 'HOUR', 'FIFTEEN_MIN')
   * @returns {Promise} - A promise that resolves with the fetched data
   */
  getZoneWellness(zoneId, timeframe, bucketType) {
    const startUnix = timeframe.start.unix();
    const endUnix = timeframe.end.unix();

    return GeneralUtils.get({
      url: `wellness/zone/${zoneId}/${bucketType}/${startUnix}/${endUnix}`,
      actionType: ActionTypes.ZONE_DETAILS_WELLNESS_FETCHED,
      failToast: "Error fetching wellness data",
    });
  }

  /**
   * Fetches utilization data for a specific zone from the API.
   *
   * @param {string} zoneId - The unique identifier of the zone
   * @param {Timeframe} timeframe - Timeframe instance containing start and end dates
   * @param {string} bucketType - The granularity of the data aggregation ('DAY', 'HOUR', 'FIFTEEN_MIN')
   * @returns {Promise} - A promise that resolves with the fetched data
   */
  getZoneUtilization(zoneId, timeframe, bucketType) {
    const startUnix = timeframe.start.unix();
    const endUnix = timeframe.end.unix();

    return GeneralUtils.get({
      url: `utilization/zone/${zoneId}/${bucketType}/${startUnix}/${endUnix}`,
      actionType: ActionTypes.ZONE_DETAILS_UTILIZATION_FETCHED,
      failToast: "Error fetching utilization data",
    });
  }
}

const zoneDetailsActions = new ZoneDetailsActions();
export default zoneDetailsActions;

import React, { useState, useEffect, useCallback } from "react";
import { useParams, useHistory } from "react-router-dom";
import Constants from "../../../constants";
import moment from 'moment';

import "./Zone.scss";

//components
import { DocumentTitle } from "../../../components/DocumentTitle";
import { Icon } from "../../../components/Icon";
import { Chart } from "../../../components/Chart";
import { LogoSpinner } from "../../../components/LogoSpinner";
import GeneralStore from "../../../stores/generalStore";
import GeneralActions from "../../../actions/generalActions";
import ZoneDetailsStore from "../../../stores/zoneDetailsStore";
import ZoneDetailsActions from "../../../actions/zoneDetailsActions";
import GeneralUtils from "../../../utils/GeneralUtils";
import Timeframe from "../../../utils/Timeframe";

/**
 * Represents a Zone component in a React application that fetches and displays various data metrics
 * for a specific zone, including wellness and utilization data. The component updates the displayed
 * data based on user-selected date ranges and bucket sizes, providing detailed insights into zone conditions.
 *
 * Functionality:
 * - Fetches and displays wellness and utilization data based on a zone ID obtained from the URL parameters.
 * - Allows users to adjust the granularity of displayed data through interactive controls.
 * - Reacts to changes in date range selections by updating the data aggregation granularity and fetching new data accordingly.
 * - Provides visual feedback during data fetching operations and indicates when no data is available for the selected parameters.
 *
 * Usage:
 * This component is typically used in a dashboard or data visualization context within an environmental monitoring system,
 * where users need to monitor and analyze environmental conditions and occupancy over time.
 *
 * Example:
 * ```jsx
 * <Route path="/zone/:id" component={Zone} />
 * ```
 *
 * @component
 * @example
 * return <Zone />
 *
 * Dependencies:
 * - React Router (useParams, useHistory) for routing and navigation.
 * - Custom stores (GeneralStore, ZoneDetailsStore) for state management related to application data.
 * - Utility functions from GeneralUtils for date formatting and calculations.
 * - Constants for predefined configurations like bucket sizes.
 */
function Zone() {
  const { id: zoneId } = useParams();
  const history = useHistory();

  const initialStart = GeneralStore.getStartDate();
  const initialEnd = GeneralStore.getEndDate();
  const initialTimeframe = new Timeframe(initialStart, initialEnd);

  const daysDifference = GeneralUtils.getDifferenceBetweenDates(
    initialStart,
    initialEnd
  );

  let initialBucket = Constants.BUCKET_FIFTEEN_MIN_OLD;
  if (daysDifference > Constants.MAX_DAYS_FOR_DAY_BUCKET) {
    initialBucket = Constants.BUCKET_DAY_OLD;
  } else if (daysDifference > Constants.MAX_DAYS_FOR_HOUR_BUCKET) {
    initialBucket = Constants.BUCKET_HOUR_OLD;
  }

  const [state, setState] = useState({
    entity: {},
    temperatureData: [],
    co2Data: [],
    humidityData: [],
    pm25Data: [],
    luxData: [],
    utilizationCountData: [],
    utilizationRateData: [],
    utilizationPirData: [],
    timeframe: initialTimeframe,
    bucket: initialBucket,
    wellnessLoading: true,
    utilizationLoading: true,
  });

  /**
   * Registers event listeners for data fetching and date range changes when the component mounts.
   * Removes these listeners when the component unmounts to prevent memory leaks and unnecessary operations.
   * This hook mimics the behavior of componentDidMount and componentWillUnmount lifecycle methods in class components.
   *
   * Dependencies:
   * - None, this effect only runs once on mount and cleanup on unmount.
   */
  useEffect(() => {
    ZoneDetailsStore.addZoneDetailsFetchListener(onZoneWellnessFetch);
    ZoneDetailsStore.addZoneUtilizationFetchListener(onZoneUtilizationFetch);
    GeneralStore.addChangeListener(onDateChange);

    return () => {
      ZoneDetailsStore.removeZoneDetailsFetchListener(onZoneWellnessFetch);
      ZoneDetailsStore.removeZoneUtilizationFetchListener(
        onZoneUtilizationFetch
      );
      GeneralStore.removeChangeListener(onDateChange);
    };
  }, []);

  /**
   * Fetches wellness data for the zone. This function constructs an API request using the
   * current zone ID, start date, end date, and bucket type from the state, then initiates
   * the data fetching process. It sets the loading state to true before the API call to indicate data is being fetched.
   *
   * This function is wrapped in `useCallback` to prevent unnecessary re-creations except when certain
   * state values change, optimizing performance especially during re-renders.
   *
   * @param {string} zoneId - The unique identifier for the zone.
   * @param {Timeframe} timeframe - Timeframe instance containing start and end dates
   * @param {string} bucket - The aggregation level for the data (e.g., 'DAY', 'HOUR', 'FIFTEEN_MIN').
   * @uses useCallback - React hook that returns a memoized callback function.
   * @uses setState - React state function to update component state.
   */
  const fetchWellnessData = useCallback(() => {
    if (zoneId && state.timeframe && state.bucket) {
      setState((prevState) => ({ ...prevState, wellnessLoading: true }));
      ZoneDetailsActions.getZoneWellness(
        zoneId,
        state.timeframe,
        state.bucket
      );
    }
  }, [zoneId, state.timeframe, state.bucket]);

  /**
   * Fetches utilization data for the zone. This function constructs an API request using the
   * current zone ID, start date, end date, and bucket type from the state, then initiates
   * the data fetching process. It sets the loading state to true before the API call to indicate data is being fetched.
   *
   * This function is wrapped in `useCallback` to prevent unnecessary re-creations except when certain
   * state values change, optimizing performance especially during re-renders.
   *
   * @param {string} zoneId - The unique identifier for the zone.
   * @param {Timeframe} timeframe - Timeframe instance containing start and end dates
   * @param {string} bucket - The aggregation level for the data (e.g., 'DAY', 'HOUR', 'FIFTEEN_MIN').
   * @uses useCallback - React hook that returns a memoized callback function.
   * @uses setState - React state function to update component state.
   */
  const fetchUtilizationData = useCallback(() => {
    if (zoneId && state.timeframe && state.bucket) {
      setState((prevState) => ({ ...prevState, utilizationLoading: true }));
      ZoneDetailsActions.getZoneUtilization(
        zoneId,
        state.timeframe,
        state.bucket
      );
    }
  }, [zoneId, state.timeframe, state.bucket]);

  /**
   * Executes fetchWellnessData and fetchUtilizationData when there are changes to the bucket size or the selected date range (start and end dates).
   * This ensures that the data displayed is always current and aligned with user-selected parameters.
   *
   * This effect also depends on the fetchWellnessData and fetchUtilizationData functions themselves to capture any changes in their definitions,
   * which could include updated logic or dependencies that affect how data is fetched.
   *
   * Dependencies:
   * - fetchWellnessData: Ensures the latest logic is used when fetching wellness data.
   * - fetchUtilizationData: Ensures the latest logic is used when fetching utilization data.
   * - state.bucket: Triggers the effect when the bucket size changes, affecting the granularity of the displayed data.
   * - state.timeframe: Triggers the effect when the start date changes, affecting the data range.
   * - state.timeframe: Triggers the effect when the end date changes, affecting the data range.
   */
  useEffect(() => {
    fetchWellnessData();
    fetchUtilizationData();
  }, [
    fetchUtilizationData,
    fetchWellnessData,
    state.bucket,
    state.timeframe.start,
    state.timeframe.end,
  ]);

  useEffect(() => {
    const searchParams = new URLSearchParams(history.location.search);
    const fromDate = searchParams.get('from');
    const toDate = searchParams.get('to');

    if (fromDate && toDate) {
      const fromTimestamp = moment(fromDate).unix();
      const toTimestamp = moment(toDate).subtract(1, 'seconds').unix();
      const urlTimeframe = new Timeframe(fromTimestamp, toTimestamp);
      GeneralActions.setDates(urlTimeframe.start.unix(), urlTimeframe.end.unix());
    }
  }, [history.location.search]);

  /**
   * Sets the bucket size for data aggregation and updates the component state.
   * This function is called when the user selects a different bucket size for data viewing.
   *
   * @param {string} bucket - The new bucket size for data aggregation.
   */
  const setBucketSize = (bucket) => {
    setState((prevState) => ({ ...prevState, bucket }));
  };

  /**
   * Determines whether a given bucket size is disabled based on the date range selected by the user.
   * This function ensures that certain bucket sizes are not available for date ranges that exceed predefined limits.
   *
   * @param {string} bucket - The bucket size to check.
   * @returns {boolean} - Returns true if the bucket size should be disabled, otherwise false.
   */
  const isBucketDisabled = (bucket) => {
    const daysDifference = GeneralUtils.getDifferenceBetweenDates(
      state.timeframe.start.unix(),
      state.timeframe.end.unix()
    );

    return (
      (bucket === Constants.BUCKET_FIFTEEN_MIN_OLD &&
        daysDifference > Constants.MAX_DAYS_FOR_HOUR_BUCKET) ||
      (bucket === Constants.BUCKET_HOUR_OLD &&
        daysDifference > Constants.MAX_DAYS_FOR_DAY_BUCKET)
    );
  };

  /**
   * Callback function that updates the component state with the latest wellness data
   * fetched from ZoneDetailsStore. It updates state attributes related to the zone entity,
   * various data arrays for temperature, CO2, humidity, PM2.5, and lux. It also sets
   * the wellness loading state to false indicating that data fetching has completed.
   *
   * @listens ZoneDetailsStore#getZoneDetails - Retrieves the current zone entity details.
   * @listens ZoneDetailsStore#getTemperatureData - Retrieves temperature data.
   * @listens ZoneDetailsStore#getCo2Data - Retrieves CO2 data.
   * @listens ZoneDetailsStore#getHumidityData - Retrieves humidity data.
   * @listens ZoneDetailsStore#getPm25Data - Retrieves PM2.5 data.
   * @listens ZoneDetailsStore#getLuxData - Retrieves lux data.
   * @uses setState - Updates the component state with new data fetched from the store.
   */
  const onZoneWellnessFetch = () => {
    setState((prevState) => ({
      ...prevState,
      entity: ZoneDetailsStore.getZoneDetails(),
      temperatureData: ZoneDetailsStore.getTemperatureData(),
      co2Data: ZoneDetailsStore.getCo2Data(),
      humidityData: ZoneDetailsStore.getHumidityData(),
      pm25Data: ZoneDetailsStore.getPm25Data(),
      luxData: ZoneDetailsStore.getLuxData(),
      wellnessLoading: false,
    }));
  };

  /**
   * Callback function that updates the component state with the latest utilization data
   * fetched from ZoneDetailsStore. It updates state attributes related to utilization count,
   * rate, and PIR data. It also sets the utilization loading state to false indicating that
   * data fetching has completed.
   *
   * @listens ZoneDetailsStore#getUtilizationCountData - Retrieves utilization count data.
   * @listens ZoneDetailsStore#getUtilizationRateData - Retrieves utilization rate data.
   * @listens ZoneDetailsStore#getUtilizationPirData - Retrieves PIR (Passive Infrared) data.
   * @uses setState - Updates the component state with new data fetched from the store.
   */
  const onZoneUtilizationFetch = () => {
    setState((prevState) => ({
      ...prevState,
      utilizationCountData: ZoneDetailsStore.getUtilizationCountData(),
      utilizationRateData: ZoneDetailsStore.getUtilizationRateData(),
      utilizationPirData: ZoneDetailsStore.getUtilizationPirData(),
      utilizationLoading: false,
    }));
  };

  /**
   * Callback function that updates the component state with new start and end dates
   * from the GeneralStore. It adjusts the bucket size based on the new date range
   * to ensure the data granularity is appropriate. This function is called when the
   * date range changes.
   *
   * @listens GeneralStore#getStartDate - Retrieves the new start date.
   * @listens GeneralStore#getEndDate - Retrieves the new end date.
   * @uses GeneralUtils#getDifferenceBetweenDates - Calculates the difference between the new start and end dates.
   * @uses setState - Updates the component state with new start and end dates and the appropriate bucket size.
   */
  const onDateChange = () => {
    const newStart = GeneralStore.getStartDate();
    const newEnd = GeneralStore.getEndDate();
    const newTimeframe = new Timeframe(newStart, newEnd);
    const daysDifference = GeneralUtils.getDifferenceBetweenDates(
      newStart,
      newEnd
    );

    setState((prevState) => {
      let newBucket = prevState.bucket;
      if (daysDifference > Constants.MAX_DAYS_FOR_DAY_BUCKET) {
        newBucket = Constants.BUCKET_DAY_OLD;
      } else if (daysDifference > Constants.MAX_DAYS_FOR_HOUR_BUCKET) {
        newBucket = Constants.BUCKET_HOUR_OLD;
      }

      return {
        ...prevState,
        timeframe: newTimeframe,
        bucket: newBucket,
      };
    });
  };

  /**
   * Returns a chart component for a given data section title.
   * This function configures the chart based on the provided title and corresponding data.
   *
   * @param {string} title - The title of the data section to display.
   * @returns {JSX.Element|null} - Returns a chart component or null if there is no data.
   */
  const getSection = (title) => {
    const {
      utilizationRateData,
      utilizationCountData,
      temperatureData,
      co2Data,
      humidityData,
      pm25Data,
      luxData,
      utilizationPirData,
      bucket,
    } = state;

    const dataMapping = {
      "Occupancy Rate": { data: utilizationRateData, unit: "%", tooltipDecimals: 0, },
      "Occupancy Count": { data: utilizationCountData, unit: " pax", tooltipDecimals: 0, },
      "Temperature": { data: temperatureData, unit: "°C", tooltipDecimals: 1 },
      "CO2": { data: co2Data, unit: " ppm", tooltipDecimals: 0 },
      "Humidity": { data: humidityData, unit: "%", tooltipDecimals: 0 },
      "PM2.5": { data: pm25Data, unit: " mcg/m3", tooltipDecimals: 0 },
      "Lux": { data: luxData, unit: " Lux", tooltipDecimals: 0 },
      "Internal Occupancy Status (PIR)": { data: utilizationPirData, unit: "", tooltipDecimals: 0, isStatusChart: true, },
    };

    const { data, unit, tooltipDecimals, isStatusChart } =
      dataMapping[title] || {};

    if (!data || data.length === 0) return null;

    const chartProps = isStatusChart
      ? {
        height: 150,
        exporting: true,
        min: 0,
        max: 100,
        options: {
          tooltip: {
            pointFormatter: function () {
              return `<br/>${this.series.name} : ${this.y > 0 ? "On" : "Off"
                }`;
            },
          },
          chart: { marginLeft: 70 },
          yAxis: {
            title: { text: null },
            labels: { enabled: false },
          },
        },
        buttons: [
          {
            label: "15 min",
            activeCondition: true,
            disabledCondition: false,
          },
          { label: "Hour", activeCondition: false, disabledCondition: true },
          { label: "Day", activeCondition: false, disabledCondition: true },
        ],
      }
      : {
        options: {
          chart: { marginLeft: 70 },
        },
        buttons: [
          {
            label: "15 min",
            activeCondition: bucket === Constants.BUCKET_FIFTEEN_MIN_OLD,
            disabledCondition: isBucketDisabled(
              Constants.BUCKET_FIFTEEN_MIN_OLD
            ),
            handler: () => setBucketSize(Constants.BUCKET_FIFTEEN_MIN_OLD),
          },
          {
            label: "Hour",
            activeCondition: bucket === Constants.BUCKET_HOUR_OLD,
            disabledCondition: isBucketDisabled(Constants.BUCKET_HOUR_OLD),
            handler: () => setBucketSize(Constants.BUCKET_HOUR_OLD),
          },
          {
            label: "Day",
            activeCondition: bucket === Constants.BUCKET_DAY_OLD,
            disabledCondition: false,
            handler: () => setBucketSize(Constants.BUCKET_DAY_OLD),
          },
        ],
      };

    return (
      <div className="col-12 mg-t-30">
        <Chart
          title={title}
          data={data}
          tooltipDecimals={tooltipDecimals}
          unit={unit}
          {...chartProps}
        />
      </div>
    );
  };

  /**
   * Retrieves the page title based on the current meter entity's name, defaulting to 'No Data' if not available.
   *
   * @returns {string} - The title of the page.
   */
  const getPageTitle = () => {
    return state.entity ? state.entity.name : "No Data";
  };

  /**
   * Generates a navigation title element with a back arrow icon. Clicking this navigates back to the previous or default page.
   *
   * @returns {JSX.Element} - The navigation title element.
   */
  const getTitle = () => {
    const lastLocation = sessionStorage.getItem("zoneLastLoc");
    const defaultLocation = "/zones";
    const backText = state.entity ? state.entity.description : "Back";

    return (
      <div
        className="title tx-18 tx-archivo-semibold shadow-base"
        onClick={() => history.push(lastLocation || defaultLocation)}
      >
        <span className="icon-wrapper">
          <Icon name='ArrowLeft' size={40} color='#000' />
        </span>
        {backText}
      </div>
    );
  };

  /**
   * Renders a message indicating that no data is available based on the current query parameters.
   * This is displayed when there are no data points to show in the charts.
   *
   * @returns {JSX.Element|null} - A component displaying a 'no data available' message or null if data is present.
   */
  const getNoDataMessage = () => {
    const {
      wellnessLoading,
      utilizationLoading,
      temperatureData,
      co2Data,
      humidityData,
      luxData,
      pm25Data,
      utilizationCountData,
      utilizationRateData,
      utilizationPirData,
    } = state;

    const series = [
      ...temperatureData,
      ...co2Data,
      ...humidityData,
      ...luxData,
      ...pm25Data,
      ...utilizationCountData,
      ...utilizationRateData,
      ...utilizationPirData,
    ];

    if (!series.length && !wellnessLoading && !utilizationLoading) {
      return (
        <div className="col-12 mg-t-30">
          <Chart noData />
        </div>
      );
    }
    return null;
  };

  return (
    <div
      id="Zone"
      className="br-mainpanel br-profile-page floorplan-background"
      style={{ scrollY: "scroll" }}
    >
      <DocumentTitle title={getPageTitle()} />
      <LogoSpinner
        loading={state.wellnessLoading || state.utilizationLoading}
      />
      <div className="br-container">
        <div className="header row">
          <div className="col mg-t-30">{getTitle()}</div>
        </div>
        <div className="charts row">
          {getNoDataMessage()}
          {getSection("Occupancy Count")}
          {getSection("Occupancy Rate")}
          {getSection("Internal Occupancy Status (PIR)")}
          {getSection("Temperature")}
          {getSection("CO2")}
          {getSection("Humidity")}
          {getSection("PM2.5")}
          {getSection("Lux")}
        </div>
      </div>
    </div>
  );
}

export default Zone;

import React, { useState, useEffect } from "react";
import "./Appliances.scss";
import AdminActions from "../../../actions/adminActions";
import AdminStore from "../../../stores/adminStore";
import { Filter } from '../../../components/Filter';
import { ApplianceCard } from "../../../components/ApplianceCard";

import { Button } from 'antd';
import { Icon } from '../../../components/Icon';

const Appliances = (props) => {

  const {
    building: { building_id },
  } = props;

  // states
  const [appliances, setAppliances] = useState(AdminStore.getAvailableAppliances());
  const [searchInput, setSearchInput] = useState("");
  const [isApplianceCreation, toggleIsApplianceCreation] = useState(false);

  // UseEffect hook replaces the lifecycle methods, calling methods on mount and dismount (return statement)
  // listeners and appliance fetching are bound to the values in array, triggering update when they change 

  const onChange = () => {
    setAppliances(AdminStore.getAvailableAppliances());
  };

  useEffect(() => {
    setAppliances([])
  }, [building_id])

  useEffect(() => {
    AdminStore.addChangeListener(onChange);
    AdminStore.addSaveApplianceListener(onChange);
    AdminStore.addDeleteApplianceListener(onChange);
    if (appliances.length === 0) {
      AdminActions.getAppliances(building_id);
    };

    return () => {
      AdminStore.removeChangeListener(onChange);
      AdminStore.removeSaveApplianceListener(onChange);
      AdminStore.removeDeleteApplianceListener(onChange);
      AdminStore.clear();
    };
  }, [appliances.length, building_id]); // <- values triggering renders on change

  const getInputFilter = () => {
    return (
      <div className="col-12 col-sm-4 my-2 pd-0">
        <Filter
          value={searchInput}
          placeholder="Filter By Name, Description, UUID, MAC Address"
          setFilter={setSearchInput}
        />
      </div>
    );
  };  

  const getAddApplianceButton = () => {
    let button = (
      <Button
        className="button green"
        icon={<Icon name="AddCircleFilled" color={'#fff'} size={18} />}
        size="small"
        onClick={() => toggleIsApplianceCreation(!isApplianceCreation)}
        style={{ marginLeft: '15px' }}
      >Add Appliance</Button>
    )

    return <div className="col-12 col-sm-2 my-2 pd-0">
            {button}
          </div>
          }

  const getFilteredApplianceList = () => {
    // checking the filter value against name, description, UUID and MAC
    let applianceList = appliances.map((appliance, index) => {
            const filter = searchInput.toLowerCase();
            const filterMatchesName = appliance.name
              .toLowerCase()
              .includes(filter);
            const filterMatchesDescription = appliance.description
              .toLowerCase()
              .includes(filter);
            const filterMatchesMac = appliance.mac_addr && appliance.mac_addr
              .toLowerCase()
              .includes(filter);
            const filterMatchesUuid = appliance.uuid && appliance.uuid
              .toLowerCase()
              .includes(filter);

            if (
              filterMatchesName ||
              filterMatchesDescription ||
              filterMatchesMac ||
              filterMatchesUuid
            ) {
              return (
                <ApplianceCard
                  key={index}
                  buildingId={building_id}
                  passedApplianceData={appliance}
                  cancelNewApplianceHandler={toggleIsApplianceCreation}
                />
              );
            }
            return null;
          })
    return applianceList;
  };

  return (
    <div className="tab-content br-profile-body">
      {isApplianceCreation ? (
        <ApplianceCard
          cancelNewApplianceHandler={toggleIsApplianceCreation}
          buildingId={building_id}
        />
      ) : null}
      <hr/>
      <div className='d-flex'>
        {getInputFilter()}
        {getAddApplianceButton()}
      </div>
      {appliances.length > 0 ? getFilteredApplianceList() : <p>No Appliances found!</p>}
    </div>
  );
};

export default Appliances;
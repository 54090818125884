
import React, { Component } from 'react';
import './Spinner.scss';

class Spinner extends Component {

  render() {

    if (this.props.mini) {
      return (
          <div style={{opacity: this.props.trueCondition ? '1' : '0', ...this.props.style}} className="mini-spinner"></div>
      );
    }

    return (
      <div className="d-flex wd-100p bg-transparent pos-relative align-items-center" style={{ height: this.props.height, flexDirection: 'column' }}>
        {this.props.text && <div>{this.props.text}</div>}
        <div className="spinner">
          <div className="rect1"></div>
          <div className="rect2"></div>
          <div className="rect3"></div>
          <div className="rect4"></div>
          <div className="rect5"></div>
        </div>
      </div>
    );
  }
}

export default Spinner;

Spinner.defaultProps = {
  height: '60vh',
  text: '',
}
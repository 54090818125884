import { EventEmitter } from "events";
import Dispatcher from "../dispatcher";
import Constants from "../constants";

const ALARMS_OVERVIEW_FETCH = Constants.ALARMS_OVERVIEW_FETCH;
const ALARMS_OVERVIEW_ACTION = Constants.ALARMS_OVERVIEW_ACTION;
const ALARMS_OVERVIEW_ALARM_SAVED = Constants.ALARMS_OVERVIEW_ALARM_SAVED;
const ALARMS_OVERVIEW_ALARM_DELETED = Constants.ALARMS_OVERVIEW_ALARM_DELETED;
const ALARMS_OVERVIEW_ALARM_UPDATED = Constants.ALARMS_OVERVIEW_ALARM_UPDATED;
const ALARMS_OVERVIEW_ALARM_STATUS_TOGGLED =
  Constants.ALARMS_OVERVIEW_ALARM_STATUS_TOGGLED;

let _alarms = [];

class AlarmsOverviewStore extends EventEmitter {
  constructor() {
    super();
    // Registers action handler with the Dispatcher.
    Dispatcher.register(this._registerToActions.bind(this));
  }

  // Switches over the action's type when an action is dispatched.
  _registerToActions(action) {
    switch (action.actionType) {
      case ALARMS_OVERVIEW_FETCH:
        this._storeData(action.payload);
        break;
      case ALARMS_OVERVIEW_ALARM_SAVED:
        this.emit(ALARMS_OVERVIEW_ACTION);
        break;
      case ALARMS_OVERVIEW_ALARM_UPDATED:
        this.emit(ALARMS_OVERVIEW_ACTION);
        break;
      case ALARMS_OVERVIEW_ALARM_DELETED:
        this.emit(ALARMS_OVERVIEW_ACTION);
        break;
      case ALARMS_OVERVIEW_ALARM_STATUS_TOGGLED:
        this.emit(ALARMS_OVERVIEW_ACTION);
        break;
      default:
        break;
    }
  }

  // Hooks a React component's callback to the CHANGED event.
  addAlarmsOverviewFetchListener(callback) {
    this.on(ALARMS_OVERVIEW_FETCH, callback);
  }
  removeAlarmsOverviewFetchListener(callback) {
    this.removeListener(ALARMS_OVERVIEW_FETCH, callback);
  }

  // Alarm action - CRUD
  addAlarmsOverviewActionListener(callback) {
    this.on(ALARMS_OVERVIEW_ACTION, callback);
  }
  removeAlarmsOverviewActionListener(callback) {
    this.removeListener(ALARMS_OVERVIEW_ACTION, callback);
  }

  clear() {
    _alarms = [];
  }

  _storeData(json) {
    _alarms = json;

    this.emit(ALARMS_OVERVIEW_FETCH);
  }

  getAlarmsOverview() {
    return _alarms;
  }
}

const alarmsOverviewStore = new AlarmsOverviewStore();
export default alarmsOverviewStore;

import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Typography } from 'antd';
import './InventoryProcessing.scss';

// components
import { DocumentTitle } from '../../components/DocumentTitle';
import { Spinner } from '../../components/Spinner';
import { SearchSelect } from '../../components/SearchSelect';

// store/actions
import AutomatedAssessmentsActions from '../../actions/automatedAssessmentsActions';
import AutomatedAssessmentsStore from '../../stores/automatedAssessmentsStore';
import BuildingActions from '../../actions/buildingActions';
import BuildingStore from '../../stores/buildingStore';

const { Title, Text } = Typography;

class InventoryProcessing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      document_id: '',
      results_object: null,
      selected_estate: null,
      estates: BuildingStore.getEstates()
    }
    this._dataFetch = this._dataFetch.bind(this);
    this._onDataFetch = this._onDataFetch.bind(this);
    this._onBuildingChange = this._onBuildingChange.bind(this);
    this._setTargetEstate = this._setTargetEstate.bind(this);
  };

  componentDidMount() {
    BuildingActions.getBuildings()
  }

  UNSAFE_componentWillMount() {
    // add listener
    AutomatedAssessmentsStore.addInventoryFetchListener(this._onDataFetch)
    BuildingStore.addChangeListener(this._onBuildingChange);
  }
  componentWillUnmount() {
    //remove listener
    AutomatedAssessmentsStore.removeInventoryFetchListener(this._onDataFetch)
    BuildingStore.removeChangeListener(this._onBuildingChange);
  }

  _dataFetch() {
    // action to fetch
    if (this.state.document_id !== '' && this.state.selected_estate != null) {
      AutomatedAssessmentsActions.getInventoryDocument(this.state.document_id, this.state.selected_estate.value);
      this.setState({ results_loading: true })
    }
  }

  _onDataFetch() {
    this.setState({
      results_loading: false,
      results_object: AutomatedAssessmentsStore.getInventoryDocumentData()
    })
  }
  _onBuildingChange() {
    this.setState({ estates: BuildingStore.getEstates() });
  }

  _setTargetEstate(selected_estate) {
    this.setState({ selected_estate: selected_estate });
  }

  getResults() {
    const results = this.state.results_object;

    if (this.state.results_loading) {
      return <Spinner height='200px' />
    }

    if (results === null) {
      return <div className="no-results">
        No document loaded or no results found.
      </div>
    }

    return <div className='results'>
      <div className='title'>Success - Building Created with name - {results.building_name}</div>
      {results.num_ahus > 0 && <div className="value">- {results.num_ahus} AHUs</div>}
      {results.num_boiler_units > 0 && <div className="value">- {results.num_boiler_units} Boilers</div>}
      {results.num_chiller_units > 0 && <div className="value">- {results.num_chiller_units} Chillers</div>}
      {results.num_fan_coil_units > 0 && <div className="value">- {results.num_fan_coil_units} FCUs</div>}
      {results.num_ahus === 0 && <div className="value">- {results.num_ahus} AHUs</div>}
      {results.num_boiler_units === 0 && <div className="value">- {results.num_boiler_units} Boilers</div>}
      {results.num_chiller_units === 0 && <div className="value">- {results.num_chiller_units} Chillers</div>}
      {results.num_fan_coil_units === 0 && <div className="value">- {results.num_fan_coil_units} FCUs</div>}
    </div>
  }

  render() {
    let estate_options = [];
    for (let i = 0; i < this.state.estates.length; i++) {
      estate_options.push({ name: this.state.estates[i].name, value: this.state.estates[i].estate_id });
    }

    return (
      <div className="br-mainpanel br-profile-page floorplan-background">
        <DocumentTitle title="Inventory Processing Tool" />
        <div className="br-container">
          <div className="row">
            <div className="col-12 mg-t-30">
              <Title level={3}>Inventory Document Processing Tool</Title>
              <Text type="secondary">
                Enter the google id of document to start the import process (e.g. 1L5H8Mp2X-lR2j7DnRk7CZvSlyKzuKGhtXlfpIjxmGBQ).
              </Text>
            </div>
            <div className="col-12">
              <div className="card pd-30 shadow-base bd-0 mg-t-20">
                <div className="mg-b-20 mg-lg-b-0 wd-100p">
                  <div className="row wd-100p mg-0">
                    <div className="col-12 col-lg-4 d-flex align-items-center">
                      <input
                        type="text"
                        className="form-control pd-t-10 mg-t-20 mg-b-20"
                        placeholder="Enter the id of document to process the inventory..."
                        value={this.state.document_id}
                        onChange={(e) =>
                          this.setState({
                            document_id: e.target.value.trim(),
                          })
                        }
                      />
                    </div>
                    <div className="col-12 mg-y-20 col-lg-4 d-flex align-items-center">
                      <div className="form-group" style={{ marginBottom: '0px', width: '100%' }}>
                        <SearchSelect 
                          extraHeight={true} 
                          limit={24} 
                          options={estate_options} 
                          placeholder={"Select a target estate."} 
                          defaultValue={this.state.selected_estate} 
                          actionOnSelectedOption={this._setTargetEstate} 
                        />
                      </div>
                    </div>
                    <div className="col-12 mg-y-20 col-lg-4 d-flex align-items-center">
                    <div
                      className="btn btn-info tx-14 tx-uppercase tx-mont tx-semibold wd-100p"
                      style={{ cursor: "pointer" }}
                      onClick={() => this._dataFetch()}
                    >
                      Ingest Inventory
                    </div>
                    </div>
                  </div>
                  <div className="row wd-100p mg-0">
                    <div className="col-12">
                      <h4 className="tx-normal tx-roboto tx-inverse mg-b-10">
                        Results:
                      </h4>
                      <div className="results-box">{this.getResults()}</div>
                      <div className="link-row wd-100p">
                        <div></div>
                        <Link className="ml-auto mg-t-10" to="/mapping-and-validation">Continue to Mapping and Validation</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default InventoryProcessing;

import React, { Component } from 'react';
import './ModifyCounterPanel.scss';

class ModifyCounterPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      building: this.props.building,
      counter: this.props.counter,
      inputValue: '',
      doorways: this.props.doorways,
      expanded: this.props.expanded,
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ building: nextProps.building, doorways: nextProps.doorways, counter: nextProps.counter, expanded: nextProps.expanded })
  }
  
  getCounterRow(counter) {
    return <tr>
      <td style={{ paddingBottom: '5px' }}><input placeholder="Name" type="text" className="inline-single-input" defaultValue={counter.name} onChange={this.props.changeCounterValues.bind(this, counter, 'name')} /></td>
      <td style={{ paddingBottom: '5px' }}><input placeholder="Description" type="text" className="inline-single-input" defaultValue={counter.description} onChange={this.props.changeCounterValues.bind(this, counter, 'description')} /></td>
      <td style={{ paddingBottom: '5px' }}><input placeholder="Location" type="text" className="inline-single-input" defaultValue={counter.location} onChange={this.props.changeCounterValues.bind(this, counter, 'location')} /></td>
      <td style={{ paddingBottom: '5px' }}><input placeholder="Device ID" type="text" className="inline-single-input" defaultValue={counter.device_id} onChange={this.props.changeCounterValues.bind(this, counter, 'device_id')} /></td>
      <td style={{ paddingBottom: '5px' }}>
        <select style={{height: '25px'}} onChange={this.props.changeCounterValues.bind(this, counter, 'doorway_id')} value={counter.doorway_id || 0} className="inline-single-input">
          <option value={0}>Select Doorway</option>
          {this.props.doorways.map(doorway => {
            return <option key={doorway.entity_type_id} disabled={doorway.disabled} value={doorway.doorway_id}>{doorway.description + ` (${doorway.doorway_id})`}</option>
          })}
        </select>
      </td>

      <td style={{ paddingBottom: '5px' }}><input disabled={true} placeholder="UUID" type="text" className="inline-single-input" defaultValue={counter.uuid} onChange={this.props.changeCounterValues.bind(this, counter, 'uuid')} /></td>
      <td style={{ paddingBottom: '5px' }}><input disabled={this.props.counter.counter_id} placeholder="Mac Address" type="text" className="inline-single-input" defaultValue={counter.mac_addr} onChange={this.props.changeCounterValues.bind(this, counter, 'mac_addr')} /></td>
      <td style={{ paddingBottom: '5px' }}><button className='btn btn-outline-info ht-20 d-flex align-items-center' onClick={() => counter.counter_id && this.props.expansionHandler(counter.counter_id)}>{this.state.expanded ? '-' : '+'}</button></td>
    </tr>
  }

  render() {
    let counter = this.state.counter;
    return <React.Fragment>
      {this.getCounterRow(counter)}
      {this.state.expanded && <tr>
        <td className="valign-middle" colSpan="8" style={{ paddingTop: '20px', border: 'none', paddingBottom: '20px' }}>
          {counter.counter_id ? <span onClick={this.props.updateCounter.bind(this, counter)} style={{ cursor: 'pointer', float: 'right', width:'80px' }} className="btn btn-icon btn-info tx-14 pd-5 mg-l-5">Update</span> : <span onClick={this.props.saveCounter.bind(this, counter)} style={{ cursor: 'pointer', float: 'right', width:'80px' }} className="btn btn-icon btn-info tx-14 pd-5 mg-l-5">Save</span>}
          {counter.counter_id ? <span onClick={this.props.deleteCounter.bind(this, counter.device_id)} style={{ cursor: 'pointer', float: 'right', width:'80px' }} className="btn btn-icon btn-danger tx-14 pd-5">Delete</span> : <span onClick={this.props.cancelCounter.bind(this, counter)} style={{ cursor: 'pointer', float: 'right', width:'80px' }} className="btn btn-icon btn-warning tx-14 pd-5">Cancel</span>}
        </td>
      </tr>}
    </React.Fragment>
  }

}

export default ModifyCounterPanel;

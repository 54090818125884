import React, { Component } from "react";
import "./RefreshProgressBar.scss";
import moment from "moment";

class RefreshProgressBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      time: moment(),
      countedSeconds: 1,
      percentComplete: 0,
    };
  }
  componentDidMount() {
    this.intervalID = setInterval(() => this.tick(), 1000);
  }
  componentWillUnmount() {
    clearInterval(this.intervalID);
  }
  tick() {
    let countedSeconds = this.state.countedSeconds + 1;
    if (countedSeconds > this.props.countdownSeconds) {
      countedSeconds = 1;
    }
    let percentComplete =
      Math.ceil(((countedSeconds / this.props.countdownSeconds) * 100) / 5) * 5;
    this.setState({
      time: moment(),
      countedSeconds: countedSeconds,
      percentComplete: percentComplete,
    });
  }
  render() {
    let percentClass = "progress-bar wd-" + this.state.percentComplete + "p";

    if (this.props.animated) percentClass += " progress-bar-animated";
    if (this.props.striped) percentClass += " progress-bar-striped";

    return (
      <div
        className="progress mg-b-10"
        style={{ height: this.props.height ? this.props.height : "2px" }}
      >
        <div
          className={percentClass}
          role="progressbar"
          aria-valuenow={this.state.percentComplete}
          aria-valuemin="0"
          aria-valuemax="100"
        ></div>
      </div>
    );
  }
}

export default RefreshProgressBar;

import ActionTypes from "../constants";
import GeneralUtils from "../utils/GeneralUtils";

/**
 * Actions related to fetching the overview of zones. This class interfaces with the API
 * to retrieve data and dispatches actions to update the application state.
 *
 * @class
 * @example
 * const zonesOverviewActions = new ZonesOverviewActions();
 * zonesOverviewActions.getZonesOverview('2020-01-01', '2020-01-31');
 */
class ZonesOverviewActions {
  /**
   * Fetches an overview of zones within a specified time range from the API and dispatches an action to store this data.
   * If no time range is specified, it fetches the general overview data.
   *
   * @param {string} [tsStart=null] - The start timestamp of the data range, formatted as YYYY-MM-DD.
   * @param {string} [tsEnd=null] - The end timestamp of the data range, formatted as YYYY-MM-DD.
   * @returns {Promise} - A promise that resolves with the fetched data.
   */
  getZonesOverview(timeframe) {
    const queryParams = GeneralUtils.toIntervalQueryParameterString(timeframe);
    return GeneralUtils.get({
      url: `v2/zones?${queryParams}`,
      actionType: ActionTypes.ZONES_OVERVIEW_FETCH,
    });
  }
}

const zonesOverviewActions = new ZonesOverviewActions();
export default zonesOverviewActions;

import ActionTypes from "../constants";
import GeneralUtils from "../utils/GeneralUtils";
import _ from "lodash";


class DataExplorerActions {
  getProperties() {
    return GeneralUtils.get({
      url: "v2/properties/mapped",
      actionType: ActionTypes.DATA_EXPLORER_PROPERTIES_FETCHED,
    });
  }

  getChartDataByRef(chartRef) {
    return GeneralUtils.get({
      url: `explorer/chart/data/ref/${chartRef}`,
      actionType: ActionTypes.DATA_EXPLORER_CHART_DATA_FETCHED,
      failToast: "Failed to fetch chart data.",
    });
  }

  getChartData(parameters) {
    return GeneralUtils.post({
      url: "explorer/chart/data",
      object: parameters,
      actionType: ActionTypes.DATA_EXPLORER_CHART_DATA_FETCHED,
      failToast: "Failed to fetch chart data.",
    });
  }

  getConfigs() {
    return GeneralUtils.get({
      url: "explorer/config",
      actionType: ActionTypes.DATA_EXPLORER_CONFIGS_FETCHED,
      failToast: "Failed to fetch configurations.",
    });
  }

  updateConfig(config, configRef) {
    return GeneralUtils.put({
      url: `explorer/config/ref/${configRef}`,
      object: config,
      actionType: ActionTypes.DATA_EXPLORER_CONFIG_UPDATED,
      successToast: "Configuration updated successfully.",
      failToast: "Failed to update configuration.",
    });
  }

  saveConfig(config) {
    return GeneralUtils.post({
      url: "explorer/config",
      object: config,
      actionType: ActionTypes.DATA_EXPLORER_CONFIG_SAVED,
      successToast: "Configuration saved successfully.",
      failToast: "Failed to save configuration.",
    });
  }

  deleteConfig(configRef) {
    return GeneralUtils.delete({
      url: `explorer/config/ref/${configRef}`,
      actionType: ActionTypes.DATA_EXPLORER_CONFIG_DELETED,
      successToast: "Configuration deleted successfully.",
      failToast: "Failed to delete configuration.",
    });
  }

  // Shared with DashboardsActions - token generating

  generateToken(tokenObject) {
    return GeneralUtils.post({
      url: "tokens/generate",
      object: tokenObject,
      actionType: ActionTypes.DATA_EXPLORER_TOKEN_GENERATED,
      modifyResponse: (response) => _.get(response, "token"),
      failToast: "Error creating sharing token.",
    });
  }

  getConfigByToken(token) {
    return GeneralUtils.get({
      authorizationNeeded: false,
      url: `tokens/process?token=${token}`,
      actionType: ActionTypes.DATA_EXPLORER_TOKEN_DATA_FETCHED,
      failToast: "Error fetching dashboard data",
    });
  }

}


const dataExplorerActions = new DataExplorerActions();
export default dataExplorerActions;

import React from "react";
import _ from "lodash";
import "./EditAlarmModal.scss";

import AlarmsOverviewActions from "../../../actions/alarmsOverviewActions";
import BuildingActions from "../../../actions/buildingActions";

import { PageTitle } from "../../../components/PageTitle";
import { BuildingSelect } from "../../../components/BuildingSelect";
import { Input } from "../../../components/Input";
import { SearchSelectDropdown } from "../../../components/SearchSelectDropdown";
import { MultiSelectDropdown } from "../../../components/MultiSelectDropdown";
import { LogoSpinner } from "../../../components/LogoSpinner";

import EntityTypeActions from "../../../actions/entityTypeActions";
import UserActions from "../../../actions/userActions";
import UserStore from "../../../stores/userStore";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import GeneralUtils from "../../../utils/GeneralUtils";

class EditAlarmModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // Selected Building
      selected_building: null,

      // Alarm Name
      alarm_name: "",

      // Alarm Description
      alarm_description: "",

      // Enabled For - Every Day, Weekday etc.
      target_period: null,

      // Data Type - energy, wellness etc.
      target_factor: null,

      // Alarm Type
      alarm_type: null,

      // Target Type - greater than/less than
      target_type: null,

      // Trigger Alarm At
      target_amount: 0,

      // Consecutive Breaches
      required_triggers: 1,

      // Alarm Target - entities
      available_entities: [],
      alarm_target: [],

      // Email Recipients
      available_recipients: [],
      email_recipients: [],
      loading: true,
    };

    this.selectBuilding = this.selectBuilding.bind(this);
    this.selectEmailRecipients = this.selectEmailRecipients.bind(this);
    this.selectAlarmTarget = this.selectAlarmTarget.bind(this);
    this.updateAlarm = this.updateAlarm.bind(this);
  }

  // Lifecycles

  componentDidMount() {
    const org_id = _.get(UserStore.getUser(), "fk_organisation_id");
    const building_id = _.get(this.props, "alarm.building.id");
    const alarm_id = _.get(this.props, "alarm.alarm.id");

    this.fetchAlarm(alarm_id);
    this.fetchUsers(org_id);
    this.fetchBuilding(building_id);
    this.fetchBuildingEntities(building_id);
  }

  async fetchBuilding(building_id) {
    try {
      const building = await BuildingActions.getBuilding(building_id);
      this.setState({ selected_building: building });
    } catch (error) {
      console.error('Failed to fetch the building: ', error.code, error.type);
    }
  }

  async fetchAlarm(alarm_id) {
    try {
      const alarm = await AlarmsOverviewActions.getAlarm(alarm_id);
      this.setState({ fetched_alarm: alarm, loading: false }, () =>
        this.mountAlarm()
      );
    } catch (error) {
      console.error('Failed to fetch the alert: ', error.code, error.type);
    }
  }

  async fetchUsers(org_id) {
    try {
      const users = await UserActions.getOrganisationUsers(org_id);
      this.setState({ available_recipients: users.organisation_users });
    } catch (error) {
      console.error('Failed to fetch users: ', error.code, error.type);
    }
  }

  async fetchBuildingEntities(id) {
    try {
      const entities = await EntityTypeActions.getBuildingEntityTypes(id);
      this.setState({ available_entities: entities });
    } catch (error) {
      console.error('Failed to fetch building entities: ', error.code, error.type);
    }
  }

  mountAlarm() {
    const alarm = this.state.fetched_alarm;
    if (alarm) {
      const name = alarm.alarm.name;
      const description = alarm.alarm.description;
      const required_triggers = alarm.alarm.required_triggers;
      const target_amount = alarm.alarm.target_amount;
      const target_period = alarm.alarm.target_period;
      const target_factor = alarm.alarm.target_factor;
      const alarm_type = alarm.alarm.target_category;
      const target_type = alarm.alarm.target_type;
      const alarm_target = [alarm.entity.type_id];
      const email_recipients = alarm.alarm.recipients.map((r) => r.user_id);

      this.setState({
        alarm_name: name,
        alarm_description: description,
        required_triggers: required_triggers,
        target_amount: target_amount,
        target_period: target_period,
        target_factor: target_factor,
        alarm_type: alarm_type,
        target_type: target_type,
        alarm_target: alarm_target,
        email_recipients: email_recipients,
      });
    }
  }

  selectBuilding(building) {
    this.setState(
      { selected_building: building, target_factor: null, alarm_target: [] },
      () => {
        this.fetchBuildingEntities(building.building_id);
      }
    );
  }

  getPeriodOptions() {
    return [
      {
        name: "Every day",
        value: "every day",
      },
      {
        name: "Weekdays Only",
        value: "weekday",
      },
      {
        name: "Weekends Only",
        value: "weekend",
      },
    ];
  }

  getTargetFactorOptions() {
    let available_factor_types = [];

    let available_features = _.get(
      this.state.selected_building,
      "available_features"
    );

    if (available_features) {
      if (available_features.indexOf("energy") > -1) {
        available_factor_types.push({
          name: "Energy Consumption",
          value: "energy consumption",
        });

        available_factor_types.push({
          name: "Energy Reactive Power (kvar)",
          value: "energy reactive power",
        });

        available_factor_types.push({
          name: "Energy Active Power (kw)",
          value: "energy active power",
        });
      }
      if (available_features.indexOf("utilization") > -1) {
        available_factor_types.push({
          name: "Occupancy Level",
          value: "utilization percent",
        });
      }
      if (available_features.indexOf("wellness") > -1) {
        available_factor_types.push({
          name: "Temperature",
          value: "wellness temperature",
        });
        available_factor_types.push({
          name: "CO2",
          value: "wellness CO2",
        });
        available_factor_types.push({
          name: "Humidity",
          value: "wellness humidity",
        });
      }
    }
    return available_factor_types;
  }

  getAlarmTypeOptions() {
    let alarm_types = [
      {
        name: "Instantaneous",
        value: "actual",
      },
    ];

    const target_factor = _.get(this.state, "target_factor");

    if (target_factor && target_factor.includes("energy")) {
      alarm_types.push({
        name: "Daily Total",
        value: "total",
      });
    }

    return alarm_types;
  }

  getTargetTypeOptions() {
    return [
      {
        name: "Greater Than",
        value: "fixed",
      },
      {
        name: "Less Than",
        value: "fixed_less",
      },
    ];
  }

  getEmailRecipientsOptions() {
    let users = this.state.available_recipients.map((r) => {
      return {
        ...r,
        label: r.name,
        value: r.user_id,
      };
    });
    return users;
  }

  selectEmailRecipients(users) {
    this.setState({
      email_recipients: users,
    });
  }

  getAlarmTargetOptions() {
    let alarmTargetOptions = [];
    const entities = _.get(this.state, "available_entities");
    const target_factor = _.get(this.state, "target_factor");

    if (entities) {
      if (
        target_factor === "energy consumption" ||
        target_factor === "energy reactive_power" ||
        target_factor === "energy active power"
      ) {
        entities
          .filter((e) => e.type === "METER")
          .forEach((f) =>
            alarmTargetOptions.push({
              value: f.entity_type_id,
              label: "Meter: " + f.name,
            })
          );
      } else {
        entities
          .filter((e) => e.type === "ZONE")
          .forEach((f) =>
            alarmTargetOptions.push({
              value: f.entity_type_id,
              label: "Zone: " + f.name,
            })
          );
      }
    }

    return alarmTargetOptions;
  }

  selectAlarmTarget(arr) {
    this.setState({ alarm_target: arr });
  }

  getUnit() {
    let unit = "%";
    const target_factor = _.get(this.state, "target_factor");
    if (target_factor) {
      if (target_factor === "energy consumption") {
        unit = "kWh";
      } else if (target_factor === "energy reactive power") {
        unit = "kvar";
      } else if (target_factor === "energy active power") {
        unit = "kw";
      } else if (target_factor === "wellness temperature") {
        unit = "°C";
      } else if (target_factor === "wellness humidity") {
        unit = "%";
      } else if (target_factor === "wellness CO2") {
        unit = "ppm";
      }
    }

    return unit;
  }

  updateAlarm() {
    const alarm_id = _.get(this.props, "alarm.alarm.id");
    const entities = this.state.alarm_target;
    const hasBuilding = !!this.state.selected_building;
    const hasName = !!this.state.alarm_name;
    const hasDescription = !!this.state.alarm_description;
    const hasTargetFactor = !!this.state.target_factor;
    const hasSelectedEntities = !!this.state.alarm_target.length;
    const hasTargetPeriod = !!this.state.target_period;
    const hasTargetType = !!this.state.target_type;
    const hasAlarmType = !!this.state.alarm_type;

    if (
      alarm_id &&
      hasBuilding &&
      hasName &&
      hasDescription &&
      hasTargetFactor &&
      hasSelectedEntities &&
      hasTargetPeriod &&
      hasTargetType &&
      hasAlarmType
    ) {
      entities.forEach((e) => {
        const alarmObj = {
          entity_type_id: e,
          name: this.state.alarm_name,
          description: this.state.alarm_description,
          status: "active",
          target_factor: this.state.target_factor,
          target_period: this.state.target_period,
          target_category: this.state.alarm_type,
          target_type: this.state.target_type,
          target_amount: +this.state.target_amount,
          required_triggers: +this.state.required_triggers,
          alarm_recipients: this.state.email_recipients.map((r) => {
            return {
              user_id: r,
              receive_sms: false,
              receive_email: true,
            };
          }),
        };
        AlarmsOverviewActions.updateAlarm(alarm_id, alarmObj);
      });
    } else {
      toast("Invalid, Please ensure all mandatory fields are selected", {
        type: toast.TYPE.WARNING,
        autoClose: 3000,
        preventDuplicated: true,
      });
    }
  }

  validateInputs() {
    const name = _.get(this.state, "alarm_name");
    const target_period = _.get(this.state, "target_period");
    const target_factor = _.get(this.state, "target_factor");
    const target_amount = _.get(this.state, "target_amount");
    const target_type = _.get(this.state, "target_type");
    const target_category = _.get(this.state, "alarm_type");
    const required_triggers = _.get(this.state, "required_triggers");
    const selected_entities =
      this.state.alarm_target && this.state.alarm_target.length > 0;

    return !!(
      name &&
      target_period &&
      target_factor &&
      target_amount &&
      target_type &&
      target_category &&
      required_triggers &&
      selected_entities
    );
  }

  getAlarmPreview() {
    const name = _.get(this.state, "alarm_name");
    const target_amount = _.get(this.state, "target_amount");
    const required_triggers = _.get(this.state, "required_triggers");

    const target_period = _.get(this.state, "target_period");
    const target_factor = _.get(this.state, "target_factor");
    const target_type = _.get(this.state, "target_type");
    const target_category = _.get(this.state, "alarm_type");

    if (this.validateInputs()) {
      return GeneralUtils.getAlarmExplainerText({
        name: name,
        target_period: target_period,
        target_factor: target_factor,
        target_amount: target_amount,
        target_type: target_type,
        target_category: target_category,
        required_triggers: required_triggers,
      });
    } else {
      return "Fill out required fields.";
    }
  }

  render() {
    const inputsValidated = this.validateInputs();
    const userOptions = this.getEmailRecipientsOptions();
    const targetOptions = this.getAlarmTargetOptions();
    const periodOptions = this.getPeriodOptions();
    const targetFactorOptions = this.getTargetFactorOptions();
    const alarmTypeOptions = this.getAlarmTypeOptions();
    const alarmPreview = this.getAlarmPreview();

    return (
      <div id="EditAlarmModal">
        <LogoSpinner loading={this.state.loading} />
        <div className="row mg-b-20">
          <div className="col-12">
            <PageTitle title={"Edit Existing Alert"} />
          </div>
        </div>
        <hr></hr>
        {/* FORM */}
        <div className="row mg-b-20">
          <div className="col-12">
            <div className="left-panel">
              <div className="row">
                <div className="col-6">
                  <div className="label">
                    Target Building{" "}
                    <span className="field-status">(required)</span>
                  </div>
                  <BuildingSelect
                    selectedBuilding={this.state.selected_building}
                    onBuildingSelect={this.selectBuilding}
                  />
                </div>
                <div className="col-6">
                  <Input
                    label={"Alert Name"}
                    isRequired
                    value={this.state.alarm_name}
                    onChangeHandler={(name) =>
                      this.setState({ alarm_name: name })
                    }
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <Input
                    label={"Alert Description"}
                    isRequired
                    value={this.state.alarm_description}
                    onChangeHandler={(desc) =>
                      this.setState({ alarm_description: desc })
                    }
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-4">
                  <SearchSelectDropdown
                    label={"Enabled For"}
                    isRequired
                    options={periodOptions}
                    value={this.state.target_period}
                    onSelectHandler={(e) =>
                      this.setState({ target_period: e.value })
                    }
                  />
                </div>
                <div className="col-4">
                  <SearchSelectDropdown
                    label={"Data Type"}
                    isRequired
                    disabled={!this.state.selected_building}
                    options={targetFactorOptions}
                    value={this.state.target_factor}
                    onSelectHandler={(e) =>
                      this.setState({
                        target_factor: e.value,
                        alarm_type: null,
                      })
                    }
                  />
                </div>
                <div className="col-4">
                  <SearchSelectDropdown
                    label={"Alert Type"}
                    isRequired
                    options={alarmTypeOptions}
                    value={this.state.alarm_type}
                    onSelectHandler={(e) =>
                      this.setState({ alarm_type: e.value })
                    }
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-4">
                  <SearchSelectDropdown
                    label={"Target Type"}
                    isRequired
                    options={this.getTargetTypeOptions()}
                    value={this.state.target_type}
                    onSelectHandler={(e) =>
                      this.setState({ target_type: e.value })
                    }
                  />
                </div>
                <div className="col-4">
                  <Input
                    label={"Trigger Alert At"}
                    value={this.state.target_amount}
                    suffix={this.getUnit()}
                    onChangeHandler={(num) =>
                      this.setState({ target_amount: num })
                    }
                    type="number"
                  />
                </div>
                <div className="col-4">
                  <Input
                    label={"Consecutive Breaches"}
                    value={this.state.required_triggers}
                    iconPrefix={"Notification"}
                    onChangeHandler={(num) =>
                      this.setState({ required_triggers: num })
                    }
                    type={"number"}
                    disabled={_.get(this.state, "alarm_type") === "total"}
                    min={1}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <MultiSelectDropdown
                    label={"Alert Target"}
                    isRequired
                    options={targetOptions}
                    disabled={
                      !this.state.selected_building || !this.state.target_factor
                    }
                    value={this.state.alarm_target}
                    onChangeHandler={this.selectAlarmTarget}
                  />
                </div>
                <div className="col-6">
                  <MultiSelectDropdown
                    label={"Email Recipients"}
                    options={userOptions}
                    value={this.state.email_recipients}
                    onChangeHandler={this.selectEmailRecipients}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* PREVIEW */}
        <div className="row mg-b-20">
          <div className="col-12">
            <div className="preview">
              <div className="label">Alert Preview</div>
              <div className="placeholder">{alarmPreview}</div>
            </div>
          </div>
        </div>
        {/* BUTTONS */}
        <div className="row">
          <div className="col">
            <div className="button-bottom-panel">
              <div className="buttons">
                <button
                  className={`btn btn-info view-button ${!inputsValidated ? "disabled" : ""
                    }`}
                  disabled={inputsValidated === false}
                  onClick={this.updateAlarm}
                >
                  Update Alarm
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default EditAlarmModal;

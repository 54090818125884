import React from 'react';
import {Countdown} from '../../components/Countdown'
import {DocumentTitle} from '../../components/DocumentTitle'
import './Maintenance.scss';

const Maintenance = (props) => {

  const {message, countdownActive, timeFormat, timeTillDate} = props;

  return (
    <div className="ht-100v d-flex align-items-center justify-content-center">
      <DocumentTitle title='Maintenance' />
    <div className=" tx-center pd-x-40 main-wrapper">
      <h1 className="tx-normal tx-inverse tx-roboto mg-b-30 ">
        <div className="signin-logo tx-bold title-wrapper">
          <span className="tx-normal left-bracket">[</span> <span className=''></span><span className="tx-info opn">OPN</span> <span className="tx-normal right-bracket">Buildings ]</span>
          </div> <span className='secondary-title-wrapper'>is down for maintenance.</span>
      </h1>
          <Countdown
            countdownActive={countdownActive}
            message={message}
            timeTillDate={timeTillDate}
            timeFormat={timeFormat}
          />
          <p className="mg-b-10 mg-t-30">We are either fixing something or preparing a new feature.</p>
          <p className="mg-b-30">Thank you for your patience and apologies for the inconvenience!</p>
    </div>
  </div>
  );
};


export default Maintenance;

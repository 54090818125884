import { EventEmitter } from 'events';
import Highcharts from 'highcharts'
import Dispatcher from '../dispatcher';
import ActionTypes from '../constants';
import ChartUtils from '../utils/ChartUtils';
import Constants from '../constants';
import moment from 'moment';

const INVENTORY_FETCHED = 'INVENTORY_FETCHED';

const AUTOMATION_MAPPING_FETCHED = "AUTOMATION_MAPPING_FETCHED";
const AUTOMATION_UNIQUE_POINTS_FETCHED = 'AUTOMATION_UNIQUE_POINTS_FETCHED';
const AUTOMATION_MAPPING_SAVED = 'AUTOMATION_MAPPING_SAVED';
const AUTOMATION_MAPPING_SAVE_FAIL = 'AUTOMATION_MAPPING_SAVE_FAIL';
const ASSESSMENT_REPORT_FETCHED = 'ASSESSMENT_REPORT_FETCHED';
const ASSESSMENT_REPORT_CHART_DATA_FETCHED = 'ASSESSMENT_REPORT_CHART_DATA_FETCHED';
const AUTOMATIC_ASSESSMENT_STARTED = "AUTOMATIC_ASSESSMENT_STARTED";
const AUTOMATIC_ASSESSMENT_START_FAILED = "AUTOMATIC_ASSESSMENT_START_FAILED";
const ASSESSMENT_REPORT_SHARED_SUCCESS = "ASSESSMENT_REPORT_SHARED_SUCCESS";

let _inventory_document_data = null;
let _automatic_assessment_data = null;
let _mapping_data = null;
let _appliances_data = null;
let _saved_mapping = null;
let _energy_performance_classification_report = null;
let _energy_performance_classification_chart_data = {
    HEATING: [],
    COOLING: [],
    HOT_WATER: [],
    VENTILATION: [],
};
// let _energy_performance_classification_chart_data =  null;

class AutomatedAssessmentsStore extends EventEmitter {

    constructor() {
        super();
        // Registers action handler with the Dispatcher.
        Dispatcher.register(this._registerToActions.bind(this));
    }

    // Switches over the action's type when an action is dispatched.
    _registerToActions(action) {
        switch (action.actionType) {
            // Inventory
            case ActionTypes.INVENTORY_FETCHED:
                this._storeInventoryDocumentData(action.payload);
                break;
            // Mapping and Validation
            case ActionTypes.AUTOMATION_MAPPING_FETCHED:
                this._storeMappingDetails(action.payload);
                break;
            case ActionTypes.AUTOMATION_UNIQUE_POINTS_FETCHED:
                this._storeAppliances(action.payload);
                break;
            case ActionTypes.AUTOMATION_MAPPING_SAVED:
                this._storeSavedMapping(action.payload);
                break;
            case ActionTypes.AUTOMATION_MAPPING_SAVE_FAIL:
                this.emit(AUTOMATION_MAPPING_SAVE_FAIL);
                break;
            // Assessment
            case ActionTypes.AUTOMATIC_ASSESSMENT_STARTED:
                this._storeAutomaticAssessmentResponse(action.payload);
                break;
            case ActionTypes.AUTOMATIC_ASSESSMENT_START_FAILED:
                this.emit(AUTOMATIC_ASSESSMENT_START_FAILED);
                break;
            // Report fetched with Token
            case ActionTypes.ASSESSMENT_REPORT_FETCHED:
                this._storeEnergyPerformanceClassificationReport(action.payload);
                break;
            case ActionTypes.ASSESSMENT_REPORT_CHART_DATA_FETCHED:
                this._storeEnergyPerformanceChartData(action.payload, action.category);
                break;
            case ActionTypes.ASSESSMENT_REPORT_SHARED:
                this.emit(ASSESSMENT_REPORT_SHARED_SUCCESS);
                break;
            default:
                break;
        }
    }

    // clear
    clear() {
        _inventory_document_data = null;
        _automatic_assessment_data = null;
        _mapping_data = null;
        _appliances_data = null;
        _saved_mapping = null;
        _energy_performance_classification_report = null;
        _energy_performance_classification_chart_data = null;
    }

    // Inventory fetched 
    addInventoryFetchListener(callback) {
        this.on(INVENTORY_FETCHED, callback);
    }

    removeInventoryFetchListener(callback) {
        this.removeListener(INVENTORY_FETCHED, callback);
    }

    _storeInventoryDocumentData(json) {
        _inventory_document_data = json
        this.emit(INVENTORY_FETCHED);
    }

    getInventoryDocumentData() {
        return _inventory_document_data;
    }

    // Mapping data fetched
    addMappingDataFetchedListener(callback) {
        this.on(AUTOMATION_MAPPING_FETCHED, callback);
    }

    removeMappingDataFetchedListener(callback) {
        this.removeListener(AUTOMATION_MAPPING_FETCHED, callback);
    }

    _storeMappingDetails(json) {
        _mapping_data = json
        this.emit(AUTOMATION_MAPPING_FETCHED);
    }

    getMappingData() {
        return _mapping_data;
    }

    // Appliances data fetched
    addAppliancesDataFetchedListener(callback) {
        this.on(AUTOMATION_UNIQUE_POINTS_FETCHED, callback);
    }

    removeAppliancesDataFetchedListener(callback) {
        this.removeListener(AUTOMATION_UNIQUE_POINTS_FETCHED, callback);
    }

    _storeAppliances(json) {
        _appliances_data = json
        this.emit(AUTOMATION_UNIQUE_POINTS_FETCHED);
    }

    getAppliancesData() {
        return _appliances_data;
    }

    // Save Mapping
    addSavedMappingListener(callback) {
        this.on(AUTOMATION_MAPPING_SAVED, callback);
    }

    removeSavedMappingListener(callback) {
        this.removeListener(AUTOMATION_MAPPING_SAVED, callback);
    }

    addFailedSavedMappingListener(callback) {
        this.on(AUTOMATION_MAPPING_SAVE_FAIL, callback);
    }

    removeFailedSavedMappingListener(callback) {
        this.removeListener(AUTOMATION_MAPPING_SAVE_FAIL, callback);
    }

    _storeSavedMapping(json) {
        _saved_mapping = json;
        this.emit(AUTOMATION_MAPPING_SAVED);
    }

    getSavedMapping() {
        return _saved_mapping;
    }


    // Automatic Assessment
    addAssessmentStartedListener(callback) {
        this.on(AUTOMATIC_ASSESSMENT_STARTED, callback);
    }

    removeAssessmentStartedListener(callback) {
        this.removeListener(AUTOMATIC_ASSESSMENT_STARTED, callback);
    }

    addAssessmentStartFailedListener(callback) {
        this.on(AUTOMATIC_ASSESSMENT_START_FAILED, callback);
    }

    removeAssessmentStartFailedListener(callback) {
        this.removeListener(AUTOMATIC_ASSESSMENT_START_FAILED, callback);
    }

    addAssessmentSharedListener(callback) {
        this.on(ASSESSMENT_REPORT_SHARED_SUCCESS, callback);
    }

    removeAssessmentSharedListener(callback) {
        this.removeListener(ASSESSMENT_REPORT_SHARED_SUCCESS, callback);
    }

    _storeAutomaticAssessmentResponse(json) {
        _automatic_assessment_data = json
        this.emit(AUTOMATIC_ASSESSMENT_STARTED);
    }

    getAutomaticAssessmentData() {
        return _automatic_assessment_data;
    }

    // Report retrieved
    addReportFetchedListener(callback) {
        this.on(ASSESSMENT_REPORT_FETCHED, callback);
    }

    removeReportFetchedListener(callback) {
        this.removeListener(ASSESSMENT_REPORT_FETCHED, callback);
    }

    _storeEnergyPerformanceClassificationReport(json) {
        _energy_performance_classification_report = json;
        this.emit(ASSESSMENT_REPORT_FETCHED)
    }

    getEnergyPerformanceClassificationReport() {
        return _energy_performance_classification_report;
    }

    // Chart Data fetched
    addReportChartDataFetchedListener(callback) {
        this.on(ASSESSMENT_REPORT_CHART_DATA_FETCHED, callback);
    }

    removeReportChartDataFetchedListener(callback) {
        this.removeListener(ASSESSMENT_REPORT_CHART_DATA_FETCHED, callback);
    }

    _storeEnergyPerformanceChartData(chart_data, category) {

        if (chart_data === null) {
            _energy_performance_classification_chart_data[category] = null;
        } else {
            let prepared_chart_data = chart_data.map(chart => {
                    let prepared_data = null;
    
                    if (chart.name === 'Supply Emission Chart') {
                        const building_size = chart.data.building_size;
                        const number_of_zone_setpoints = chart.data.num_zone_setpoints;
    
                        const building_data = [
                            [building_size, number_of_zone_setpoints],
                        ]
    
                        const suboptimal_ranges = [...Array(11)].map(( _ , index) => {
                            let rounded_building_size = Math.round(building_size / 100) * 100;
                            let modifier = 0;
                            if (rounded_building_size > 500)  modifier = -500 + (100 * index);
                            if (rounded_building_size < 500)  modifier = -250 + (50 * index);
                            const size = rounded_building_size + modifier;
                            const setpoints_range = Math.round(size / 100);
                            return [ size, 0 , setpoints_range]
                        })
    
                        const maxFromSetpoints = Math.round(number_of_zone_setpoints * 1.2);
                        const maxFromSuboptimals = Math.round(suboptimal_ranges[10][2] * 1.2);
                        const yMax = maxFromSetpoints > maxFromSuboptimals ? maxFromSetpoints : maxFromSuboptimals;
    
                        const optimal_ranges = suboptimal_ranges.map(num => {
                            return [num[0], num[2] + 1, yMax + 100000]
                        })
    
                        prepared_data = [
                        {
                            name: 'Your Building',
                            data: building_data,
                            zIndex: 1,
                            marker: {
                                fillColor: 'white',
                                lineWidth: 4,
                                lineColor: 'Black'
                            }
                        },
                        {
                            name: 'Suboptimal Range',
                            data: suboptimal_ranges,
                            type: 'arearange',
                            lineWidth: 0,
                            color: '#F8817D',
                            zIndex: 0,
                            marker: {
                                enabled: false
                            }
                        },
                        {
                            name: 'Optimal Range',
                            enableMouseTracking: false,
                            data: optimal_ranges,
                            type: 'arearange',
                            lineWidth: 0,
                            color: '#4B947E',
                            zIndex: 0,
                            marker: {
                                enabled: false
                            }
                        }
                        ]
    
                    } else {
                        prepared_data = chart.data.label_values.map((serie, index) => {
                            let serie_data = ChartUtils.getLineConsumptionSeries(serie.label, Highcharts.getOptions().colors[index], Constants.SOLID, 1, true, true);
    
                            serie.values.forEach(entry => {
                                const timestamp = moment(entry.timestamp).valueOf()
                                const value = entry.value
    
                                serie_data.data.push([timestamp, value])
                            })
    
                            return serie_data;
                        })
                    }
    
                    return {
                        name: chart.name,
                        description: chart.description,
                        data: prepared_data,
                    }
                })
    
            _energy_performance_classification_chart_data[category] = prepared_chart_data;
        }

        this.emit(ASSESSMENT_REPORT_CHART_DATA_FETCHED)
    }

    getEnergyPerformanceChartData() {
        return _energy_performance_classification_chart_data;
    }

}

const automatedAssessmentsStore = new AutomatedAssessmentsStore();
export default automatedAssessmentsStore;

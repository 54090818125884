import React, { PureComponent } from "react";
import PropTypes from "prop-types";

class DoubleRowCell extends PureComponent {
  render() {
    const noData = (
      <td className="tx-14 not-available" style={{ color: "#8f8f8f" }}>
        N/A
      </td>
    );

    const defaultFormat = (
      <td className="tx-14">
        {this.props.firstRow && <div>{this.props.firstRow}</div>}
        {this.props.secondRow && (
          <div style={{ color: "#8f8f8f", fontSize: "12px" }}>
            {this.props.secondRow}
          </div>
        )}
      </td>
    );

    if (!this.props.firstRow && !this.props.secondRow) {
      return noData;
    } else {
      return defaultFormat;
    }
  }
}

DoubleRowCell.propTypes = {
  firstRow: PropTypes.any,
  secondRow: PropTypes.any,
};

export default DoubleRowCell;

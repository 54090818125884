import React, { useEffect } from "react";
import PropTypes from "prop-types";
import "./PasswordChecker.scss";
import { Icon } from "../Icon";

const PasswordChecker = (props) => {
  const { password, confirmPassword, setPasswordValidation } = props;

  // password checks
  const atLeast10Characters = password.length >= 10;
  const oneLowercaseCharacter = /(.*[a-z].*)/.test(password);
  const oneUppercaseCharacter = /(.*[A-Z].*)/.test(password);
  const oneDigitCharacter = /(.*[0-9].*)/.test(password);
  const passwordsMatch = confirmPassword.length && password === confirmPassword;
  const passwordValidated =
    atLeast10Characters &&
    oneLowercaseCharacter &&
    oneUppercaseCharacter &&
    oneDigitCharacter &&
    passwordsMatch;

  useEffect(() => {
    if (passwordValidated) {
      setPasswordValidation(true);
    } else {
      setPasswordValidation(false);
    }
  }, [setPasswordValidation, passwordValidated, password]);

  const getIcon = (regexTest) => {
    if (regexTest === true) {
      return (
        <span className="icon-wrapper-checked mg-r-10">
          <Icon name="CheckCircleOutline" color='green' />
        </span>
      );
    } else {
      return (
        <span className="icon-wrapper-unchecked mg-r-10">
          <Icon name="Circle" color='#868ba1' />
        </span>
      );
    }
  };

  return (
    <div id="PasswordChecker" className="pd-t-20 pd-l-10">
      <div className="mg-b-3">
        {getIcon(atLeast10Characters)}At least 10 characters
      </div>
      <div className="mg-b-3">
        {getIcon(oneLowercaseCharacter)}At least one lowercase character
      </div>
      <div className="mg-b-3">
        {getIcon(oneUppercaseCharacter)}At least one uppercase character
      </div>
      <div className="mg-b-3">
        {getIcon(oneDigitCharacter)}At least one digit
      </div>
      <div className="mg-b-3">{getIcon(passwordsMatch)}Passwords match</div>
    </div>
  );
};

PasswordChecker.propTypes = {
  password: PropTypes.string.isRequired,
  confirmPassword: PropTypes.string.isRequired,
  setPasswordValidation: PropTypes.func.isRequired,
};

export default PasswordChecker;

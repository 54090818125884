import React from "react";
import PropTypes from "prop-types";
import "./ButtonToggle.scss";

const ButtonToggle = ({
  id,
  name,
  checked,
  onChange,
  optionLabels,
  small,
  disabled,
  colorLeft,
  colorRight,
}) => {
  function handleKeyPress(e) {
    if (e.keyCode !== 32) return;

    e.preventDefault();
    onChange(!checked);
  }

  return (
    <div
      className={"button-toggle" + (small ? " small-switch" : "")}
      style={{
        "--colorLeft": colorLeft,
        "--colorRight": colorRight,
      }}
    >
      <input
        type="checkbox"
        name={name}
        className="button-toggle-checkbox"
        id={id}
        checked={checked}
        onChange={onChange}
        disabled={disabled}
      />
      {id ? (
        <label
          className="button-toggle-label"
          tabIndex={disabled ? -1 : 1}
          onKeyDown={(e) => handleKeyPress(e)}
          htmlFor={id}
        >
          <span
            className={
              disabled
                ? "button-toggle-inner button-toggle-disabled"
                : "button-toggle-inner"
            }
            data-yes={optionLabels[0]}
            data-no={optionLabels[1]}
            tabIndex={-1}
          />
          <span
            className={
              disabled
                ? "button-toggle-switch button-toggle-disabled"
                : "button-toggle-switch"
            }
            tabIndex={-1}
          />
        </label>
      ) : null}
    </div>
  );
};

// Set optionLabels for rendering.
ButtonToggle.defaultProps = {
  optionLabels: ["Yes", "No"],
  colorLeft: "#bbb",
  colorRight: "#bbb",
};

ButtonToggle.propTypes = {
  id: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string,
  colorLeft: PropTypes.string,
  colorRight: PropTypes.string,
  optionLabels: PropTypes.array,
  small: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default ButtonToggle;

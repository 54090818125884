import React, { useState, useCallback, useEffect } from 'react';
import { DocumentTitle } from '../../components/DocumentTitle';
import { LogoSpinner } from '../../components/LogoSpinner';
import { TimeframePicker } from '../../components/TimeframePicker';
import { PropertyTable } from '../../components/PropertyTable';
import { Icon } from '../../components/Icon';
import Constants from '../../constants';
import moment from 'moment';
import styles from './DataExporter.module.scss';

// Add imports
import classNames from 'classnames';
import { Button, Typography } from 'antd';
import DataExporterActions from '../../actions/dataExporterActions';

const { Title, Text } = Typography;

const EXCLUDED_PROPERTY_IDS = [538, 539, 540, 551, 552, 553]
const MAX_DATA_POINTS = 600000;

const DataExporter = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    // Add state management
    const [granularity, setGranularity] = useState(Constants.BUCKET_WEEK);
    const [tsStart, setTsStart] = useState(moment().startOf('isoWeek').format(Constants.DATA_EXPLORER_DATE_FORMAT));
    const [tsEnd, setTsEnd] = useState(moment().startOf('isoWeek').add(6, 'days').format(Constants.DATA_EXPLORER_DATE_FORMAT));
    const [bucketSize, setBucketSize] = useState(Constants.BUCKET_FIFTEEN_MIN);

    // Add properties
    const [properties, setProperties] = useState([]);
    const [selectedProperties, setSelectedProperties] = useState([]);

    const createSearchInterval = useCallback(() => {
        const extendedEnd = moment(tsEnd).add(1, 'days');
        return {
            start: tsStart,
            end: extendedEnd
        }
    }, [tsStart, tsEnd]);

    const fetchData = async () => {
        try {
            setLoading(true)
            setError(null)
            await DataExporterActions.getExportData({
                bucket_type: bucketSize,
                search_interval: createSearchInterval(),
                data_points: selectedProperties.map(prop => ({
                    entity_type_id: prop.entity.entity_type_id,
                    property_id: prop.property.property_id
                }))
            });
        } catch (error) {
            console.error('Failed to fetch data:', error.code, error.type);
            setError(`${error.code} - ${error.type}`);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        const fetchProperties = async () => {
            try {
                setLoading(true);
                setError(null);
                let data = await DataExporterActions.getProperties();
                data = data.filter(item => !EXCLUDED_PROPERTY_IDS.includes(item.property.property_id));
                setProperties(data);
            } catch (error) {
                setError(`Failed to fetch table properties: ${error.code} - ${error.type}`);
                console.error('Failed to fetch properties:', error.code, error.type);
            } finally {
                setLoading(false);
            }
        };

        if (properties.length === 0) fetchProperties();

    }, [properties]);



    const getTimeframePicker = () => {

        return (
            <div className={styles.timeframePickerSection}>
                <div className={styles.timeframePickerHeader}>
                    <div className={styles.headerTitle}>Date</div>
                </div>
                <TimeframePicker
                    start={tsStart}
                    end={tsEnd}
                    defaultGranularity={granularity.toLowerCase()}
                    onChange={(start, end, granularity) => setAbsoluteTimeframe(start, end, granularity)}
                    granularities={['custom', 'day', 'week', 'month', 'year']}
                    spacing="10px"
                    backgroundColor='#f3f3f3'
                    buttonColor='#5e656e'
                />
            </div>
        );
    }

    const setAbsoluteTimeframe = (start, end, newGranularity) => {
        setTsStart(start);
        setTsEnd(end);
        setGranularity(newGranularity.toUpperCase());
    };

    const getTimeBucketPicker = () => {

        const bucketSizes = [
            { value: Constants.BUCKET_FIFTEEN_MIN, label: '15 Min' },
            { value: Constants.BUCKET_HOUR, label: 'Hour' },
            { value: Constants.BUCKET_DAY, label: 'Day' },
            { value: Constants.BUCKET_WEEK, label: 'Week' },
            { value: Constants.BUCKET_MONTH, label: 'Month' },
            { value: Constants.BUCKET_YEAR, label: 'Year' },
        ]

        return (
            <div className={styles.bucketPickerSection}>
                <div className={styles.buttonPickerHeader}>
                    <div className={styles.headerTitle}>Time Bucket</div>
                </div>
                <div className={styles.buttonWrapper}>
                    {bucketSizes.map((size) => {
                        return <Button
                            key={size.label}
                            size="small"
                            onClick={() => size.disabled ? undefined : setBucketSize(size.value)}
                            type={bucketSize === size.value ? 'primary' : 'text'}
                            disabled={size.disabled}
                            className={classNames({
                                [styles.activeButton]: bucketSize === size.value,
                                [styles.disabledButton]: size.disabled,
                            })}
                        >
                            {size.label}
                        </Button>
                    })}
                </div>

            </div>
        );
    }

    const getSelectedProperties = () => {
        return (
            <div className={styles.selectedPropertiesSection}>
                <div className={styles.buttonPickerHeader}>
                    <div className={styles.headerTitle}>Selected Properties</div>
                </div>
                <div className={styles.selectedPropertiesList}>
                    {selectedProperties.length === 0 ? (
                        <Text type="secondary">No properties selected</Text>
                    ) : (
                        selectedProperties.map((prop, index) => (
                            <div key={index} className={styles.selectedProperty} onClick={() => handleSelectedRows(prop)}>
                                <Button
                                    type="text"
                                    size="small"
                                    icon={<Icon name="Delete" size={14} color="#5e656e" />}
                                />
                                <Text>{prop.property.name} - {prop.entity.name} - {prop.building.name}</Text>
                            </div>
                        ))
                    )}
                </div>
            </div>
        );
    }

    const getPropertyTable = () => {
        return <div style={{ height: '100%', width: '100%', overflow: 'hidden', minHeight: '500px' }}>
            <PropertyTable
                height='100%'
                selectedRows={selectedProperties}
                onRowSelectionChange={handleSelectedRows}
                clearAllSelections={clearAllSelections}
                data={properties}
                headers={[
                    { label: 'Building', accessor: 'building.name', dropdown: true, filterable: true, width: 25 },
                    { label: 'Type', accessor: 'entity.type', dropdown: true, width: 15 },
                    { label: 'Entity', accessor: 'entity.name', dropdown: true, filterable: true, width: 30 },
                    { label: 'Property', accessor: 'property.name', dropdown: true, filterable: true, width: 30 }
                ]}

            />

        </div>
    }

    const handleSelectedRows = (selectedRow) => {
        // Check if an object with the same identifiers already exists in the array
        const existing = selectedProperties.find(obj =>
            obj.entity.entity_type_id === selectedRow.entity.entity_type_id && obj.property.property_id === selectedRow.property.property_id
        );

        if (existing) {
            const updatedProperties = selectedProperties.filter(obj =>
                !(obj.entity.entity_type_id === selectedRow.entity.entity_type_id && obj.property.property_id === selectedRow.property.property_id)
            );
            setSelectedProperties(updatedProperties);
        } else {
            const updatedProperties = [...selectedProperties, selectedRow]; // Store the entire selectedRow object
            setSelectedProperties(updatedProperties); // Update state to trigger re-render
        }
    }

    const clearAllSelections = () => {
        setSelectedProperties([]);
    }

    const calculateDataPoints = useCallback(() => {
        const start = moment(tsStart).subtract(1, 'days');
        const end = moment(tsEnd);
        const now = moment();

        const effectiveEnd = end.isAfter(now) ? now : end;
        const diffInMinutes = effectiveEnd.diff(start, 'minutes');

        let pointsPerProperty;
        switch (bucketSize) {
            case Constants.BUCKET_FIFTEEN_MIN:
                pointsPerProperty = Math.ceil(diffInMinutes / 15);
                break;
            case Constants.BUCKET_HOUR:
                pointsPerProperty = Math.ceil(diffInMinutes / 60);
                break;
            case Constants.BUCKET_DAY:
                pointsPerProperty = Math.ceil(diffInMinutes / (60 * 24));
                break;
            case Constants.BUCKET_WEEK:
                pointsPerProperty = Math.ceil(diffInMinutes / (60 * 24 * 7));
                break;
            case Constants.BUCKET_MONTH:
                pointsPerProperty = Math.ceil(diffInMinutes / (60 * 24 * 30));
                break;
            case Constants.BUCKET_YEAR:
                pointsPerProperty = Math.ceil(diffInMinutes / (60 * 24 * 365));
                break;
            default:
                pointsPerProperty = 0;
        }

        return pointsPerProperty * selectedProperties.length;
    }, [tsStart, tsEnd, bucketSize, selectedProperties.length]);

    const isButtonDisabled = () => {
        return selectedProperties.length === 0 || loading || calculateDataPoints() > MAX_DATA_POINTS;
    }

    return (
        <div className="br-mainpanel br-profile-page floorplan-background">
            <DocumentTitle title="Data Exporter" />
            <LogoSpinner loading={loading} />
            <div className="br-container">
                <div className="row mg-t-30">
                    <div className="col-12">
                        <Title level={3}>Data Exporter</Title>
                        <Text type='secondary'>
                            Export data from the selected properties.
                        </Text>
                    </div>
                </div>
                <div className='row mg-t-30' style={{ gap: '15px 0', background: 'white', padding: '20px', borderRadius: '12px' }}>
                    <div className={`${styles.leftConfig} col-12 col-lg-4 `}>
                        <div className='row' style={{ gap: '15px 0' }}>
                            <div className={`${styles.timeframeWrapper} col-12 col-md-6 col-lg-12`}>
                                {getTimeframePicker()}
                            </div>
                            <div className={`${styles.bucketWrapper} col-12 col-md-6 col-lg-12`}>
                                {getTimeBucketPicker()}
                            </div>
                            <div className={`${styles.selectedPropertiesWrapper} col-12 col-md-6 col-lg-12`}>
                                {getSelectedProperties()}
                            </div>
                        </div>
                    </div>
                    <div className={`${styles.rightConfig} col-12 col-lg-8 mg-b-15`}>
                        {getPropertyTable()}
                    </div>
                    <div className='col-12'>
                        <div className='d-flex'>
                            {selectedProperties.length > 0 && (
                                <Text type={calculateDataPoints() > MAX_DATA_POINTS ? "danger" : "secondary"} style={{ marginRight: '15px', alignSelf: 'center' }}>
                                    Estimated data points: {calculateDataPoints().toLocaleString()}{calculateDataPoints() > MAX_DATA_POINTS ? ` - You've reached the limit of potential data points (Max ${MAX_DATA_POINTS.toLocaleString()}).` : ''}
                                </Text>
                            )}
                            {error && (
                                <Text type="danger" style={{ marginRight: '15px', alignSelf: 'center' }}>
                                    {error}
                                </Text>
                            )}
                            <Button
                                type="primary"
                                size='large'
                                icon={<Icon name="Download" color={isButtonDisabled() ? '#c0c0c0' : '#fff'} size={18} />}
                                onClick={fetchData}
                                disabled={isButtonDisabled()}
                                style={{ marginLeft: 'auto' }}
                            >
                                Export Data
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DataExporter;
import ActionTypes from "../constants";
import GeneralUtils from "../utils/GeneralUtils";

/**
 * Actions related to managing meters. This class interfaces with the API
 * to retrieve, create, update, and delete meter data, dispatching actions to update the application state.
 *
 * @class
 * @example
 * const meterActions = new MeterActions();
 * meterActions.saveMeter({ /* meter data *\/ });
 */
class MeterActions {
  /**
   * Saves a new meter to the API and dispatches an action to store the new meter data.
   *
   * @param {Object} meter - The meter object to be saved.
   * @returns {Promise} - A promise that resolves with the response of the save operation.
   */
  saveMeter(meter) {
    return GeneralUtils.post({
      url: "meter",
      object: meter,
      actionType: ActionTypes.METER_SAVED,
      successToast: "Meter saved successfully",
      failToast: "Unable to save meter, please try again",
    });
  }

  /**
   * Updates an existing meter in the API and dispatches an action to store the updated data.
   *
   * @param {Object} meter - The meter object to be updated.
   * @returns {Promise} - A promise that resolves with the response of the update operation.
   */
  updateMeter(meter) {
    if (meter.asset_id === 0) {
      meter.asset_id = null;
    }

    return GeneralUtils.put({
      url: "meter",
      object: meter,
      actionType: ActionTypes.METER_UPDATED,
      successToast: "Meter updated successfully",
      failToast: "Unable to save meter, please try again",
    });
  }

  /**
   * Deletes a specific meter by its ID from the API and dispatches an action to remove this data from the state.
   *
   * @param {string} meterId - The unique identifier of the meter to be deleted.
   * @returns {Promise} - A promise that resolves with the response of the delete operation.
   */
  deleteMeter(meterId) {
    return GeneralUtils.delete({
      url: `meter/${meterId}`,
      actionType: ActionTypes.METER_DELETED,
      successToast: "Meter deleted successfully",
      failToast: "Unable to delete meter, please try again",
    });
  }

  /**
   * Fetches the meters for a specific building from the API and dispatches an action to store this data.
   *
   * @param {string} buildingId - The unique identifier of the building.
   * @returns {Promise} - A promise that resolves with the fetched data.
   */
  getMetersByBuildingId(buildingId) {
    return GeneralUtils.get({
      url: `meter/building/${buildingId}`,
      actionType: ActionTypes.METERS_FETCHED,
    });
  }
}

const meterActions = new MeterActions();
export default meterActions;


import React, { Component } from 'react';
import './DialChart.scss';

class DialChart extends Component {
  constructor(props) {
    super(props);
    this.containerRef = React.createRef();
    
    this.state = {
      loading: false,
      color: this.props.color || 'black'
    };
    
    this.handleResize = this.handleResize.bind(this)
    this.getChart = this.getChart.bind(this)
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize)
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize)
  }

  handleResize(e) {
    this.setState({ radiusWidth: this.containerRef.current && (this.containerRef.current.clientHeight * 2) })
  }

  getChart() {

    const width = this.state.radiusWidth || (this.containerRef.current && (this.containerRef.current.clientHeight * 2));

    const getBackground = (step) => {
      if (((this.props.goalValue / 11) * step) <= this.props.currentValue) return this.state.color;
      return '#cccccc'
    }

    let message = null;
    if (this.props.goalValue === 0) {
      message = <div className='message' style={{color: '#cccccc'}} >No Data</div>
    } else if (this.props.goalValue <= this.props.currentValue) {
      message = <div className='message' style={{fontSize: width/12, color: this.state.color}}>Goal Reached</div>    
    }

    return <div className='DialChartContainer'>
    <div className='base' ref={this.containerRef} >
      <div className='radius' style={{width: width}}>
        <div className='top' style={{width: this.props.milestoneLength || '20%', background: getBackground(11)}}></div>
        <div></div>
      </div>
      <div className='radius'  style={{width: width}}>
        <div></div>
        <div className='bottom' style={{width: this.props.milestoneLength || '20%', background: getBackground(2)}}></div>
      </div>
      <div className='radius'  style={{width: width}}>
        <div></div>
        <div className='bottom' style={{width: this.props.milestoneLength || '20%', background: getBackground(3)}}></div>
      </div>
      <div className='radius'  style={{width: width}}>
        <div></div>
        <div className='bottom' style={{width: this.props.milestoneLength || '20%', background: getBackground(4)}}></div>
      </div>
      <div className='radius'  style={{width: width}}>
        <div></div>
        <div className='bottom' style={{width: this.props.milestoneLength || '20%', background: getBackground(5)}}></div>
      </div>
      <div className='radius'  style={{width: width}}>
        <div></div>
        <div className='bottom' style={{width: this.props.milestoneLength || '20%', background: getBackground(6)}}></div>
      </div>
      <div className='radius'  style={{width: width}}>
        <div></div>
        <div className='bottom' style={{width: this.props.milestoneLength || '20%', background: getBackground(7)}}></div>
      </div>
      <div className='radius'  style={{width: width}}>
        <div></div>
        <div className='bottom' style={{width: this.props.milestoneLength || '20%', background: getBackground(8)}}></div>
      </div>
      <div className='radius'  style={{width: width}}>
        <div></div>
        <div className='bottom' style={{width: this.props.milestoneLength || '20%', background: getBackground(9)}}></div>
      </div>
      <div className='radius'  style={{width: width}}>
        <div></div>
        <div className='bottom' style={{width: this.props.milestoneLength || '20%', background: getBackground(10)}}></div>
      </div>
      <div className='radius'  style={{width: width}}>
        <div></div>
        <div className='bottom' style={{width: this.props.milestoneLength || '20%', background: getBackground(1)}}></div>
      </div>
      {message}
    </div>
  </div>
  }

  render() {
    return this.getChart();
  }

}
export default DialChart;
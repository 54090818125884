import { EventEmitter } from "events";
import Dispatcher from "../dispatcher";
import ActionTypes from "../constants";

const CHANGE = "METER_CHANGE";
const METER_OPERATION = "METER_OPERATION";

let _meters = [];

class MeterStore extends EventEmitter {
  constructor() {
    super();
    // Registers action handler with the Dispatcher.
    Dispatcher.register(this._registerToActions.bind(this));
  }

  // Switches over the action's type when an action is dispatched.
  _registerToActions(action) {
    switch (action.actionType) {
      case ActionTypes.METERS_FETCHED:
        this._storeMeters(action.payload);
        break;
      case ActionTypes.METER_SAVED:
        this._saveMeter(action.payload);
        break;
      case ActionTypes.METER_UPDATED:
        this._updateMeter(action.payload);
        break;
      case ActionTypes.METER_DELETED:
        this._deleteMeter(action.payload);
        break;
      default:
        break;
    }
  }

  // Hooks a React component's callback to the CHANGED event.
  addChangeListener(callback) {
    this.on(CHANGE, callback);
  }
  addMeterOperationListener(callback) {
    this.on(METER_OPERATION, callback);
  }

  // Removes the listener from the CHANGED event.
  removeChangeListener(callback) {
    this.removeListener(CHANGE, callback);
  }
  removeMeterOperationListener(callback) {
    this.removeListener(METER_OPERATION, callback);
  }

  clear() {
    _meters = [];
  }

  _storeMeters(json) {
    _meters = [];
    if (json != null && json.length > 0) {
      _meters = json;
    }
    this.emit(CHANGE);
  }

  _saveMeter() {
    this.emit(METER_OPERATION);
  }
  _updateMeter() {
    this.emit(METER_OPERATION);
  }
  _deleteMeter() {
    this.emit(METER_OPERATION);
  }

  // Getters

  getMeters() {
    return _meters;
  }
}

const meterStore = new MeterStore();
export default meterStore;

import React, { Component } from "react";
import "./PageTitle.scss";
import PropTypes from "prop-types";
import {Icon} from "../Icon";

class PageTitle extends Component {
  render() {
    return (
      <div id={"PageTitle"}>
        {this.props.icon && (
          <div className="pagetitle-detail-icon" style={{ paddingLeft: "0px" }}>
            <Icon
              name={this.props.icon}
              size={54}
            />
          </div>
        )}
        <div className="title-section">
          {this.props.subtitle ? (
            <h4 className="pagetitle-title tx-archivo-bold">
              {this.props.title} - {this.props.subtitle}
            </h4>
          ) : (
            <h4 className="pagetitle-title tx-archivo-bold">
              {this.props.title}
            </h4>
          )}
          <p className="mg-b-0">{this.props.description}</p>
        </div>
        {this.props.buttons && (
          <div className="tag-filter">
            {this.props.buttons.map((tag) => {
              let classes = "tag tx-archivo-medium";
              if (tag.trueCondition) {
                classes += " active";
              }

              return (
                <div
                  key={tag.label}
                  className={classes}
                  onClick={tag.clickHandler ? tag.clickHandler : undefined}
                >
                  {tag.label}
                </div>
              );
            })}
          </div>
        )}
      </div>
    );
  }
}

PageTitle.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  description: PropTypes.string,
  icon: PropTypes.element,
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      trueCondition: PropTypes.bool,
      clickHandler: PropTypes.func,
    })
  ),
};

export default PageTitle;

import { EventEmitter } from "events";
import Dispatcher from "../dispatcher";
import ActionTypes from "../constants";
import Constants from "../constants";

import GeneralUtils from "../utils/GeneralUtils";
import ChartUtils from "../utils/ChartUtils";
import moment from "moment";

let _raw_meters = [];
let _meters = [];
let _buildings = [];
let _meter_catalogue = [];
let _multiple_meter_consumptions = [];
let _comparison_multiple_meter_consumptions = [];

const METERS_EXPLORER_METERS_FETCHED =
  ActionTypes.METERS_EXPLORER_METERS_FETCHED;
const METERS_EXPLORER_CHART_DATA_FETCHED =
  ActionTypes.METERS_EXPLORER_CHART_DATA_FETCHED;
const METERS_EXPLORER_COMPARISON_CHART_DATA_FETCHED =
  ActionTypes.METERS_EXPLORER_COMPARISON_CHART_DATA_FETCHED;
const METERS_EXPLORER_BUILDINGS_FETCHED =
  ActionTypes.METERS_EXPLORER_BUILDINGS_FETCHED;
const METERS_EXPLORER_CATALOGUE_CREATED =
  ActionTypes.METERS_EXPLORER_CATALOGUE_CREATED;

class MetersExplorerStore extends EventEmitter {
  constructor() {
    super();
    // Registers action handler with the Dispatcher.
    Dispatcher.register(this._registerToActions.bind(this));
  }

  clear() {
    _raw_meters = [];
    _meters = [];
    _buildings = [];
    _meter_catalogue = [];
    _multiple_meter_consumptions = [];
    _comparison_multiple_meter_consumptions = [];
  }

  // Switches over the action's type when an action is dispatched.
  _registerToActions(action) {
    switch (action.actionType) {
      case METERS_EXPLORER_METERS_FETCHED:
        this.storeMeters(action.payload);
        break;
      case METERS_EXPLORER_BUILDINGS_FETCHED:
        this.storeBuildings(action.payload);
        break;
      case METERS_EXPLORER_CHART_DATA_FETCHED:
        this.storeMeterChartData(action.payload);
        break;
      case METERS_EXPLORER_COMPARISON_CHART_DATA_FETCHED:
        this.storeComparisonMeterChartData(action.payload, action.isComparison);
        break;
      default:
        break;
    }
  }

  // Fetching all meters for the user.
  addMetersFetchedListener(callback) {
    this.on(METERS_EXPLORER_METERS_FETCHED, callback);
  }

  removeMetersFetchedListener(callback) {
    this.removeListener(METERS_EXPLORER_METERS_FETCHED, callback);
  }

  storeMeters(json) {
    _meters = [];
    _raw_meters = [];

    if (json) {
      let allMeters = json;
      allMeters.forEach(function (meter) {

        meter.submeters = [];
        meter.expanded = false;
        json.forEach(function (m) {
          if (m.parent_id === meter.meter_id) {
            meter.submeters.push(m);
            _raw_meters.push(m);
          }
        });
        if (meter.parent_id == null || meter.parent_id === 0) {
          _meters.push(meter);
          _raw_meters.push(meter);
        }

      });
    }

    this.createCatalogue();
    this.emit(METERS_EXPLORER_METERS_FETCHED);
  }

  getMeters() {
    return _meters;
  }
  getRawMeters() {
    return _raw_meters;
  }

  // Fetching all buildings for the user.
  addBuildingsFetchedListener(callback) {
    this.on(METERS_EXPLORER_BUILDINGS_FETCHED, callback);
  }

  removeBuildingsFetchedListener(callback) {
    this.removeListener(METERS_EXPLORER_BUILDINGS_FETCHED, callback);
  }

  storeBuildings(json) {
    _buildings = json;
    this.createCatalogue();
    this.emit(METERS_EXPLORER_BUILDINGS_FETCHED);
  }

  getBuildings() {
    return _buildings;
  }

  // Create a catalogue
  addCatalogueCreatedListener(callback) {
    this.on(METERS_EXPLORER_CATALOGUE_CREATED, callback);
  }

  removeCatalogueCreatedListener(callback) {
    this.removeListener(METERS_EXPLORER_CATALOGUE_CREATED, callback);
  }

  createCatalogue() {
    let catalogue = _buildings
      .map((b) => {
        return {
          ...b,
          expanded: false,
          meters: _meters.filter((m) => m.building_id === b.building_id),
        };
      })
      .filter((b) => b.meters.length > 0);

    _meter_catalogue = catalogue;
    this.emit(METERS_EXPLORER_CATALOGUE_CREATED);
  }

  getCatalogue() {
    return _meter_catalogue;
  }

  // Chart Data

  addChartDataListener(callback) {
    this.on(METERS_EXPLORER_CHART_DATA_FETCHED, callback);
  }

  removeChartDataListener(callback) {
    this.removeListener(METERS_EXPLORER_CHART_DATA_FETCHED, callback);
  }

  storeMeterChartData(data) {
    const json = data.isDataExplorerCall ? this.transformToSourceFormat(data, data.main_meter_id) : data.response;

    let aggregate = [];
    _multiple_meter_consumptions = [];

    if (
      json.main_meter &&
      json.main_meter.consumptions &&
      json.main_meter.consumptions.length > 0
    ) {
      let meterConsumption = ChartUtils.getLineConsumptionSeries(
        json.main_meter.name,
        null,
        Constants.SOLID,
        3,
        true,
        true
      );

      meterConsumption.meter_id = json.main_meter.meter_id;

      let consumptions = json.main_meter.consumptions;
      consumptions.forEach(function (consumption) {
        let date = moment(consumption.timestamp);

        meterConsumption.data.push([
          date.valueOf(),
          GeneralUtils.roundNumber(consumption.actual_consumption, 1),
        ]);
      });

      aggregate.push(meterConsumption);
    }

    if (json.comparison_meters && json.comparison_meters.length > 0) {
      for (let x = 0; x < json.comparison_meters.length; x++) {
        let meter = json.comparison_meters[x];
        if (meter && meter.consumptions && meter.consumptions.length > 0) {
          let meterConsumption = ChartUtils.getLineConsumptionSeries(
            meter.name,
            null,
            Constants.SOLID,
            3,
            true,
            true
          );

          meterConsumption.meter_id = meter.meter_id;

          let consumptions = meter.consumptions;
          consumptions.forEach(function (consumption) {
            let date = moment(consumption.timestamp);

            meterConsumption.data.push([
              date.valueOf(),
              GeneralUtils.roundNumber(consumption.actual_consumption, 1),
            ]);
          });

          aggregate.push(meterConsumption);
        }
      }
    }

    _multiple_meter_consumptions = [...aggregate];

    this.emit(METERS_EXPLORER_CHART_DATA_FETCHED);
  }

  getChartData() {
    return _multiple_meter_consumptions;
  }

  // Comparison Data calls

  addComparisonChartDataListener(callback) {
    this.on(METERS_EXPLORER_COMPARISON_CHART_DATA_FETCHED, callback);
  }

  removeComparisonChartDataListener(callback) {
    this.removeListener(
      METERS_EXPLORER_COMPARISON_CHART_DATA_FETCHED,
      callback
    );
  }

  storeComparisonMeterChartData(data) {
    const json = data.isDataExplorerCall ? this.transformToSourceFormat(data, data.main_meter_id) : data.response;

    let aggregate = [];
    _comparison_multiple_meter_consumptions = [];

    if (
      json.main_meter &&
      json.main_meter.consumptions &&
      json.main_meter.consumptions.length > 0
    ) {
      let meterConsumption = ChartUtils.getLineConsumptionSeries(
        json.main_meter.name,
        null,
        Constants.SOLID,
        3,
        true,
        true
      );

      meterConsumption.meter_id = json.main_meter.meter_id;

      let consumptions = json.main_meter.consumptions;
      consumptions.forEach(function (consumption) {
        let date = moment(consumption.timestamp);

        meterConsumption.data.push([
          date.valueOf(),
          GeneralUtils.roundNumber(consumption.actual_consumption, 1),
        ]);
      });

      aggregate.push(meterConsumption);
    }

    if (json.comparison_meters && json.comparison_meters.length > 0) {
      for (let x = 0; x < json.comparison_meters.length; x++) {
        let meter = json.comparison_meters[x];
        if (meter && meter.consumptions && meter.consumptions.length > 0) {
          let meterConsumption = ChartUtils.getLineConsumptionSeries(
            meter.name,
            null,
            Constants.SOLID,
            3,
            true,
            true
          );

          meterConsumption.meter_id = meter.meter_id;

          let consumptions = meter.consumptions;
          consumptions.forEach(function (consumption) {
            let date = moment(consumption.timestamp);

            meterConsumption.data.push([
              date.valueOf(),
              GeneralUtils.roundNumber(consumption.actual_consumption, 1),
            ]);
          });

          aggregate.push(meterConsumption);
        }
      }
    }

    _comparison_multiple_meter_consumptions = [...aggregate];

    this.emit(METERS_EXPLORER_COMPARISON_CHART_DATA_FETCHED);
  }

  getComparisonChartData() {
    return _comparison_multiple_meter_consumptions;
  }

  // helper to transform the Data Explorer response to Meter Explorer response
  transformToSourceFormat(copyData) {

    // No data returns empty object
    if (copyData.response.length === 0) {
      return {};
    }

    const mainMeterData = copyData.response.find(item =>
      item.entity_type.entity_id === copyData.main_meter_id
    );

    const comparisonMeterData = copyData.response.filter(item =>
      item.entity_type.entity_id !== copyData.main_meter_id
    );

    function transformMeterData(meterData) {
      return {
        meter_id: meterData.entity_type.entity_id,
        name: meterData.entity_type.name,
        consumptions: meterData.data.map(item => ({
          timestamp: item.timestamp,
          actual_consumption: item.value,
        }))
      }
    }

    const output = {
      main_meter: transformMeterData(mainMeterData),
      comparison_meters: comparisonMeterData.map(meter => transformMeterData(meter))
    };

    return output;
  }
}

const metersExplorerStore = new MetersExplorerStore();
export default metersExplorerStore;

import { EventEmitter } from 'events';
import Dispatcher from '../dispatcher';
import ActionTypes from '../constants';

const TOKEN_FETCHED = 'TOKEN_FETCHED';
const TOKENS_BULK_FETCHED = 'TOKENS_BULK_FETCHED';
const CHANGE = 'ZONE_KPI_FETCHED';

let _zone_kpi = {};
let _tokens_bulk = []
let _token = null;

class QrStore extends EventEmitter {

    constructor() {
        super();
        // Registers action handler with the Dispatcher.
        Dispatcher.register(this._registerToActions.bind(this));
    }

    // Switches over the action's type when an action is dispatched.
    _registerToActions(action) {
        switch (action.actionType) {
            case ActionTypes.QR_TOKEN_FETCHED:
                this._storeToken(action.payload);
                break;
            case ActionTypes.QR_ZONE_KPI_FETCHED:
                this._storeZoneKpi(action.payload);
                break;
            case ActionTypes.QR_TOKENS_BULK_FETCHED:
                this._storeTokensBulk(action.payload);
                break;
            default:
                break;
        }
    }

    // Hooks a React component's callback to the CHANGED event.
    addChangeListener(callback) {
        this.on(CHANGE, callback);
    }
    addTokenFetchListener(callback) {
        this.on(TOKEN_FETCHED, callback);
    }
    addTokensBulkFetchListener(callback) {
        this.on(TOKENS_BULK_FETCHED, callback);
    }

    // Removes the listener from the CHANGED event.
    removeChangeListener(callback) {
        this.removeListener(CHANGE, callback);
    }
    removeTokenFetchListener(callback) {
        this.removeListener(TOKEN_FETCHED, callback);
    }
    removeTokensBulkFetchListener(callback) {
        this.removeListener(TOKENS_BULK_FETCHED, callback);
    }

    _clear() {
        _token = null;
        _tokens_bulk = [];
        _zone_kpi = {};
    }

    _storeTokensBulk(json) {
        _tokens_bulk = json;
        this.emit(TOKENS_BULK_FETCHED)
    }

    _storeZoneKpi(json) {
        _zone_kpi = json;
        if (_zone_kpi.occupancy_percent != null && _zone_kpi.occupancy_count != null && _zone_kpi.occupancy_target_limit != null) {
            if (_zone_kpi.occupancy_percent === 0 && _zone_kpi.occupancy_count > 0 && _zone_kpi.occupancy_target_limit > 0) {
                _zone_kpi.occupancy_percent = (Math.round((_zone_kpi.occupancy_count / _zone_kpi.occupancy_target_limit) * 100));
            }
        }

        if (_zone_kpi.co2_value != null && _zone_kpi.co2_target != null) {
            if (_zone_kpi.co2_value > 0 && _zone_kpi.co2_target > 0) {
                //Create an artificial slight movement to reassure user value is reading every scan.
                let random = Math.floor(Math.random() * 3)
                _zone_kpi.co2_value = (_zone_kpi.co2_value + random);
            }
        }

        if (_zone_kpi.temperature_value != null && _zone_kpi.temperature_upper_target != null) {
            if (_zone_kpi.temperature_value > 0 && _zone_kpi.temperature_upper_target > 0) {
                //Create an artificial slight movement to reassure user value is reading every scan.
                _zone_kpi.temperature_value = (_zone_kpi.temperature_value + (Math.random() * 0.2));
            }
        }

        this.emit(CHANGE)
    }

    _storeToken(json) {
        _token = json;
        this.emit(TOKEN_FETCHED)
    }

    _getZoneKpi() {
        return _zone_kpi;
    }

    _getToken() {
        return _token;
    }
    _getTokensBulk() {
        return _tokens_bulk;
    }
}

const qrStore = new QrStore();
export default qrStore;

import React, { Component } from "react";
import styles from "./BuildingSelect.module.scss";
import PropTypes from "prop-types";
import { SearchSelect } from "../SearchSelect";
import BuildingActions from "../../actions/buildingActions";

class BuildingSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      buildings: [],
    };
  }

  componentDidMount() {
    this.fetchBuildings();
  }

  async fetchBuildings() {
    try {
      const buildings = await BuildingActions.getBuildings();
      this.setState({ buildings: buildings });
    } catch (error) {
      console.error('Failed to fetch buildings: ', error.code, error.type);
    }
  }

  render() {
    let selectedBuilding = this.props.selectedBuilding;

    if (typeof selectedBuilding === "number") {
      selectedBuilding = this.state.buildings.find(
        (building) => building.building_id === selectedBuilding
      );
    }

    const filteredBuildings = this.props.filter
      ? this.state.buildings.filter(this.props.filter)
      : this.state.buildings;

    return (
      <div
        className={
          this.props.white
            ? styles["BuildingSelectWhite"]
            : styles["BuildingSelect"]
        }
      >
        <SearchSelect
          options={filteredBuildings}
          placeholder={this.props.placeholder || "Select the Building..."}
          defaultValue={selectedBuilding}
          actionOnSelectedOption={this.props.onBuildingSelect}
        />
      </div>
    );
  }
}

export default BuildingSelect;

BuildingSelect.propTypes = {
  selectedBuilding: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
  onBuildingSelect: PropTypes.func,
  placeholder: PropTypes.string,
  filter: PropTypes.func,
  white: PropTypes.bool,
};

import React, { Component } from "react";
import "./MeterDataUpload.scss";
import _ from "lodash";

// Stores
import AdminActions from "../../actions/adminActions";
import AdminStore from "../../stores/adminStore";
import EnergyActions from "../../actions/energyActions";
import EnergyStore from "../../stores/energyStore";
import BuildingActions from "../../actions/buildingActions";

// Components
import { SearchSelect } from "../../components/SearchSelect";
import { LogoSpinner } from "../../components/LogoSpinner";
import { DocumentTitle } from "../../components/DocumentTitle";
import { Icon } from "../../components/Icon";

class MeterDataUpload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedMeter: null,
      selectedBuilding: null,
      availableMeters: EnergyStore.getMeters(),
      availableBuildings: [],
      loading: true,
      type: null,
      timestampColumn: "1",
      valueColumn: "2",
      startRow: "1",
      timezone: "Europe/Dublin",
    };
    this._onMeterChange = this._onMeterChange.bind(this);
    this._onUploadChange = this._onUploadChange.bind(this);
    this._selectMeter = this._selectMeter.bind(this);
    this._selectBuilding = this._selectBuilding.bind(this);
    this._uploadFile = this._uploadFile.bind(this);
    this.handleUploadButtonClick = this.handleUploadButtonClick.bind(this);
    this.handleFileChange = this.handleFileChange.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);

    this.fileInputRef = React.createRef();
  }

  _onMeterChange() {
    let _selectableMeters = [];
    let availableMeters = _.orderBy(
      EnergyStore.getMeters(),
      ["description"],
      ["asc"]
    );
    for (let i = 0; i < availableMeters.length; i++) {
      _selectableMeters.push({
        name: availableMeters[i].description,
        meter_id: availableMeters[i].meter_id,
      });
    }
    this.setState({ availableMeters: _selectableMeters, loading: false });
  }

  _onUploadChange() {
    this.setState({ loading: false });
  }

  UNSAFE_componentWillMount() {
    AdminStore.addChangeListener(this._onUploadChange);
  }

  componentWillUnmount() {
    AdminStore.clear();
    EnergyStore.clear();
    AdminStore.removeChangeListener(this._onUploadChange);
  }

  componentDidMount() {
    this.fetchBuildings();
  }

  async fetchBuildings() {
    const buildings = await BuildingActions.getBuildings();
    this.setState({
      availableBuildings: buildings,
      loading: false,
    });
  }

  _selectBuilding(selectedBuilding) {
    this.setState(
      {
        selectedBuilding,
        selectedMeter: null,
        type: null,
        loading: true,
      },
      () => this.fetchMeters(selectedBuilding.building_id)
    );
  }

  async fetchMeters(buildingId) {
    const meters = await EnergyActions.getBuildingMeters(buildingId);
    const filtered_meters = meters
      .filter((m) => m.type.includes("ELECTRIC") || m.type.includes("GAS"))
      .map((n) => {
        return { ...n, name: n.description, value: n.building_id };
      });

    const availableMeters = _.orderBy(
      filtered_meters,
      ["description"],
      ["asc"]
    );

    this.setState({ loading: false, availableMeters: availableMeters });
  }

  _selectMeter(selectedMeter) {
    this.setState({ selectedMeter: selectedMeter, type: null });
  }

  _uploadFile(file) {
    const {
      selectedMeter,
      type,
      timezone,
      timestampColumn,
      valueColumn,
      startRow,
    } = this.state;
    if (selectedMeter !== null) {
      if (type === "COMMON_VERTICAL") {
        // Convert inputs from 1-based to 0-based
        const adjustedTimestampColumn = (parseInt(timestampColumn, 10) - 1).toString();
        const adjustedValueColumn = (parseInt(valueColumn, 10) - 1).toString();
        const adjustedStartRow = (parseInt(startRow, 10) - 1).toString();

        AdminActions.uploadEnergyCSV(
          selectedMeter.meter_id,
          type,
          file,
          timezone,
          adjustedTimestampColumn,
          adjustedValueColumn,
          adjustedStartRow
        );
      } else {
        AdminActions.uploadEnergyCSV(
          selectedMeter.meter_id,
          type,
          file
        );
      }
      this.setState({ loading: true });
    }
  }

  handleFileChange(event) {
    const meterId = _.get(this.state, "selectedMeter.meter_id");
    const type = _.get(this.state, "type");
    const file = event.target.files[0];

    if (file && file.name.endsWith(".csv")) {
      this.setState({ loading: true }, () => {
        if (type === "COMMON_VERTICAL") {
          // Convert inputs from 1-based to 0-based
          const timestampColumn = parseInt(this.state.timestampColumn, 10) - 1;
          const valueColumn = parseInt(this.state.valueColumn, 10) - 1;
          const startRow = parseInt(this.state.startRow, 10) - 1;

          AdminActions.uploadEnergyCSV(
            meterId,
            type,
            file,
            this.state.timezone,
            timestampColumn.toString(),
            valueColumn.toString(),
            startRow.toString()
          );
        } else {
          AdminActions.uploadEnergyCSV(meterId, type, file);
        }
      });
    } else if (file && !file.name.endsWith(".csv")) {
      alert("Please select a CSV file.");
    }

    this.fileInputRef.current.value = "";
  }

  handleUploadButtonClick() {
    this.fileInputRef.current.click();
  }

  handleInputChange(event) {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  }

  getTypeCard(type) {
    const isSelected = type === this.state.type;
    let title, description, imgsrc;

    if (type === "COMMON_HORIZONTAL") {
      title = "Horizontal";
      imgsrc = "/img/csv-types/common_horizontal.png";
      description =
        "First row outlines the hour timestamps, following rows provide a date timestamp with number of entries and energy values";
    } else if (type === "COMMON_VERTICAL") {
      title = "Generic";
      imgsrc = "/img/csv-types/common_vertical.png";
      description =
        "Please specify the timestamp column, value column, and start row (all starting from 1). Optionally, you can also specify the timezone.";
    } else if (type === "ESB") {
      title = "ESB";
      imgsrc = "/img/csv-types/esb.png";
      description =
        "First two rows show the Meter's MPRN and hour timestamps, every next row starts with day timestamp and is followed by energy values";
    }

    return (
      <div
        className={`type-card shadow-base hoverable ${isSelected ? "selected" : ""}`}
        onClick={() => this.setState({ type: type })}
      >
        {!isSelected || type !== "COMMON_VERTICAL" ? (
          <>
            <div className="picture">
              <img src={imgsrc} alt="#" />
            </div>
            <div className="title">{title}</div>
            <div className="description">{description}</div>
          </>
        ) : (
          <div className="vertical-csv-inputs">
            <div className="form-group">
              <label>Timestamp Column</label>
              <input
                type="number"
                className="form-control"
                name="timestampColumn"
                value={this.state.timestampColumn}
                onChange={this.handleInputChange}
                max="100"
                min="1"
              />
            </div>
            <div className="form-group">
              <label>Value Column</label>
              <input
                type="number"
                className="form-control"
                name="valueColumn"
                value={this.state.valueColumn}
                onChange={this.handleInputChange}
                max="100"
                min="1"
              />
            </div>
            <div className="form-group">
              <label>Start Row</label>
              <input
                type="number"
                className="form-control"
                name="startRow"
                value={this.state.startRow}
                onChange={this.handleInputChange}
                max="100"
                min="1"
              />
            </div>
            <div className="form-group">
              <label>Timezone</label>
              <select
                className="form-control"
                name="timezone"
                value={this.state.timezone}
                onChange={this.handleInputChange}
              >
                <option value="Europe/Dublin">Europe/Dublin</option>
                <option value="UTC">UTC</option>
              </select>
            </div>
          </div>
        )}
      </div>
    );
  }

  render() {
    const { selectedMeter, type, loading } = this.state;

    return (
      <div className="br-mainpanel br-profile-body floorplan-background" id="MeterDataUpload">
        <DocumentTitle title={"Meter Data Upload"} />
        <LogoSpinner loading={loading} />
        <div className="br-container">
          <div className="row">
            <div className="col mg-t-15">
              <div className="card pd-30 mg-t-30 shadow-base bd-0 mg-t-10">
                <div className="row">
                  <div className="col-12 col-sm-12">
                    <div className="row pd-t-6">
                      <div className="col-12 col-sm-6">
                        <SearchSelect
                          limit={999}
                          options={this.state.availableBuildings}
                          placeholder={"Select Building..."}
                          defaultValue={this.state.selectedBuilding}
                          actionOnSelectedOption={this._selectBuilding}
                        />
                      </div>
                      <div className="col-12 col-sm-6">
                        <SearchSelect
                          limit={999}
                          options={this.state.availableMeters}
                          placeholder={"Select Meter..."}
                          defaultValue={this.state.selectedMeter}
                          actionOnSelectedOption={this._selectMeter}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                {selectedMeter ? (
                  <div className="row mg-t-10">
                    <div className="col-12 col-sm-12">
                      <div className="card bd-0 shadow-base">
                        <div
                          className="card-body bd pd-b-0 pd-t-0"
                          style={{
                            backgroundColor: "#fff",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <p className="mg-t-20 mg-b-20 mg-x-20 tx-center">
                            <Icon name='CloudUpload' color='#868ba1' size={48} />
                          </p>
                          <h4 className="tx-default mg-b-20 tx-center">
                            {" "}
                            Select a CSV type file to upload.
                          </h4>
                          <div className="type-selection">
                            {this.getTypeCard("COMMON_VERTICAL")}
                            {this.getTypeCard("COMMON_HORIZONTAL")}
                            {this.getTypeCard("ESB")}
                          </div>
                          <div className="custom-file">
                            <input
                              type="file"
                              ref={this.fileInputRef}
                              onChange={this.handleFileChange}
                              className="file-input"
                            />
                            <button
                              onClick={this.handleUploadButtonClick}
                              className="custom-button"
                              disabled={!type}
                            >
                              Upload File
                            </button>
                          </div>
                          {this.state.error && (
                            <div className="error-message">
                              {this.state.error}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="row mg-t-10">
                    <div className="col-12 col-sm-12 col-md-12">
                      <div className="card bd-0 shadow-base">
                        <div
                          className="card-body bd pd-b-0 pd-t-0"
                          style={{
                            backgroundColor: "#fff",
                            height: "300px",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <p className="mg-b-20 mg-x-20 tx-center">
                            <Icon name='Construction' color='#868ba1' size={60} />
                          </p>
                          <h4 className="tx-default mg-b-20 tx-center">
                            {" "}
                            Please select a target meter to use in the upload process.
                          </h4>
                          <p className="mg-x-20 tx-center">
                            Once selection is made then the upload form will appear.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default MeterDataUpload;


import React from 'react';
import PropTypes from 'prop-types';
import './DashboardSlideshow.scss';

class DashboardSlideshow extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            counter: 0
        };
    }
    
    componentDidMount() {
        this.interval = setInterval(() => {
            if (this.state.counter === this.props.data.length - 1) {
                this.setState({counter: 0})
            } else {
                this.setState((prev) => ({
                    counter: prev.counter + 1
                }))
            }
        }, this.props.interval);
    }
    
    componentWillUnmount() {
        if (this.interval) {
            clearInterval(this.interval);
        }
    }
    
    render() {
        const carouselId = "dashboard-carousel-" + this.props.title

        return <div className='DashboardSlideshow'>
        <div id={carouselId} className="carousel" data-ride="carousel" data-interval={this.props.interval}>
          <ol className="carousel-indicators">
            {this.props.data.map((_, index) => {
              return (index === this.state.counter ? <li key={index} data-target={carouselId} className="active" onClick={() => this.setState({counter: index})}></li>: <li key={index} data-target={carouselId} onClick={() => this.setState({counter: index})}></li>)
            })}
          </ol>
          <div className="carousel-inner">
            {this.props.data.map((slide, index) => {
              return <div key={index} className={index === this.state.counter ? "carousel-item active" : "carousel-item"}>
                <div className='trivia'>
                  <div className='header'>
                    <div className='title'>{this.props.title}</div>
                    <div className='subtitle'></div>
                  </div>
                  <div className='body'>
                    <div className='image'>
                      <img alt={slide.title} src={slide.image} />
                    </div>
                    <div className='text'>
                      <div className='value'>{slide.title}</div>
                      <div className='label'>{slide.text}</div>
                    </div>
                  </div>
                </div>
              </div>;
            })}
          </div>
        </div>
      </div>
    }
}

DashboardSlideshow.propTypes = {
    title: PropTypes.string,
    interval: PropTypes.number,
    data: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string,
            text: PropTypes.string,
            image: PropTypes.string,
        })
    )
}

export default DashboardSlideshow;
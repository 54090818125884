const constants = {
  //UTILITY CONSTANTS
  Moneyformatter: function (fractionDigits = 0) {
    return new Intl.NumberFormat("en-IE", {
      style: "currency",
      currency: "EUR",
      maximumFractionDigits: fractionDigits,
      minimumFractionDigits: fractionDigits,
    });
  },
  Numberformatter: function (fractionDigits = 0, locale = "en-IE") {
    return new Intl.NumberFormat(locale, {
      maximumFractionDigits: fractionDigits,
      minimumFractionDigits: fractionDigits,
    });
  },

  BUCKET_CUSTOM: "CUSTOM",
  BUCKET_FIFTEEN_MIN_OLD: "15",
  BUCKET_FIFTEEN_MIN: "FIFTEEN_MIN",
  BUCKET_HOUR_OLD: "hour",
  BUCKET_HOUR: "HOUR",
  BUCKET_DAY_OLD: "day",
  BUCKET_DAY: "DAY",
  BUCKET_WEEK: "WEEK",
  BUCKET_MONTH: "MONTH",
  BUCKET_YEAR: "YEAR",
  BUCKET_SINGULAR: "SINGULAR",

  DATA_EXPLORER_DATE_FORMAT: 'YYYY-MM-DD',

  MAX_DAYS_FOR_MIN_BUCKET: 7,
  MAX_DAYS_FOR_HOUR_BUCKET: 31,
  MAX_DAYS_FOR_DAY_BUCKET: 366,

  MIN_DAYS_FOR_MONTH_BUCKET: 28,
  MIN_DAYS_FOR_YEAR_BUCKET: 365,

  ELECTRICITY_UNIT_COST: 0.11, // (c / kWh)
  GAS_UNIT_COST: 0.05, // (c / kWh)
  WATER_UNIT_COST: 0.00235, // (c / liter)
  GAS_CARBON_COST: 0.2047, // kgCO₂/kWh
  ELECTRICITY_CARBON_COST: 0.259, // kgCO₂/kWh
  WATER_CARBON_COST: 0, // kgCO₂/ liter

  REPORT_VERIFICATION_CODE: "zW2RHg5v2JFkzSzd",

  //PRIMARY COLORS
  GREEN: "#43A047",
  GREEN_TRANSPARENT: "#43A04796",
  BLUE: "#3f85d1",
  BLUE_TRANSPARENT: "#3f85d285",
  RED: "#DC3545",
  RED_TRANSPARENT: "#DC354569",
  ORANGE: "#F49917",
  ORANGE_TRANSPARENT: "#F499177d",
  YELLOW: "#FFD34E",
  YELLOW_TRANSPARENT: "#ffd34e8a",

  //DASH STYLES
  SOLID: "Solid",
  DASH: "Dash",
  DOT: "Dot",

  //PROPERTY CONSTANTS
  ZONE_ASSETS: ["cassette", "fcu", "rhahu"],
  AIR_HANDLING_ASSETS: ["ahu", "fahu", "hrahu", "mau", "ptac", "rtu", "hru"],
  HEATING_ASSETS: ["boiler", "hws", "chp", "hp", "hs"],
  COOLING_ASSETS: ["chiller", "cooltwr"],
  CIRCUIT_ASSETS: ["cdc", "hdc"],
  SENSOR_ASSETS: ["sensor"],

  TERMINAL_ASSETS_FULL: [
    "FAN_COIL_UNIT",
    "REHEAT_AIR_HANDLING_UNIT",
    "CASSETTE",
  ],
  COOLING_ASSETS_FULL: [
    "CHILLER_COMPONENT",
    "COOLING_CIRCUIT",
    "COOLING_TOWER",
  ],
  HEATING_ASSETS_FULL: [
    "HOT_WATER_SYSTEM_COMPONENT",
    "HEATING_SYSTEM",
    "HEATING_CIRCUIT",
    "HEAT_PUMP",
    "CHP_UNIT",
    "CHP_UNIT",
    "BOILER_COMPONENT",
  ],
  AIR_HANDLING_ASSETS_FULL: [
    "ROOFTOP_UNIT",
    "HEAT_RECOVERY_UNIT",
    "FRESH_AIR_HANDLING_UNIT",
    "AIR_HANDLING_UNIT_WITH_HEAT_RECOVERY",
    "AIR_HANDLING_UNIT",
    "MAKEUP_AIR_UNIT",
    "PACKAGED_TERMINAL_AIR_CONDITIONER",
  ],

  AIR_HANDLING_UNIT: "Air Handling Unit",
  FRESH_AIR_HANDLING_UNIT: "Fresh Air Handling Unit",
  AIR_HANDLING_UNIT_WITH_HEAT_RECOVERY: "Air Handling Unit With Heat Recovery",
  REHEAT_AIR_HANDLING_UNIT: "Reheat Air Handling Unit",
  FAN_COIL_UNIT: "Fan Coil Unit",
  MAKEUP_AIR_UNIT: "Makeup Air Handling Unit",
  PACKAGED_TERMINAL_AIR_CONDITIONER: "Packaged Terminal Air Conditioner",
  ROOFTOP_UNIT: "Rooftop Unit",
  HEAT_RECOVERY_UNIT: "Heat Recovery Unit",
  BOILER_COMPONENT: "Boiler",
  CHILLER_COMPONENT: "Chiller",
  HOT_WATER_SYSTEM_COMPONENT: "Hot Water System",
  PUMPS_COMPONENT: "Pump",
  CASSETTE: "Cassette",
  SENSOR: "Sensor",
  HEATING_CIRCUIT: "Heating Circuit",
  COOLING_CIRCUIT: "Cooling Circuit",
  CHP_UNIT: "Chp Unit",
  COOLING_TOWER: "Cooling Tower",
  HEAT_PUMP: "Heat Pump",
  HEATING_SYSTEM: "Heating System",

  WATER_METER: "WATER",
  GAS_METER: "GAS",
  ELECTRIC_METER: "ELECTRIC",
  HEAT_METER: "HEAT",

  METER_TYPES: {
    WATER: {
      label: "Water Meter",
      type: "WATER",
    },
    GAS: {
      label: "Gas Meter",
      type: "GAS",
    },
    ELECTRIC: {
      label: "Electric Meter",
      type: "ELECTRIC",
    },
    HEAT: {
      label: "Heat Meter",
      type: "HEAT",
    },
    STEAM: {
      label: "Steam Meter",
      type: "STEAM",
    },
    NITROGEN: {
      label: "Nitrogen Meter",
      type: "NITROGEN",
    },
  },

  LITERS: "litres",
  KILOWATT_HOURS: "kWh",
  KILOWATTS: "kW",
  KVARH: "kVArh",
  METERS_CUBED: "m3",
  POWER_FACTOR: "PF",
  EURO: "€",
  KG_CO2_EMISSIONS: "kgCO2e",

  //COMMENTS CONSTANTS
  COMMENT_RAW_TEXT_LIMIT: 512,
  COMMENT_HTML_TEXT_LIMIT: 2280,

  //REGEX PATTERNS
  MAC_ADDRESS_PATTERN: /^([0-9a-fA-F][0-9a-fA-F]:){5}([0-9a-fA-F][0-9a-fA-F])$/,
  UUID_PATTERN:
    /\b[0-9a-f]{8}\b-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-\b[0-9a-f]{12}\b/,

  //ACTION CONSTANTS
  LOGIN: "LOGIN",
  LOGIN_FAIL: "LOGIN_FAIL",
  UPDATE_PASSWORD_VALUE: "UPDATE_PASSWORD_VALUE",
  UPDATE_MAIL_VALUE: "UPDATE_MAIL_VALUE",
  INVITE_ACTIVATION: "INVITE_ACTIVATION",
  INVITE_ACTIVATION_FAIL: "INVITE_ACTIVATION_FAIL",
  PASSWORD_RESET: "PASSWORD_RESET",
  PASSWORD_RESET_FAIL: "PASSWORD_RESET_FAIL",
  SET_USER: "SET_USER",
  ORGANISATION_USERS_FETCHED: "ORGANISATION_USERS_FETCHED",
  USER_SAVED: "USER_SAVED",
  USER_UPDATED: "USER_UPDATED",
  USER_DELETED: "USER_DELETED",
  USER_ORGANISATION_UPDATED: "USER_ORGANISATION_UPDATED",
  BUILDINGS_FETCHED: "BUILDINGS_FETCHED",
  BUILDING_FETCHED: "BUILDING_FETCHED",
  BUILDING_SAVED: "BUILDING_SAVED",
  BUILDING_UPDATED: "BUILDING_UPDATED",
  BUILDING_DELETED: "BUILDING_DELETED",

  ORGANISATIONS_FETCHED: "ORGANISATIONS_FETCHED",
  ORGANISATION_FETCHED: "ORGANISATION_FETCHED",

  BUILDINGS_WITH_BREACHES_FETCHED: "BUILDINGS_WITH_BREACHES_FETCHED",

  ZONES_FETCHED: "ZONES_FETCHED",
  ZONE_SAVED: "ZONE_SAVED",
  ZONE_UPDATED: "ZONE_UPDATED",
  ZONE_DELETED: "ZONE_DELETED",

  ZONE_PROFILES_FETCHED: "ZONE_PROFILES_FETCHED",
  ZONE_SETPOINT_HISTORY_FETCHED: "ZONE_SETPOINT_HISTORY_FETCHED",
  METER_SAVED: "METER_SAVED",
  METER_UPDATED: "METER_UPDATED",
  METER_DELETED: "METER_DELETED",
  METERS_FETCHED: "METERS_FETCHED",

  METER_USAGE_DATA_FETCHED: "METER_USAGE_DATA_FETCHED",
  ESTATE_ENERGY_LEADERBOARD_DATA_FETCHED:
    "ESTATE_ENERGY_LEADERBOARD_DATA_FETCHED",

  QR_TOKEN_FETCHED: "QR_TOKEN_FETCHED",
  QR_ZONE_KPI_FETCHED: "QR_ZONE_KPI_FETCHED",
  QR_TOKENS_BULK_FETCHED: "QR_TOKENS_BULK_FETCHED",

  BUILDING_METERS_FETCHED: "BUILDING_METERS_FETCHED",
  ENERGY_BENCHMARK_REPORT_FETCHED: "ENERGY_BENCHMARK_REPORT_FETCHED",
  ENERGY_BENCHMARK_REPORT_SHARED: "ENERGY_BENCHMARK_REPORT_SHARED",

  APPLIANCE_POINTS_FETCHED: "APPLIANCE_POINTS_FETCHED",
  APPLIANCES_FETCHED: "APPLIANCES_FETCHED",

  APPLIANCE_SAVED: "APPLIANCE_SAVED",
  APPLIANCE_UPDATED: "APPLIANCE_UPDATED",
  APPLIANCE_DELETED: "APPLIANCE_DELETED",
  APPLIANCE_CONFIG_FILE_FETCHED: "APPLIANCE_CONFIG_FILE_FETCHED",
  APPLIANCE_CONFIG_FILE_SAVED: "APPLIANCE_CONFIG_FILE_SAVED",
  APPLIANCE_BLACKLIST_FILE_FETCHED: "APPLIANCE_BLACKLIST_FILE_FETCHED",
  APPLIANCE_BLACKLIST_FILE_SAVED: "APPLIANCE_BLACKLIST_FILE_SAVED",
  APPLIANCE_CONTROLLERS_FETCHED: "APPLIANCE_CONTROLLERS_FETCHED",

  LIVE_ZONE_FETCHED: "LIVE_ZONE_FETCHED",

  ASSETS_FETCHED: "ASSETS_FETCHED",
  ASSET_SAVED: "ASSET_SAVED",
  ASSET_UPDATED: "ASSET_UPDATED",
  ASSET_DELETED: "ASSET_DELETED",

  ASSET_TYPES_FETCHED: "ASSET_TYPES_FETCHED",
  SET_DATES: "SET_DATES",

  UNIQUE_POINTS_FETCHED: "UNIQUE_POINTS_FETCHED",
  POINT_VALUES_FETCHED: "POINT_VALUES_FETCHED",
  MAPPED_POINT_VALUES_FETCHED: "MAPPED_POINT_VALUES_FETCHED",

  DATASOURCES_FETCHED: "DATASOURCES_FETCHED",
  DATASOURCE_MAPPINGS_FETCHED: "DATASOURCE_MAPPINGS_FETCHED",
  ADMIN_HEALTH_CHECKS_FETCHED: "ADMIN_HEALTH_CHECKS_FETCHED",
  CSV_FILE_UPLOADED: "CSV_FILE_UPLOADED",
  CSV_FILE_UPLOAD_FAILED: "CSV_FILE_UPLOAD_FAILED",

  BUILDING_SIGFOX_SENSORS: "BUILDING_SIGFOX_SENSORS",
  SIGFOX_DEVICE_TYPES: "SIGFOX_DEVICE_TYPES",
  SENSOR_SAVED: "SENSOR_SAVED",
  SENSOR_UPDATED: "SENSOR_UPDATED",
  SENSOR_DELETED: "SENSOR_DELETED",
  SENSOR_TRANSACTION_FAILED: "SENSOR_TRANSACTION_FAILED",

  DOORWAYS_FETCHED: "DOORWAYS_FETCHED",
  DOORWAY_SAVED: "DOORWAY_SAVED",
  DOORWAY_UPDATED: "DOORWAY_UPDATED",
  DOORWAY_DELETED: "DOORWAY_DELETED",

  COUNTERS_FETCHED: "COUNTERS_FETCHED",
  COUNTER_SAVED: "COUNTER_SAVED",
  COUNTER_UPDATED: "COUNTER_UPDATED",
  COUNTER_DELETED: "COUNTER_DELETED",

  LINK_SAVED: "LINK_SAVED",

  ENTITY_TYPES_FETCHED: "ENTITY_TYPES_FETCHED",
  DATA_SOURCE_ENTITY_MAP_FETCHED: "DATA_SOURCE_ENTITY_MAP_FETCHED",

  MAPPING_SAVED: "MAPPING_SAVED",
  MAPPING_BULK_SAVED: "MAPPING_BULK_SAVED",
  MAPPING_DELETED: "MAPPING_DELETED",
  MAPPING_GENERATED: "MAPPING_GENERATED",
  MAPPING_BY_DATA_SOURCE_FETCHED: "MAPPING_BY_DATA_SOURCE_FETCHED",
  MAPPING_BY_ENTITY_FETCHED: "MAPPING_BY_ENTITY_FETCHED",

  // Automated Assessments
  INVENTORY_FETCHED: "INVENTORY_FETCHED",
  AUTOMATION_MAPPING_FETCHED: "AUTOMATION_MAPPING_FETCHED",
  AUTOMATION_UNIQUE_POINTS_FETCHED: "AUTOMATION_UNIQUE_POINTS_FETCHED",
  AUTOMATION_MAPPING_SAVED: "AUTOMATION_MAPPING_SAVED",
  AUTOMATION_MAPPING_SAVE_FAIL: "AUTOMATION_MAPPING_SAVE_FAIL",

  AUTOMATIC_ASSESSMENT_STARTED: "AUTOMATIC_ASSESSMENT_STARTED",
  AUTOMATIC_ASSESSMENT_START_FAILED: "AUTOMATIC_ASSESSMENT_START_FAILED",

  ASSESSMENT_REPORT_SHARED: "ASSESSMENT_REPORT_SHARED",
  ASSESSMENT_REPORT_FETCHED: " ASSESSMENT_REPORT_FETCHED",
  ASSESSMENT_REPORT_CHART_DATA_FETCHED: "ASSESSMENT_REPORT_CHART_DATA_FETCHED",

  ALL_PROPERTIES_FETCHED: "ALL_PROPERTIES_FETCHED",
  AVAILABLE_PROPERTIES_FOR_ENTITY_FETCHED:
    "AVAILABLE_PROPERTIES_FOR_ENTITY_FETCHED",
  AVAILABLE_PROPERTIES_FETCHED: "AVAILABLE_PROPERTIES_FETCHED",
  AVAILABLE_REPORTS_FETCHED: "AVAILABLE_REPORTS_FETCHED",
  REPORT_SAVED: "REPORT_SAVED",
  SIGNIFICANT_ENERGY_USERS_REPORT_FETCHED:
    "SIGNIFICANT_ENERGY_USERS_REPORT_FETCHED",

  CHANGE_REQUEST_SENT: "CHANGE_REQUEST_SENT",

  METERS_EXPLORER_METERS_FETCHED: "METERS_EXPLORER_METERS_FETCHED",
  METERS_EXPLORER_BUILDINGS_FETCHED: "METERS_EXPLORER_BUILDINGS_FETCHED",
  METERS_EXPLORER_CHART_DATA_FETCHED: "METERS_EXPLORER_CHART_DATA_FETCHED",
  METERS_EXPLORER_COMPARISON_CHART_DATA_FETCHED:
    "METERS_EXPLORER_COMAPRISON_CHART_DATA_FETCHED",
  METERS_EXPLORER_CATALOGUE_CREATED: "METERS_EXPLORER_CATALOGUE_CREATED",

  // HERES

  ENERGY_REVIEW_METERS_FETCHED: "ENERGY_REVIEW_METERS_FETCHED",
  ENERGY_REVIEW_BUILDINGS_FETCHED: "ENERGY_REVIEW_BUILDINGS_FETCHED",
  ENERGY_REVIEW_CATALOGUE_CREATED: "ENERGY_REVIEW_CATALOGUE_CREATED",
  ENERGY_REVIEW_LISTS_FETCHED: "ENERGY_REVIEW_LISTS_FETCHED",
  ENERGY_REVIEW_LIST_FETCHED: "ENERGY_REVIEW_LIST_FETCHED",
  ENERGY_REVIEW_LIST_UPDATED: "ENERGY_REVIEW_LIST_UPDATED",
  ENERGY_REVIEW_LIST_CREATED: "ENERGY_REVIEW_LIST_CREATED",
  ENERGY_REVIEW_LIST_DELETED: "ENERGY_REVIEW_LIST_DELETED",
  ENERGY_REVIEW_REVIEW_METERS_FETCHED: "ENERGY_REVIEW_REVIEW_METERS_FETCHED",
  ENERGY_REVIEW_REVIEW_METER_FETCHED: "ENERGY_REVIEW_REVIEW_METER_FETCHED",
  ENERGY_REVIEW_REVIEW_METER_CREATED: "ENERGY_REVIEW_REVIEW_METER_CREATED",
  ENERGY_REVIEW_REVIEW_METER_DELETED: "ENERGY_REVIEW_REVIEW_METER_DELETED",
  ENERGY_REVIEW_REVIEW_METER_UPDATED: "ENERGY_REVIEW_REVIEW_METER_UPDATED",
  ENERGY_REVIEW_REPORT_FETCHED: "ENERGY_REVIEW_REPORT_FETCHED",
  ENERGY_REVIEW_TOKEN_GENERATED: "ENERGY_REVIEW_TOKEN_GENERATED",
  ENERGY_REVIEW_LIST_FETCHED_VIA_TOKEN: 'ENERGY_REVIEW_LIST_FETCHED_VIA_TOKEN',
  ENERGY_REVIEW_METER_CHART_FETCHED: "ENERGY_REVIEW_METER_CHART_FETCHED",

  BUILDING_LINKED: "BUILDING_LINKED",
  LINKED_BUILDING_UPDATED: "LINKED_BUILDING_UPDATED",
  LINKED_BUILDING_DELETED: "LINKED_BUILDING_DELETED",

  LINKABLE_ZONES_FETCHED: "LINKABLE_ZONES_FETCHED",
  LINKED_ZONE_DELETED: "LINKED_ZONE_DELETED",

  LINKABLE_METERS_FETCHED: "LINKABLE_METERS_FETCHED",
  METER_LINKED: "METER_LINKED",
  LINKED_METER_DELETED: "LINKED_METER_DELETED",

  //PROPERTY CODES
  /**
   * General Asset Properties (00)
   **/
  // Enable / Run Status
  UNIT_ACTIVE: "UnitAct",
  UNIT_ACTIVE_CALCULATED: "UnitActCalc",
  UNIT_RUN_STATUS: "UnitSts",
  UNIT_RUN_STATUS_CALCULATED: "UnitStsCalc",

  // Fault / Alarm
  UNIT_FAULT: "UnitFlt",
  UNIT_FAULT_CALCULATED: "UnitFltCalc",
  UNIT_FIRE_ALARM: "FireAlrm",
  UNIT_PCB_FAULT: "PCBFlt",

  ENCODED_MODE_OF_OPERATION: "EncOpMod",

  /**
   * Outside Air Section (01)
   **/
  OUTSIDE_AIR_TEMPERATURE: "OutT",
  OUTSIDE_AIR_HUMIDITY: "OutH",
  OUTSIDE_AIR_ENTHAPLY: "OutE",
  OUTSIDE_AIR_PRESSURE: "OutP",
  OUTSIDE_AIR_CO2: "OutCO2",

  /**
   * Intake Air Section (02)
   **/

  INTAKE_AIR_TEMPERATURE: "InAirT",
  INTAKE_AIR_HUMIDITY: "InAirH",
  INTAKE_AIR_ENTHAPLY: "InAirE",
  INTAKE_AIR_PRESSURE: "InAirP",
  INTAKE_AIR_CO2: "InAirCO2",

  /**
   * Return Air Section (03)
   **/
  RETURN_AIR_TEMPERATURE: "RetT",

  RETURN_AIR_HUMIDITY: "RetH",
  RETURN_AIR_ENTHALPY: "RetE",
  RETURN_AIR_PRESSURE: "RetP",
  RETURN_AIR_CO2: "RetCO2",

  // Set-points
  RETURN_AIR_TEMPERATURE_SETPOINT: "RetTSP",

  RETURN_AIR_TEMP_SETPOINT_UPPER_BOUND: "RetTSPUB",
  RETURN_AIR_TEMP_SETPOINT_LOWER_BOUND: "RetTSPLB",

  RETURN_AIR_TEMPERATURE_DEADBAND: "RetTDb",
  RETURN_AIR_TEMP_COOLING_DEADBAND: "RetTCDb",
  RETURN_AIR_TEMP_HEATING_DEADBAND: "RetTHDb",

  RETURN_AIR_HUMIDITY_SETPOINT: "RetHSP",
  RETURN_AIR_HUMIDITY_DEADBAND: "RetHDb",

  RETURN_AIR_PRESSURE_SETPOINT: "RetPSP",
  RETURN_AIR_PRESSURE_DEADBAND: "RetPDb",

  RETURN_AIR_CO2_SETPOINT: "RetCSP",
  RETURN_AIR_CO2_DEADBAND: "RetCDb",

  // Fan
  RETURN_FAN_ENABLE: "RetFEn",
  RETURN_FAN_ENABLE_CALCULATED: "RetFEnCalc",
  RETURN_FAN_RUN_STATUS: "RetFSts",
  RETURN_FAN_RUN_STATUS_CALCULATED: "RetFStsCalc",
  RETURN_FAN_FAULT: "RetFFlt",
  RETURN_FAN_SPEED: "RetFVSD",

  RETURN_FAN_HI_LO: "RetFHiLo",
  RETURN_FAN_MIN_SPEED: "RetFMinS",
  RETURN_FAN_MAX_SPEED: "RetFMaxS",

  RETURN_FAN_MOTOR_POWER: "RetFMP",

  RETURN_AIRFLOW_SWITCH: "RetFAfSw",
  RETURN_AIRFLOW_SWITCH_CALCULATED: "RetFAfSwCalc",
  RETURN_AIRFLOW_DIFFERENTIAL_PRESSURE_SENSOR: "RetFAfDP",
  RETURN_AIRFLOW_DIFFERENTIAL_PRESSURE_SETPOINT: "RetFAfSTP",

  /**
   * Dampers Section (04)
   **/
  MIN_DAMPER_LEVEL: "MinD",
  INTAKE_DAMPER_POSITION: "IntD",
  MIXING_DAMPER_POSITION: "MixD",
  EXHAUST_DAMPER_POSITION: "ExhD",

  HEAT_RECOVERY_DAMPER: "HruD",
  HEAT_RECOVERY_BYPASS_DAMPER: "HruBypD",
  HEAT_RECOVERY_WHEEL_SPEED: "HrwSpd",

  HEAT_RECOVERY_INTAKE_TEMPERATURE: "HRIntT",
  HEAT_RECOVERY_SUPPLY_TEMPERATURE: "HRSupT",
  HEAT_RECOVERY_RETURN_TEMPERATURE: "HRRetT",
  HEAT_RECOVERY_EXHAUST_TEMPERATURE: "HRExhT",

  /**
   * Mixed In Air Section (05)
   **/
  MIXED_AIR_TEMPERATURE: "MixT",
  MIXED_AIR_TEMPERATURE_CALCULATED: "MixTCalc",
  MIXED_AIR_HUMIDITY: "MixH",
  MIXED_AIR_ENTALPY: "MixE",
  MIXED_AIR_PRESSURE: "MixP",
  MIXED_AIR_CO2: "MixCO2",

  /**
   * Air Filters Section (06)
   **/
  FILTER_STATUS: "FltrSts",
  PANEL_FILTER_STATUS: "PanFSts",
  BAG_FILTER_STATUS: "BagFSts",
  HEPA_FILTER_STATUS: "HepFSts",

  /**
   * Frost Section (07)
   **/
  FROST_OFF_COIL_AIR_TEMP: "FroT",
  FROST_ON_COIL_AIR_TEMP: "FroTonC",
  OUTSIDE_FROST_SETPOINT: "FroOATSP",
  IMMERSION_FROST_SETPOINT: "FroImTSP",
  OUTSIDE_AIR_FROST_STATUS: "FroSts",
  IMMERSION_FROST_STATUS: "ImmFroSts",
  FROST_VALVE_POSITION: "FroV",

  /**
   * Heating Section (08)
   **/
  HEATING_COIL_OFF_COIL_AIR_TEMP: "HeaT",
  HEATING_COIL_ON_COIL_AIR_TEMP: "HeaTonC",
  HEAT_DEMAND: "HeaDmd",
  HEATING_COMPONENT_FAULT: "HeaFlt",

  // Heater
  HEATING_STAGE_1: "HeaStg1",
  HEATING_STAGE_2: "HeaStg2",
  // Heating Coil
  HEATING_COIL_VALVE_POSITION: "HeaV",
  HEATING_COIL_SUPPLY_WATER_TEMP: "HeaSupT",
  HEATING_COIL_RETURN_WATER_TEMP: "HeaRetT",

  HEATING_COMPONENT_LEVEL_CALCULATED: "HeaCalc",
  // Pumps
  HEATING_PUMP_1_ENABLE: "HPmp1En",
  HEATING_PUMP_1_FAULT: "HPmp1Flt",
  HEATING_PUMP_1_STATUS: "HPmp1Sts",
  HEATING_PUMP_2_ENABLE: "HPmp2En",
  HEATING_PUMP_2_FAULT: "HPmp2Flt",
  HEATING_PUMP_2_STATUS: "HPmp2Sts",

  /**
   * Cooling Section (09)
   **/
  COOLING_COIL_OFF_COIL_AIR_TEMP: "CooT",
  COOLING_COIL_ON_COIL_AIR_TEMP: "CooTonC",
  COOLING_DEMAND: "CooDmd",
  COOLING_FAULT: "CooFlt",

  // DX Chiller
  COOLING_STAGE_1: "CooStg1",
  COOLING_STAGE_2: "CooStg2",
  // Cooling Coil
  COOLING_COIL_VALVE_POSITION: "CooV",

  COOLING_COMPONENT_LEVEL_CALCULATED: "CooCalc",
  COOLING_COIL_SUPPLY_WATER_TEMP: "CooSupT",
  COOLING_COIL_RETURN_WATER_TEMP: "CooRetT",

  // Pumps
  COOLING_PUMP_1_ENABLE: "CPmp1En",
  COOLING_PUMP_1_FAULT: "CPmp1Flt",
  COOLING_PUMP_1_STATUS: "CPmp1Sts",
  COOLING_PUMP_2_ENABLE: "CPmp2En",
  COOLING_PUMP_2_FAULT: "CPmp2Flt",
  COOLING_PUMP_2_STATUS: "CPmp2Sts",

  /**
   * Humidifier Section (10)
   **/
  HUMIDIFIER_ENABLE: "HumEn",
  HUMIDIFIER_STATUS: "HumSts",
  HUMIDIFIER_FAULT: "HumFlt",
  HUMIDIFIER_OFF_COIL_AIR_TEMP: "HumT",
  HUMIDIFIER_OFF_COIL_HUMIDITY_LEVEL: "HumH",
  HUMIDIFIER_DEW_POINT: "HumDew",
  HUMIDITY_SET_POINT: "HumHSP",
  HUMIDIFIER_VALVE_POSITION: "HumV",

  /**
   * Supply Air Section (12)
   **/
  SUPPLY_AIR_TEMPERATURE: "SupT",
  SUPPLY_AIR_HUMIDITY: "SupH",
  SUPPLY_AIR_ENTHAPLY: "SupE",
  SUPPLY_AIR_PRESSURE: "SupP",
  SUPPLY_AIR_CO2: "SupCO2",
  // Set-points
  SUPPLY_AIR_TEMPERATURE_SETPOINT: "SupTSP",
  SUPPLY_AIR_TEMP_SETPOINT_UPPER_BOUND: "SupTSPUB",
  SUPPLY_AIR_TEMP_SETPOINT_LOWER_BOUND: "SupTSPLB",
  SUPPLY_AIR_TEMPERATURE_DEADBAND: "SupTDb",
  SUPPLY_AIR_TEMP_COOLING_DEADBAND: "SupTCDb",
  SUPPLY_AIR_TEMP_HEATING_DEADBAND: "SupTHDb",

  SUPPLY_AIR_HUMIDITY_SETPOINT: "SupHSP",
  SUPPLY_AIR_HUMIDITY_DEADBAND: "SupHDb",

  SUPPLY_AIR_PRESSURE_SETPOINT: "SupPSP",
  SUPPLY_AIR_PRESSURE_DEADBAND: "SupPDb",

  SUPPLY_AIR_CO2_SETPOINT: "SupCSP",
  SUPPLY_AIR_CO2_DEADBAND: "SupCDb",

  // Fan
  SUPPLY_FAN_ENABLE: "SupFEn",
  SUPPLY_FAN_ENABLE_CALCULATED: "SupFEnCalc",
  SUPPLY_FAN_RUN_STATUS: "SupFSts",
  SUPPLY_FAN_RUN_STATUS_CALCULATED: "SupFStsCalc",

  SUPPLY_FAN_FAULT: "SupFFlt",
  SUPPLY_FAN_SPEED: "SupFVSD",

  SUPPLY_FAN_HIGH_LOW: "SupFHiLo",
  SUPPLY_FAN_MIN_SPEED: "SupFMinS",
  SUPPLY_FAN_MAX_SPEED: "SupFMaxS",

  SUPPLY_FAN_MOTOR_POWER: "SupFMP",

  SUPPLY_AIRFLOW_SWITCH: "SupFAfSw",
  SUPPLY_AIRFLOW_DIFFERENTIAL_PRESSURE_SENSOR: "SupFAfDP",
  SUPPLY_AIRFLOW_DIFFERENTIAL_PRESSURE_SETPOINT: "SupFAfSTP",

  /**
   * Zone Section (13)
   **/
  ZONE_AIR_TEMPERATURE_1: "ZonT1",
  ZONE_AIR_TEMPERATURE_2: "ZonT2",
  ZONE_AIR_TEMPERATURE_3: "ZonT3",
  ZONE_AIR_TEMPERATURE_4: "ZonT4",
  ZONE_AIR_TEMPERATURE_5: "ZonT5",
  ZONE_AIR_TEMPERATURE_AVG: "ZonTAvg",

  ZONE_AIR_HUMIDITY: "ZonH",
  ZONE_AIR_PRESSURE: "ZonP",
  ZONE_AIR_CO2: "ZonCO2",
  ZONE_LUX_LEVEL: "ZonLux",

  // Set-points
  ZONE_AIR_TEMPERATURE_SETPOINT: "ZonTSP",
  ZONE_AIR_TEMP_SETPOINT_UPPER_BOUND: "ZonTSPUB",
  ZONE_AIR_TEMP_SETPOINT_LOWER_BOUND: "ZonTSPLB",

  ZONE_AIR_TEMPERATURE_DEADBAND: "ZonTDb",
  ZONE_AIR_TEMP_COOLING_DEADBAND: "ZonTCDb",
  ZONE_AIR_TEMP_HEATING_DEADBAND: "ZonTHDb",

  ZONE_AIR_HUMIDITY_SETPOINT: "ZonHSP",
  ZONE_AIR_HUMIDITY_DEADBAND: "ZonHDb",

  ZONE_AIR_PRESSURE_SETPOINT: "ZonPSP",
  ZONE_AIR_PRESSURE_DEADBAND: "ZonPDb",

  ZONE_AIR_CO2_SETPOINT: "ZonCSP",
  ZONE_AIR_CO2_DEADBAND: "ZonCDb",
  ZONE_LUX_SETPOINT: "ZonLuxSP",
  ZONE_SOUND: "ZonSnd",
  ZONE_OCCUPANCY: "ZonOcc",

  /**
   * Boiler Section (14)
   **/
  BOILER_HEAT_DEMAND: "BoHeaDmd",
  BOILER_HEAT_DEMAND_01: "BoHeaDmd01",
  BOILER_HEAT_DEMAND_02: "BoHeaDmd02",
  BOILER_HEAT_DEMAND_03: "BoHeaDmd03",
  BOILER_HEAT_DEMAND_04: "BoHeaDmd04",
  BOILER_HEAT_DEMAND_05: "BoHeaDmd05",
  BOILER_HEAT_DEMAND_06: "BoHeaDmd06",
  BOILER_HEAT_DEMAND_07: "BoHeaDmd07",
  BOILER_HEAT_DEMAND_08: "BoHeaDmd08",
  BOILER_HEAT_DEMAND_09: "BoHeaDmd09",
  BOILER_HEAT_DEMAND_10: "BoHeaDmd10",
  BOILER_HEAT_DEMAND_11: "BoHeaDmd11",
  BOILER_HEAT_DEMAND_12: "BoHeaDmd12",

  BOILER_ENABLE: "BoiEn",
  BOILER_1_ENABLE: "Boi1En",
  BOILER_2_ENABLE: "Boi2En",
  BOILER_3_ENABLE: "Boi3En",
  BOILER_4_ENABLE: "Boi4En",
  BOILER_5_ENABLE: "Boi5En",
  BOILER_6_ENABLE: "Boi6En",
  BOILER_7_ENABLE: "Boi7En",
  BOILER_8_ENABLE: "Boi8En",

  BOILER_RUN_STATUS: "BoiSts",
  BOILER_1_RUN_STATUS: "Boi1Sts",
  BOILER_2_RUN_STATUS: "Boi2Sts",
  BOILER_3_RUN_STATUS: "Boi3Sts",
  BOILER_4_RUN_STATUS: "Boi4Sts",
  BOILER_5_RUN_STATUS: "Boi5Sts",
  BOILER_6_RUN_STATUS: "Boi6Sts",
  BOILER_7_RUN_STATUS: "Boi7Sts",
  BOILER_8_RUN_STATUS: "Boi8Sts",

  MASTER_BOILER_ALARM: "BoiMsAl",
  BOILER_1_ALARM: "Boi1Al",
  BOILER_2_ALARM: "Boi2Al",
  BOILER_3_ALARM: "Boi3Al",
  BOILER_4_ALARM: "Boi4Al",
  BOILER_5_ALARM: "Boi5Al",
  BOILER_6_ALARM: "Boi6Al",
  BOILER_7_ALARM: "Boi7Al",
  BOILER_8_ALARM: "Boi8Al",

  SLAVE_BOILER_ALARM: "BoiSlAl",
  BOILER_GAS_ALARM: "BoiGasAl",

  BOILER_LPHW_HIGH_PRESSURE: "LPHWHiP",
  BOILER_LPHW_LOW_PRESSURE: "LPHWLoP",
  BOILER_PRESS_UNIT_FAULT: "LPHWPUFl",

  BOILER_STEAM_TEMPERATURE: "BoiStmT",
  BOILER_LPHW_FLOW_TEMPERATURE: "LPHWFloT",
  BOILER_LPHW_RETURN_TEMPERATURE: "LPHWRetT",
  BOILER_LPHW_TEMPERATURE_SETPOINT: "LPHWTSP",

  // Pumps
  BOILER_PUMP_1_ENABLE: "BoPm1En",
  BOILER_PUMP_1_FAULT: "BoPm1Flt",
  BOILER_PUMP_1_RUN_STATUS: "BoPm1Sts",
  BOILER_PUMP_2_ENABLE: "BoPm2En",
  BOILER_PUMP_2_FAULT: "BoPm2Flt",
  BOILER_PUMP_2_RUN_STATUS: "BoPm2Sts",

  /**
   * LPHW Circuits (15)
   **/
  LPHW_CIRCUIT_1_PUMP_1_ENABLE: "LPHWCir1Pm1En",
  LPHW_CIRCUIT_1_PUMP_2_ENABLE: "LPHWCir1Pm2En",
  LPHW_CIRCUIT_1_PUMP_1_FAULT: "LPHWCir1Pm1Flt",
  LPHW_CIRCUIT_1_PUMP_2_FAULT: "LPHWCir1Pm2Flt",
  LPHW_CIRCUIT_1_PUMP_1_RUN_STATUS: "LPHWCir1Pm1Sts",
  LPHW_CIRCUIT_1_PUMP_2_RUN_STATUS: "LPHWCir1Pm2Sts",
  LPHW_CIRCUIT_2_PUMP_1_ENABLE: "LPHWCir2Pm1En",
  LPHW_CIRCUIT_2_PUMP_2_ENABLE: "LPHWCir2Pm2En",
  LPHW_CIRCUIT_2_PUMP_1_FAULT: "LPHWCir2Pm1Flt",
  LPHW_CIRCUIT_2_PUMP_2_FAULT: "LPHWCir2Pm2Flt",
  LPHW_CIRCUIT_2_PUMP_1_RUN_STATUS: "LPHWCir2Pm1Sts",
  LPHW_CIRCUIT_2_PUMP_2_RUN_STATUS: "LPHWCir2Pm2Sts",
  LPHW_CIRCUIT_3_PUMP_1_ENABLE: "LPHWCir3Pm1En",
  LPHW_CIRCUIT_3_PUMP_2_ENABLE: "LPHWCir3Pm2En",
  LPHW_CIRCUIT_3_PUMP_1_FAULT: "LPHWCir3Pm1Flt",
  LPHW_CIRCUIT_3_PUMP_2_FAULT: "LPHWCir3Pm2Flt",
  LPHW_CIRCUIT_3_PUMP_1_RUN_STATUS: "LPHWCir3Pm1Sts",
  LPHW_CIRCUIT_3_PUMP_2_RUN_STATUS: "LPHWCir3Pm2Sts",
  LPHW_CIRCUIT_4_PUMP_1_ENABLE: "LPHWCir4Pm1En",
  LPHW_CIRCUIT_4_PUMP_2_ENABLE: "LPHWCir4Pm2En",
  LPHW_CIRCUIT_4_PUMP_1_FAULT: "LPHWCir4Pm1Flt",
  LPHW_CIRCUIT_4_PUMP_2_FAULT: "LPHWCir4Pm2Flt",
  LPHW_CIRCUIT_4_PUMP_1_RUN_STATUS: "LPHWCir4Pm1Sts",
  LPHW_CIRCUIT_4_PUMP_2_RUN_STATUS: "LPHWCir4Pm2Sts",
  LPHW_CIRCUIT_5_PUMP_1_ENABLE: "LPHWCir5Pm1En",
  LPHW_CIRCUIT_5_PUMP_2_ENABLE: "LPHWCir5Pm2En",
  LPHW_CIRCUIT_5_PUMP_1_FAULT: "LPHWCir5Pm1Flt",
  LPHW_CIRCUIT_5_PUMP_2_FAULT: "LPHWCir5Pm2Flt",
  LPHW_CIRCUIT_5_PUMP_1_RUN_STATUS: "LPHWCir5Pm1Sts",
  LPHW_CIRCUIT_5_PUMP_2_RUN_STATUS: "LPHWCir5Pm2Sts",

  /**
   * Chiller Section (16)
   **/
  CHILLER_COOLING_DEMAND: "ChCooDmd",
  CHILLER_COOLING_DEMAND_01: "ChCooDmd01",
  CHILLER_COOLING_DEMAND_02: "ChCooDmd02",
  CHILLER_COOLING_DEMAND_03: "ChCooDmd03",
  CHILLER_COOLING_DEMAND_04: "ChCooDmd04",
  CHILLER_COOLING_DEMAND_05: "ChCooDmd05",
  CHILLER_COOLING_DEMAND_06: "ChCooDmd06",
  CHILLER_COOLING_DEMAND_07: "ChCooDmd07",
  CHILLER_COOLING_DEMAND_08: "ChCooDmd08",
  CHILLER_COOLING_DEMAND_09: "ChCooDmd09",
  CHILLER_COOLING_DEMAND_10: "ChCooDmd10",
  CHILLER_COOLING_DEMAND_11: "ChCooDmd11",
  CHILLER_COOLING_DEMAND_12: "ChCooDmd12",
  CHILLER_ENABLE: "ChlEn",
  CHILLER_1_ENABLE: "Chl1En",
  CHILLER_2_ENABLE: "Chl2En",
  CHILLER_3_ENABLE: "Chl3En",
  CHILLER_4_ENABLE: "Chl4En",
  CHILLER_5_ENABLE: "Chl5En",
  CHILLER_6_ENABLE: "Chl6En",
  CHILLER_7_ENABLE: "Chl7En",
  CHILLER_8_ENABLE: "Chl8En",
  CHILLER_RUN_STATUS: "ChlSts",
  CHILLER_1_RUN_STATUS: "Chl1Sts",
  CHILLER_2_RUN_STATUS: "Chl2Sts",
  CHILLER_3_RUN_STATUS: "Chl3Sts",
  CHILLER_4_RUN_STATUS: "Chl4Sts",
  CHILLER_5_RUN_STATUS: "Chl5Sts",
  CHILLER_6_RUN_STATUS: "Chl6Sts",
  CHILLER_7_RUN_STATUS: "Chl7Sts",
  CHILLER_8_RUN_STATUS: "Chl8Sts",
  CHILLER_FAULT: "ChlFlt",
  CHILLER_1_FAULT: "Chl1Flt",
  CHILLER_2_FAULT: "Chl2Flt",
  CHILLER_3_FAULT: "Chl3Flt",
  CHILLER_4_FAULT: "Chl4Flt",
  CHILLER_5_FAULT: "Chl5Flt",
  CHILLER_6_FAULT: "Chl6Flt",
  CHILLER_7_FAULT: "Chl7Flt",
  CHILLER_8_FAULT: "Chl8Flt",
  CHILLER_CHW_HIGH_PRESSURE: "CHWHiP",
  CHILLER_CHW_LOW_PRESSURE: "CHWLoP",
  CHILLER_PRESS_UNIT_FAULT: "CHWPUFl",
  CHILLER_CHW_FLOW_TEMPERATURE: "CHWFloT",
  CHILLER_CHW_RETURN_TEMPERATURE: "CHWRetT",
  CHILLER_CHW_TEMPERATURE_SETPOINT: "CHWTSP",

  // Pumps
  CHILLER_PUMP_1_ENABLE: "ChPm1En",
  CHILLER_PUMP_1_FAULT: "ChPm1Flt",
  CHILLER_PUMP_1_RUN_STATUS: "ChRPm1Sts",
  CHILLER_PUMP_2_ENABLE: "ChPm2En",
  CHILLER_PUMP_2_FAULT: "ChPm2Flt",
  CHILLER_PUMP_2_RUN_STATUS: "ChRPm2Sts",

  /**
   * CHW Circuits (17)
   **/
  CHW_CIRCUIT_1_PUMP_1_ENABLE: "CHWCir1Pm1En",
  CHW_CIRCUIT_1_PUMP_2_ENABLE: "CHWCir1Pm2En",
  CHW_CIRCUIT_1_PUMP_1_FAULT: "CHWCir1Pm1Flt",
  CHW_CIRCUIT_1_PUMP_2_FAULT: "CHWCir1Pm2Flt",
  CHW_CIRCUIT_1_PUMP_1_RUN_STATUS: "CHWCir1Pm1Sts",
  CHW_CIRCUIT_1_PUMP_2_RUN_STATUS: "CHWCir1Pm2Sts",
  CHW_CIRCUIT_2_PUMP_1_ENABLE: "CHWCir2Pm1En",
  CHW_CIRCUIT_2_PUMP_2_ENABLE: "CHWCir2Pm2En",
  CHW_CIRCUIT_2_PUMP_1_FAULT: "CHWCir2Pm1Flt",
  CHW_CIRCUIT_2_PUMP_2_FAULT: "CHWCir2Pm2Flt",
  CHW_CIRCUIT_2_PUMP_1_RUN_STATUS: "CHWCir2Pm1Sts",
  CHW_CIRCUIT_2_PUMP_2_RUN_STATUS: "CHWCir2Pm2Sts",
  CHW_CIRCUIT_3_PUMP_1_ENABLE: "CHWCir3Pm1En",
  CHW_CIRCUIT_3_PUMP_2_ENABLE: "CHWCir3Pm2En",
  CHW_CIRCUIT_3_PUMP_1_FAULT: "CHWCir3Pm1Flt",
  CHW_CIRCUIT_3_PUMP_2_FAULT: "CHWCir3Pm2Flt",
  CHW_CIRCUIT_3_PUMP_1_RUN_STATUS: "CHWCir3Pm1Sts",
  CHW_CIRCUIT_3_PUMP_2_RUN_STATUS: "CHWCir3Pm2Sts",
  CHW_CIRCUIT_4_PUMP_1_ENABLE: "CHWCir4Pm1En",
  CHW_CIRCUIT_4_PUMP_2_ENABLE: "CHWCir4Pm2En",
  CHW_CIRCUIT_4_PUMP_1_FAULT: "CHWCir4Pm1Flt",
  CHW_CIRCUIT_4_PUMP_2_FAULT: "CHWCir4Pm2Flt",
  CHW_CIRCUIT_4_PUMP_1_RUN_STATUS: "CHWCir4Pm1Sts",
  CHW_CIRCUIT_4_PUMP_2_RUN_STATUS: "CHWCir4Pm2Sts",
  CHW_CIRCUIT_5_PUMP_1_ENABLE: "CHWCir5Pm1En",
  CHW_CIRCUIT_5_PUMP_2_ENABLE: "CHWCir5Pm2En",
  CHW_CIRCUIT_5_PUMP_1_FAULT: "CHWCir5Pm1Flt",
  CHW_CIRCUIT_5_PUMP_2_FAULT: "CHWCir5Pm2Flt",
  CHW_CIRCUIT_5_PUMP_1_RUN_STATUS: "CHWCir5Pm1Sts",
  CHW_CIRCUIT_5_PUMP_2_RUN_STATUS: "CHWCir5Pm2Sts",

  /**
   * How Water System (18)
   **/
  HWS_ENABLE: "HWSEn",
  HWS_RUN_STATUS: "HWSSts",
  HWS_BOOST: "HWSBst",
  HWS_BOOST_STATUS: "HWSBstSt",
  HWS_SUMMER_WINTER_SWITCH: "HWSSeaSw",
  HWS_FAULT: "HWSFlt",
  HWS_BOOST_FAULT: "HWSBstFl",
  HWS_HIGH_LIMIT: "HWSHL",
  HWS_FLOW_TEMPERATURE: "HWSFloT",
  HWS_RETURN_TEMPERATURE: "HWSRetT",
  HWS_TEMPERATURE_SETPOINT: "HWSTSP",
  HWS_DIVERSION_VALVE: "HWSDivV",
  HWS_IMMERSION_HEATER_1: "HWSImm1",
  HWS_IMMERSION_HEATER_2: "HWSImm2",
  HWS_BURNER: "HWSBrn",

  /**
   * Meters (19)
   **/
  ELECTRIC_PULSE_COUNT: "ElecPulCt",
  GAS_PULSE_COUNT: "GasPulCt",
  WATER_PULSE_COUNT: "WaterPulCt",
  ELECTRIC_CONSUMPTION: "ElecCon",
  GAS_CONSUMPTION: "GasCon",
  WATER_CONSUMPTION: "WaterCon",
  ELECTRIC_KWH: "EleckWh",
  ELECTRIC_KW: "EleckW",
  ELECTRIC_PF: "ElecPF",
  GAS_KWH: "GaskWh",
  GAS_KW: "GaskW",
  WATER_LITERS: "WaterLtr",

  /**
   * Other properties (20)
   **/
  ASSET_MODE_OF_OPERATION: "OpMode",
  RETURN_SETPOINT_TEMP_DELTA: "RetStpTD",
  SUPPLY_RETURN_TEMP_DELTA: "SupRetTD",
  SUPPLY_OUTSIDE_TEMP_DELTA: "SupOutTD",
  SUPPLY_MIXED_TEMP_DELTA: "SupMixTD",
  HEATING_DEGREE_DAYS: "HDD",
  COOLING_DEGREE_DAYS: "CDD",

  OCCUPANCY_COUNT: "OccCnt",
  OCCUPANCY_ENTRANCE: "OccEnt",
  OCCUPANCY_EXIT: "OccExt",
  OCCUPANCY_STATUS: "OccSts",

  AVAILABLE_FEATURES: [
    "overview",
    "insights",
    "energy",
    "wellness",
    "assets",
    "utilization",
  ],
  CURRENCIES: ["EUR", "GBP"],
  METER_CATEGORIES: [
    "GENERATED_ELECTRICITY_PV",
    "GENERATED_ELECTRICITY_WIND",
    "GENERATED_ELECTRICITY_CHP",
    "GENERATED_THERMAL_SOLAR",
    "GENERATED_THERMAL_GEO_HEAT_PUMP",
    "GENERATED_THERMAL_AIR_HEAT_PUMP",
    "GENERATED_THERMAL_CHP",
    "IMPORTED_ELECTRICITY",
    "IMPORTED_DISTRICT_HEAT",
    "IMPORTED_DISTRICT_COOLING",
    "IMPORTED_NATURAL_GAS",
    "EXPORTED_ELECTRICITY",
    "EXPORTED_HEAT",
    "EXPORTED_COOLING",
    "IMPORTED_WATER",
    "GENERATED_WATER",
  ],

  // Manage users column
  ACCESSIBLE_APPS: [
    { label: "Engineering UI", value: "ENGINEERING_UI" },
    { label: "Management UI", value: "MANAGEMENT_UI" },
  ],

  ASSESSMENT_OVERRIDES: [
    // Heating
    {
      name: "Enable Heating Checks",
      description: "Override to toggle if heating checks should be run or not.",
      code: "ENB_HEA",
      category: "category",
    },
    {
      name: "PIR Emission Control",
      description:
        "Override for Emissions control check, if emission is controlled by PIRs then A grade is given.",
      code: "PR_EM_CN",
      category: "ENB_HEA",
    },
    {
      name: "Rad TRVs Present",
      description:
        "Override for Emissions control check, if Rad TRVs are present then C grade is given.",
      code: "RAD_TRV",
      category: "ENB_HEA",
    },
    // Cooling
    {
      name: "Enable Cooling Checks",
      description: "Override to toggle if cooling checks should be run or not.",
      code: "ENB_COO",
      category: "category",
    },
    {
      name: "Zone Setpoint Deadbands Present",
      description:
        "Override for Interlock check, if no Zone Setpoint Deadbands are present then D grade is given.",
      code: "ZON_DB",
      category: "ENB_COO",
    },
    // Hot Water
    {
      name: "Enable Hot Water Checks",
      description:
        "Override to toggle if hot water checks should be run or not.",
      code: "ENB_HWT",
      category: "category",
    },
    // Ventilation
    {
      name: "Enable Ventilation Checks",
      description:
        "Override to toggle if ventilation checks should be run or not.",
      code: "ENB_VEN",
      category: "category",
    },
    {
      name: "CO2 Controlled Ventilation",
      description:
        "Override for Zone Ventilation check, if no CO2 Controlled Ventilation is present then an A grade is given.",
      code: "VEN_CO2",
      category: "ENB_VEN",
    },
    // Mixed overrides - category must be an array of codes
    // Heating and Cooling
    {
      name: "Room Temperature Control",
      description:
        "Override for Emissions control check, if emission is controlled by local room temperature control then C grade is given.",
      code: "RM_TP_CN",
      category: ["ENB_COO", "ENB_HEA"],
    },
  ],

  // NEW UI
  // Overviews
  ZONES_OVERVIEW_FETCH: "ZONES_OVERVIEW_FETCH",
  METERS_OVERVIEW_FETCH: "METERS_OVERVIEW_FETCH",
  HVAC_OVERVIEW_FETCH: "HVAC_OVERVIEW_FETCH",

  ALARMS_OVERVIEW_FETCH: "ALARMS_OVERVIEW_FETCH",
  ALARMS_OVERVIEW_ALARM_FETCH: "ALARMS_OVERVIEW_ALARM_FETCH",
  ALARMS_OVERVIEW_ACTION: "ALARMS_OVERVIEW_ACTION",
  ALARMS_OVERVIEW_ALARM_SAVED: "ALARMS_OVERVIEW_ALARM_SAVED",
  ALARMS_OVERVIEW_ALARM_UPDATED: "ALARMS_OVERVIEW_ALARM_UPDATED",
  ALARMS_OVERVIEW_ALARM_STATUS_TOGGLED: "ALARMS_OVERVIEW_ALARM_STATUS_TOGGLED",
  ALARMS_OVERVIEW_ALARM_DELETED: "ALARMS_OVERVIEW_ALARM_DELETED",

  // Details
  ZONE_DETAILS_WELLNESS_FETCHED: "ZONE_DETAILS_WELLNESS_FETCHED",
  ZONE_DETAILS_UTILIZATION_FETCHED: "ZONE_DETAILS_UTILIZATION_FETCHED",

  METER_DETAILS_CONSUMPTION_FETCHED: "METER_DETAILS_CONSUMPTION_FETCHED",

  HVAC_DETAILS_INFO_FETCHED: "HVAC_DETAILS_INFO_FETCHED",
  HVAC_DETAILS_CHARTS_FETCHED: "HVAC_DETAILS_CHARTS_FETCHED",

  DASHBOARD_TOKEN_GENERATED: "DASHBOARD_TOKEN_GENERATED",
  DASHBOARD_DATA_FETCHED: "DASHBOARD_DATA_FETCHED",

  DATA_EXPLORER_PROPERTIES_FETCHED: "DATA_EXPLORER_PROPERTIES_FETCHED",
  DATA_EXPLORER_CHART_DATA_FETCHED: "DATA_EXPLORER_CHART_DATA_FETCHED",
  DATA_EXPLORER_CONFIG_SAVED: 'DATA_EXPLORER_CONFIG_SAVED',
  DATA_EXPLORER_CONFIG_UPDATED: 'DATA_EXPLORER_CONFIG_UPDATED',
  DATA_EXPLORER_CONFIGS_FETCHED: 'DATA_EXPLORER_CONFIGS_FETCHED',
  DATA_EXPLORER_CONFIG_DELETED: 'DATA_EXPLORER_CONFIG_DELETED',
  DATA_EXPLORER_TOKEN_GENERATED: 'DATA_EXPLORER_TOKEN_GENERATED',
  DATA_EXPLORER_TOKEN_DATA_FETCHED: 'DATA_EXPLORER_TOKEN_DATA_FETCHED',

  DATA_EXPORTER_EXPORT_DATA_FETCHED: 'DATA_EXPORTER_EXPORT_DATA_FETCHED',
  DATA_EXPORTER_PROPERTIES_FETCHED: 'DATA_EXPORTER_PROPERTIES_FETCHED',

  CONSUMPTION_ANALYSIS_DATA_FETCHED: "CONSUMPTION_ANALYSIS_DATA_FETCHED",
  CONSUMPTION_ANALYSIS_METERS_FETCHED: "CONSUMPTION_ANALYSIS_METERS_FETCHED",
  CONSUMPTION_ANALYSIS_TOKEN_GENERATED: 'CONSUMPTION_ANALYSIS_TOKEN_GENERATED',
  CONSUMPTION_ANALYSIS_TOKEN_DATA_FETCHED: 'CONSUMPTION_ANALYSIS_TOKEN_DATA_FETCHED',

  BUILDING_BENCHMARK_REPORT_FETCHED: "BUILDING_BENCHMARK_REPORT_FETCHED",
  BUILDING_BENCHMARK_REPORTS_FETCHED: "BUILDING_BENCHMARK_REPORTS_FETCHED",
  BUILDING_BENCHMARK_REPORT_DELETED: 'BUILDING_BENCHMARK_REPORT_DELETED',
  BUILDING_BENCHMARK_TOKEN_GENERATED: 'BUILDING_BENCHMARK_TOKEN_GENERATED',
  BUILDING_BENCHMARK_REPORT_FETCHED_VIA_TOKEN: 'BUILDING_BENCHMARK_REPORT_FETCHED_VIA_TOKEN',

  BUILDING_BENCHMARK_BUILDING_TYPES: [
    { value: "BAR_PUB_CLUB", label: "Bar, pub or licensed club" },
    { value: "CLINIC", label: "Clinic" },
    { value: "COLD_STORAGE", label: "Cold storage" },
    { value: "COVERED_CAR_PARK", label: "Covered car park" },
    { value: "CULTURAL_ACTIVITIES", label: "Cultural activities" },
    { value: "DRY_SPORTS_LEISURE", label: "Dry sports and leisure facility" },
    { value: "EMERGENCY_SERVICES", label: "Emergency services" },
    { value: "ENTERTAINMENT_HALLS", label: "Entertainment halls" },
    { value: "FITNESS_HEALTH_CENTRE", label: "Fitness and health centre" },
    { value: "GENERAL_ACCOMMODATION", label: "General accommodation" },
    { value: "GENERAL_OFFICE", label: "General office" },
    { value: "GENERAL_RETAIL", label: "General retail" },
    { value: "HIGH_STREET_AGENCY", label: "High street agency" },
    { value: "HOSPITAL_CLINICAL_RESEARCH", label: "Hospital; clinical and research" },
    { value: "HOTEL", label: "Hotel" },
    { value: "LABORATORY_OPERATING_THEATRE", label: "Laboratory or operating theatre" },
    { value: "LARGE_FOOD_STORE", label: "Large food store" },
    { value: "LARGE_NON_FOOD_SHOP", label: "Large non-food shop" },
    { value: "LONG_TERM_RESIDENTIAL", label: "Long term residential" },
    { value: "PUBLIC_BUILDINGS_LIGHT_USAGE", label: "Public buildings with light usage" },
    { value: "PUBLIC_WAITING_CIRCULATION", label: "Public waiting or circulation" },
    { value: "RESTAURANT", label: "Restaurant" },
    { value: "SCHOOLS_SEASONAL_PUBLIC_BUILDINGS", label: "Schools and seasonal public buildings" },
    { value: "SMALL_FOOD_STORE", label: "Small food store" },
    { value: "STORAGE_FACILITY", label: "Storage facility" },
    { value: "SWIMMING_POOL_CENTRE", label: "Swimming pool centre" },
    { value: "TERMINAL", label: "Terminal" },
    { value: "UNIVERSITY_CAMPUS", label: "University campus" },
    { value: "WORKSHOP", label: "Workshop" }
  ]
};

export default constants;

import React, { useState, useCallback, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Table, Input, Button } from 'antd';
import { Icon } from '../Icon';
import _ from 'lodash';
import moment from 'moment';
import styles from './AntTable.module.scss';
import classNames from 'classnames';
import ReactDOMServer from 'react-dom/server';

const AntTable = (props) => {
    const {
        columns,
        dataSource = [],
        tags,
        addNewButton,
        hasInputFilter = false,
        multiTagFilter,
        pagination = false,
        onRow,
        scroll = {},
        exporting,
        tableId,
        stickyFilters = true,
        ...rest
    } = props;

    // State initializations read from session storage if stickyFilters is true
    const [inputFilter, setInputFilter] = useState(() =>
        stickyFilters ? sessionStorage.getItem(`${tableId}_inputFilter`) || '' : ''
    );
    const [selectedTag, setSelectedTag] = useState(() =>
        stickyFilters ? sessionStorage.getItem(`${tableId}_selectedTag`) || 'All' : 'All'
    );
    const [sortField, setSortField] = useState(() => {
        if (stickyFilters) {
            const storedSortField = sessionStorage.getItem(`${tableId}_sortField`);
            return storedSortField ? JSON.parse(storedSortField) : null;
        }
        return null;
    });
    const [sortOrder, setSortOrder] = useState(() =>
        stickyFilters ? sessionStorage.getItem(`${tableId}_sortOrder`) || null : null
    );

    const [tableHeight, setTableHeight] = useState(null);
    const [isNonScrollable, setIsNonScrollable] = useState(false);
    const wrapperRef = useRef(null);
    const filtersRef = useRef(null);

    const filterData = useCallback((filterValue, tagLabel) => {
        const lowercasedFilter = filterValue.toLowerCase();
        let filtered = dataSource;

        if (lowercasedFilter) {
            filtered = dataSource.filter(item => {
                return columns.some(column => {
                    if (column.filterable) {
                        const value = _.get(item, column.dataIndex);
                        return value && value.toString().toLowerCase().includes(lowercasedFilter);
                    }
                    return false;
                });
            });
        }

        if (tags && tags.length > 0) {
            const selectedTagObj = tags.find(tag => tag.label === tagLabel);
            if (selectedTagObj && selectedTagObj.condition) {
                filtered = filtered.filter(record => selectedTagObj.condition(record));
            }
        }

        return filtered;
    }, [dataSource, columns, tags]);

    const filteredData = filterData(inputFilter, selectedTag);

    const handleInputChange = (e) => {
        const value = e.target.value;
        setInputFilter(value);
        if (stickyFilters) {
            sessionStorage.setItem(`${tableId}_inputFilter`, value);
        }
    };

    const handleTagClick = (label) => {
        setSelectedTag(label);
        if (stickyFilters) {
            sessionStorage.setItem(`${tableId}_selectedTag`, label);
        }
    };

    const handleTableChange = (pagination, filters, sorter) => {
        setSortField(sorter.field);
        setSortOrder(sorter.order);
        if (stickyFilters) {
            sessionStorage.setItem(`${tableId}_sortField`, JSON.stringify(sorter.field));
            sessionStorage.setItem(`${tableId}_sortOrder`, sorter.order);
        }
    };

    const getInputFilter = () => {
        return (
            <Input
                className={styles.inputFilter}
                placeholder={`Search in ${filteredData.length} entries...`}
                value={inputFilter}
                onChange={handleInputChange}
            />
        );
    };

    const getTagFilter = () => {
        return (
            <div className={styles.tagFilters}>
                {tags.map((tag) => {
                    let tagClasses = [styles.tag];
                    if (selectedTag === tag.label) {
                        tagClasses.push(styles.active);
                    }

                    return (
                        <div
                            key={tag.label}
                            className={tagClasses.join(' ')}
                            onClick={() => handleTagClick(tag.label)}
                        >
                            {tag.label}
                        </div>
                    );
                })}
            </div>
        );
    }

    // Modify columns to include custom sorter and render function
    const modifiedColumns = columns.map(column => {
        const newColumn = { ...column };

        // Wrap the existing render function or create a new one
        const originalRender = newColumn.render;
        newColumn.render = (text, record, index) => {
            const value = originalRender
                ? originalRender(text, record, index)
                : _.get(record, newColumn.dataIndex);

            return value === null || value === undefined
                ? <span style={{ opacity: 0.2 }}>N/A</span>
                : value;
        };

        // Add custom sorter if not present
        if (!newColumn.sorter) {
            newColumn.sorter = (a, b, sortOrder) => {
                const aValue = Array.isArray(newColumn.dataIndex)
                    ? _.get(a, newColumn.dataIndex)
                    : _.get(a, newColumn.dataIndex);
                const bValue = Array.isArray(newColumn.dataIndex)
                    ? _.get(b, newColumn.dataIndex)
                    : _.get(b, newColumn.dataIndex);

                // Always push null values to the bottom
                if (aValue === null || aValue === undefined) return sortOrder === 'ascend' ? 1 : -1;
                if (bValue === null || bValue === undefined) return sortOrder === 'ascend' ? -1 : 1;
                if (aValue === null && bValue === null) return 0;

                // Compare numbers
                if (typeof aValue === 'number' && typeof bValue === 'number') {
                    return aValue - bValue;
                }

                // Compare strings (case-insensitive, trimmed)
                return String(aValue).toLowerCase().trim().localeCompare(String(bValue).toLowerCase().trim());
            };
        }

        // Set the sortOrder based on the stored state
        if (Array.isArray(sortField)
            ? _.isEqual(newColumn.dataIndex, sortField)
            : newColumn.dataIndex === sortField) {
            newColumn.sortOrder = sortOrder;
        }

        return newColumn;
    });

    const _triggerDownload = () => {
        // Filter out columns that have dontExport set to true
        const exportableColumns = columns.filter(col => !col.dontExport);

        let csv = [];
        let headers = exportableColumns.map(col => col.title);
        csv.push(headers.join(","));

        const exportType = exporting.type || 'formatted';

        filteredData.forEach(row => {
            let rowData = exportableColumns.map(col => {
                let value;
                if (exportType === 'formatted' && col.render) {
                    // Use the render function to get the displayed value
                    value = col.render(_.get(row, col.dataIndex), row);
                    // Convert React elements or HTML to string, maybe change to substitute render?
                    if (React.isValidElement(value)) {
                        value = ReactDOMServer.renderToStaticMarkup(value);
                    } else if (typeof value === 'object' && value !== null) {
                        value = JSON.stringify(value);
                    }
                } else {
                    value = _.get(row, col.dataIndex);
                }
                // Convert to string, escape commas and double quotes, and wrap in quotes
                value = value !== null && value !== undefined ?
                    '"' + String(value).replace(/"/g, '""').replace(/\n/g, ' ') + '"' :
                    '""';
                return value;
            });
            csv.push(rowData.join(","));
        });

        let csvFile = new Blob([csv.join("\n")], { type: "text/csv" });
        let downloadLink = document.createElement("a");

        const filename = exporting.filename || `${tableId} ${moment().format("DD_MMM_YYYY")}.csv`;
        downloadLink.download = filename;
        downloadLink.href = window.URL.createObjectURL(csvFile);
        downloadLink.style.display = "none";
        document.body.appendChild(downloadLink);
        downloadLink.click();
    };

    useEffect(() => {
        const updateTableDimensions = () => {
            if (wrapperRef.current && filtersRef.current) {
                const wrapperWidth = wrapperRef.current.clientWidth;
                const wrapperHeight = wrapperRef.current.clientHeight;
                const filtersHeight = filtersRef.current.clientHeight;

                if (!scroll.y) {
                    setTableHeight(wrapperHeight - filtersHeight - 65);
                }

                setIsNonScrollable(scroll.x && wrapperWidth > scroll.x);
            }
        };

        updateTableDimensions();
        window.addEventListener('resize', updateTableDimensions);

        return () => window.removeEventListener('resize', updateTableDimensions);
    }, [scroll]);

    const tableScroll = scroll.y ? scroll : { ...scroll, y: tableHeight || 0 };

    const getFilters = () => {
        if (!hasInputFilter && !tags && !exporting) {
            return <div className={styles.filters} ref={filtersRef} style={{ margin: 0 }}></div>
        }
        return (
            <div className={styles.filters} ref={filtersRef}>
                {hasInputFilter && getInputFilter()}
                <div className='d-flex'>
                    {tags && getTagFilter()}
                    {exporting && (
                        <Button
                            className="button blue"
                            icon={<Icon name="Download" color={'#fff'} size={18} />}
                            size="small"
                            onClick={_triggerDownload}
                            style={{ height: '34px', minWidth: '34px', marginLeft: '10px' }}
                        >Export</Button>
                    )}
                </div>
            </div>
        );
    }

    return (
        <div className={styles.wrapper} ref={wrapperRef}>
            {getFilters()}
            <Table
                className={classNames(
                    styles.table,
                    {
                        [styles.clickable]: !!onRow,
                        [styles.nonScrollable]: isNonScrollable
                    }
                )}
                columns={modifiedColumns}
                dataSource={filteredData}
                pagination={pagination}
                showSorterTooltip={false}
                onRow={onRow}
                scroll={tableScroll}
                onChange={handleTableChange}
                {...rest}
            />
        </div>
    );
};

AntTable.propTypes = {
    /** Unique identifier for the table, used for session storage keys */
    tableId: PropTypes.string.isRequired,

    /** Array of column definitions for the table */
    columns: PropTypes.arrayOf(PropTypes.shape({
        /** Title of the column */
        title: PropTypes.string.isRequired,
        /** Data index or key of the column */
        dataIndex: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.arrayOf(PropTypes.string)
        ]).isRequired,
        /** Whether the column is filterable */
        filterable: PropTypes.bool,
        /** Key of the column */
        key: PropTypes.string,
        /** Custom render function for the column */
        render: PropTypes.func,
        /** Whether to exclude this column from CSV export */
        dontExport: PropTypes.bool,
        /** Custom sorter function for the column */
        sorter: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
        /** Width of the column */
        width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        /** Whether the column can be resized */
        className: PropTypes.string,
        /** Style object for the column */
        style: PropTypes.object,
    })).isRequired,

    /** Array of data objects to be displayed in the table */
    dataSource: PropTypes.arrayOf(PropTypes.object),

    /** Array of tag objects for filtering */
    tags: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string.isRequired,
        condition: PropTypes.func.isRequired,
    })),

    /** Whether to show an input filter for the table */
    hasInputFilter: PropTypes.bool,

    /** Whether to allow multiple tag selection for filtering */
    multiTagFilter: PropTypes.bool,

    /** Configuration object for pagination or boolean to enable/disable */
    pagination: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),

    /** Function to handle row events (e.g., onClick) */
    onRow: PropTypes.func,

    /** Configuration object for table scrolling */
    scroll: PropTypes.shape({
        x: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        y: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    }),

    /** Configuration for exporting table data */
    exporting: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.shape({
            filename: PropTypes.string,
            // Formatted tries to use render method for csv whereas raw uses raw data e.g.
            // value = 26.773 => formatted = "26.8 °C" and raw = 26.773
            type: PropTypes.oneOf(['formatted', 'raw']),
        })
    ]),

    /** Whether to use session storage for persisting table state */
    stickyFilters: PropTypes.bool,
};

export default AntTable;
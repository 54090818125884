import { useEffect } from "react";
import "./DocumentTitle.scss";
import PropTypes from "prop-types";

const DocumentTitle = (props) => {
  const { title, notifications } = props;

  useEffect(() => {
    if (typeof title === "string") {
      const notification = notifications ? `(${notifications}) ` : "";
      const suffix = " | OPNBuildings";
      document.title = notification + title + suffix;
    } else {
      document.title = "OPNBuildings";
    }
  }, [notifications, title]);

  return null;
};

export default DocumentTitle;

DocumentTitle.propTypes = {
  title: PropTypes.string,
  notifications: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

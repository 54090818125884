import React, { useState, useMemo } from 'react';
import styles from './BenchmarkQuestionnaire.module.scss';
import moment from 'moment';
import dayjs from 'dayjs';
import Constants from '../../../../constants';
import ComponentUtils from "../../../../utils/ComponentUtils";
import GeneralUtils from "../../../../utils/GeneralUtils";
import { Steps, Form, Input, Select, Button, DatePicker, InputNumber, Row, Col, Typography, Card, Radio, Space } from 'antd';

const { Title } = Typography;
const { Step } = Steps;
const { Option } = Select;

const formatNumber = (value) => {
    return value ? new Intl.NumberFormat().format(value) : '';
};

const BenchmarkQuestionnaire = ({ onSubmit }) => {
    const [current, setCurrent] = useState(0);
    const [occupationPeriod, setOccupationPeriod] = useState('year');
    const [formData, setFormData] = useState({
        name: '',
        description: '',
        location: '',
        type: '',
        floor_area: 0,
        hours_of_occupation: 0,
        data_period_start: '',
        currency: 'EUR',
        electricity: {
            consumption: 0,
            cost: 0
        },
        thermal: {
            fuel_type: '',
            consumption: 0,
            cost: 0
        },
        roi: {
            payback_period: 3,
            inflation_rate: 2
        }
    });

    const fuelTypeEnum = ["ELECTRICITY", "FUEL_OIL", "NATURAL_GAS_CONDENSING_BOILER", "NATURAL_GAS_NON_CONDENSING_BOILER"];

    function convertFuelTypeToDisplayText(value) {
        switch (value) {
          case "NATURAL_GAS_CONDENSING_BOILER":
            return "Natural Gas - Condensing Boiler";
          case "NATURAL_GAS_NON_CONDENSING_BOILER":
            return "Natural Gas - Traditional Non-condensing Boiler";
          default:
            return GeneralUtils.defaultValueToDisplayText(value);   
        }
      }

    const fuelTypeOptions = ComponentUtils.generateLabelsForValues(fuelTypeEnum, convertFuelTypeToDisplayText);

    const buildingTypes = Constants.BUILDING_BENCHMARK_BUILDING_TYPES;

    const handleInputChange = (name, value) => {
        setFormData(prevData => {
            const newData = { ...prevData };
            if (name.includes('.')) {
                const [key, subKey] = name.split('.');
                newData[key] = { ...newData[key], [subKey]: value };
            } else {
                newData[name] = value;
            }
            return newData;
        });
    };

    const handleHeatingFuelTypeChange = (value) => {
        handleInputChange('thermal.fuel_type', value);
    };

    const handleOccupationPeriodChange = (e) => {
        const newPeriod = e.target.value;
        if (formData.hours_of_occupation && newPeriod === 'week' && formData.hours_of_occupation > 168) {
            handleInputChange('hours_of_occupation', 168);
        }
        setOccupationPeriod(newPeriod);
    };

    const handleHoursOfOccupationChange = (value) => {
        handleInputChange('hours_of_occupation', value);
    };

    const handleLocationChange = (value) => {
        handleInputChange('location', value);
        let currency = 'EUR';
        if (value === 'GREAT_BRITAIN' || value === 'NORTHERN_IRELAND') {
            currency = 'GBP';
        }
        handleInputChange('currency', currency);
    };

    const handleDateChange = (date, dateString) => {
        handleInputChange('data_period_start', moment(dateString).format('YYYY-MM-DD'));
    };

    const validateStep = (stepIndex) => {
        switch (stepIndex) {
            case 0:
                return formData.name && formData.floor_area && formData.type && formData.location && formData.hours_of_occupation && formData.floor_area > 0 && formData.hours_of_occupation > 0;
            case 1:
                return formData.currency && formData.data_period_start;
            case 2:
                return formData.electricity.consumption && formData.electricity.cost &&
                    formData.thermal.fuel_type &&
                    (formData.thermal.fuel_type === 'ELECTRICITY' ||
                        (formData.thermal.consumption && formData.thermal.cost));
            case 3:
                return formData.roi.payback_period && formData.roi.inflation_rate;
            default:
                return false;
        }
    };

    const validateAllSteps = () => {
        let result = true;
        stepContents.forEach((_, index) => {
            if (!validateStep(index)) {
                result = false;
            }
        });

        return result;
    };

    const getStepStatus = (stepIndex) => {
        if (stepIndex === current) return 'process';
        if (stepIndex > current) return 'wait';
        return validateStep(stepIndex) ? 'finish' : 'error';
    };

    const onFinish = () => {
        if (validateAllSteps()) {
            const modifiedFormData = {
                ...formData,
            }
            if (formData.thermal.fuel_type === 'ELECTRICITY') {
                delete modifiedFormData.thermal;
            }
            onSubmit(modifiedFormData);
        } else {
            console.error('Please complete all required fields');
        }
    };

    const next = () => {
        setCurrent(current + 1);
    };

    const prev = () => {
        setCurrent(current - 1);
    };

    const handleInputFocus = (event) => {
        event.target.select();
    };

    const getMissingFields = (stepIndex) => {
        const missing = [];
        switch (stepIndex) {
            case 0:
                if (!formData.name) missing.push('Building Name');
                if (!formData.floor_area || formData.floor_area <= 0) missing.push('Floor Area');
                if (!formData.type) missing.push('Building Type');
                if (!formData.location) missing.push('Building Location');
                if (!formData.hours_of_occupation || formData.hours_of_occupation <= 0) missing.push('Hours of Occupation');
                break;
            case 1:
                if (!formData.currency) missing.push('Currency');
                if (!formData.data_period_start) missing.push('Start Date');
                break;
            case 2:
                if (!formData.electricity.consumption) missing.push('Electricity Consumption');
                if (!formData.electricity.cost) missing.push('Electricity Cost');
                if (!formData.thermal.fuel_type) missing.push('Main Source of Heating');
                if (formData.thermal.fuel_type && formData.thermal.fuel_type !== 'ELECTRICITY') {
                    if (!formData.thermal.consumption) missing.push('Thermal Energy Consumption');
                    if (!formData.thermal.cost) missing.push('Thermal Energy Cost');
                }
                break;
            case 3:
                if (!formData.roi.payback_period) missing.push('Payback Period');
                if (!formData.roi.inflation_rate) missing.push('Inflation Rate');
                break;
            default:
                break;
        }
        return missing;
    };

    const stepContents = useMemo(() => [
        // Building Information
        <Row gutter={16} key="building-info">
            <Col span={24} xl={12}>
                <Form
                    layout="vertical"
                    initialValues={{
                        name: formData.name,
                        description: formData.description,
                        floor_area: formData.floor_area,
                        type: formData.type,
                        location: formData.location,
                        hours_of_occupation: formData.hours_of_occupation,
                        occupation_period: formData.occupation_period
                    }}
                >
                    <Form.Item name="name" label="Building Name" rules={[{ required: true, message: 'Building name is required' }]} validateTrigger={['onChange', 'onBlur']}>
                        <Input style={{ width: '300px' }} onChange={(e) => handleInputChange('name', e.target.value)} value={formData.name} />
                    </Form.Item>
                    <Form.Item name="description" label="Building Description">
                        <Input
                            style={{ width: '300px' }}
                            onChange={(e) => handleInputChange('description', e.target.value)}
                            value={formData.description}
                        />
                    </Form.Item>
                    <Form.Item name="location" label="Building Location" rules={[{ required: true, message: 'Building location is required' }]} validateTrigger={['onChange', 'onBlur']}>
                        <Select onChange={handleLocationChange} style={{ width: '300px' }} value={formData.location}>
                            <Option value="GREAT_BRITAIN">Great Britain</Option>
                            <Option value="IRELAND">Ireland</Option>
                            <Option value="NORTHERN_IRELAND">Northern Ireland</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item required label="Floor Area and Building Type">
                        <Space>
                            <Form.Item
                                name="floor_area"
                                noStyle
                                validateTrigger={['onChange', 'onBlur']}
                                rules={[
                                    { required: true, message: 'Floor area is required' },
                                    { type: 'number', min: 1, message: 'Floor area must be greater than 0' }
                                ]}
                            >
                                <InputNumber
                                    style={{ width: 145 }}
                                    min={0}
                                    placeholder="Floor area"
                                    onChange={(value) => handleInputChange('floor_area', value)}
                                    value={formData.floor_area}
                                    addonAfter="m²"
                                    onFocus={handleInputFocus}
                                    formatter={formatNumber}
                                />
                            </Form.Item>
                            <Form.Item
                                name="type"
                                noStyle
                                rules={[{ required: true, message: 'Building type is required' }]}
                                validateTrigger={['onChange', 'onBlur']}
                            >
                                <Select
                                    style={{ width: 145 }}
                                    placeholder="Select building type"
                                    onChange={(value) => handleInputChange('type', value)}
                                    value={formData.type}
                                    options={buildingTypes}
                                    popupMatchSelectWidth={false}
                                    filterSort={(optionA, optionB) =>
                                        optionA.label.toLowerCase().localeCompare(optionB.label.toLowerCase())
                                    }
                                />
                            </Form.Item>
                        </Space>
                    </Form.Item>
                    <Form.Item required label="Hours of Occupation" >
                        <Space>
                            <Form.Item
                                validateTrigger={['onChange', 'onBlur']}
                                name="hours_of_occupation"
                                noStyle
                                rules={[
                                    { required: true, message: 'Hours of occupation is required' },
                                    { type: 'number', min: 1, message: 'Hours of occupation must be greater than 0' }
                                ]}>
                                <InputNumber
                                    min={0}
                                    max={occupationPeriod === 'year' ? 8760 : 168}
                                    style={{ width: '115px' }}
                                    onChange={handleHoursOfOccupationChange}
                                    value={formData.hours_of_occupation}
                                    addonAfter='hrs'
                                    onFocus={handleInputFocus}
                                    formatter={formatNumber}
                                />
                            </Form.Item>
                            <Radio.Group onChange={handleOccupationPeriodChange} value={occupationPeriod}>
                                <Radio.Button value="year">Per Year</Radio.Button>
                                <Radio.Button value="week">Per Week</Radio.Button>
                            </Radio.Group>
                        </Space>
                    </Form.Item>
                </Form>
            </Col>
            <Col span={24} xl={12}>
                <div className={styles.explanation}>
                    <p><strong>Building Name:</strong> Enter the name of the building.</p>
                    <p><strong>Building Description:</strong> Provide a brief description of the building (optional).</p>
                    <p><strong>Building Location:</strong> Select the location of the building. This will automatically set the currency.</p>
                    <p><strong>Floor Area (m²):</strong> Input the total floor area of the building in square meters.</p>
                    <p><strong>Building Type:</strong> Choose the type of building from the available options.</p>
                    <p><strong>Hours of Occupation:</strong> Select whether to enter hours per year or per week, then input the total number of hours the building is occupied.</p>
                    {!validateStep(current) && (
                        <>
                            <p style={{ color: '#ff4d4f', marginTop: '20px' }}><strong>Missing Required Fields:</strong></p>
                            <ul style={{ color: '#ff4d4f', marginBottom: 0 }}>
                                {getMissingFields(current).map((field, index) => (
                                    <li key={index}>{field}</li>
                                ))}
                            </ul>
                        </>
                    )}
                </div>
            </Col>
        </Row>,

        // Financial Information
        <Row gutter={16} key="financial-info">
            <Col span={24} xl={12}>
                <Form
                    layout="vertical"
                    initialValues={{
                        currency: formData.currency,
                        data_period_start: formData.data_period_start ? moment(formData.data_period_start, 'YYYY-MM-DD') : null
                    }}
                >
                    <Form.Item name="currency" label="Currency" rules={[{ required: true, message: 'Currency is required' }]} validateTrigger={['onChange', 'onBlur']}>
                        <Select style={{ width: '300px' }} onChange={(value) => handleInputChange('currency', value)} value={formData.currency}>
                            <Option value="EUR">EUR</Option>
                            <Option value="GBP">GBP</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item name="data_period_start" label="12 Month Period Start" rules={[{ required: true, message: 'Start date is required' }]} validateTrigger={['onChange', 'onBlur']}>
                        <DatePicker
                            picker="month"
                            format="YYYY-MM"
                            style={{ width: '300px' }}
                            defaultPickerValue={dayjs().subtract(1, 'year')}
                            onChange={handleDateChange}
                            value={formData.data_period_start ? moment(formData.data_period_start, 'YYYY-MM') : null}
                            disabledDate={(current) => {
                                const yearAgo = moment().subtract(13, 'months');
                                return current && current > yearAgo;
                            }}
                        />
                    </Form.Item>
                </Form>
            </Col>
            <Col span={24} xl={12}>
                <div className={styles.explanation}>
                    <p><strong>Currency:</strong> The currency is automatically set based on the building location. Currency can be changed if necessary.</p>
                    <p><strong>Start Date (12 Month Period):</strong> Choose the start date for the 12-month analysis period. You can select any month up to 12 months ago. The most recent 11 months are not selectable.</p>
                    {!validateStep(current) && (
                        <>
                            <p style={{ color: '#ff4d4f', marginTop: '20px' }}><strong>Missing Required Fields:</strong></p>
                            <ul style={{ color: '#ff4d4f', marginBottom: 0 }}>
                                {getMissingFields(current).map((field, index) => (
                                    <li key={index}>{field}</li>
                                ))}
                            </ul>
                        </>
                    )}
                </div>
            </Col>
        </Row>,

        // Energy Consumption
        <Row gutter={16} key="energy-consumption">
            <Col span={24} xl={12}>
                <Form
                    layout="vertical"
                    initialValues={{
                        electricity_consumption: formData.electricity.consumption,
                        electricity_cost: formData.electricity.cost,
                        thermal_fuel_type: formData.thermal.fuel_type,
                        thermal_consumption: formData.thermal.consumption,
                        thermal_cost: formData.thermal.cost
                    }}
                >
                    <Form.Item name="electricity_consumption" label="Electricity Consumption" rules={[{ required: true, message: 'Electricity consumption is required' }, { type: 'number', min: 1, message: 'Electricity consumption must be greater than 0' }]} validateTrigger={['onChange', 'onBlur']}>
                        <InputNumber min={0} style={{ width: '300px' }} onChange={(value) => handleInputChange('electricity.consumption', value)} value={formData.electricity.consumption} addonAfter='kWh' onFocus={handleInputFocus} formatter={formatNumber} />
                    </Form.Item>
                    <Form.Item name="electricity_cost" label="Electricity Cost" rules={[{ required: true, message: 'Electricity cost is required' }, { type: 'number', min: 1, message: 'Electricity cost must be greater than 0' }]} validateTrigger={['onChange', 'onBlur']}>
                        <InputNumber min={0} style={{ width: '300px' }} onChange={(value) => handleInputChange('electricity.cost', value)} value={formData.electricity.cost} addonAfter={formData.currency} onFocus={handleInputFocus} formatter={formatNumber} />
                    </Form.Item>
                    <Form.Item name="thermal_fuel_type" label="What's the main source of heating?" rules={[{ required: true, message: 'Main source of heating is required' }]} validateTrigger={['onChange', 'onBlur']}>
                        <Select options={fuelTypeOptions} onChange={handleHeatingFuelTypeChange} style={{ width: '300px' }} dropdownStyle={{ minWidth: 360 }}  value={formData.thermal.fuel_type}/>
                    </Form.Item>
                    {formData.thermal.fuel_type && formData.thermal.fuel_type !== 'ELECTRICITY' && (
                        <>
                            <Form.Item name="thermal_consumption" label="Thermal Energy Consumption" rules={[{ required: true, message: 'Please input the thermal energy consumption!' }, { type: 'number', min: 1, message: 'Thermal energy consumption must be greater than 0' }]} validateTrigger={['onChange', 'onBlur']}>
                                <InputNumber min={0} style={{ width: '300px' }} onChange={(value) => handleInputChange('thermal.consumption', value)} value={formData.thermal.consumption} addonAfter='kWh' onFocus={handleInputFocus} formatter={formatNumber} />
                            </Form.Item>
                            <Form.Item name="thermal_cost" label="Thermal Energy Cost" rules={[{ required: true, message: 'Please input the thermal energy cost!' }, { type: 'number', min: 1, message: 'Thermal energy cost must be greater than 0' }]} validateTrigger={['onChange', 'onBlur']}>
                                <InputNumber min={0} style={{ width: '300px' }} onChange={(value) => handleInputChange('thermal.cost', value)} value={formData.thermal.cost} addonAfter={formData.currency} onFocus={handleInputFocus} formatter={formatNumber} />
                            </Form.Item>
                        </>
                    )}
                </Form>
            </Col>
            <Col span={24} xl={12}>
                <div className={styles.explanation}>
                    <p><strong>Electricity Consumption (kWh):</strong> Enter the total electricity consumption, in kilowatt-hours, for the analysis period.</p>
                    <p><strong>Electricity Cost:</strong> Input the total cost of electricity for the analysis period.</p>
                    <p><strong>Heating Fuel Type:</strong> Select the primary source of fuel or thermal energy used for heating the building.</p>
                    {formData.thermal.fuel_type === 'ELECTRICITY' && (
                        <p><strong>Note:</strong> When electricity is the primary source of heat, industry benchmarks will be used to proportionally allocate between heating and other uses.</p>
                    )}
                    {formData.thermal.fuel_type && formData.thermal.fuel_type !== 'ELECTRICITY' && (
                        <>
                            <p><strong>Heating Energy Consumption (kWh):</strong> Enter the total heating fuel consumption, in kilowatt-hours, for the analysis period.</p>
                            <p><strong>Heating Energy Cost:</strong> Input the total cost of heating for the analysis period.</p>
                        </>
                    )}
                    {!validateStep(current) && (
                        <>
                            <p style={{ color: '#ff4d4f', marginTop: '20px' }}><strong>Missing Required Fields:</strong></p>
                            <ul style={{ color: '#ff4d4f', marginBottom: 0 }}>
                                {getMissingFields(current).map((field, index) => (
                                    <li key={index}>{field}</li>
                                ))}
                            </ul>
                        </>
                    )}
                </div>
            </Col>
        </Row>,

        // Return on Investment
        <Row gutter={16} key="roi">
            <Col span={24} xl={12}>
                <Form
                    layout="vertical"
                    initialValues={{
                        payback_period: formData.roi.payback_period,
                        inflation_rate: formData.roi.inflation_rate
                    }}
                >
                    <Form.Item
                        name="payback_period"
                        label="Required Payback Period"
                        rules={[
                            { required: true, message: 'Please input the required payback period!' },
                            { type: 'number', min: 1, max: 20, message: 'Payback period must be between 1 and 20 years' }
                        ]}
                        validateTrigger={['onChange', 'onBlur']}
                    >
                        <InputNumber min={1} max={20} style={{ width: '300px' }} onChange={(value) => handleInputChange('roi.payback_period', value)} value={formData.roi.payback_period} addonAfter='years' onFocus={handleInputFocus} />
                    </Form.Item>
                    <Form.Item
                        name="inflation_rate"
                        label="Inflation Rate"
                        rules={[{ required: true, message: 'Inflation rate is required' }]}
                        validateTrigger={['onChange', 'onBlur']}
                    >
                        <InputNumber
                            min={0}
                            max={100}
                            step={0.1}
                            style={{ width: '300px' }}
                            onChange={(value) => handleInputChange('roi.inflation_rate', value)}
                            value={formData.roi.inflation_rate}
                            addonAfter='%'
                            onFocus={handleInputFocus}
                        />
                    </Form.Item>
                </Form>
            </Col>
            <Col span={24} xl={12}>
                <div className={styles.explanation}>
                    <p><strong>Required Payback Period (years):</strong> Enter the desired payback period in years (between 1 and 20).</p>
                    <p><strong>Inflation Rate (%):</strong> Input the expected inflation rate as a percentage. You can use decimals with a step of 0.1%.</p>
                    {!validateStep(current) && (
                        <>
                            <p style={{ color: '#ff4d4f', marginTop: '20px' }}><strong>Missing Required Fields:</strong></p>
                            <ul style={{ color: '#ff4d4f', marginBottom: 0 }}>
                                {getMissingFields(current).map((field, index) => (
                                    <li key={index}>{field}</li>
                                ))}
                            </ul>
                        </>
                    )}
                </div>
            </Col>
        </Row>
        // eslint-disable-next-line
    ], [formData, occupationPeriod, getMissingFields]);

    return (
        <>
            <Title level={3} >Building Benchmark</Title>
            <Card style={{ background: '#FDFDFB', borderBottomLeftRadius: '0px', borderBottomRightRadius: '0px' }}>
                <Steps current={current} onChange={setCurrent} size='small'>
                    <Step title="Building Information" status={getStepStatus(0)} />
                    <Step title="Financial Information" status={getStepStatus(1)} />
                    <Step title="Energy Consumption" status={getStepStatus(2)} />
                    <Step title="Return on Investment" status={getStepStatus(3)} />
                </Steps>
            </Card>
            <Card style={{ borderTopLeftRadius: '0px', borderTopRightRadius: '0px' }}>
                <div className={styles.stepsContent}>
                    <Row justify="center" style={{ minHeight: '400px' }}>
                        <Col span={20}>
                            {stepContents[current]}
                        </Col>
                    </Row>
                </div>
                <div className={styles.stepsAction}>
                    <Row justify="space-between" align="middle">
                        <Col>
                            {current > 0 && (
                                <Button onClick={() => prev()}>
                                    Previous
                                </Button>
                            )}
                        </Col>
                        <Col>
                            {current < stepContents.length - 1 && (
                                <Button type="primary" onClick={() => next()} >
                                    Next
                                </Button>
                            )}
                            {current === stepContents.length - 1 && (
                                <Button type="primary" onClick={onFinish} disabled={!validateAllSteps()}>
                                    Submit
                                </Button>
                            )}
                        </Col>
                    </Row>
                </div>
            </Card>
        </>
    );
};

export default BenchmarkQuestionnaire;
import jwtDecode from 'jwt-decode';

function getToken() {
    return localStorage.getItem('id_token');
}

function validateToken(token) {
    try {
        const decodedToken = jwtDecode(token);
        const currentTime = Date.now() / 1000;
        return decodedToken.exp > currentTime;
    } catch (error) {
        return false;
    }
}

function redirectToLogin() {
    window.location.href = '/login';
}

export function checkTokenOnFocus() {

    const handleFocus = () => {
        const token = getToken();
        if (token) {
            const isValid = validateToken(token);
            if (!isValid) {
                redirectToLogin();
            }
        }
    };

    window.addEventListener('focus', handleFocus);

    return () => {
        window.removeEventListener('focus', handleFocus);
    };
}
import React, { Component } from "react";
import "./PointDebugComponent.scss";
import AdminActions from "../../actions/adminActions";
import AdminStore from "../../stores/adminStore";
import { HighChart } from "../../components/HighChart";
import { SearchSelect } from "../../components/SearchSelect";
import { Spinner } from "../../components/Spinner";
import { Icon } from "../../components/Icon";
import _ from "lodash";

class PointDebugComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      all_points: [],
      selected_point: null,
      selected_controller: null,
      selected_appliance: null,
      selected_property: null,
      search_input: "",
      loading: false,
      modal_object: null,
      current_point_name: "",
      current_point_names: [],
    };
    this._onPointsChange = this._onPointsChange.bind(this);
    this._onFilterInput = this._onFilterInput.bind(this);
    this._selectAppliance = this._selectAppliance.bind(this);
    this._selectController = this._selectController.bind(this);
    this._selectProperty = this._selectProperty.bind(this);
  }

  _onPointsChange() {
    this.setState({
      loading: false,
    });
  }

  UNSAFE_componentWillMount() {
    AdminStore.addChangeListener(this._onPointsChange);
  }

  componentWillUnmount() {
    AdminStore.clear();
    AdminStore.removeChangeListener(this._onPointsChange);
  }

  componentDidMount() {

    this.setState({
      all_points: this.props.points,
      modal_object: this.props.modalObject,
    });
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.building.building_id && nextProps.building.building_id) {
      let building_id = Number.parseInt(nextProps.building.building_id);
      let prev_Building_id = Number.parseInt(this.props.building.building_id);
      if (building_id !== prev_Building_id) {
      }

      if (
        this.props.ts_start &&
        this.props.ts_end &&
        nextProps.ts_start &&
        nextProps.ts_end
      ) {
        if (
          this.props.ts_start !== nextProps.ts_start ||
          this.props.ts_end !== nextProps.ts_end
        ) {
          if (
            this.state.selected_appliance !== null &&
            this.state.selected_controller !== null
          ) {
            if (this.state.selected_point != null) {
              AdminActions.getPointValues(
                this.state.selected_point,
                nextProps.ts_start,
                nextProps.ts_end
              );
            }
          }
        }
      }
    }
  }

  _selectAppliance(selected_appliance) {
    this.setState({
      selected_appliance: selected_appliance,
    });
  }

  _selectController(selected_controller) {
    this.setState({
      selected_controller: selected_controller,
    });
  }

  _selectProperty(selected_property) {
    this.setState({ selected_property: selected_property });
  }

  _onFilterInput(event) {
    this.setState({ search_input: event.target.value });
  }

  getTableHeader() {
    return (
      <thead>
        <tr>
          <th
            className="wd-15p tx-mont tx-medium"
            style={{ cursor: "pointer" }}
          >
            Name
          </th>
          <th className="wd-15p tx-10-force tx-mont tx-medium tx-center">
            Description
          </th>
          <th className="wd-15p tx-10-force tx-mont tx-medium tx-center">
            Appliance
          </th>
          <th className="wd-15p tx-10-force tx-mont tx-medium tx-center">
            Controller
          </th>
          <th className="wd-10p tx-10-force tx-mont tx-medium tx-center">
            Property
          </th>
          <th className="wd-10p tx-10-force tx-mont tx-medium tx-center">
            Probability
          </th>
          <th className="wd-10p tx-10-force tx-mont tx-medium tx-center"></th>
        </tr>
      </thead>
    );
  }

  getPointValues(e, point) {
    e.preventDefault();
    AdminActions.getPointValues(point, this.props.ts_start, this.props.ts_end);
    this.setState({ selected_point: point, loading: true });
  }

  getApplianceOptions() {
    // All null values are filtered out
    let availableAppliances = [
      ...new Set(
        this.state.all_points.map((pt) => pt.data_source.name).filter((e) => e)
      ),
    ];
    availableAppliances = availableAppliances.map((controller) => {
      return { name: controller };
    });
    availableAppliances = [{ name: "ALL APPLIANCES" }, ...availableAppliances];
    return availableAppliances;
  }

  getControllerOptions() {
    // All null values are filtered out
    let availableControllers = [
      ...new Set(
        this.state.all_points.map((pt) => pt.controller_name).filter((e) => e)
      ),
    ];
    availableControllers = availableControllers.map((controller) => {
      return { name: controller };
    });
    availableControllers = [
      { name: "ALL CONTROLLERS" },
      ...availableControllers,
    ];
    return availableControllers;
  }

  getPropertyOptions() {
    // All null values are filtered out
    let availableProperties = [
      ...new Set(
        this.state.all_points
          .map((pt) => pt.suggested_property)
          .filter((e) => e)
      ),
    ];
    availableProperties = availableProperties.map((property) => {
      return { name: property };
      // return { name: property.toUpperCase() };
    });
    availableProperties = [{ name: "ALL PROPERTIES" }, ...availableProperties];
    return availableProperties;
  }

  getPointRows() {
    let searchValue = this.state.search_input;
    let displayPoints = this.state.all_points;

    // property filter
    if (
      this.state.selected_property &&
      this.state.selected_property.name !== "ALL PROPERTIES"
    ) {
      displayPoints = _.filter(
        displayPoints,
        function (obj) {
          if (obj.suggested_property === null) {
            return false;
          }
          return (
            obj.suggested_property.toUpperCase() ===
            this.state.selected_property.name.toUpperCase()
          );
        }.bind(this)
      );
    }

    // controller filter
    if (
      this.state.selected_controller &&
      this.state.selected_controller.name !== "ALL CONTROLLERS"
    ) {
      displayPoints = _.filter(
        displayPoints,
        function (obj) {
          if (obj.controller_name === null) {
            return false;
          }
          return (
            obj.controller_name.toUpperCase() ===
            this.state.selected_controller.name.toUpperCase()
          );
        }.bind(this)
      );
    }

    // appliance filter
    if (
      this.state.selected_appliance &&
      this.state.selected_appliance.name !== "ALL APPLIANCES"
    ) {
      displayPoints = _.filter(
        displayPoints,
        function (obj) {
          if (obj.data_source.name === null) {
            return false;
          }
          return (
            obj.data_source.name.toUpperCase() ===
            this.state.selected_appliance.name.toUpperCase()
          );
        }.bind(this)
      );
    }

    // input filter
    if (this.state.search_input && this.state.search_input.length > 0) {
      displayPoints = _.filter(displayPoints, function (obj) {
        return (
          obj.name.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1 ||
          obj.controller_name
            .toLowerCase()
            .indexOf(searchValue.toLowerCase()) !== -1 ||
          obj.description.toLowerCase().indexOf(searchValue.toLowerCase()) !==
          -1
        );
      });
    }

    if (displayPoints.length === 0) {
      return (
        <tr>
          <td className="valign-middle">
            <div className="d-flex align-items-center">
              <div>
                <div className="tx-inverse">No points available.</div>
              </div>
            </div>
          </td>
          <td className="valign-middle tx-center"></td>
          <td className="valign-middle tx-center"></td>
          <td className="valign-middle tx-center"></td>
          <td className="valign-middle tx-center"></td>
          <td className="valign-middle tx-center"></td>
          <td className="valign-middle tx-center"></td>
        </tr>
      );
    } else {

      const associated_property = this.props.modifiedEntity.properties.find(pr => pr.property_id === this.state.modal_object.property.property_id && pr.code === this.state.modal_object.property.code);
      const associated_updated_mappings = _.get(associated_property, 'updated_mappings', []);

      // Sort by probability if property is selected
      let sortedDisplayPoints = _.sortBy(displayPoints, ['suggested_probability', 'name', 'description', 'data_source_name']);
      if (_.get(this.state, 'selected_property.name') === 'ALL PROPERTIES' || this.state.selected_property === null) {
        // Sort by name if no property is selected
        sortedDisplayPoints = _.sortBy(displayPoints, ['name', 'description', 'data_source_name', 'suggested_probability']);
      }

      return sortedDisplayPoints
        .reverse()
        .map((point, key) => {

          const pointSelected = associated_updated_mappings.find(e => point.name === e.name && e.data_source_id === point.fk_data_source_id && point.instance_number === e.instance_number)

          return (
            <tr
              key={key}
              className={`${point === this.state.selected_point ? "selected-row" : ""
                } hover-row`}
              onClick={(e) => this.getPointValues(e, point)}
            >
              <td className="valign-middle">
                <div className="d-flex align-items-center">
                  <div>
                    <div className="tx-inverse">
                      {point.name}
                    </div>
                  </div>
                </div>
              </td>
              <td className="valign-middle tx-center">{point.description}</td>
              <td className="valign-middle tx-center">
                {point.data_source.name}
              </td>
              <td className="valign-middle tx-center">
                {point.controller_name}
              </td>
              <td className="valign-middle tx-center">
                {point.suggested_property ? point.suggested_property : "N/A"}
              </td>
              <td className="valign-middle tx-center">
                {point.suggested_probability
                  ? Math.round(point.suggested_probability)
                  : "0"}
                %
              </td>
              <td className="valign-middle tx-center">
                <button
                  style={{ minWidth: "80px" }}
                  className={`btn ${pointSelected
                    ? "btn-success"
                    : "btn-info"
                    }`}
                  onClick={(e) => {
                    e.stopPropagation();
                    this.props.selectedPropertyCallback(
                      point,
                      this.state.modal_object
                    );
                  }}
                >
                  {pointSelected ? "Selected" : "Select"}
                </button>
              </td>
            </tr>
          );
        });
    }
  }

  getPointChart() {
    let chart_data = AdminStore.getPointValues();
    let chart_id = "DebugChart1";
    let title = "Select A Point";

    if (this.state.loading) {
      return (
        <div className="row" style={{ height: "30%" }}>
          <div className="col-12 col-sm-12 ht-100p">
            <div className="card bd-0 ht-100p">
              <Spinner height={"100%"} text={"Your data is being loaded..."} />
            </div>
          </div>
        </div>
      );
    }

    if (chart_data.length === 0) {
      return (
        <div className="row chart-wrapper" style={{ height: "30%" }}>
          <div className="col-12 col-sm-12 ht-100p">
            <div className="card bd-0 ht-100p" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <h5 className="card-title tx-uppercase tx-12 mg-b-0">
                No Data Available
              </h5>
              <div className="close-button" onClick={() => this.setState({ selected_point: null })}>Close</div>
            </div>
          </div>
        </div>
      );
    }

    if (chart_data && chart_data.length > 0 && this.state.selected_point) {
      title = chart_data[0].name;
      return (
        <div className="row chart-wrapper" style={{ height: "30%" }}>
          <div className="col-12 col-sm-12 ht-100p">
            {this.getChartHeaderContent(title)}
            <div
              className="card-body bd pd-b-0"
              style={{ backgroundColor: "#fff", height: "100%" }}
            >
              <HighChart
                main_type="line"
                exporting={true}
                series={chart_data}
                dataType={""}
                chart_Tag={chart_id}
              />
            </div>
          </div>
        </div>
      );
    }

    return null;
  }

  getChartHeaderContent(title) {
    return (
      <div className="card-header bg-transparent pd-x-25 pd-y-15 bd-0 d-flex justify-content-between align-items-center">
        <div style={{ float: "left", display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
          <h6
            className="card-title tx-uppercase tx-12 mg-b-0"
            style={{ float: "left", marginTop: "6px" }}
          >
            {title}
          </h6>
          <div className="close-button" onClick={() => this.setState({ selected_point: null })}>Close</div>
        </div>
      </div>
    );
  }

  currentMappingClickHandler(name) {
    this.setState({
      selected_controller: null,
      selected_appliance: null,
      selected_property: null,
      search_input: name,
    });
  }

  getUnitAndPropertyName() {
    let unit_and_property_label = "";
    const property_id =
      this.state.modal_object && this.state.modal_object.property.property_id;
    if (property_id) {
      unit_and_property_label = `${this.state.modal_object.unit.properties.find(
        (e) => e.property_id === property_id
      ).name
        } @ ${this.state.modal_object.unit.name}`;
    }

    return (
      <div className="labels">
        <h5 className="property-and-unit-name">{unit_and_property_label}</h5>
        <div className="mappings-wrapper">
          <h6 className="current-mapping-name">Current Mappings:</h6>
          {this.state.modal_object && this.state.modal_object.property.current_mappings.length === 0 && <h6 className="current-mapping-name">
            <span>
              <Icon name='Link' color='#868ab1' style={{ marginRight: '3px' }} />
            </span>
            No Mapping
          </h6>}
          {this.state.modal_object && this.state.modal_object.property.current_mappings.map(mapp => {
            return <h6
              key={mapp.data_source_id + mapp.name}
              className="current-mapping-name"
              onClick={(e) => this.currentMappingClickHandler(mapp.name)}
            >
              <span>
                <Icon name='Link' color='#868ab1' style={{ marginRight: '3px' }} />
              </span>
              {mapp.name}
            </h6>
          })}
        </div>
      </div>
    );
  }

  getCurrentMapping() {
    if (this.state.current_point_name === "") return null;
    return <h6>{this.state.current_point_name}</h6>;
  }

  getPropertiesList() {
    let icon = (
      <span>
        <Icon name="CheckCircleOutline" color='#868ba1' style={{ marginLeft: "5px" }} />
      </span>
    );

    if (this.state.modal_object && this.state.modal_object.unit.properties.length) {
      return this.props.modifiedEntity.properties.map((prop) => {


        const property_modified = !_.isEqual(prop.current_mappings, prop.updated_mappings);

        const property_selected = _.get(this.state, 'selected_property.name') === prop.code;

        return (
          <div
            key={prop.property_id}
            className={`property-item ${property_selected ? "selected" : ""} ${property_modified ? "changed" : ""
              }`}
            onClick={() => this.selectNewProperty(prop)}
          >
            {prop.name}
            {property_modified ? icon : <span style={{ width: "14px" }}></span>}
          </div>
        );
      });
    }
  }

  selectNewProperty(property) {

    let modified_modal_object = {
      ...this.state.modal_object,
      property: property,
      name: this.state.modal_object.property.name,
    };

    const property_filter_unselected =
      this.state.selected_property &&
      this.state.selected_property.name === "ALL PROPERTIES";

    this.setState({
      modal_object: modified_modal_object,
      selected_point: null,
      search_input: '',
      selected_property: property_filter_unselected
        ? { name: "ALL PROPERTIES" }
        : { name: property.code },
    });
  }


  render() {
    return (
      <div
        id={"PointDebugComponent"}
        style={{
          padding: "20px",
          height: "100%",
          display: "flex",
          flexDirection: "row",
          width: "100%",
        }}
      >
        <div className="property-menu col-2">{this.getPropertiesList()}</div>
        <div className="main-table col-10">
          {/* Property and Unit */}
          <div className="row">
            <div className="col-12 col-sm-12 d-flex justify-content-between">
              {this.getUnitAndPropertyName()}
              <div
                className="form-group modal-controls"
                onClick={this.props.toggleModalOpen}
              >
                <input
                  checked={this.props.keepModalOpen}
                  readOnly
                  className="form-group-input mr-2"
                  type="checkbox"
                />
                <label className="form-group-label">Keep Modal Open</label>
              </div>
            </div>
          </div>
          {/* Appliance and Controller Filters */}
          <div className="row" style={{ height: "7%" }}>
            <div className="col-12 col-sm-12">
              <div className="row pd-t-6">
                <div className="col-12 col-sm-6">
                  <SearchSelect
                    limit={999}
                    options={this.getApplianceOptions()}
                    placeholder={"Filter Appliances..."}
                    defaultValue={this.state.selected_appliance}
                    actionOnSelectedOption={this._selectAppliance}
                  />
                </div>
                <div className="col-12 col-sm-6">
                  <SearchSelect
                    limit={999}
                    options={this.getControllerOptions()}
                    placeholder={"Filter Controllers..."}
                    defaultValue={this.state.selected_controller}
                    actionOnSelectedOption={this._selectController}
                  />
                </div>
              </div>
            </div>
          </div>

          {/* Input and Property Filters */}
          <div className="row" style={{ height: "7%" }}>
            <div
              className="col-6"
              style={{ marginBottom: "5px", marginTop: "5px" }}
            >
              <div className="input-group">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <Icon
                      name='Search'
                      color='#868ba1'
                    />
                  </span>
                </div>
                <input
                  value={this.state.search_input}
                  type="text"
                  className="form-control"
                  placeholder="Filter by Name and Description"
                  onChange={this._onFilterInput}
                />
              </div>
            </div>
            <div
              className="col-6 col-sm-4"
              style={{ marginBottom: "5px", marginTop: "8px" }}
            >
              <SearchSelect
                limit={999}
                options={this.getPropertyOptions()}
                placeholder={"Filter Point Property..."}
                defaultValue={this.state.selected_property}
                actionOnSelectedOption={this._selectProperty}
              />
            </div>
            <div className="col-6 col-sm-2 d-flex align-items-center">
              <button
                className="btn btn-danger"
                style={{ width: "100%", height: "36px", padding: 0 }}
                onClick={(e) => {
                  e.stopPropagation();
                  this.setState({
                    selected_point: null,
                    selected_controller: null,
                    selected_appliance: null,
                    selected_property: null,
                    search_input: "",
                  });
                }}
              >
                Clear All Filters
              </button>
            </div>
          </div>

          {/* Points Table */}
          <div
            className="row mg-t-0 mg-b-5"
            style={{
              overflowY: "scroll",
              height: this.state.selected_point ? "40%" : "79%",
              borderBottom: "1px solid #cccccc",
            }}
          >
            <div
              className="col-12"
              style={{ marginBottom: "5px", marginTop: "5px" }}
            >
              <table className="table mg-b-0 table-contact">
                {this.getTableHeader()}
                <tbody>{this.getPointRows()}</tbody>
              </table>
            </div>
          </div>

          {/* Point Chart */}
          {this.state.selected_point && this.getPointChart()}
        </div>
      </div>
    );
  }
}

export default PointDebugComponent;

import React, { Component } from "react";
import "./ModifyZonePanel.scss";
import { Button } from 'antd';
import { Icon } from '../../../components/Icon';

class ModifyZonePanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      building: this.props.building,
      zone: this.props.zone,
    };
  }
  componentWillReceiveProps(nextProps) {
    this.setState({
      building: nextProps.building,
      zone: nextProps.zone,
    });
  }

  getZoneRow(zone) {
    return (
      <tr>
        <td style={{ paddingBottom: "5px" }}>
          <input
            placeholder="Name"
            type="text"
            className="inline-single-input"
            defaultValue={zone.name}
            onChange={this.props.changeZoneValues.bind(this, zone, "name")}
          />
        </td>
        <td style={{ paddingBottom: "5px" }}>
          <input
            placeholder="Description"
            type="text"
            className="inline-single-input"
            defaultValue={zone.description}
            onChange={this.props.changeZoneValues.bind(
              this,
              zone,
              "description"
            )}
          />
        </td>
        <td style={{ paddingBottom: "5px" }}>
          <input
            placeholder="Location"
            type="text"
            className="inline-single-input"
            defaultValue={zone.location}
            onChange={this.props.changeZoneValues.bind(this, zone, "location")}
          />
        </td>
        <td style={{ paddingBottom: "5px" }}>
          <select
            onChange={this.props.changeZoneValues.bind(
              this,
              zone,
              "criticality"
            )}
            value={zone.criticality}
            className="inline-single-input"
          >
            <option value={0} disabled>
              Select Criticality
            </option>
            <option value={"high"}>High</option>
            <option value={"medium"}>Medium</option>
            <option value={"low"}>Low</option>
          </select>
        </td>
        <td style={{ paddingBottom: "5px" }}>
          <select
            onChange={this.props.changeZoneValues.bind(this, zone, "type")}
            value={zone.type}
            className="inline-single-input"
          >
            <option value={""} disabled>
              Select Type
            </option>
            <option value={"building"}>Building</option>
            <option value={"space"}>Space</option>
            <option value={"room"}>Room</option>
            <option value={"desk"}>Desk</option>
          </select>
        </td>
        <td style={{ paddingBottom: "5px" }}>
          <input
            placeholder="Capacity"
            type="number"
            className="inline-single-input"
            defaultValue={zone.capacity}
            onChange={this.props.changeZoneValues.bind(this, zone, "capacity")}
          />
        </td>
        <td style={{ paddingBottom: "5px" }}>
          <input
            placeholder="Size"
            type="number"
            className="inline-single-input"
            defaultValue={zone.size}
            onChange={this.props.changeZoneValues.bind(this, zone, "size")}
          />
        </td>
      </tr>
    );
  }

  getTemperatureSelections(zone, target, type) {
    return (
      <select
        style={{ marginBottom: "5px" }}
        onChange={this.props.changeZoneValues.bind(this, zone, target)}
        value={zone[target]}
        className="inline-single-input"
      >
        <option value={0} disabled>
          {type}
        </option>
        <option value={1}>1°C</option>
        <option value={2}>2°C</option>
        <option value={3}>3°C</option>
        <option value={4}>4°C</option>
        <option value={5}>5°C</option>
        <option value={6}>6°C</option>
        <option value={7}>7°C</option>
        <option value={8}>8°C</option>
        <option value={9}>9°C</option>
        <option value={10}>10°C</option>
        <option value={11}>11°C</option>
        <option value={12}>12°C</option>
        <option value={13}>13°C</option>
        <option value={14}>14°C</option>
        <option value={15}>15°C</option>
        <option value={16}>16°C</option>
        <option value={17}>17°C</option>
        <option value={18}>18°C</option>
        <option value={19}>19°C</option>
        <option value={20}>20°C</option>
        <option value={21}>21°C</option>
        <option value={22}>22°C</option>
        <option value={23}>23°C</option>
        <option value={24}>24°C</option>
        <option value={25}>25°C</option>
        <option value={26}>26°C</option>
        <option value={27}>27°C</option>
        <option value={28}>28°C</option>
        <option value={29}>29°C</option>
        <option value={30}>30°C</option>
      </select>
    );
  }
  getCo2Selections(zone, target, type) {
    return (
      <select
        style={{ marginBottom: "5px" }}
        onChange={this.props.changeZoneValues.bind(this, zone, target)}
        value={zone[target]}
        className="inline-single-input"
      >
        <option value={0} disabled>
          {type}
        </option>
        <option value={100}>100ppm</option>
        <option value={200}>200ppm</option>
        <option value={300}>300ppm</option>
        <option value={400}>400ppm</option>
        <option value={500}>500ppm</option>
        <option value={600}>600ppm</option>
        <option value={700}>700ppm</option>
        <option value={800}>800ppm</option>
        <option value={900}>900ppm</option>
        <option value={1000}>1000ppm</option>
        <option value={1100}>1100ppm</option>
        <option value={1200}>1200ppm</option>
        <option value={1300}>1300ppm</option>
        <option value={1400}>1400ppm</option>
        <option value={1500}>1500ppm</option>
        <option value={1600}>1600ppm</option>
        <option value={1700}>1700ppm</option>
        <option value={1800}>1800ppm</option>
        <option value={1900}>1900ppm</option>
        <option value={2000}>2000ppm</option>
      </select>
    );
  }
  getHumiditySelections(zone, target, type) {
    return (
      <select
        style={{ marginBottom: "5px" }}
        onChange={this.props.changeZoneValues.bind(this, zone, target)}
        value={zone[target]}
        className="inline-single-input"
      >
        <option value={0} disabled>
          {type}
        </option>
        <option value={5}>5%</option>
        <option value={10}>10%</option>
        <option value={15}>15%</option>
        <option value={20}>20%</option>
        <option value={25}>25%</option>
        <option value={30}>30%</option>
        <option value={35}>35%</option>
        <option value={40}>40%</option>
        <option value={45}>45%</option>
        <option value={50}>50%</option>
        <option value={55}>55%</option>
        <option value={60}>60%</option>
        <option value={65}>65%</option>
        <option value={70}>70%</option>
        <option value={75}>75%</option>
        <option value={80}>80%</option>
        <option value={85}>85%</option>
        <option value={90}>90%</option>
        <option value={95}>95%</option>
        <option value={100}>100%</option>
      </select>
    );
  }
  getPM25Selections(zone, target, type) {
    return (
      <select
        style={{ marginBottom: "5px" }}
        onChange={this.props.changeZoneValues.bind(this, zone, target)}
        value={zone[target]}
        className="inline-single-input"
      >
        <option value={0} disabled>
          {type}
        </option>
        <option value={5}>5 mcg/m³</option>
        <option value={10}>10 mcg/m³</option>
        <option value={15}>15 mcg/m³</option>
        <option value={20}>20 mcg/m³</option>
        <option value={25}>25 mcg/m³</option>
        <option value={30}>30 mcg/m³</option>
        <option value={35}>35 mcg/m³</option>
        <option value={40}>40 mcg/m³</option>
        <option value={45}>45 mcg/m³</option>
        <option value={50}>50 mcg/m³</option>
        <option value={55}>55 mcg/m³</option>
        <option value={60}>60 mcg/m³</option>
        <option value={65}>65 mcg/m³</option>
        <option value={70}>70 mcg/m³</option>
        <option value={75}>75 mcg/m³</option>
        <option value={80}>80 mcg/m³</option>
        <option value={85}>85 mcg/m³</option>
        <option value={90}>90 mcg/m³</option>
        <option value={95}>95 mcg/m³</option>
        <option value={100}>100 mcg/m³</option>
      </select>
    );
  }
  getLuxSelections(zone, target, type) {
    return (
      <select
        style={{ marginBottom: "5px" }}
        onChange={this.props.changeZoneValues.bind(this, zone, target)}
        value={zone[target]}
        className="inline-single-input"
      >
        <option value={0} disabled>
          {type}
        </option>
        <option value={50}>50 Lux</option>
        <option value={100}>100 Lux</option>
        <option value={150}>150 Lux</option>
        <option value={200}>200 Lux</option>
        <option value={250}>250 Lux</option>
        <option value={300}>300 Lux</option>
        <option value={350}>350 Lux</option>
        <option value={400}>400 Lux</option>
        <option value={450}>450 Lux</option>
        <option value={500}>500 Lux</option>
        <option value={550}>550 Lux</option>
        <option value={600}>600 Lux</option>
        <option value={650}>650 Lux</option>
        <option value={700}>700 Lux</option>
        <option value={750}>750 Lux</option>
        <option value={800}>800 Lux</option>
        <option value={850}>850 Lux</option>
        <option value={900}>900 Lux</option>
        <option value={950}>950 Lux</option>
        <option value={1000}>1000 Lux</option>
      </select>
    );
  }

  getZoneKPIsRow(zone) {
    return (
      <tr>
        <td
          className="valign-middle"
          colSpan="7"
          style={{ border: "none", padding: "0px" }}
        >
          <div className="d-flex align-items-center">
            <div style={{ width: "100%" }}>
              <table
                className="table mg-b-0 table-contact pd-l-0 pd-r-0"
                style={{ marginTop: "0px" }}
              >
                <thead>
                  <tr>
                    <th
                      className="wd-20p tx-mont tx-medium"
                      style={{ fontSize: "10px" }}
                    >
                      Temperature (°C)
                    </th>
                    <th
                      className="wd-20p tx-10-force tx-mont tx-medium"
                      style={{ fontSize: "10px" }}
                    >
                      CO2 (ppm)
                    </th>
                    <th
                      className="wd-20p tx-10-force tx-mont tx-medium"
                      style={{ fontSize: "10px" }}
                    >
                      PM2.5 (mcg/m³)
                    </th>
                    <th
                      className="wd-20p tx-10-force tx-mont tx-medium"
                      style={{ fontSize: "10px" }}
                    >
                      Humidity (%)
                    </th>
                    <th
                      className="wd-20p tx-10-force tx-mont tx-medium"
                      style={{ fontSize: "10px" }}
                    >
                      Light (Lux)
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    {/* TEMPERATURE */}
                    <td
                      className="valign-middle"
                      style={{
                        border: "none",
                        paddingTop: "0px",
                        paddingBottom: "0px",
                      }}
                    >
                      {this.getTemperatureSelections(
                        zone,
                        "temp_target_lower",
                        "Lower Temperature Target"
                      )}
                      {this.getTemperatureSelections(
                        zone,
                        "temp_target_upper",
                        "Upper Temperature Target"
                      )}
                    </td>
                    {/* CO2 */}
                    <td
                      className="valign-middle"
                      style={{
                        border: "none",
                        paddingTop: "0px",
                        paddingBottom: "0px",
                      }}
                    >
                      {this.getCo2Selections(
                        zone,
                        "co2_target_lower",
                        "Lower CO2 Target"
                      )}
                      {this.getCo2Selections(
                        zone,
                        "co2_target_upper",
                        "Upper CO2 Target"
                      )}
                    </td>
                    {/* PM2.5 */}
                    <td
                      className="valign-middle"
                      style={{
                        border: "none",
                        paddingTop: "0px",
                        paddingBottom: "0px",
                      }}
                    >
                      {this.getPM25Selections(
                        zone,
                        "pm25_target_lower",
                        "Lower PM2.5 Target"
                      )}
                      {this.getPM25Selections(
                        zone,
                        "pm25_target_upper",
                        "Upper PM2.5 Target"
                      )}
                    </td>
                    {/* HUMIDITY */}
                    <td
                      className="valign-middle"
                      style={{
                        border: "none",
                        paddingTop: "0px",
                        paddingBottom: "0px",
                      }}
                    >
                      {this.getHumiditySelections(
                        zone,
                        "humd_target_lower",
                        "Lower Humidity Target"
                      )}
                      {this.getHumiditySelections(
                        zone,
                        "humd_target_upper",
                        "Upper Humidity Target"
                      )}
                    </td>
                    {/* LIGHT */}
                    <td
                      className="valign-middle"
                      style={{
                        border: "none",
                        paddingTop: "0px",
                        paddingBottom: "0px",
                      }}
                    >
                      {this.getLuxSelections(
                        zone,
                        "lux_target_lower",
                        "Lower Lux Target"
                      )}
                      {this.getLuxSelections(
                        zone,
                        "lux_target_upper",
                        "Upper Lux Target"
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </td>
      </tr>
    );
  }


  render() {
    let zone = this.state.zone;
    return (
      <React.Fragment>
        {this.getZoneRow(zone)}
        {this.getZoneKPIsRow(zone)}
        <tr>
          <td
            className="valign-middle"
            colSpan="8"
            style={{
              display: "flex",
              paddingTop: "20px",
              border: "none",
              paddingBottom: "20px",
            }}
          >
            {zone.zone_id ? (
              <Button
                className="button orange"
                icon={<Icon name="Edit" color={'#fff'} size={18} />}
                size="small"
                onClick={this.props.updateZone.bind(this, zone)}
              >Update</Button>
            ) : (
              <Button
                className="button green"
                icon={<Icon name="AddCircleFilled" color={'#fff'} size={18} />}
                size="small"
                onClick={this.props.saveZone.bind(this, zone)}
              >Save</Button>
            )}
            {zone.zone_id ? (
              <Button
                className="button red"
                icon={<Icon name="Delete" color={'#fff'} size={18} />}
                size="small"
                onClick={this.props.deleteZone.bind(this, zone.zone_id)}
                style={{ marginLeft: "15px" }}
              >Delete</Button>
            ) : (
              <Button
                className="button red"
                icon={<Icon name="Close" color={'#fff'} size={18} />}
                size="small"
                onClick={this.props.cancelZone.bind(this, zone)}
                style={{ marginLeft: "15px" }}
              >Cancel</Button>
            )}
          </td>
        </tr>
      </React.Fragment>
    );
  }
}

export default ModifyZonePanel;

import ActionTypes from "../constants";
import GeneralUtils from "../utils/GeneralUtils";

const MOCK_REQUEST_OBJECT = {
  bucket_type: "FIFTEEN_MIN",
  search_interval: {
    start: "2024-11-25",
    end: "2025-12-01"
  },
  data_points: [
    { entity_type_id: 2989, property_id: 72 }
  ]
}

class DataExporterActions {

  getProperties() {
    return GeneralUtils.get({
      url: "v2/properties/mapped",
      actionType: ActionTypes.DATA_EXPORTER_PROPERTIES_FETCHED,
    });
  }

  getExportData(config = MOCK_REQUEST_OBJECT) {
    return GeneralUtils.postDownload({
      url: "export",
      object: config,
      actionType: ActionTypes.DATA_EXPORTER_EXPORT_DATA_FETCHED,
      failToast: "Failed to fetch export data.",
    });
  }

}
const dataExporterActions = new DataExporterActions();
export default dataExporterActions;

import React, { Component } from "react";
import ReportActions from "../../actions/reportActions";
import ReportStore from "../../stores/reportStore";
import UserActions from "../../actions/userActions";
import UserStore from "../../stores/userStore";
import "./ManageNotifications.scss";
import { DocumentTitle } from "../../components/DocumentTitle";
import { Icon } from "../../components/Icon";

import ReactHintFactory from "react-hint";
import { Lightbox } from "react-modal-image";
import "react-hint/css/index.css";
import { LogoSpinner } from "../../components/LogoSpinner";

import { Typography } from "antd";
const { Title, Text } = Typography;

const ReactHint = ReactHintFactory(React);
class ManageNotifications extends Component {
  constructor(props) {
    super(props);
    this.state = {
      edit_report: null,
      preview_report: null,
      reports: ReportStore.getAvailableReports(),
      buildings: UserStore.getOrganisationBuildings(),
      users: UserStore.getUsers(),
      current_user: UserStore.getUser(),
      loading: true,
    };
    this._onReportsChange = this._onReportsChange.bind(this);
    this._onReportsSave = this._onReportsSave.bind(this);
    this._onUsersChange = this._onUsersChange.bind(this);
    this._setEditReport = this._setEditReport.bind(this);
    this._closeEditReport = this._closeEditReport.bind(this);
    this._saveEditReport = this._saveEditReport.bind(this);
    this._setSampleReport = this._setSampleReport.bind(this);
    this._closeReportPreview = this._closeReportPreview.bind(this);
    this._addNewRecipientToReport = this._addNewRecipientToReport.bind(this);
    this._deleteScheduleFromReport = this._deleteScheduleFromReport.bind(this);
    this._changeRecipientValue = this._changeRecipientValue.bind(this);
  }
  _onUsersChange() {
    this.setState({
      users: UserStore.getUsers(),
      current_user: UserStore.getUser(),
      buildings: UserStore.getOrganisationBuildings(),
    });
  }
  _onReportsChange() {
    this.setState({
      reports: ReportStore.getAvailableReports(),
      original_reports: ReportStore.getAvailableReports(),
      edit_report: null,
      preview_report: null,
      loading: false,
    });
  }
  _onReportsSave() {
    let current_user = UserStore.getUser();
    if (current_user && current_user.fk_organisation_id) {
      ReportActions.getAvailableReports(current_user.fk_organisation_id);
      this.setState({ loading: true });
    }
  }
  UNSAFE_componentWillMount() {
    UserStore.addChangeListener(this._onUsersChange);
    ReportStore.addChangeListener(this._onReportsChange);
    ReportStore.addSaveChangeListener(this._onReportsSave);
  }
  componentDidMount() {
    let current_user = UserStore.getUser();
    if (current_user && current_user.fk_organisation_id) {
      UserActions.getOrganisationUsers(current_user.fk_organisation_id);
      ReportActions.getAvailableReports(current_user.fk_organisation_id);
    }
  }
  componentWillUnmount() {
    UserStore.removeChangeListener(this._onUsersChange);
    ReportStore.removeChangeListener(this._onReportsChange);
    ReportStore.removeSaveChangeListener(this._onReportsSave);
  }
  _setSampleReport(report) {
    this.setState({ preview_report: report });
  }
  _closeReportPreview() {
    this.setState({ preview_report: null });
  }
  _setEditReport(report) {
    this.setState({ edit_report: report });
  }
  _closeEditReport() {
    let current_user = UserStore.getUser();
    if (current_user && current_user.fk_organisation_id) {
      ReportActions.getAvailableReports(current_user.fk_organisation_id);
      this.setState({ loading: true });
    }
  }
  _saveEditReport(report) {
    let current_user = UserStore.getUser();
    if (current_user && current_user.fk_organisation_id) {
      ReportActions.saveReport(current_user.fk_organisation_id, report);
    }
  }
  _addNewRecipientToReport(report) {
    let reports = ReportStore.getAvailableReports();
    for (let i = 0; i < reports.length; i++) {
      if (reports[i].name.toLowerCase() === report.name.toLowerCase()) {
        reports[i].schedules.push({
          recipient_name: "",
          recipient_id: 0,
          target_type: "",
          target_name: "",
          target_id: 0,
        });
      }
    }
    this.setState({ reports: reports });
  }
  _deleteScheduleFromReport(report, recipient) {
    let reports = this.state.reports;
    for (let i = 0; i < reports.length; i++) {
      if (reports[i].name.toLowerCase() === report.name.toLowerCase()) {
        let new_schedules = [];
        for (let x = 0; x < reports[i].schedules.length; x++) {
          if (
            reports[i].schedules[x].recipient_id === recipient.recipient_id &&
            reports[i].schedules[x].target_id === recipient.target_id
          ) {
          } else {
            new_schedules.push(reports[i].schedules[x]);
          }
        }
        reports[i].schedules = new_schedules;
        break;
      }
    }
    this.setState({ reports: reports });
  }
  _changeRecipientValue(report, recipient, target_property, index, event) {
    let selected_id = Number.parseInt(event.target.value);
    if (target_property === "recipient_id") {
      for (let i = 0; i < report.users.length; i++) {
        if (report.users[i].user_id === selected_id) {
          recipient.recipient_id = report.users[i].user_id;
          recipient.recipient_name = report.users[i].user_name;
          break;
        }
      }
    } else if (target_property === "entity_id") {
      for (let i = 0; i < report.targets.length; i++) {
        if (report.targets[i].entity_id === selected_id) {
          recipient.target_id = report.targets[i].entity_id;
          recipient.target_name = report.targets[i].entity_name;
          recipient.target_type = report.targets[i].entity_type;
          break;
        }
      }
    }

    let reports = this.state.reports;
    for (let i = 0; i < reports.length; i++) {
      if (reports[i].name.toLowerCase() === report.name.toLowerCase()) {
        reports[i].schedules[index] = recipient;
        break;
      }
    }
    this.setState({ reports: reports });
  }
  getReportRecipientRow(report) {
    if (report.schedules.length === 0) {
      return (
        <tbody>
          <tr>
            <td
              className="valign-middle"
              colSpan={3}
              style={{
                paddingLeft: "0px",
                paddingRight: "5px",
                paddingBottom: "2px",
                paddingTop: "4px",
              }}
            >
              <div className="d-flex align-items-center">
                <div>
                  <div className="tx-inverse tx-center">
                    No recipients set for this report, please add a recipient.
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      );
    } else {
      return (
        <tbody>
          {report.schedules.map((recipient, key) => {
            return (
              <tr
                key={
                  recipient.recipient_id +
                  "" +
                  recipient.target_id +
                  "" +
                  Math.random().toString(36)
                }
              >
                <td
                  className="valign-middle"
                  style={{
                    paddingLeft: "0px",
                    paddingRight: "5px",
                    paddingBottom: "2px",
                    paddingTop: "4px",
                  }}
                >
                  <select
                    className="inline-single-input"
                    defaultValue={recipient.recipient_id}
                    onChange={this._changeRecipientValue.bind(
                      this,
                      report,
                      recipient,
                      "recipient_id",
                      key
                    )}
                  >
                    <option value={0} disabled>
                      Recipient
                    </option>
                    {report.users.map(function (user, key) {
                      return (
                        <option key={key} value={user.user_id}>
                          {user.user_name}
                        </option>
                      );
                    })}
                  </select>
                </td>
                <td
                  className="valign-middle"
                  style={{
                    paddingLeft: "0px",
                    paddingRight: "5px",
                    paddingBottom: "2px",
                    paddingTop: "4px",
                  }}
                >
                  <select
                    className="inline-single-input"
                    defaultValue={recipient.target_id}
                    onChange={this._changeRecipientValue.bind(
                      this,
                      report,
                      recipient,
                      "entity_id",
                      key
                    )}
                  >
                    <option value={0} disabled>
                      Target
                    </option>
                    {report.targets.map(function (target, key) {
                      return (
                        <option key={key} value={target.entity_id}>
                          {target.entity_name}
                        </option>
                      );
                    })}
                  </select>
                </td>
                <td
                  className="valign-middle tx-center"
                  style={{
                    paddingLeft: "0px",
                    paddingRight: "0px",
                    paddingBottom: "2px",
                    paddingTop: "4px",
                  }}
                >
                  <span
                    style={{ cursor: "pointer", color: "red" }}
                    onClick={() =>
                      this._deleteScheduleFromReport(report, recipient)
                    }
                  >
                    <Icon name='Delete' size={30} color='red' />
                  </span>
                </td>
              </tr>
            );
          })}
        </tbody>
      );
    }
  }
  getReportCard(report) {
    if (
      this.state.edit_report != null &&
      this.state.edit_report.name.toLowerCase() === report.name.toLowerCase()
    ) {
      let infoClass = "btn btn-info pd-x-50 mg-r-10";
      let outlineClass = "btn btn-outline-info pd-x-50 mg-l-10";
      if (window.innerWidth <= 900) {
        infoClass = "btn btn-info pd-x-10 mg-r-10";
        outlineClass = "btn btn-outline-info pd-x-10 mg-l-10";
      }
      return (
        <div
          key={report.name}
          className="col-sm-6 col-lg-4 mg-t-0 mg-sm-t-0 mg-b-20"
        >
          <div
            className="card widget-7"
            style={{
              paddingTop: "15px",
              paddingBottom: "15px",
              paddingLeft: "15px",
              paddingRight: "15px",
              boxShadow: "0px 1px 3px 0px rgb(0 0 0 / 75%)",
            }}
          >
            <button
              data-rh="Add Recipient"
              className="btn btn-info pd-5"
              style={{ position: "absolute", top: "15px" }}
              onClick={() => this._addNewRecipientToReport(report)}
            >
              <Icon name='AddUser' size={18} color='#fff' />{" "}
              Add New Recipient
            </button>
            <table className="table mg-t-45 table-contact">
              <thead>
                <tr>
                  <th
                    className="wd-50p tx-mont tx-medium"
                    style={{ padding: "0px" }}
                  >
                    User
                  </th>
                  <th
                    className="wd-45p tx-10-force tx-mont tx-medium"
                    style={{ padding: "0px" }}
                  >
                    Target
                  </th>
                  <th
                    className="wd-5p tx-10-force tx-mont tx-medium tx-center"
                    style={{ padding: "0px" }}
                  ></th>
                </tr>
              </thead>
              {this.getReportRecipientRow(report)}
            </table>
            <p className="mg-b-0">
              <button
                className={infoClass}
                onClick={() => this._saveEditReport(report)}
              >
                Save
              </button>
              <button
                className={outlineClass}
                onClick={() => this._closeEditReport()}
              >
                Cancel
              </button>
            </p>
          </div>
        </div>
      );
    } else {
      let toggleDisplay =
        report.available === true
          ? "br-toggle br-toggle-rounded br-toggle-info on"
          : "br-toggle br-toggle-rounded br-toggle-info off";
      let cardBackground =
        report.available === true
          ? "card shadow-base widget-7"
          : "card shadow-base widget-7 bg-gray-300";
      let outlineClass = "btn btn-outline-info pd-x-50 mg-r-10";
      let infoClass = "btn btn-info pd-x-50 mg-l-10";
      if (window.innerWidth <= 900) {
        outlineClass = "btn btn-outline-info pd-x-10 mg-r-10";
        infoClass = "btn btn-info pd-x-10 mg-l-10";
      }
      return (
        <div
          key={report.name}
          className="col-sm-6 col-lg-4 mg-t-0 mg-sm-t-0 mg-b-20"
        >
          <div className={cardBackground}>
            <div
              data-rh={
                report.available
                  ? "Report is Available"
                  : " Report Not Available - Contact OPNBuildings"
              }
              className={toggleDisplay}
              style={{ position: "absolute", top: "15px", right: "15px" }}
            >
              <div className="br-toggle-switch"></div>
            </div>
            <div className="tx-center">
              <h4 className="tx-normal tx-inverse tx-roboto mg-t-20 mg-b-2">
                {report.name}
              </h4>
              <p className="mg-b-20">{report.target_description}</p>
              <p className="mg-b-20">{report.description}</p>
              <p className="mg-b-0">
                <button
                  className={outlineClass}
                  onClick={() => this._setSampleReport(report)}
                >
                  Sample
                </button>
                {report.available ? (
                  <button
                    className={infoClass}
                    onClick={() => this._setEditReport(report)}
                  >
                    Settings
                  </button>
                ) : (
                  <button className={infoClass} disabled>
                    Settings
                  </button>
                )}
              </p>
            </div>
          </div>
        </div>
      );
    }
  }

  render() {
    let renderPreview;
    if (this.state.preview_report !== null) {
      renderPreview = (
        <Lightbox
          medium={this.state.preview_report.sample_image}
          hideZoom={true}
          alt={this.state.preview_report.name + " Sample"}
          onClose={this._closeReportPreview}
        />
      );
    }

    return (
      <div className="br-mainpanel br-profile-page floorplan-background">
        <ReactHint autoPosition events delay />
        <DocumentTitle title="Manage Notifications" />
        <LogoSpinner loading={this.state.loading} />
        <div className="br-container">
          {renderPreview}
          <div className="row mg-t-30">
            <div className="col-12">
              <Title level={3}>Report Center</Title>
              <Text type='secondary'>
                Manage the distribution of automated reports and alerting.
              </Text>
            </div>
          </div>
          <div className="row row-sm mg-b-0 mg-t-30">
            <div className="col-12 mg-t-0 mg-sm-t-0">
              <div className="br-pagetitle pd-l-0 pd-b-0 mg-b-0">
                <h6 className="mg-b-0">ENERGY REPORTS</h6>
              </div>
              <hr style={{ marginTop: "0.2rem", marginBottom: "0.5rem" }} />
            </div>
          </div>
          <div className="row row-sm mg-b-25 mg-t-5">
            {this.state.reports.map((report, key) => {
              if (report.name.toLowerCase().indexOf("usage") > -1) {
                return this.getReportCard(report);
              } else {
                return null;
              }
            })}
          </div>
          <div className="row row-sm mg-b-0 mg-t-15">
            <div className="col-12 mg-t-0 mg-sm-t-0">
              <div className="br-pagetitle pd-l-0 pd-b-0 mg-b-0">
                <h6 className="mg-b-0">INDOOR CONDITIONING REPORTS</h6>
              </div>
              <hr style={{ marginTop: "0.2rem", marginBottom: "0.5rem" }} />
            </div>
          </div>
          <div className="row row-sm mg-b-25 mg-t-5">
            {this.state.reports.map((report, key) => {
              if (report.name.toLowerCase().indexOf("conditions") > -1) {
                return this.getReportCard(report);
              }
              return null;
            })}
          </div>
        </div>
      </div>
    );

  }
}

export default ManageNotifications;

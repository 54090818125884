import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import moment from "moment";

class DateCell extends PureComponent {
  render() {
    const noData = (
      <td className="tx-14 not-available" style={{ color: "#c9c9c9" }}>
        N/A
      </td>
    );

    const defaultFormat = (
      <td className="tx-14">
        <div>{moment(this.props.timestamp).format("Do MMM YYYY h:mm a")}</div>
      </td>
    );

    const formatSpecified = (
      <td className="tx-14">
        <div>{moment(this.props.timestamp).format(this.props.format)}</div>
      </td>
    );

    if (
      !this.props.timestamp ||
      moment(this.props.timestamp).format("hh:mm:ss") === "Invalid date"
    ) {
      return noData;
    } else if (this.props.format) {
      return formatSpecified;
    } else {
      return defaultFormat;
    }
  }
}

DateCell.propTypes = {
  timestamp: PropTypes.string,
  format: PropTypes.string,
};

export default DateCell;

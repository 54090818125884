import React, { Component } from "react";
import styles from "./SensorListing.module.scss";
import AdminStore from "../../stores/adminStore";
import AdminActions from "../../actions/adminActions";
import _ from "lodash";
import moment from "moment";
import { DocumentTitle } from "../../components/DocumentTitle";
import { LogoSpinner } from "../../components/LogoSpinner";
import { SearchSelect } from "../../components/SearchSelect";
import AntTable from "../../components/AntTable/AntTable";
import { Row, Col, Typography } from "antd";

const { Title, Text } = Typography;

class SensorListing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      available_buildings: [],
      available_estates: [],
      selected_building: { name: "All Buildings" },
      selected_estate: { name: "All Estates" },
      sensors: AdminStore.getSensorStats(),
      loading: true,
      sortBy: "status",
      sortDirection: "asc",
      searchInput: "",
    };
    this._onStatusChange = this._onStatusChange.bind(this);
    this._setBuildingFilter = this._setBuildingFilter.bind(this);
    this._setEstateFilter = this._setEstateFilter.bind(this);
  }
  _onStatusChange() {
    let sensors = AdminStore.getSensorStats();
    let available_buildings = [];
    let available_building_names = [];
    let available_estates = [];
    let available_estate_names = [];

    for (let i = 0; i < sensors.length; i++) {
      if (!available_building_names.includes(sensors[i].building_name)) {
        available_building_names.push(sensors[i].building_name);
      }
      if (!available_estate_names.includes(sensors[i].estate_name)) {
        available_estate_names.push(sensors[i].estate_name);
      }
    }

    for (let i = 0; i < available_building_names.length; i++) {
      available_buildings.push({ name: available_building_names[i] });
    }
    for (let i = 0; i < available_estate_names.length; i++) {
      available_estates.push({ name: available_estate_names[i] });
    }

    available_buildings = _.orderBy(available_buildings, "name", "asc");
    available_buildings.unshift({ name: "All Buildings" });

    available_estates = _.orderBy(available_estates, "name", "asc");
    available_estates.unshift({ name: "All Estates" });

    this.setState({
      sensors: sensors,
      available_buildings: available_buildings,
      available_estates: available_estates,
      loading: false,
    });
  }
  componentDidMount() {
    AdminStore.addChangeListener(this._onStatusChange);
    AdminActions.getHealthChecks(true);
  }
  componentWillUnmount() {
    AdminStore.removeChangeListener(this._onStatusChange);
  }
  _setBuildingFilter(building) {
    this.setState({ selected_building: building });
  }
  _setEstateFilter(estate) {
    let sensors = AdminStore.getSensorStats();
    let available_buildings = [];
    let available_building_names = [];
    for (let i = 0; i < sensors.length; i++) {
      if (
        !available_building_names.includes(sensors[i].building_name) &&
        (sensors[i].estate_name === estate.name ||
          estate.name === "All Estates")
      ) {
        available_building_names.push(sensors[i].building_name);
      }
    }
    for (let i = 0; i < available_building_names.length; i++) {
      available_buildings.push({ name: available_building_names[i] });
    }

    available_buildings = _.orderBy(available_buildings, "name", "asc");
    available_buildings.unshift({ name: "All Buildings" });

    this.setState({
      selected_estate: estate,
      available_buildings: available_buildings,
      selected_building: { name: "All Buildings" },
    });
  }

  filterSensors() {
    const { sensors, selected_building, selected_estate } = this.state;
    return sensors.filter(sensor => {
      const buildingMatch = selected_building.name === "All Buildings" || sensor.building_name === selected_building.name;
      const estateMatch = selected_estate.name === "All Estates" || sensor.estate_name === selected_estate.name;
      return buildingMatch && estateMatch;
    });
  }

  render() {
    const columns = [
      {
        title: "Building",
        dataIndex: "building_name",
        key: "building_name",
        filterable: true,
      },
      {
        title: "Location",
        dataIndex: "location",
        key: "location",
        filterable: true,
      },
      {
        title: "Model & ID",
        dataIndex: "name",
        key: "name",
        filterable: true,
      },
      {
        title: "Last Online",
        dataIndex: "ts_last_data",
        key: "ts_last_data",
        render: (ts_last_data) => ts_last_data ? `${moment(ts_last_data).fromNow()} - ${moment(ts_last_data).format("YYYY-MM-DD, HH:MM")}` : null,
      }
    ];

    const filteredSensors = this.filterSensors();

    return (<div
      id="Sensor Listing"
      className="br-mainpanel br-profile-page floorplan-background"
    >
      <DocumentTitle title="Sensor Inventory" />
      <LogoSpinner loading={this.state.loading} />
      <div className="br-container">
        <div className={styles.mainWrapper}>
          <Row gutter={[15, 15]}>
            <Col span={24}>
              <Title level={3}>View Sensors</Title>
              <Text type='secondary'>
                Inventory of sensors on the system, their mapped locations and
                connectivity status.
              </Text>
            </Col>
            <Col span={12} md={8} xl={6} className={styles.filter}>
              <SearchSelect
                limit={100}
                options={this.state.available_estates}
                placeholder={"Select Estate"}
                defaultValue={this.state.selected_estate}
                actionOnSelectedOption={this._setEstateFilter}
              /></Col>
            <Col span={12} md={8} xl={6} className={styles.filter}>
              <SearchSelect
                limit={100}
                options={this.state.available_buildings}
                placeholder={"Select Building"}
                defaultValue={this.state.selected_building}
                actionOnSelectedOption={this._setBuildingFilter}
              />
            </Col>
            <Col span={24}>
              <div style={{ height: '70vh' }} >
                <AntTable
                  tableId="Sensor Listing"
                  columns={columns}
                  dataSource={filteredSensors}
                  hasInputFilter={true}
                  scroll={{ x: 800 }}
                  exporting
                  rowKey={(record) => `${record.building_name}-${record.name}-${record.location}`}
                />
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div >)

  }
}

export default SensorListing;

import { EventEmitter } from "events";
import Dispatcher from "../dispatcher";
import ActionTypes from "../constants";

const CHANGE = "DATA_SOURCE_CHANGE";

let _data_source_entity_map = [];

class DataSourceStore extends EventEmitter {
  constructor() {
    super();
    // Registers action handler with the Dispatcher.
    Dispatcher.register(this._registerToActions.bind(this));
  }

  // Switches over the action's type when an action is dispatched.
  _registerToActions(action) {
    switch (action.actionType) {
      case ActionTypes.DATA_SOURCE_ENTITY_MAP_FETCHED:
        this._storeDataSourceEntityMap(action.payload);
        break;
      default:
        break;
    }
  }

  // Hooks a React component's callback to the CHANGED event.
  addChangeListener(callback) {
    this.on(CHANGE, callback);
  }

  // Removes the listener from the CHANGED event.
  removeChangeListener(callback) {
    this.removeListener(CHANGE, callback);
  }

  clear() {
    _data_source_entity_map = [];
  }

  _storeDataSourceEntityMap(json) {
    _data_source_entity_map = json;
    this.emit(CHANGE);
  }

  // Getters

  getDataSourceEntityMap() {
    return _data_source_entity_map;
  }
}

const dataSourceStore = new DataSourceStore();
export default dataSourceStore;

import ActionTypes from "../constants";
import GeneralUtils from "../utils/GeneralUtils";

/**
 * Actions related to fetching utilization data. This class interfaces with the API
 * to retrieve live zone data and dispatches actions to update the application state.
 *
 * @class
 * @example
 * const utilizationActions = new UtilizationActions();
 * utilizationActions.getLiveZone('zone123', 'some-token');
 */
class UtilizationActions {
  /**
   * Fetches live data for a specific zone from the API and dispatches an action to store this data.
   * Optionally includes a token in the request URL if provided.
   *
   * @param {string} zoneId - The unique identifier of the zone.
   * @param {string} [token] - An optional token for the request.
   * @returns {Promise} - A promise that resolves with the fetched data.
   */
  getLiveZone(zoneId, token) {
    let url = `utilization/zone/${zoneId}/live`;
    if (token && token.length > 0) {
      url += `?token=${token}`;
    }
    return GeneralUtils.get({
      url: url,
      actionType: ActionTypes.LIVE_ZONE_FETCHED,
      failToast: "Unable to fetch live zone data, please try again",
    });
  }
}

const utilizationActions = new UtilizationActions();
export default utilizationActions;

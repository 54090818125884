import React from "react";
import "./SearchSelectDropdown.scss";
import PropTypes from "prop-types";
import { SearchSelect } from "../SearchSelect";

const SearchSelectDropdown = ({
  label,
  isRequired,
  options,
  placeholder,
  value,
  onSelectHandler,
  disabled,
}) => {
  let selectedOption = value;

  if (typeof value !== "object" && value !== null) {
    selectedOption = options.find((option) => option.value === value);
  }

  return (
    <div className={`SearchSelectDropdown ${disabled ? "disabled" : ""}`}>
      {label && (
        <div className="label">
          {label}{" "}
          {isRequired && <span className="field-status">(required)</span>}
        </div>
      )}
      <SearchSelect
        isDisabled={disabled}
        options={options}
        placeholder={placeholder}
        defaultValue={selectedOption}
        actionOnSelectedOption={onSelectHandler}
      />
    </div>
  );
};

export default SearchSelectDropdown;

SearchSelectDropdown.propTypes = {
  options: PropTypes.array,
  value: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
    PropTypes.number,
  ]),
  onSelectHandler: PropTypes.func,
  placeholder: PropTypes.string,
};

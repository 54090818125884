import React from 'react';
import _ from 'lodash';
import moment from 'moment';
import BuildingBenchmarkActions from '../../../../actions/buildingBenchmarkActions';

import { ShareButton } from '../../../../components/ShareButton';

import ImprovementCard from './ReportComponents/ImprovementCard';
import ColumnChartCard from './ReportComponents/ColumnChartCard';
import GaugeCard from './ReportComponents/GaugeCard';
import SavingsCard from './ReportComponents/SavingsCard';

import COLOR_PALETTE from './ReportComponents/ColorPalette';

import { Row, Col, Typography, Space } from 'antd';
const { Title, Text, Link, Paragraph } = Typography;

const BenchmarkReport = ({ data, isLoggedIn, token, loading }) => {
  const improvements = _.get(data, 'improvements', []);

  const getObjectForToken = () => {
    const ref = _.get(data, 'user_data.ref');
    return {
      type: "BUILDING_BENCHMARK",
      ref: ref
    };
  }

  const getTitle = () => {
    return <Title level={3}>
      {_.get(data, 'user_data.name', 'No Data Found')}
    </Title>
  }

  const getDescription = () => {
    const description = _.get(data, 'user_data.description', '');
    const startMonth = _.get(data, 'user_data.data_period_start');
    const date = `Data covers the 12-month period from ${moment(startMonth).format('MMMM YYYY')} to ${moment(startMonth).add(11, 'month').format('MMMM YYYY')}.`

    return <>
      <Paragraph type='secondary' style={{ margin: 0 }}>
        {description}
      </Paragraph>
      {startMonth && <Paragraph type='secondary' style={{ margin: 0 }}>
        {date}
      </Paragraph>}
    </>
  }

  const getLegend = () => {
    return (
      <Space size="large">
        <span style={{ display: 'inline-flex', alignItems: 'center' }}>
          <span style={{ width: 16, height: 16, backgroundColor: COLOR_PALETTE.energyTypes.thermal, display: 'inline-block', marginRight: 8, borderRadius: '50%' }}></span>
          <Text>Thermal</Text>
        </span>
        <span style={{ display: 'inline-flex', alignItems: 'center' }}>
          <span style={{ width: 16, height: 16, backgroundColor: COLOR_PALETTE.energyTypes.electricity, display: 'inline-block', marginRight: 8, borderRadius: '50%' }}></span>
          <Text>Electricity</Text>
        </span>
      </Space>
    );
  }

  if (loading) {
    return null;
  }

  return (
    <div className="benchmark-report">
      <Row gutter={[20, 20]}>
        <Col xs={24} sm={20}>
          {getTitle()}
          {getDescription()}
          {isLoggedIn && token && (
            <Text type='secondary'>
              This is a shared report, to adjust the variables or create a new one - {' '}
              <Link href="/analysis/building-benchmark">click here</Link>.
            </Text>
          )}
        </Col>
        <Col xs={24} sm={4}>
          {data && <ShareButton
            condition={!!_.get(data, 'user_data.ref')}
            object={getObjectForToken()}
            generateToken={BuildingBenchmarkActions.generateToken}
            urlPath="analysis/building-benchmark/{newToken}"
          />}
        </Col>
        {/* SECTION ONE - EXECUTIVE SUMMARY */}
        <Col span={24} lg={18} >
          <GaugeCard data={data} />
        </Col>
        <Col span={24} lg={6}>
          <SavingsCard data={data} />
        </Col>

        {/* SECTION TWO - DETAILS */}
        <Col span={24}>
          <Title level={3}>Detailed View</Title>
          {getLegend()}
        </Col>
        <Col span={24} lg={8}>
          <ColumnChartCard data={data} type="consumption" />
        </Col>
        <Col span={24} lg={8}>
          <ColumnChartCard data={data} type="emissions" />
        </Col>
        <Col span={24} lg={8}>
          <ColumnChartCard data={data} type="cost" />
        </Col>

        {/* SECTION THREE - IMPROVEMENTS */}
        <Col span={24}>
          {!!improvements.length && <Title level={3}>Improvement Predictions</Title>}
        </Col>
        <Col span={24}>
          {!!improvements.length && (
            <ImprovementCard
              data={data}
            />
          )}
        </Col>
      </Row>
    </div>
  );
};

export default BenchmarkReport;

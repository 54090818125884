import React, { Component } from 'react';
import MultiSelect from "@khanacademy/react-multi-select";
import './ModifyDoorwayPanel.scss';

class ModifyDoorwayPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      building: this.props.building,
      doorway: this.props.doorway,
      zones: this.props.zones
    };
  }
  componentWillReceiveProps(nextProps) {
    this.setState({ building: nextProps.building, doorway: nextProps.doorway, zones: nextProps.zones})
  }
  
  getDoorwayRow(doorway) {
    let interiorNotEditable = false;
    let exteriorNotEditable = false;
    const doorwayLinks = doorway.links.length > 0 ? doorway.links : false
    if (doorwayLinks) {
      doorwayLinks.forEach(link => {
        if (link.sensor_placement === 1) {
          if (link.link_id) interiorNotEditable = true
        } else if (link.sensor_placement === -1) {
          if (link.link_id) exteriorNotEditable = true
        }
      })
    }

    const doorwayOptions = this.props.zones.map(zone => {
      return {name: zone.name, id: zone.zone_id}
    })

    const selectedInteriorZones = doorway.links.map(link => {
      if (link.sensor_placement === 1) {
        return link.zone_id
      }
      return null;
    }).filter(id => id)
    
    const selectedExteriorZones = doorway.links.map(link => {
      if (link.sensor_placement === -1) {
        return link.zone_id
      }
      return null;
    }).filter(id => id)

    const interiorDoorwayOptions = this.props.zones.map(zone => {
      if (!selectedExteriorZones.includes(zone.zone_id)) {
        return {label: zone.name, value: zone.zone_id};
      } else {
        return null;
      }
    }).filter(el => el)

    const exteriorDoorwayOptions = this.props.zones.map(zone => {
      if (!selectedInteriorZones.includes(zone.zone_id)) {
        return {label: zone.name, value: zone.zone_id};
      } else {
        return null;
      }
    }).filter(el => el)

    const interiorZonesString = selectedInteriorZones.map(id => {
      for (let i = 0; i < doorwayOptions.length; i++) {
        if (doorwayOptions[i].id === id) {
          return doorwayOptions[i].name;
        }
      }
      return null;
    }).join(', ')

    const exteriorZonesString = selectedExteriorZones.map(id => {
      for (let i = 0; i < doorwayOptions.length; i++) {
        if (doorwayOptions[i].id === id) {
          return doorwayOptions[i].name;
        } 
      }
      return null;
    }).join(', ')

    return <tr>
      <td style={{ verticalAlign: 'inherit' }}><input placeholder="Name" type="text" className="inline-single-input" defaultValue={doorway.name} onChange={this.props.changeDoorwayValues.bind(this, doorway, 'name')} /></td>
      <td style={{ verticalAlign: 'inherit' }}><input placeholder="Description" type="text" className="inline-single-input" defaultValue={doorway.description} onChange={this.props.changeDoorwayValues.bind(this, doorway, 'description')} /></td>
      <td style={{ verticalAlign: 'inherit' }}>
        <MultiSelect
          disabled={interiorNotEditable}
          disableSearch={false}
          hasSelectAll={false}
          overrideStrings={{
            selectSomeItems: "No interior zones selected",
            allItemsAreSelected: interiorZonesString
          }}
          options={interiorDoorwayOptions}
          selected={selectedInteriorZones}
          onSelectedChanged={this.props.changeDoorwayZones.bind(this, doorway, 'interior_zone' )}
        />
      </td>
      <td style={{ verticalAlign: 'inherit' }}>
        <MultiSelect
          disabled={exteriorNotEditable}
          disableSearch={false}
          hasSelectAll={false}
          overrideStrings={{
            selectSomeItems: "No exterior zones selected",
            allItemsAreSelected: exteriorZonesString
          }}
          options={exteriorDoorwayOptions}
          selected={selectedExteriorZones}
          onSelectedChanged={this.props.changeDoorwayZones.bind(this, doorway, 'exterior_zone' )}
        />
      </td>
      <td style={{ verticalAlign: 'inherit' }}>
        <div style={{  display: 'flex', justifyContent: 'space-evenly'}}>
          {doorway.doorway_id ? <span onClick={this.props.updateDoorway.bind(this, doorway)} style={{ cursor: 'pointer', float: 'right', width:'60px' }} className="btn btn-icon btn-info tx-14 pd-5 mg-l-5">Update</span> : <span onClick={this.props.saveDoorway.bind(this, doorway)} style={{ cursor: 'pointer', float: 'right', width:'60px' }} className="btn btn-icon btn-info tx-14 pd-5 mg-l-5">Save</span>}
          {doorway.doorway_id ? <span onClick={this.props.deleteDoorway.bind(this, doorway.doorway_id)} style={{ cursor: 'pointer', float: 'right', width:'60px' }} className="btn btn-icon btn-danger tx-14 pd-5">Delete</span> : <span onClick={this.props.cancelDoorway.bind(this, doorway)} style={{ cursor: 'pointer', float: 'right', width:'60px' }} className="btn btn-icon btn-warning tx-14 pd-5">Cancel</span>}
        </div>
        </td>
    </tr>
  }


  render() {
    let doorway = this.state.doorway;
    return <React.Fragment>
      {this.getDoorwayRow(doorway)}
    </React.Fragment>
  }

}

export default ModifyDoorwayPanel;

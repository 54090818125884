import ActionTypes from "../constants";
import GeneralUtils from "../utils/GeneralUtils";

/**
 * Actions related to fetching energy data. 
 * This class interfaces with the API to retrieve data and dispatches actions to update the application state.
 *
 * @class
 * @example
 * const energyActions = new EnergyActions();
 */
class EnergyActions {
  /**
   * Fetches the meters for a specific building from the API and dispatches an action to store this data.
   *
   * @param {string} buildingId - The unique identifier of the building.
   * @returns {Promise} - A promise that resolves with the fetched data.
   */
  getBuildingMeters(buildingId) {
    return GeneralUtils.get({
      url: `meter/building/${buildingId}`,
      actionType: ActionTypes.BUILDING_METERS_FETCHED,
    });
  }
}

const energyActions = new EnergyActions();
export default energyActions;

import ActionTypes from "../constants";
import GeneralUtils from "../utils/GeneralUtils";

/**
 * Actions related to fetching and managing meter consumption data. This class interfaces with the API
 * to retrieve data and dispatches actions to update the application state via a Dispatcher.
 *
 * @class
 * @example
 * const meterDetailsActions = new MeterDetailsActions();
 * meterDetailsActions.getMeterConsumption('meter123', new Timeframe('2020-01-01', '2020-01-31'), 'DAY');
 */
class MeterDetailsActions {
  /**
   * Fetches consumption data for a specific meter from the API.
   *
   * @param {string} meterId - The unique identifier of the meter
   * @param {Timeframe} timeframe - Timeframe instance containing start and end dates
   * @param {string} bucketType - The granularity of the data aggregation ('DAY', 'HOUR', 'FIFTEEN_MIN')
   * @returns {Promise} - A promise that resolves with the fetched data
   */
  getMeterConsumption(meterId, timeframe, bucketType) {
    const queryParams = GeneralUtils.toIntervalQueryParameterString(timeframe);

    return GeneralUtils.get({
      url: `v2/meters/${meterId}/data?${queryParams}&bucket=${bucketType}`,
      actionType: ActionTypes.METER_DETAILS_CONSUMPTION_FETCHED,
      modifyResponse: (response) => {
        return {
          data: response,
          bucketType: bucketType
        };
      },
      failToast: "Error fetching meter consumption data",
    });
  }
}

const meterDetailsActions = new MeterDetailsActions();
export default meterDetailsActions;

import React from "react";
import styles from "./Modal.module.scss";
import Icon from "../Icon/Icon";

const Modal = (props) => {
  const {
    modalClassName = "",
    toggleOpen,
    children,
    style,
    hasExit = false,
    backdropUnclickable = false,
  } = props;

  return (
    <div
      className={styles["modal"]}
      onClick={backdropUnclickable ? null : toggleOpen}
    >
      <div
        className={`${styles["modal-card"]} card ${modalClassName}`}
        style={style}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        {hasExit && (
          <div className={styles["exit"]} onClick={toggleOpen}>
            <Icon name="Cross" color="#868ba1" size={24} />
          </div>
        )}
        {children}
      </div>
    </div>
  );
};

export default Modal;


import PropTypes from 'prop-types';
import React, { Component } from 'react';
import './DonutChart.scss';
import Constants from '../../constants';

class DonutChart extends Component {
  render() {
    const halfsize = (this.props.size * 0.5);
    const radius = halfsize - (this.props.strokewidth * 0.5);
    const circumference = 2 * Math.PI * radius;

    if (this.props.isMetricChart) {
      const strokeval = ((((this.props.value / this.props.subValue) * 100) * circumference) / 100);
      let dashval = (strokeval + ' ' + circumference);
      let trackstyle = { strokeWidth: this.props.strokewidth, stroke: Constants.GREEN_TRANSPARENT };
      let indicatorstyle = { strokeWidth: this.props.strokewidth, strokeDasharray: dashval, stroke: Constants.GREEN_TRANSPARENT }
      const rotateval = 'rotate(-90 ' + halfsize + ',' + halfsize + ')';

      let textValue = "--";
      let scoreColor = '#868ba1';
      if (this.props.value != null) {
        if (this.props.value > 0) {
          scoreColor = Constants.RED;
        } else {
          scoreColor = Constants.GREEN;
        }

        if(this.props.alwaysGreen){
          scoreColor = Constants.GREEN;
        }

        textValue = this.props.value.toFixed();
        trackstyle = { strokeWidth: this.props.strokewidth, stroke: scoreColor };
        indicatorstyle = { strokeWidth: this.props.strokewidth, strokeDasharray: dashval, stroke: scoreColor}
      }

      let yPos = halfsize + 4;
      if (this.props.textSize) {
        yPos = halfsize + (this.props.textSize / 3)
      }

      return (
        <svg width={this.props.size} height={this.props.size} className="donutchart">
          <circle r={radius} cx={halfsize} cy={halfsize} transform={rotateval} style={trackstyle} className="donutchart-track" />
          <circle r={radius} cx={halfsize} cy={halfsize} transform={rotateval} style={indicatorstyle} className="donutchart-indicator" />
          <text className="donutchart-text" x={halfsize} y={yPos} style={{ textAnchor: 'middle', fill: scoreColor }} >
            <tspan className="donutchart-text-val" style={{ fontSize: this.props.textSize }}>{textValue}</tspan>
          </text>
        </svg>
      );
    } else {
      const strokeval = ((this.props.value * circumference) / 100);
      const dashval = (strokeval + ' ' + circumference);
      let scoreColor = '#868ba1';

      let trackstyle = { strokeWidth: this.props.strokewidth, stroke: Constants.GREEN_TRANSPARENT };
      let indicatorstyle = { strokeWidth: this.props.strokewidth, strokeDasharray: dashval, stroke: Constants.GREEN_TRANSPARENT }
      const rotateval = 'rotate(-90 ' + halfsize + ',' + halfsize + ')';
      if (this.props.value != null) {
        if (this.props.trafficInverted) {
          if (this.props.value < 50) {
            scoreColor = Constants.GREEN;
          }
          if (this.props.value < 80 && this.props.value >= 50) {
            scoreColor = Constants.ORANGE;
          }
          if (this.props.value >= 80) {
            scoreColor = Constants.RED;
          }
        } else {
          if (this.props.value < 50) {
            scoreColor = Constants.RED;
          }
          if (this.props.value < 80 && this.props.value >= 50) {
            scoreColor = Constants.ORANGE;
          }
          if (this.props.value >= 80) {
            scoreColor = Constants.GREEN;
          }
          if (this.props.value > 100) {
            scoreColor = Constants.ORANGE;
          }
        }

        if (this.props.strokeColor) {
          scoreColor = this.props.strokeColor;
        }


        trackstyle = { strokeWidth: this.props.strokewidth };

        if (this.props.transparentTrack) {
          trackstyle = { strokeWidth: this.props.strokewidth, stroke: 'rgba(255, 255, 255, 0.25)' };
        }
        indicatorstyle = { strokeWidth: this.props.strokewidth, strokeDasharray: dashval, stroke: scoreColor }
      }

      let textValue = "--";
      let percentSymbol = "";
      if (this.props.value != null) {
        textValue = this.props.value.toFixed();
        percentSymbol = "%";
      }

      let yPos = halfsize + 4;
      if (this.props.textSize) {
        yPos = halfsize + (this.props.textSize / 4)
      }

      if(this.props.hideValue){
        textValue ='';
        percentSymbol='';
      }

      if(this.props.hidePercent){
        percentSymbol='';
      }

      return (
        <svg width={this.props.size} height={this.props.size} className="donutchart">
          <circle r={radius} cx={halfsize} cy={halfsize} transform={rotateval} style={trackstyle} className="donutchart-track" />
          <circle r={radius} cx={halfsize} cy={halfsize} transform={rotateval} style={indicatorstyle} className="donutchart-indicator" />
          <text className="donutchart-text" x={halfsize} y={yPos} style={{ textAnchor: 'middle', fill: scoreColor }} >
            <tspan className="donutchart-text-val" style={{ fontSize: this.props.textSize }}>{textValue}</tspan>
            <tspan className="donutchart-text-percent" style={{ fontSize: this.props.percentSize }}>{percentSymbol}</tspan>
          </text>
        </svg>
      );
    }
  }
}

DonutChart.propTypes = {
  value: PropTypes.number,        // value the chart should show
  valuelabel: PropTypes.string,   // label for the chart
  size: PropTypes.number,         // diameter of chart
  strokewidth: PropTypes.number   // width of chart line
}

DonutChart.defaultProps = {
  value: 0,
  size: 76,
  strokewidth: 6
}

export default DonutChart;

import React, { Component } from 'react';
import Highcharts from 'highcharts';
import HC_exporting from 'highcharts/modules/exporting';
import HC_export_data from 'highcharts/modules/export-data';
import HC_more from 'highcharts/highcharts-more';
import './SupplyEmissionChart.scss';
import ChartUtils from '../../utils/ChartUtils'

class SupplyEmissionChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
    this.drawChart = this.drawChart.bind(this);
  }
  componentDidMount() {
    if (this.props.series[0] && this.props.series[0].data && this.props.series[0].data.length > 0) {
      this.drawChart();
    }
  }
  componentDidUpdate() {
    if (this.props.series[0] && this.props.series[0].data && this.props.series[0].data.length > 0) {
      this.drawChart();
    }
  }
  componentWillUnmount() {
    if (this.chart) {
      this.chart.destroy();
    }
  }
  shouldComponentUpdate(nextProps) {


    if (nextProps.interval && this.props.interval && nextProps.interval !== this.props.interval) {
      return true;
    }
    if (nextProps.dataType && this.props.dataType && nextProps.dataType !== this.props.dataType) {
      return true;
    }

    if (nextProps.meter_id && this.props.meter_id && nextProps.meter_id !== this.props.meter_id) {
      return true;
    }

    if (this.props.series && nextProps.series) {
      if (JSON.stringify(this.props.series) !== JSON.stringify(nextProps.series)) {
        return true;
      }
    }

    if (this.props.alwaysRedraw) {
      return true;
    }

    return false;

  }
  render() {
    if (ChartUtils.checkHasData(this.props.series)) {
      return (<div id={this.props.chart_Tag} style={{ height: '100%', width: '100%' }} />);
    } else {
      return (<div className="d-flex align-items-center justify-content-center" style={{ marginTop: 'auto', marginBottom: 'auto', height: "100%" }}>
        <div className="tx-center">
          <span className="tx-12" style={{ opacity: '0.6' }}>
            <img alt="no sensor" style={{ maxHeight: '120px', maxWidth: '120px', width: "100%", height: "100%" }} src="/img/graphics/sensors-icon.png" />
            <p style={{ opacity: '0.6' }}>No Data Available</p></span>
        </div>
      </div>)
    }
  }
  drawChart() {
    HC_exporting(Highcharts)
    HC_export_data(Highcharts);
    HC_more(Highcharts);


    const getYAxisMax = () => {
      let maxFromSetpoint = 0;
      let maxFromSuboptimals = 0;
      this.props.series.forEach(serie => {
        if (serie.data.length === 1) {
          maxFromSetpoint = Math.round(serie.data[0][1] * 1.2)
        } else if (serie.name === 'Suboptimal Range') {
          maxFromSuboptimals = Math.round(serie.data[10][2] * 1.2)
        }
      });

      return maxFromSetpoint > maxFromSuboptimals ? maxFromSetpoint : maxFromSuboptimals;
    }

    let options = {
      title: {
        enabled: false,
        text: '',
      },
      credits: {
        enabled: false
      },
      plotOptions: {
        series: {
          states: {
            inactive: {
              opacity: 1
            }
          }
        }
      },
      yAxis: {
        endOnTick: true,
        title: {
          text: this.props.yAxisTitle
        },
        min: 0,
        max: this.props.yMax ? this.props.yMax : getYAxisMax()
      },
      exporting: {
        enabled: false
      },
      tooltip: {
        headerFormat: '<b>Your Building</b><br>',
        pointFormat: `{point.y} ${this.props.valueSuffix} / {point.x} Sqm`,
      },
      xAxis: {
        title: {
          text: this.props.xAxisTitle
        },
      },
      series: this.props.series
    }

    if (this.props.options) {
      options = {
        ...options,
        ...this.props.options
      }
    }

    this.chart = Highcharts.chart(this.props.chart_Tag, options);
  }
}

export default SupplyEmissionChart;
// src/index.js

import React from 'react';
import { createRoot } from 'react-dom/client';
import { Router, Route } from 'react-router-dom';
import { Routes } from './routes';
import * as serviceWorker from './serviceWorker';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Highcharts from 'highcharts';
import { polyfill } from 'es6-promise';
import { checkTokenOnFocus } from './utils/tokenValidator';
import { createBrowserHistory } from 'history';
import {
  createReactRouterV5Options,
  getWebInstrumentations,
  initializeFaro,
  ReactIntegration,
} from '@grafana/faro-react';
import { TracingInstrumentation } from '@grafana/faro-web-tracing';

import './scss/index.scss';

// Define Faro configurations
const PROD_FARO_CONFIG = {
  url: 'https://faro-collector-prod-us-west-0.grafana.net/collect/1a63cc17654dfec7ce8e821304fb464b',
  app: {
    name: 'Prod UI',
    version: '1.0.0',
    environment: 'production',
  },
};

const DEV_FARO_CONFIG = {
  url: 'https://faro-collector-prod-us-west-0.grafana.net/collect/79f81eb9fc2cdcfe399d81f384c9f2b5',
  app: {
    name: 'Dev UI',
    version: '1.0.0',
    environment: 'production',
  },
};

const ignoreUrlPatterns = [
  /\/dashboards\/occupancy-status\/.*/,
  /\/meter-dashboard\/.*/,
];

const getFaroConfig = (host) => {
  switch (host) {
    case 'ui.opnbuildings.com':
      return PROD_FARO_CONFIG;
    case 'dev-ui.opnbuildings.com':
      return DEV_FARO_CONFIG;
    default:
      return null;
  }
};

const history = createBrowserHistory();

const initializeFaroForEnvironment = () => {
  const host = window.location.hostname;
  const faroConfig = getFaroConfig(host);

  if (!faroConfig) {
    console.warn(`[Faro] No Faro configuration found for host: ${host}`);
    return;
  }

  initializeFaro({
    ...faroConfig,
    beforeSend: (payload) => {
      const url = payload?.meta?.page?.url || '';
      const shouldIgnore = ignoreUrlPatterns.some((pattern) => pattern.test(url));

      if (shouldIgnore) {
        return null;
      }
      return payload;
    },
    instrumentations: [
      ...getWebInstrumentations(),
      new TracingInstrumentation(),
      new ReactIntegration({
        router: createReactRouterV5Options({
          history,
          Route,
        }),
      }),
    ],
  });
};

initializeFaroForEnvironment();

// Configure toast notifications
toast.configure();

// Apply polyfills
polyfill();

// Validate token on focus
checkTokenOnFocus();

// Configure Highcharts
Highcharts.setOptions({
  global: {
    useUTC: false,
  },
});

// Polyfill for String.prototype.includes
if (!String.prototype.includes) {
  // eslint-disable-next-line no-extend-native
  String.prototype.includes = function (search, start) {
    if (typeof start !== 'number') {
      start = 0;
    }

    if (start + search.length > this.length) {
      return false;
    } else {
      return this.indexOf(search, start) !== -1;
    }
  };
}

// Polyfill for Object.keys (IE 8/9)
if (!Object.keys) {
  Object.keys = (function () {
    var hasOwnProperty = Object.prototype.hasOwnProperty,
      hasDontEnumBug = !({ toString: null }).propertyIsEnumerable('toString'),
      dontEnums = [
        'toString',
        'toLocaleString',
        'valueOf',
        'hasOwnProperty',
        'isPrototypeOf',
        'propertyIsEnumerable',
        'constructor',
      ],
      dontEnumsLength = dontEnums.length;

    return function (obj) {
      if (typeof obj !== 'function' && (typeof obj !== 'object' || obj === null)) {
        throw new TypeError('Object.keys called on non-object');
      }

      var result = [],
        prop,
        i;

      for (prop in obj) {
        if (hasOwnProperty.call(obj, prop)) {
          result.push(prop);
        }
      }

      if (hasDontEnumBug) {
        for (i = 0; i < dontEnumsLength; i++) {
          if (hasOwnProperty.call(obj, dontEnums[i])) {
            result.push(dontEnums[i]);
          }
        }
      }
      return result;
    };
  })();
}

// Polyfill for Object.entries (IE 11)
if (!Object.entries) {
  Object.entries = function (obj) {
    var ownProps = Object.keys(obj),
      i = ownProps.length,
      resArray = new Array(i); // preallocate the Array
    while (i--)
      resArray[i] = [ownProps[i], obj[ownProps[i]]];

    return resArray;
  };
}

// Polyfill for Number.parseInt
if (Number.parseInt === undefined) {
  Number.parseInt = window.parseInt;
}

// Maintenance configuration
const MAINTENANCE_CONFIG = {
  active: false, // Whether maintenance mode is active
  message: '', // Displays a message (default is "We plan to be back soon!")
  countdownActive: false, // controls whether countdown component is visible
  timeTillDate: '23 11 2020, 09:00 am', // Sets countdown goal
  timeFormat: 'DD MM YYYY, h:mm a', // Countdown goal format
};

// Select the root DOM node
const container = document.getElementById('root');

// Create a root.
const root = createRoot(container);

// Initial render
root.render(
  <Router history={history}>
    <Routes maintenanceData={MAINTENANCE_CONFIG} />
  </Router>
);

// Register or unregister service worker
serviceWorker.unregister();

import React, { useState, useEffect } from "react";
import "./EnergyPerformanceTable.scss";
import _ from "lodash";
import moment from "moment";
import GeneralUtils from "../../../../utils/GeneralUtils";
import Timeframe from "../../../../utils/Timeframe";

import { DocumentTitle } from "../../../../components/DocumentTitle";
import { LogoSpinner } from "../../../../components/LogoSpinner";
import { TimeframePicker } from "../../../../components/TimeframePicker";
import { ShareButton } from "../../../../components/ShareButton";
import { Icon } from "../../../../components/Icon";
import { AntTable } from "../../../../components/AntTable";
import { Typography, Modal } from 'antd';
import { EnergyPerformanceCharts } from "../EnergyPerformanceCharts";

import EnergyReviewActions from "../../../../actions/energyReviewActions";
import UserStore from "../../../../stores/userStore";

const { Text, Link, Title } = Typography;

const COLOR_PALETTE = {
  RED: "#fbb5b3",
  GREEN: "#c2e5db"
}

const EnergyPerformanceTable = ({ listRef, token }) => {
  const [loading, setLoading] = useState(true);
  const [state, setState] = useState({
    report: null,
    meters: [],
    list: null,
    timeframe: Timeframe.lastWeek(),
    lists: [],
    selected_list: null,
    menuOpen: true,
    selectedMeter: null,
    isModalOpen: false,
  });

  const [isLoggedIn] = useState(UserStore.loggedIn());


  // fetch list
  useEffect(() => {

    const fetchList = async () => {
      setLoading(true)
      try {
        const report = await EnergyReviewActions.getReport(listRef, state.timeframe);

        setState(prevState => ({
          ...prevState,
          report,
          meters: report.energy_review_meters,
          list: report.energy_review_list,
        }));
      } catch (error) {
        console.error("Error fetching report: ", error.code, error.type);
      } finally {
        setLoading(false)
      }
    };

    if (listRef && !token) {
      fetchList()
    }

  }, [listRef, state.timeframe, token]);

  // fetch list via token

  useEffect(() => {
    const fetchListViaToken = async () => {
      setLoading(true)
      try {
        const report = await EnergyReviewActions.getReportViaToken(token);
        setState(prevState => ({
          ...prevState,
          report: report,
          meters: report.energy_review_meters,
          list: report.energy_review_list
        }));
      } catch (error) {
        console.error('Error fetching report via token: ', error.code, error.type);
      } finally {
        setLoading(false)
      }
    }

    if (token) {
      fetchListViaToken();
    }

  }, [token])

  const handleTimeframeChange = (start, end, granularity) => {
    setState(prevState => ({
      ...prevState,
      timeframe: new Timeframe(start, end),
    }));
  };

  const columnsAnt = [
    {
      title: "Meter",
      dataIndex: ["meter", "description"],
      key: "meterDescription",
      defaultSorter: 'ascend',
      width: '15%',
      render: (v, record) => {
        const name = _.get(record, "meter.description");
        const building_name = _.get(record, "building.name");
        const type = _.get(record, "meter.type");

        let icon;
        if (type.includes("ELECTRIC")) icon = <Icon name="Electricity" color="#e67e22" size={30} style={{ marginRight: '10px' }} />;
        if (type.includes("HEAT")) icon = <Icon name="Thermometer" color="#e74c3c" size={30} style={{ marginRight: '10px' }} />;
        if (type.includes("GAS")) icon = <Icon name="Flame" color="#3498db" size={30} style={{ marginRight: '10px' }} />;

        return (
          <div className="d-flex align-items-center cell-container name-cell">
            <div className="icon">{icon}</div>
            <div className="labels">
              <div className="tx-inverse column-emphasis name">{name}</div>
              <div className="building">{building_name}</div>
            </div>
          </div>
        );
      }
    },
    {
      title: "Expected",
      dataIndex: ["baseline_consumption", "interval_consumption"],
      align: 'center',
      key: "baseline_consumption.interval_consumption",
      width: '10%',
      render: (value) => value !== undefined && value !== null
        ? GeneralUtils.getFormattedNumberWithUnit(value, " kWh", 0)
        : "-"
    },
    {
      title: "Actual",
      align: 'center',
      dataIndex: ["current_consumption", "interval_consumption"],
      key: "current_consumption.interval_consumption",
      width: '10%',
      render: (value) => value !== undefined && value !== null
        ? GeneralUtils.getFormattedNumberWithUnit(value, " kWh", 0)
        : "-"
    },
    {
      title: "Performance",
      align: 'center',
      dataIndex: ["interval_consumption_change", "relative"],
      key: "interval_consumption_change.relative",
      width: '10%',
      render: (v, record) => {
        const value = _.get(record, "interval_consumption_change.relative");
        const secondary_value = _.get(record, "interval_consumption_change.absolute");
        return renderChangeCell(value, secondary_value);
      }
    },
    {
      title: "YTD Expected",
      align: 'center',
      dataIndex: ["baseline_consumption", "ytd_consumption"],
      key: "baseline_consumption.ytd_consumption",
      width: '10%',
      render: (value) => value !== undefined && value !== null
        ? GeneralUtils.getFormattedNumberWithUnit(value, " kWh", 0)
        : "-"
    },
    {
      title: "YTD Actual",
      align: 'center',
      dataIndex: ["current_consumption", "ytd_consumption"],
      key: "current_consumption.ytd_consumption",
      width: '10%',
      render: (value) => value !== undefined && value !== null
        ? GeneralUtils.getFormattedNumberWithUnit(value, " kWh", 0)
        : "-"
    },
    {
      title: "YTD Performance",
      align: 'center',
      key: "ytd_consumption_change.relative",
      dataIndex: ["ytd_consumption_change", "relative"],
      width: '10%',
      render: (v, record) => {
        const value = _.get(record, "ytd_consumption_change.relative");
        const secondary_value = _.get(record, "ytd_consumption_change.absolute");
        return renderChangeCell(value, secondary_value);
      }
    },
    {
      title: "YTD Cost",
      align: 'center',
      key: "baseline_cost.ytd_cost",
      dataIndex: ["baseline_cost", "ytd_cost"],
      width: '10%',
      render: (v, record) => {
        const value = _.get(record, "baseline_cost.ytd_cost");
        const secondary_value = _.get(record, "ytd_cost_change.absolute");
        const currency = _.get(record, "building.currency");
        return renderCostCell(value, secondary_value, currency);
      }
    }
  ];



  const renderChangeCell = (value, secondary_value) => {

    let formatted_value = "-";
    let formatted_secondary_value = "-";
    let backgroundColor = "";

    if (value !== undefined && value !== null && value > 0) {
      formatted_value = "+" + GeneralUtils.getFormattedNumberWithUnit(value, "%", 1);
      formatted_secondary_value = "+" + GeneralUtils.getFormattedNumberWithUnit(secondary_value, " kWh", 0);
      backgroundColor = COLOR_PALETTE.RED;
    } else if (value !== undefined && value !== null && value < 0) {
      formatted_value = GeneralUtils.getFormattedNumberWithUnit(value, "%", 1);
      formatted_secondary_value = GeneralUtils.getFormattedNumberWithUnit(secondary_value, " kWh", 0);
      backgroundColor = COLOR_PALETTE.GREEN;
    }

    return (
      <div className="change-cell" style={{ backgroundColor, margin: '0px', padding: '5px', borderRadius: '5px' }}>
        <div className="align-items-center cell-container">
          <div className="labels">
            <div className="column-emphasis value">{formatted_value}</div>
            {!!secondary_value && <div className="secondary-value">{formatted_secondary_value}</div>}
          </div>
        </div>
      </div>
    );
  };

  const renderCostCell = (value, secondary_value, currency) => {
    let formatted_value = "-";
    let formatted_secondary_value = "-";
    let unit = currency === "GBP" ? "£" : "€";

    if (value !== undefined && value !== null) {
      formatted_value = unit + GeneralUtils.getFormattedNumberWithUnit(value, "", 0);
    }

    if (secondary_value !== undefined && secondary_value !== null) {
      const prefix = secondary_value > 0 ? "+" : "-";
      formatted_secondary_value = unit + prefix + GeneralUtils.getFormattedNumberWithUnit(Math.abs(secondary_value), "", 0);
    }

    return (
      <div className="change-cell">
        <div className="align-items-center cell-container">
          <div className="labels">
            <div className="tx-inverse column-emphasis value">{formatted_value}</div>
            {secondary_value && <div className="secondary-value">{formatted_secondary_value}</div>}
          </div>
        </div>
      </div>
    );
  };

  const handleRowClick = (record) => {

    setState(prevState => ({
      ...prevState,
      selectedMeter: record,
      isModalOpen: true,
    }));
  };

  const handleModalClose = () => {
    setState(prevState => ({
      ...prevState,
      selectedMeter: null,
      isModalOpen: false,
    }));
  };

  const getTable = () => {
    return (
      <div>
        <div className="wd-100p" style={{
          height: '63vh'
        }}>
          <AntTable
            tableId="energy-performance-secondary"
            columns={columnsAnt}
            dataSource={state.meters}
            hasInputFilter={false}
            pagination={false}
            rowKey={(record) => {
              return `${record.meter.description}${record.building.name}`
            }}
            scroll={{ x: 1000 }}
            stickyFilters={false}
            onRow={isLoggedIn ?
              (record) => ({
                onClick: () => handleRowClick(record)
              }) : null}
          />
        </div>
        <Modal
          title={`${state.selectedMeter?.meter?.description || ''}`}
          open={state.isModalOpen}
          onCancel={handleModalClose}
          footer={null}
          width={'80vw'}
          centered
        >
          {state.selectedMeter && (
            <EnergyPerformanceCharts meter={state.selectedMeter} />
          )}
        </Modal>
      </div>
    );
  };

  const getSummaryCards = () => {
    return (
      <div className="summary-cards">
        {getTimeframePerformanceCard()}
        {getYTDPerformanceCard()}
        {getYTDCostCard()}
      </div>
    );
  };

  const sumUpProperties = (array, property) => {
    const keys = property.split(".");
    const reducer = (accumulator, currentValue) => {
      if (currentValue !== undefined && currentValue !== null) {
        return accumulator + currentValue;
      }
      return accumulator;
    };

    return array.reduce((accumulator, currentValue) => {
      let nestedValue = currentValue;

      for (let i = 0; i < keys.length; i++) {
        try {
          nestedValue = nestedValue[keys[i]];
        } catch { }

        if (nestedValue === undefined) break;
      }

      return reducer(accumulator, nestedValue);
    }, 0);
  };

  const getYTDPerformanceCard = () => {
    let relative = "-";
    let absolute = "-";
    let backgroundColor = "";
    let unit = "";

    const meters = state.meters;

    if (meters.length) {
      absolute = sumUpProperties(
        meters,
        "ytd_consumption_change.absolute"
      );

      const totalBaseline = sumUpProperties(
        meters,
        "baseline_consumption.ytd_consumption"
      );

      relative = (absolute / totalBaseline) * 100;
      relative = GeneralUtils.getFormattedNumberWithUnit(relative, "%", 1);
      if (absolute < 0) {
        backgroundColor = COLOR_PALETTE.GREEN;
      } else if (absolute > 0) {
        backgroundColor = COLOR_PALETTE.RED;
        unit = "+";
      }
      absolute = GeneralUtils.getFormattedNumberWithUnit(absolute, " kWh", 0);
    }

    return (
      <div
        className="card with-icon shadow-base"
        style={{ backgroundColor }}
      >
        <div className="title tx-mont">YTD Performance</div>
        <div className="summary-card-body">
          <div className="data">
            <div className="primary">
              {unit}
              {relative}
            </div>
            <div className="secondary">
              {unit}
              {absolute}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const getTimeframePerformanceCard = () => {
    let relative = "-";
    let absolute = "-";
    let backgroundColor = "";
    let unit = "";

    const meters = state.meters;

    if (meters.length) {
      absolute = sumUpProperties(
        meters,
        "interval_consumption_change.absolute"
      );

      const totalBaseline = sumUpProperties(
        meters,
        "baseline_consumption.interval_consumption"
      );

      relative = (absolute / totalBaseline) * 100;
      relative = GeneralUtils.getFormattedNumberWithUnit(relative, "%", 1);
      if (absolute < 0) {
        backgroundColor = COLOR_PALETTE.GREEN;
      } else if (absolute > 0) {
        backgroundColor = COLOR_PALETTE.RED;
        unit = "+";
      }
      absolute = GeneralUtils.getFormattedNumberWithUnit(absolute, " kWh", 0);
    }

    return (
      <div
        className="card with-icon shadow-base"
        style={{ backgroundColor }}
      >
        <div className="title tx-mont">Performance</div>
        <div className="summary-card-body">
          <div className="data">
            <div className="primary">
              {unit}
              {relative}
            </div>
            <div className="secondary">
              {unit}
              {absolute}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const getYTDCostCard = () => {
    let total_cost = "-";
    let total_change = "";
    let unit = "";
    let backgroundColor = "";

    const meters = state.meters;

    if (meters.length) {
      const currency = _.get(state, "meters[0].building.currency");
      if (currency === "EUR") {
        unit = "€";
      } else if (currency === "GBP") {
        unit = "£";
      }

      total_cost = sumUpProperties(meters, "baseline_cost.ytd_cost");
      total_cost = GeneralUtils.getFormattedNumberWithUnit(total_cost, "", 0);
      total_cost = unit + total_cost;

      total_change = sumUpProperties(meters, "ytd_cost_change.absolute");
      if (total_change < 0) {
        backgroundColor = COLOR_PALETTE.GREEN;
      } else if (total_change > 0) {
        backgroundColor = COLOR_PALETTE.RED;
      }
      total_change = Math.abs(total_change);
      total_change = GeneralUtils.getFormattedNumberWithUnit(
        total_change,
        "",
        0
      );
      total_change = unit + total_change;
    }

    return (
      <div
        className="card with-icon shadow-base"
        style={{ backgroundColor }}
      >
        <div className="title tx-mont">YTD Cost</div>
        <div className="summary-card-body">
          <div className="data">
            <div className="primary">{total_cost}</div>
            <div className="secondary">{total_change}</div>
          </div>
        </div>
      </div>
    );
  };

  const getTimeframePicker = () => {
    const formatted = state.timeframe.format();
    return (
      <div>
        <TimeframePicker
          start={formatted.start}
          end={formatted.end}
          defaultGranularity={'week'}
          onChange={handleTimeframeChange}
          granularities={['week', 'month']}
          spacing="10px"
        />
      </div>
    )
  }

  const getObjectForToken = () => {
    const formatted = state.timeframe.extendEndByDay().format();
    return {
      type: "ENERGY_REPORT",
      ref: listRef,
      search_interval: {
        start: formatted.start,
        end: formatted.end
      }
    }
  }

  const getReportArea = () => {
    const name = _.get(state, 'report.energy_review_list.name', 'Unknown List');

    return <div className={`mainArea ${state.menuOpen ? '' : 'menuClosed'}`}>
      <div className="row">
        <div className="col">
          <Title
            level={3}>
            {name}
          </Title>
        </div>
        <div className="col">
          {listRef && <ShareButton
            condition={!!listRef}
            object={getObjectForToken()}
            generateToken={EnergyReviewActions.generateToken}
            urlPath="analysis/energy-performance-report/{newToken}"
          />}
        </div>
      </div>

      {token && isLoggedIn && (
        <div className="row mg-b-10">
          <div className="col-12">
            <Title type='primary' level={5}>
              {moment(state.timeframe.start).format('DD MMM YYYY')} - {moment(state.timeframe.end).format('DD MMM YYYY')}
            </Title>
          </div>
          <div className="col-12">
            <Text type='secondary'>
              This is a shared report, to adjust the variables or create a new one - {' '}
              <Link href="/analysis/energy-performance-report">click here</Link>.
            </Text>
          </div>
        </div>
      )}

      <div className="top-row row mg-b-20">
        <div className="cards col ">{getSummaryCards()}</div>
        {!token && <div className="selector col ">{getTimeframePicker()}</div>}
      </div>
      <div className="row">
        <div className="col">
          {getTable(state.report)}
        </div>
      </div>
    </div >

  }

  return (
    <div id="EnergyPerformanceTable" className={'floorplan-background'}>
      <DocumentTitle title="Energy Review Report" />
      <LogoSpinner loading={loading} />
      {getReportArea()}
    </div>
  );
}

export default EnergyPerformanceTable;

import React, { useEffect, useState, useRef } from 'react';
import UrlManager from '../../utils/UrlManager';
import PropTypes from 'prop-types';
import Stomp from 'stompjs';


const Websocket = (props) => {
  const { destination, children, debug, login, password, heartbeatIncoming = 60000, heartbeatOutgoing = 0, retryDelay = 60000, initialData = null } = props;
  // Data is last received message
  const [data, setData] = useState(initialData);
  // isOnline is a bool that shows if the link is active and healthy
  const [isOnline, setIsOnline] = useState(false);
  // Controller's function is to trigger re-renders and therefore reconnscts
  const [controller, triggerController] = useState(false);
  // Mounted is a bool checking if component is  mounted in DOM
  const mounted = useRef(false)

  const handleMessage = (message) => {
    setData(message)
  }

  useEffect(() => {
    mounted.current = true;
    const client = Stomp.client(UrlManager.getWebSocketUrl());
    client.heartbeat.incoming = heartbeatIncoming;
    client.heartbeat.outgoing = heartbeatOutgoing;

    const retry_connect = function () {
      console.log('Retrying Connection...')

      client.ws.close()

      if (mounted.current) {
        setTimeout(function () {
          setIsOnline(false);
          triggerController(!controller)
        }, retryDelay);
      }
    };

    const connect_callback = function (frame) {
      console.log('Connection Established')
      setIsOnline(true);

      if (typeof destination === 'string') {
        client.subscribe(destination, subscribe_callback);
      } else if (Array.isArray(destination) && destination.length > 0) {
        destination.forEach(topic => {
          client.subscribe(topic, subscribe_callback);
        })
      } else {
        console.log('No websocket destination subscriptions selected')
      }
    };

    const connect_error_callback = function (frame) {
      console.log('Connection Error')
      retry_connect();
    };

    const connect_close_callback = function (frame) {
      console.log('Connection Closed')
      retry_connect();
    };

    const subscribe_callback = (message) => {
      setIsOnline(true);
      handleMessage(JSON.parse(message.body))
    };

    if (debug !== true) client.debug = function (str) { };

    if (login != null && password != null) {
      client.connect(
        login,
        password,
        connect_callback,
        connect_error_callback,
        connect_close_callback
      );

    }
    const handleOnline = () => {
      triggerController(!controller)
    }
    const handleOffline = () => {
      setIsOnline(false)
      triggerController(!controller)
    }

    window.addEventListener('offline', handleOffline)
    window.addEventListener('online', handleOnline)

    return () => {
      mounted.current = false;

      client.ws.close()

      window.removeEventListener('offline', handleOffline)
      window.removeEventListener('online', handleOnline)
    }

  }, [controller, debug, destination, login, password, heartbeatIncoming, heartbeatOutgoing, retryDelay])


  return <div className='WebSocket'>
    {React.cloneElement(children, { websocketData: data, websocketOnline: isOnline })}
  </div>
};

Websocket.propTypes = {
  destination: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,           // Destination for websocket, cane be a string or array of strings
  debug: PropTypes.bool,                                                                      // True will result in debug messages showing in console
  children: PropTypes.element.isRequired,                                                     // React element utilizing the connection
  heartbeatOutgoing: PropTypes.number,                                                        // Outgoing Heartbeat
  heartbeatIncoming: PropTypes.number,                                                        // Incoming Heartbeat
  login: PropTypes.string,                                                                    // Socket Login
  password: PropTypes.string,                                                                 // Socket Password
  retryDelay: PropTypes.number,                                                               // Time in ms until the reconnect is attempted. Default: 60s
  initialData: PropTypes.any,                                                                 // Initial data fetched from regular request
}

export default Websocket;
import React, { Component } from "react";
import PropTypes from "prop-types";
import "./Chart.scss";

import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import HC_exporting from "highcharts/modules/exporting";
import HC_export_data from "highcharts/modules/export-data";
import HC_more from "highcharts/highcharts-more";

import { Icon } from '../Icon';

class Chart extends Component {
  mergeOptions(config, prop) {
    if (!prop || typeof prop !== "object") {
      return config;
    }

    const mergedOptions = { ...config };

    for (const key in prop) {
      if (prop.hasOwnProperty(key)) {
        if (typeof prop[key] === "object" && typeof config[key] === "object") {
          mergedOptions[key] = this.mergeOptions(config[key], prop[key]);
        } else {
          mergedOptions[key] = prop[key];
        }
      }
    }

    return mergedOptions;
  }

  getChartOptions() {
    const unit = this.props.unit;
    const height = this.props.height;
    const tooltipDecimals = this.props.tooltipDecimals;

    let options = {
      chart: {
        type: "line",
        zoomType: "x",
        height: height ? height : 300,
      },
      rangeSelector: {
        enabled: false,
      },
      navigator: {
        enabled: false,
      },
      scrollbar: {
        enabled: false,
      },
      legend: {
        enabled: true,
        align: "center",
        verticalAlign: "bottom",
        itemStyle: {
          color: "#343a40",
          fontSize: "9px",
          fontWeight: "bold",
        },
      },
      credits: {
        enabled: false,
      },
      title: {
        text: null,
      },
      xAxis: {
        gridLineDashStyle: "longdash",
        type: "datetime",
        gridLineWidth: 1,
        gridLineColor: "#e6e6e6",
        minorLineWidth: 1,
        startOnTick: false,
        endOnTick: false,

        labels: {
          style: {
            color: "#343a40",
            fontSize: "9px",
          },
        },
        plotBands: [],
        units: [
          ["millisecond", [1, 2, 5, 10, 20, 25, 50, 100, 200, 500]],
          ["second", [1, 2, 5, 10, 15, 30]],
          ["minute", [1, 2, 5, 10, 15, 30]],
          ["hour", [1, 3, 5, 7, 9, 11, 13, 15, 17, 19, 21, 23]],
          ["day", [1]],
          ["week", [1]],
          ["month", [1, 3, 6]],
          ["year", null],
        ],
      },
      yAxis: {
        title: {
          text: null,
        },
        opposite: false,
        height: "100%",
        offset: 0,
        lineWidth: 2,
        gridLineDashStyle: "longdash",
        gridLineWidth: 1,
        gridLineColor: "#e6e6e6",
        minorLineWidth: 1,
        startOnTick: false,
        endOnTick: false,
        showFirstLabel: true,
        showLastLabel: true,
        labels: {
          style: {
            color: "#343a40",
            fontSize: "9px",
          },
          formatter: function () {
            if (unit) return Math.abs(this.value) + unit;
            return Math.abs(this.value);
          },
        },
        softMin: 15,
        softMax: 25,
        min: this.props.min,
        max: this.props.max ? this.props.max : unit.includes("%") ? 100 : null,
      },
      tooltip: {
        shared: true,
        crosshairs: true,
        valueDecimals: tooltipDecimals !== undefined ? tooltipDecimals : 2,
        valueSuffix: this.props.unit,
        xDateFormat: "%a, %b %e : %H:%M",
      },
      plotOptions: {
        series: {
          turboThreshold: 0,
          marker: {
            enabled: false,
          },
          groupPadding: 0,
          pointPadding: 0,
          borderWidth: 0.2,
          pointPlacement: "on",
        },
        line: {
          dataLabels: {
            enabled: false,
          },
          softThreshold: false,
          series: {
            marker: {
              enabled: false,
            },
          },
        },
        column: {
          softThreshold: false,
        },
        area: {
          stacking: "normal",
          step: "right",
          softThreshold: false,
        },
      },
      exporting: {
        enabled: false,
      },
      navigation: {
        buttonOptions: {
          verticalAlign: "bottom",
          y: 0,
          x: 0,
          theme: {
            style: {
              color: "#17A2B8",
              textDecoration: "underline",
            },
          },
        },
      },
      series: this.props.data,
    };

    if (this.props.options) {
      options = this.mergeOptions(options, this.props.options);
    }

    if (this.props.exporting) {
      options.exporting = {
        enabled: true,
        buttons: {
          contextButton: {
            enabled: true,
          },
          exportButton: {
            text: "Export",
            menuItems: [
              "downloadCSV",
              "downloadPDF",
              "downloadPNG",
              "viewFullscreen",
            ],
          },
        },
      };
    }

    return options;
  }

  getBucketButtons() {
    if (this.props.buttons) {
      return (
        <div className="buttons">
          {this.props.buttons.map((b) => {
            let classes = "time-bucket";
            if (b.disabledCondition) {
              classes += " disabled";
            } else if (b.activeCondition) {
              classes += " active";
            }

            return (
              <div
                key={b.label}
                className={classes}
                onClick={
                  b.handler && !b.disabledCondition ? b.handler : undefined
                }
              >
                {b.label}
              </div>
            );
          })}
        </div>
      );
    }

    return <div className="buttons"></div>;
  }

  render() {
    HC_exporting(Highcharts);
    HC_export_data(Highcharts);
    HC_more(Highcharts);

    if (this.props.noData || this.props.data.length === 0) {
      return (
        <div
          className="Chart shadow-base card bd-0 shadow-base noData"
          style={{ width: this.props.width, height: "280px" }}
        >
          <Icon name='Warning' color='#868ba1' />
          <div>No Data Available</div>
        </div>
      );
    }

    return (
      <div
        className="Chart card bd-0 shadow-base"
        style={{ width: this.props.width }}
      >
        <div className="chart-header">
          <div className="title tx-archivo-semibold">{this.props.title}</div>
          {this.getBucketButtons()}
        </div>
        <div className="chart-body mg-t-20">
          <HighchartsReact
            highcharts={Highcharts}
            options={this.getChartOptions()}
          />
        </div>
      </div>
    );
  }
}

Chart.defaultProps = {
  title: "",
  height: 300,
  noData: false,
  exporting: true,
};

Chart.propTypes = {
  title: PropTypes.string,
  data: PropTypes.array,
  buttons: PropTypes.array,
  height: PropTypes.number,
  min: PropTypes.number,
  max: PropTypes.number,
  tooltipDecimals: PropTypes.number,
  width: PropTypes.string,
  unit: PropTypes.string,
  options: PropTypes.object,
  noData: PropTypes.bool,
  exporting: PropTypes.bool,
};

export default Chart;

import React, { Component } from "react";
import { Table, Button } from 'antd';
import "./ApplianceLogDebug.scss";
import AdminActions from "../../actions/adminActions";
import AdminStore from "../../stores/adminStore";
import BuildingActions from "../../actions/buildingActions";
import BuildingStore from "../../stores/buildingStore";
import GeneralStore from "../../stores/generalStore";
import { HighChart } from "../../components/HighChart";
import { SearchSelect } from "../../components/SearchSelect";
import { DocumentTitle } from "../../components/DocumentTitle";
import { LogoSpinner } from "../../components/LogoSpinner";
import { Icon } from "../../components/Icon";
import _ from "lodash";

class ApplianceLogDebug extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ts_start: GeneralStore.getStartDate(),
      ts_end: GeneralStore.getEndDate(),
      selected_point: null,
      selected_controller: null,
      selected_appliance: null,
      selected_property: null,
      selected_building: null,
      available_buildings: BuildingStore.getBuildings(),
      available_properties: AdminStore.getAvailableProperties(),
      available_appliances: AdminStore.getAvailableAppliances(),
      available_controllers: AdminStore.getAvailableControllers(),
      unique_points: AdminStore.getUniquePoints(),
      chart_data: AdminStore.getPointValues(),
      search_input: "",
      loading: true,
    };
    this._onPointsChange = this._onPointsChange.bind(this);
    this._onFilterInput = this._onFilterInput.bind(this);
    this._selectBuilding = this._selectBuilding.bind(this);
    this._selectAppliance = this._selectAppliance.bind(this);
    this._selectController = this._selectController.bind(this);
    this._selectProperty = this._selectProperty.bind(this);
    this._onDateChange = this._onDateChange.bind(this);
    this._onDateChange = this._onDateChange.bind(this);
    this._clearAllFilters = this._clearAllFilters.bind(this);
  }

  UNSAFE_componentWillMount() {
    GeneralStore.addChangeListener(this._onDateChange);
    AdminStore.addChangeListener(this._onPointsChange);
  }
  componentWillUnmount() {
    AdminStore.clear();
    GeneralStore.removeChangeListener(this._onDateChange);
    AdminStore.removeChangeListener(this._onPointsChange);
  }
  componentDidMount() {
    this.fetchBuildings();
  }

  async fetchBuildings() {
    const buildings = await BuildingActions.getBuildings();
    this.setState({
      available_buildings: buildings,
      loading: false,
    });
  }

  fetchPoints(building_id) {
    this.setState({ loading: true });
    AdminActions.getUniquePointsByBuildingId(building_id);
  }

  _selectBuilding(selected_building) {
    this.setState(
      {
        selected_building,
        search_input: "",
        chart_data: [],
        selected_appliance: null,
        selected_controller: null,
        selected_property: null,
        selected_point: null,
      },
      this.fetchPoints(selected_building.building_id)
    );
  }

  _onDateChange() {
    const ts_start = GeneralStore.getStartDate();
    const ts_end = GeneralStore.getEndDate();

    this.setState(
      {
        ts_start,
        ts_end,
      },
      () => {
        if (this.state.selected_building) {
          this.fetchPoints(this.state.selected_building.building_id)

          if (this.state.selected_point != null) {
            AdminActions.getPointValues(
              this.state.selected_point,
              ts_start,
              ts_end
            );
          }
        }
      }
    );
  }

  _onPointsChange() {
    const points = AdminStore.getUniquePoints();
    const chart_data = AdminStore.getPointValues();

    this.setState({
      unique_points: points,
      chart_data: chart_data,

      available_properties: this.getPropertyOptions(points),
      available_controllers: this.getControllerOptions(points),
      available_appliances: this.getApplianceOptions(points),

      loading: false,
    });
  }

  getApplianceOptions(points) {

    let availableAppliances = [
      ...new Set(
        points.map((pt) => pt.data_source.name).filter((e) => e)
      ),
    ];

    availableAppliances = availableAppliances.map((controller) => {
      return { name: controller };
    });

    availableAppliances = [{ name: "ALL APPLIANCES" }, ...availableAppliances];

    return availableAppliances;
  }

  getControllerOptions(points) {
    let availableControllers = [
      ...new Set(
        points.map((pt) => pt.controller_name).filter((e) => e)
      ),
    ];
    availableControllers = availableControllers.map((controller) => {
      return { name: controller };
    });
    availableControllers = [
      { name: "ALL CONTROLLERS" },
      ...availableControllers,
    ];
    return availableControllers;
  }

  getPropertyOptions(points) {
    let availableProperties = [
      ...new Set(
        points
          .map((pt) => pt.suggested_property)
          .filter((e) => e)
      ),
    ];
    availableProperties = availableProperties.map((property) => {
      return { name: property };
    });
    availableProperties = [{ name: "ALL PROPERTIES" }, ...availableProperties];
    return availableProperties;
  }

  _selectAppliance(selected_appliance) {
    this.setState({
      selected_appliance: selected_appliance,
    });
  }

  _selectController(selected_controller) {
    this.setState({
      selected_controller: selected_controller,
    });
  }

  _selectProperty(selected_property) {
    this.setState({ selected_property: selected_property });
  }

  _onFilterInput(event) {
    this.setState({ search_input: event.target.value });
  }

  getFilteredPoints() {
    let searchValue = this.state.search_input;
    let displayPoints = this.state.unique_points;

    // Sort displayPoints alphabetically by name
    displayPoints = _.sortBy(displayPoints, point => point.name.toLowerCase());

    // Apply filters
    if (this.state.selected_appliance && this.state.selected_appliance.name !== "ALL APPLIANCES") {
      displayPoints = displayPoints.filter(point => point.data_source.name === this.state.selected_appliance.name);
    }

    if (this.state.selected_controller && this.state.selected_controller.name !== "ALL CONTROLLERS") {
      displayPoints = displayPoints.filter(point => point.controller_name === this.state.selected_controller.name);
    }

    if (this.state.selected_property && this.state.selected_property.name !== "ALL PROPERTIES") {
      displayPoints = displayPoints.filter(point =>
        point.suggested_property.toUpperCase() === this.state.selected_property.name.toUpperCase()
      );
    }

    if (this.state.search_input && this.state.search_input.length > 0) {
      displayPoints = displayPoints.filter(point =>
        point.name.toLowerCase().includes(searchValue.toLowerCase()) ||
        point.description.toLowerCase().includes(searchValue.toLowerCase())
      );
    }

    return displayPoints;
  }

  getPointTable() {
    const columns = [
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        sorter: (a, b) => a.name.localeCompare(b.name),
        defaultSortOrder: 'ascend',
        width: '25%'
      },
      {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
        sorter: (a, b) => a.description.localeCompare(b.description),
        width: '20%'
      },
      {
        title: 'Appliance',
        dataIndex: ['data_source', 'name'],
        key: 'appliance',
        render: (text) => text || '-',
        sorter: (a, b) => (a.data_source.name || '').localeCompare(b.data_source.name || ''),
        width: '20%'
      },
      {
        title: 'Controller',
        dataIndex: 'controller_name',
        key: 'controller',
        render: (text) => text || '-',
        sorter: (a, b) => (a.controller_name || '').localeCompare(b.controller_name || ''),
        width: '15%'
      },
      {
        title: 'Property',
        dataIndex: 'suggested_property',
        key: 'property',
        render: (text) => text || '-',
        sorter: (a, b) => (a.suggested_property || '').localeCompare(b.suggested_property || ''),
        width: '10%'
      },
      {
        title: 'Probability',
        dataIndex: 'suggested_probability',
        key: 'probability',
        render: (text) => text ? `${Math.round(text)}%` : '-',
        sorter: (a, b) => (a.suggested_probability || 0) - (b.suggested_probability || 0),
        width: '10%'
      },
    ];

    return (
      <Table
        columns={columns}
        dataSource={this.getFilteredPoints()}
        rowKey={'unique_appliance_point_id'}
        onRow={(record) => ({
          onClick: () => this.getPointValues(record),
        })}
        pagination={false}
        virtual={true}
        scroll={{
          x: 900,
          y: 280,
        }}
      />
    );
  }

  getPointValues(point) {
    this.setState({
      selected_point: point,
      loading: true
    },
      () => AdminActions.getPointValues(point, this.state.ts_start, this.state.ts_end));
  }

  getPointChart() {
    const chart_data = this.state.chart_data;
    const selected_point = this.state.selected_point;
    const loading = this.state.loading;

    let chart_id = "DebugChart1";
    let title = "Select A Point";
    if (selected_point && chart_data && chart_data.length > 0) {

      title = chart_data[0].name;

      return (
        <div className="row mg-t-20 mg-b-20">
          <div className="col-12 col-sm-12">
            <div className="card bd-0 shadow-base" style={{ overflow: 'hidden' }}>
              {this.getChartHeaderContent(title)}
              <div
                className="card-body bd pd-b-0"
                style={{ backgroundColor: "#fff", height: '200px' }}
              >
                <HighChart
                  main_type="line"
                  exporting={true}
                  series={chart_data}
                  dataType={""}
                  chart_Tag={chart_id}
                />
              </div>
            </div>
          </div>
        </div>
      );
    } else if (!loading && selected_point && chart_data && chart_data.length === 0) {
      title = `${this.state.selected_point.name} - ${this.state.selected_point.description}`;

      return <div className="row mg-t-20 mg-b-20">
        <div className="col-12 col-sm-12">
          <div className="card bd-0 shadow-base" style={{ overflow: 'hidden' }}>
            {this.getChartHeaderContent('No data received for ' + title)}
          </div>
        </div>
      </div>
    } else {
      return null;
    }
  }

  getChartHeaderContent(title) {
    return (
      <div className="card-header bg-transparent pd-x-25 pd-y-15 bd d-flex justify-content-between align-items-center">
        <div style={{ float: "left" }}>
          <h6
            className="card-title tx-uppercase tx-12 mg-b-0"
            style={{ float: "left" }}
          >
            {title}
          </h6>
        </div>
      </div>
    );
  }

  _clearAllFilters() {
    this.setState({
      search_input: "",
      selected_appliance: null,
      selected_controller: null,
      selected_property: null,
    });
  }

  isAnyFilterSelected = () => {
    return !!this.state.search_input ||
      !!this.state.selected_appliance ||
      !!this.state.selected_controller ||
      !!this.state.selected_property;
  }

  render() {
    const list_shown = !!this.state.selected_building;

    return (
      <div
        className="br-mainpanel br-profile-body floorplan-background"
        id="ApplianceLogDebug"
      >
        <DocumentTitle title={"Appliance Log Debug"} />
        <LogoSpinner loading={this.state.loading} />
        <div className="br-container">
          <div className="row">
            <div className="col mg-t-15">
              <div className="card pd-20 shadow-base bd-0 mg-t-10">
                <div className="row">
                  <div className="col-12 col-sm-12">
                    <div className="row pd-t-6">
                      <div className="col-12 col-sm-4">
                        <SearchSelect
                          limit={999}
                          options={this.state.available_buildings}
                          placeholder={"Select Building..."}
                          defaultValue={this.state.selected_building}
                          actionOnSelectedOption={this._selectBuilding}
                        />
                      </div>
                      {this.isAnyFilterSelected() && (
                        <div className="col-12 col-sm-4 d-flex align-items-center">
                          <Button onClick={this._clearAllFilters} type="primary" danger>
                            Clear All Filters
                          </Button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                {list_shown ? (
                  <div className="row mg-b-5">
                    <div className="col-12 col-sm-12">
                      <hr />
                      <div className="bd-0 mg-t-10">
                        <div
                          className="bd-0 pd-b-0 pd-t-0"
                          style={{ backgroundColor: "#fff" }}
                        >
                          <div className="row mg-t-10 filters">
                            <div className="col-12 col-sm-6 col-lg-3">
                              <div className="input-group">
                                <div className="input-group-prepend">
                                  <span className="input-group-text">
                                    <Icon name='Search' color='#868ba1' />
                                  </span>
                                </div>
                                <input
                                  value={this.state.search_input}
                                  type="text"
                                  className="form-control"
                                  placeholder="Name/Description filter"
                                  onChange={this._onFilterInput}
                                />
                              </div>
                            </div>
                            <div className="col-12 col-sm-6 col-lg-3">
                              <SearchSelect
                                limit={999}
                                options={this.state.available_appliances}
                                placeholder={"Appliance Filter"}
                                defaultValue={this.state.selected_appliance}
                                actionOnSelectedOption={this._selectAppliance}
                              />
                            </div>
                            <div className="col-12 col-sm-6 col-lg-3">
                              <SearchSelect
                                limit={999}
                                options={this.state.available_controllers}
                                placeholder={"Controller Filter"}
                                defaultValue={this.state.selected_controller}
                                actionOnSelectedOption={this._selectController}
                              />
                            </div>
                            <div className="col-12 col-sm-6 col-lg-3">
                              <SearchSelect
                                limit={999}
                                options={this.state.available_properties}
                                placeholder={"Property Filter"}
                                defaultValue={this.state.selected_property}
                                actionOnSelectedOption={this._selectProperty}
                              />
                            </div>
                          </div>
                          <div
                            className="row mg-t-10 mg-b-5"
                          >
                            <div
                              className="col-12"
                              style={{ marginBottom: "5px", marginTop: "5px" }}
                            >
                              {this.getPointTable()}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="row mg-t-10">
                    <div className="col-12 col-sm-12 col-md-12 mg-b-15">
                      <div className="card bd-0 shadow-base">
                        <div
                          className="card-body bd pd-b-0 pd-t-0"
                          style={{
                            backgroundColor: "#fff",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            padding: "60px 0",
                            borderRadius: '12px'
                          }}
                        >
                          <h4 className="tx-bold mg-b-20 tx-center">
                            Appliance Log Debug
                          </h4>
                          <p className="mg-x-20 tx-center">

                            <Icon name='Construction' color='#868ba1' size={60} />
                          </p>
                          <h4 className="tx-default tx-center">
                            {" "}
                            Please select the building, appliance and controller
                            to filter points from.
                          </h4>
                          <p className="tx-center">
                            Once all selections are made then the available data
                            points will populate.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          {this.getPointChart()}
        </div>
      </div>
    );
  }
}

export default ApplianceLogDebug;

import ActionTypes from "../constants";
import GeneralUtils from "../utils/GeneralUtils";

/**
 * Actions related to fetching and managing building data. This class interfaces with the API
 * to retrieve, create, update, and delete building data, dispatching actions to update the application state.
 *
 * @class
 * @example
 * const buildingActions = new BuildingActions();
 * buildingActions.getBuildings();
 */
class BuildingActions {
  /**
   * Fetches the list of allowed buildings from the API and dispatches an action to store this data.
   *
   * @returns {Promise} - A promise that resolves with the fetched data.
   */
  getBuildings() {
    return GeneralUtils.get({
      url: "building/allowed",
      actionType: ActionTypes.BUILDINGS_FETCHED,
    });
  }

  /**
   * Fetches the details of a specific building by its ID from the API and dispatches an action to store this data.
   *
   * @param {string} buildingId - The unique identifier of the building to be fetched.
   * @returns {Promise} - A promise that resolves with the fetched data.
   */
  getBuilding(buildingId) {
    return GeneralUtils.get({
      url: `building/${buildingId}`,
      actionType: ActionTypes.BUILDING_FETCHED,
    });
  }

  /**
   * Saves a new building to the API and dispatches an action to store the new building data.
   *
   * @param {Object} building - The building object to be saved.
   * @returns {Promise} - A promise that resolves with the response of the save operation.
   */
  saveBuilding(building) {
    return GeneralUtils.post({
      url: "building",
      object: building,
      actionType: ActionTypes.BUILDING_SAVED,
      successToast: "Building saved successfully",
      failToast: "Unable to save building, please try again",
    });
  }

  /**
   * Updates an existing building in the API and dispatches an action to store the updated data.
   *
   * @param {Object} building - The building object to be updated.
   * @returns {Promise} - A promise that resolves with the response of the update operation.
   */
  updateBuilding(building) {
    return GeneralUtils.put({
      url: "building",
      object: building,
      actionType: ActionTypes.BUILDING_UPDATED,
      successToast: "Building updated successfully",
      failToast: "Unable to update building, please try again",
    });
  }

  /**
   * Deletes a specific building by its ID from the API and dispatches an action to remove this data from the state.
   *
   * @param {string} buildingId - The unique identifier of the building to be deleted.
   * @returns {Promise} - A promise that resolves with the response of the delete operation.
   */
  deleteBuilding(buildingId) {
    return GeneralUtils.delete({
      url: `building/${buildingId}`,
      actionType: ActionTypes.BUILDING_DELETED,
      successToast: "Building deleted successfully",
      failToast: "Unable to delete building, please try again",
      modifyResponse: (response) => {
        response.deleted_building_id = buildingId;
        return response;
      },
    });
  }  
}

const buildingActions = new BuildingActions();
export default buildingActions;

import Dispatcher from "../dispatcher";
import ActionTypes from "../constants";

/**
 * Actions related to general application state updates. This class interacts with the Dispatcher
 * to update the application state.
 *
 * @class
 * @example
 * const generalActions = new GeneralActions();
 * generalActions.setDates(1622505600, 1625097600);
 */
class GeneralActions {
  /**
   * Sets the start and end dates in the application's state by dispatching an action with the provided timestamps.
   *
   * @param {number} ts_start - The start timestamp.
   * @param {number} ts_end - The end timestamp.
   */
  setDates(ts_start, ts_end, granularity) {
    setTimeout(function () {
      Dispatcher.dispatch({
        actionType: ActionTypes.SET_DATES,
        payload: { ts_start, ts_end, granularity },
      });
    }, 100);
  }
}

const generalActions = new GeneralActions();
export default generalActions;

import React, { Component } from "react";
import "./TickingTime.scss";
import moment from "moment";

class TickingTime extends Component {
  constructor(props) {
    super(props);
    this.state = {
      time: moment(),
    };
  }
  componentDidMount() {
    this.intervalID = setInterval(() => this.tick(), 1000);
  }
  componentWillUnmount() {
    clearInterval(this.intervalID);
  }
  tick() {
    this.setState({
      time: moment(),
    });
  }
  render() {
    return <div>{this.state.time.format(this.props.format)}</div>;
  }
}

export default TickingTime;

TickingTime.defaultProps = {
  format: "h:mm:ss A",
};

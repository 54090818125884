import ActionTypes from "../constants";
import GeneralUtils from "../utils/GeneralUtils";
import moment from "moment";

/**
 * Actions related to fetching building breach information. This class interfaces with the API
 * to retrieve data on building breaches and dispatches actions to update the application state.
 *
 * @class
 * @example
 * const breachesActions = new BreachesActions();
 * breachesActions.getBuildingsWithBreaches();
 */
class BreachesActions {
  /**
   * Fetches information on buildings with breaches within a specified time range from the API
   * and dispatches an action to store this data.
   *
   * @param {number} [startTs=moment().subtract(1, "hour").unix()] - The start timestamp of the breach information range (default is 1 hour ago).
   * @param {number} [endTs=moment().unix()] - The end timestamp of the breach information range (default is current time).
   * @returns {Promise} - A promise that resolves with the fetched data.
   */
  getBuildingsWithBreaches(startTs = moment().subtract(1, "hour").unix(), endTs = moment().unix()) {
    return GeneralUtils.get({
      url: `building/allowed/breaches/${startTs}/${endTs}`,
      actionType: ActionTypes.BUILDINGS_WITH_BREACHES_FETCHED,
      failToast: "Unable to get breaches info, please try again",
    });
  }
}

const breachesActions = new BreachesActions();
export default breachesActions;

import ActionTypes from '../constants';
import GeneralUtils from '../utils/GeneralUtils';

/**
 * Actions related to fetching carbon emissions and energy usage data. This class interfaces with the API
 * to retrieve data for buildings, meters, and estates, dispatching actions to update the application state.
 *
 * @class
 * @example
 * const carbonEmissionsActions = new CarbonEmissionsActions();
 * carbonEmissionsActions.getCarbonEmissionsData('building123');
 */
class CarbonEmissionsActions {
  /**
   * Fetches usage data for a specific meter from the API and dispatches an action to store this data.
   *
   * @param {string} meterId - The unique identifier of the meter.
   * @returns {Promise} - A promise that resolves with the fetched data.
   */
  getMeterUsageData(meterId) {
    return GeneralUtils.get({
      url: `energy/dashboard/usage/meter/${meterId}`,
      actionType: ActionTypes.METER_USAGE_DATA_FETCHED,
    });
  }

  /**
   * Fetches energy leaderboard data for a specific estate from the API and dispatches an action to store this data.
   *
   * @param {string} estateId - The unique identifier of the estate.
   * @returns {Promise} - A promise that resolves with the fetched data.
   */
  getEstateEnergyLeaderboardData(estateId) {
    return GeneralUtils.get({
      url: `energy/dashboard/estate/${estateId}`,
      actionType: ActionTypes.ESTATE_ENERGY_LEADERBOARD_DATA_FETCHED,
    });
  }
}

const carbonEmissionsActions = new CarbonEmissionsActions();
export default carbonEmissionsActions;

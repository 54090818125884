import React, { Component } from 'react';
import './ChangeRequest.scss';
import AdminActions from '../../actions/adminActions'
import { DocumentTitle } from '../../components/DocumentTitle'
import { SearchSelect } from '../../components/SearchSelect'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { Typography } from "antd";
const { Title, Text } = Typography;

class ChangeRequest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      available_protocols: [{
        name: "Sigfox",
        value: 'SIGFOX'
      }, {
        name: "Lorawan",
        value: 'LORAWAN'
      }],
      available_sensor_models: [
        {
          name: "Airwits Plus (CO2, Temperature & Humidity)",
          value: 'AIRWITSCO2PLUS'
        },
        {
          name: "Airwits (CO2, Temperature & Humidity)",
          value: 'AIRWITSCO2'
        },
        {
          name: "Airwits (Temperature & Humidity)",
          value: 'AIRWITSTEMP'
        }, {
          name: "Insafe (CO2, Temperature & Humidity)",
          value: 'INSAFE'
        }, {
          name: "Elsys (CO2, Temperature & Humidity)",
          value: 'ELSYS'
        }],
      available_disable_reasons: [{
        name: "Faulty Readings",
        value: 'BAD-READINGS'
      }, {
        name: "Sensor is missing",
        value: 'MISSING-SENSOR'
      }, {
        name: "Other",
        value: 'OTHER-REASON'
      }],
      disable_comment: '',
      reassign_comment: '',
      assign_comment: '',
      disable_identifier: '',
      reassign_identifier: '',
      assign_identifier: '',
      assign_registration_code: '',
      reassign_zone_name: '',
      assign_zone_name: '',
      selected_option: null,
      selected_protocol_value: null,
      selected_disable_reason: null,
      selected_model_value: null,
      selected_zone_type: null,
    };
    this._setChangeRequestType = this._setChangeRequestType.bind(this);
    this._changeDisableComment = this._changeDisableComment.bind(this);
    this._changeDisableIdentifier = this._changeDisableIdentifier.bind(this);
    this._changeReassignComment = this._changeReassignComment.bind(this);
    this._changeReassignIdentifier = this._changeReassignIdentifier.bind(this);
    this._changeReassignZoneName = this._changeReassignZoneName.bind(this);
    this._changeAssignComment = this._changeAssignComment.bind(this);
    this._changeAssignIdentifier = this._changeAssignIdentifier.bind(this);
    this._changeAssignRegistrationCode = this._changeAssignRegistrationCode.bind(this);
    this._changeAssignZoneName = this._changeAssignZoneName.bind(this);
    this._changeProtocolType = this._changeProtocolType.bind(this);
    this._changeModelType = this._changeModelType.bind(this);
    this._changeDisableReason = this._changeDisableReason.bind(this);
    this._submitDisableRequest = this._submitDisableRequest.bind(this);
    this._submitReassignRequest = this._submitReassignRequest.bind(this);
    this._submitAssignRequest = this._submitAssignRequest.bind(this);
    this._changeZoneType = this._changeZoneType.bind(this);
    this._cancelRequest = this._cancelRequest.bind(this);
  }

  _setChangeRequestType(value) {
    this.setState({ selected_option: value })
  }
  _changeProtocolType(value) {
    this.setState({ selected_protocol_value: value })
  }
  _changeModelType(value) {
    this.setState({ selected_model_value: value })
  }
  _changeDisableReason(value) {
    this.setState({ selected_disable_reason: value })
  }
  _changeDisableComment(event) {
    this.setState({ disable_comment: event.target.value })
  }
  _changeDisableIdentifier(event) {
    this.setState({ disable_identifier: event.target.value })
  }
  _changeReassignComment(event) {
    this.setState({ reassign_comment: event.target.value })
  }
  _changeReassignIdentifier(event) {
    this.setState({ reassign_identifier: event.target.value })
  }
  _changeReassignZoneName(event) {
    this.setState({ reassign_zone_name: event.target.value })
  }
  _changeAssignComment(event) {
    this.setState({ assign_comment: event.target.value })
  }
  _changeAssignIdentifier(event) {
    this.setState({ assign_identifier: event.target.value })
  }
  _changeAssignRegistrationCode(event) {
    this.setState({ assign_registration_code: event.target.value })
  }
  _changeAssignZoneName(event) {
    this.setState({ assign_zone_name: event.target.value })
  }
  _changeZoneType(value) {
    this.setState({ selected_zone_type: value })
  }
  _submitAssignRequest(event) {
    if (this.state.assign_identifier.length !== 6 && this.state.assign_identifier.length !== 8 && this.state.assign_identifier.length !== 16) {
      toast("Request not sent, please validate sensor identifier is correct", { type: toast.TYPE.ERROR, autoClose: 3000, preventDuplicated: true });
    } else if (this.state.assign_registration_code.length !== 16 && this.state.assign_registration_code.length !== 32) {
      toast("Request not sent, please validate sensor registration code is correct", { type: toast.TYPE.ERROR, autoClose: 3000, preventDuplicated: true });
    } else if (this.state.assign_zone_name.length < 3) {
      toast("Request not sent, please enter a valid zone name (greater than 3 characters)", { type: toast.TYPE.ERROR, autoClose: 3000, preventDuplicated: true });
    } else if (this.state.selected_protocol_value === null || this.state.selected_model_value === null || this.state.selected_zone_type === null) {
      toast("Request not sent, please make sure all required selections are made", { type: toast.TYPE.ERROR, autoClose: 3000, preventDuplicated: true });
    } else {
      let change_request = {
        type: 'New Assignment Request',
        device_identifier: this.state.assign_identifier,
        device_registration_code: this.state.assign_registration_code,
        target_zone_name: this.state.assign_zone_name,
        target_zone_type: this.state.selected_zone_type.value,
        protocol: this.state.selected_protocol_value.value,
        model: this.state.selected_model_value.value,
        comment: this.state.assign_comment
      }
      AdminActions.submitChangeRequest(change_request);
      toast("Request sent successfully, please allow up to 48 hours for the request to be processed", { type: toast.TYPE.SUCCESS, autoClose: 3000, preventDuplicated: true });
      this.setState({ assign_registration_code: '', assign_zone_name: '', assign_comment: '', assign_identifier: '', selected_zone_type: null, reassign_zone_name: '', reassign_comment: '', reassign_identifier: '', selected_option: null, disable_comment: '', disable_identifier: '', selected_protocol_value: null, selected_model_value: null, selected_disable_reason: null })
    }
  }
  _submitDisableRequest(event) {
    if (this.state.disable_identifier.length !== 6 && this.state.disable_identifier.length !== 8 && this.state.disable_identifier.length !== 16) {
      toast("Request not sent, please validate sensor identifier is correct", { type: toast.TYPE.ERROR, autoClose: 3000, preventDuplicated: true });
    } else if (this.state.selected_protocol_value === null || this.state.selected_model_value === null || this.state.selected_disable_reason === null) {
      toast("Request not sent, please make sure all required selections are made", { type: toast.TYPE.ERROR, autoClose: 3000, preventDuplicated: true });
    } else {
      let change_request = {
        type: 'Disable Request',
        device_identifier: this.state.disable_identifier,
        device_registration_code: 'N/A',
        target_zone_name: 'N/A',
        target_zone_type: 'N/A',
        protocol: this.state.selected_protocol_value.value,
        model: this.state.selected_model_value.value,
        comment: this.state.selected_disable_reason.value + ' : ' + this.state.disable_comment
      }
      AdminActions.submitChangeRequest(change_request);
      toast("Request sent successfully, please allow up to 48 hours for the request to be processed", { type: toast.TYPE.SUCCESS, autoClose: 3000, preventDuplicated: true });
      this.setState({ assign_registration_code: '', assign_zone_name: '', assign_comment: '', assign_identifier: '', selected_zone_type: null, reassign_zone_name: '', reassign_comment: '', reassign_identifier: '', selected_option: null, disable_comment: '', disable_identifier: '', selected_protocol_value: null, selected_model_value: null, selected_disable_reason: null })
    }
  }
  _submitReassignRequest(event) {
    if (this.state.reassign_identifier.length !== 6 && this.state.reassign_identifier.length !== 8 && this.state.reassign_identifier.length !== 16) {
      toast("Request not sent, please validate sensor identifier is correct", { type: toast.TYPE.ERROR, autoClose: 3000, preventDuplicated: true });
    } else if (this.state.reassign_zone_name.length < 3) {
      toast("Request not sent, please enter a valid zone name (greater than 3 characters)", { type: toast.TYPE.ERROR, autoClose: 3000, preventDuplicated: true });
    } else if (this.state.selected_protocol_value === null || this.state.selected_model_value === null || this.state.selected_zone_type === null) {
      toast("Request not sent, please make sure all required selections are made", { type: toast.TYPE.ERROR, autoClose: 3000, preventDuplicated: true });
    } else {
      let change_request = {
        type: 'Reassign Request',
        device_identifier: this.state.reassign_identifier,
        device_registration_code: 'N/A',
        target_zone_name: this.state.reassign_zone_name,
        target_zone_type: this.state.selected_zone_type.value,
        protocol: this.state.selected_protocol_value.value,
        model: this.state.selected_model_value.value,
        comment: this.state.reassign_comment
      }
      AdminActions.submitChangeRequest(change_request);
      toast("Request sent successfully, please allow up to 48 hours for the request to be processed", { type: toast.TYPE.SUCCESS, autoClose: 3000, preventDuplicated: true });
      this.setState({ assign_registration_code: '', assign_zone_name: '', assign_comment: '', assign_identifier: '', selected_zone_type: null, reassign_zone_name: '', reassign_comment: '', reassign_identifier: '', selected_option: null, disable_comment: '', disable_identifier: '', selected_protocol_value: null, selected_model_value: null, selected_disable_reason: null })
    }
  }
  _cancelRequest(event) {
    this.setState({ assign_registration_code: '', assign_zone_name: '', assign_comment: '', assign_identifier: '', selected_zone_type: null, reassign_zone_name: '', reassign_comment: '', reassign_identifier: '', selected_option: null, disable_comment: '', disable_identifier: '', selected_protocol_value: null, selected_model_value: null, selected_disable_reason: null })
  }
  getSelectedForm() {
    if (this.state.selected_option === 'disable') {
      return (
        <div className="row">
          <div className="col-sm-12">
            <div className="br-pagetitle mg-b-10 mg-t-10 pd-l-0 pd-t-0">
              <h6>DISABLE A SENSOR</h6>
            </div>
            <div className="form-layout form-layout-1 pd-y-15 bg-white">
              <div className="row mg-b-5">
                <div className="col-lg-6">
                  <div className="form-group" style={{ marginBottom: '15px' }}>
                    <label className="form-control-label">Sensor Identifier: <span className="tx-danger">*</span></label>
                    <input className='form-control' type="text" placeholder="Example: 00818C9A or A81758FFFE06386F" value={this.state.disable_identifier} onChange={this._changeDisableIdentifier} />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group" style={{ marginBottom: '15px' }}>
                    <label className="form-control-label">Sensor Model: <span className="tx-danger">*</span></label>
                    <SearchSelect extraHeight={true} limit={5} options={this.state.available_sensor_models} placeholder={"Select a sensor model"} defaultValue={this.state.selected_model_value} actionOnSelectedOption={this._changeModelType} />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group" style={{ marginBottom: '15px' }}>
                    <label className="form-control-label">Sensor Protocol: <span className="tx-danger">*</span></label>
                    <SearchSelect extraHeight={true} limit={5} options={this.state.available_protocols} placeholder={"Select a protocol type"} defaultValue={this.state.selected_protocol_value} actionOnSelectedOption={this._changeProtocolType} />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group" style={{ marginBottom: '15px' }}>
                    <label className="form-control-label">Reason Type: <span className="tx-danger">*</span></label>
                    <SearchSelect extraHeight={true} limit={5} options={this.state.available_disable_reasons} placeholder={"Select a reason for disabling"} defaultValue={this.state.selected_disable_reason} actionOnSelectedOption={this._changeDisableReason} />
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="form-group" style={{ marginBottom: '15px' }}>
                    <label className="form-control-label">Comment:</label>
                    <input placeholder="Enter an optional comment" type="text" className="form-control" value={this.state.disable_comment} onChange={this._changeDisableComment} />
                  </div>
                </div>
              </div>
              <div className="form-layout-footer">
                <button className="btn btn-outline-info" onClick={this._submitDisableRequest}>Submit Request</button>
                <button className="btn btn-outline-warning mg-l-10" onClick={this._cancelRequest}>Cancel Request</button>
              </div>
            </div >
          </div>
        </div>
      )
    } else if (this.state.selected_option === 'assign') {
      return (
        <div className="row">
          <div className="col-sm-12">
            <div className="br-pagetitle mg-b-10 mg-t-10 pd-l-0 pd-t-0">
              <h6>ASSIGN A NEW SENSOR</h6>
            </div>
            <div className="form-layout form-layout-1 bg-white pd-y-15">
              <div className="row mg-b-5">
                <div className="col-lg-6">
                  <div className="form-group" style={{ marginBottom: '15px' }}>
                    <label className="form-control-label">Sensor Identifier: <span className="tx-danger">*</span></label>
                    <input className='form-control' type="text" placeholder="Example: 00818C9A or A81758FFFE06386F" value={this.state.assign_identifier} onChange={this._changeAssignIdentifier} />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group" style={{ marginBottom: '15px' }}>
                    <label className="form-control-label">Sensor Registration Code: <span className="tx-danger">*</span></label>
                    <input className='form-control' type="text" placeholder="Example: 20E098E77C9DCC24 or 0554E213B5102E74B0CDAA830F159E79" value={this.state.assign_registration_code} onChange={this._changeAssignRegistrationCode} />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group" style={{ marginBottom: '15px' }}>
                    <label className="form-control-label">Sensor Model: <span className="tx-danger">*</span></label>
                    <SearchSelect extraHeight={true} limit={5} options={this.state.available_sensor_models} placeholder={"Select a sensor model"} defaultValue={this.state.selected_model_value} actionOnSelectedOption={this._changeModelType} />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group" style={{ marginBottom: '15px' }}>
                    <label className="form-control-label">Sensor Protocol: <span className="tx-danger">*</span></label>
                    <SearchSelect extraHeight={true} limit={5} options={this.state.available_protocols} placeholder={"Select a protocol type"} defaultValue={this.state.selected_protocol_value} actionOnSelectedOption={this._changeProtocolType} />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group" style={{ marginBottom: '15px' }}>
                    <label className="form-control-label">New/Existing Zone: <span className="tx-danger">*</span></label>
                    <SearchSelect extraHeight={true} limit={5} options={[{
                      name: "New Zone",
                      value: 'NEWZONE'
                    }, {
                      name: "Existing Zone",
                      value: 'EXISTINGZONE'
                    }]} placeholder={"Select if the target zone exists already or needs to be created"} defaultValue={this.state.selected_zone_type} actionOnSelectedOption={this._changeZoneType} />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group" style={{ marginBottom: '15px' }}>
                    <label className="form-control-label">Assign Sensor To: <span className="tx-danger">*</span></label>
                    <input className='form-control' type="text" placeholder="Enter the name of the zone to assign the sensor to" value={this.state.assign_zone_name} onChange={this._changeAssignZoneName} />
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="form-group" style={{ marginBottom: '15px' }}>
                    <label className="form-control-label">Comment:</label>
                    <input placeholder="Enter an optional comment" type="text" className="form-control" value={this.state.assign_comment} onChange={this._changeAssignComment} />
                  </div>
                </div>
              </div>
              <div className="form-layout-footer">
                <button className="btn btn-outline-info" onClick={this._submitAssignRequest}>Submit Request</button>
                <button className="btn btn-outline-warning mg-l-10" onClick={this._cancelRequest}>Cancel Request</button>
              </div>
            </div >
          </div>
        </div>
      )
    } else if (this.state.selected_option === 'reassign') {
      return (
        <div className="row">
          <div className="col-sm-12">
            <div className="br-pagetitle mg-b-10 mg-t-10 pd-l-0 pd-t-0">
              <h6>REASSIGN AN EXISTING SENSOR</h6>
            </div>
            <div className="form-layout form-layout-1 bg-white pd-y-15">
              <div className="row mg-b-5">
                <div className="col-lg-6">
                  <div className="form-group" style={{ marginBottom: '15px' }}>
                    <label className="form-control-label">Sensor Identifier: <span className="tx-danger">*</span></label>
                    <input className='form-control' type="text" placeholder="Example: 00818C9A or A81758FFFE06386F" value={this.state.reassign_identifier} onChange={this._changeReassignIdentifier} />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group" style={{ marginBottom: '15px' }}>
                    <label className="form-control-label">Sensor Model: <span className="tx-danger">*</span></label>
                    <SearchSelect extraHeight={true} limit={5} options={this.state.available_sensor_models} placeholder={"Select a sensor model"} defaultValue={this.state.selected_model_value} actionOnSelectedOption={this._changeModelType} />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group" style={{ marginBottom: '15px' }}>
                    <label className="form-control-label">Sensor Protocol: <span className="tx-danger">*</span></label>
                    <SearchSelect extraHeight={true} limit={5} options={this.state.available_protocols} placeholder={"Select a protocol type"} defaultValue={this.state.selected_protocol_value} actionOnSelectedOption={this._changeProtocolType} />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group" style={{ marginBottom: '15px' }}>
                    <label className="form-control-label">New/Existing Zone: <span className="tx-danger">*</span></label>
                    <SearchSelect extraHeight={true} limit={5} options={[{
                      name: "New Zone",
                      value: 'NEWZONE'
                    }, {
                      name: "Existing Zone",
                      value: 'EXISTINGZONE'
                    }]} placeholder={"Select if the target zone exists already or needs to be created"} defaultValue={this.state.selected_zone_type} actionOnSelectedOption={this._changeZoneType} />
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="form-group" style={{ marginBottom: '15px' }}>
                    <label className="form-control-label">Assign Sensor To: <span className="tx-danger">*</span></label>
                    <input className='form-control' type="text" placeholder="Enter the name of the zone to assign the sensor to" value={this.state.reassign_zone_name} onChange={this._changeReassignZoneName} />
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="form-group" style={{ marginBottom: '15px' }}>
                    <label className="form-control-label">Comment:</label>
                    <input placeholder="Enter an optional comment" type="text" className="form-control" value={this.state.reassign_comment} onChange={this._changeReassignComment} />
                  </div>
                </div>
              </div>
              <div className="form-layout-footer">
                <button className="btn btn-outline-info" onClick={this._submitReassignRequest}>Submit Request</button>
                <button className="btn btn-outline-warning mg-l-10" onClick={this._cancelRequest}>Cancel Request</button>
              </div>
            </div >
          </div>
        </div>
      )
    } else {
      return (
        <div className="row row-sm mg-t-20">
          <div className="col-sm-6 col-lg-4 mg-t-20 mg-sm-t-0">
            <div className="card shadow-base widget-7">
              <div className="tx-center">
                <h4 className="tx-normal tx-inverse tx-roboto mg-t-20 mg-b-2">New Assignment Request</h4>
                <p className="mg-b-20">IAQ Sensors</p>
                <p className="mg-b-20">Please use this form when you want to register and assign new sensors on the platform for analysis.</p>
                <p className="mg-b-0">
                  <button onClick={this._setChangeRequestType.bind(this, 'assign')} className="btn btn-info pd-x-50">Start Request</button>
                </p>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-lg-4 mg-t-20 mg-sm-t-0">
            <div className="card shadow-base widget-7">
              <div className="tx-center">
                <h4 className="tx-normal tx-inverse tx-roboto mg-t-20 mg-b-2">Re-assignment Request</h4>
                <p className="mg-b-20">IAQ Sensors</p>
                <p className="mg-b-20">Please use this form when you want to re-assign an existing sensor on the platform to a new zone/area.</p>
                <p className="mg-b-0">
                  <button onClick={this._setChangeRequestType.bind(this, 'reassign')} className="btn btn-info pd-x-50">Start Request</button>
                </p>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-lg-4 mg-t-20 mg-sm-t-0">
            <div className="card shadow-base widget-7">
              <div className="tx-center">
                <h4 className="tx-normal tx-inverse tx-roboto mg-t-20 mg-b-2">Disable Request</h4>
                <p className="mg-b-20">IAQ Sensors</p>
                <p className="mg-b-20">Please use this form when you want an existing sensor to be removed or temporarily hidden from the platform.</p>
                <p className="mg-b-0">
                  <button onClick={this._setChangeRequestType.bind(this, 'disable')} className="btn btn-info pd-x-50">Start Request</button>
                </p>
              </div>
            </div>
          </div>
        </div>
      )
    }
  }
  render() {
    return (
      <div className="br-mainpanel br-profile-page floorplan-background">
        <DocumentTitle title='Change Request' />
        <div className='br-container'>
          <div className="row mg-t-30">
            <div className="col-12">
              <Title level={3}>Report Center</Title>
              <Text type='secondary'>
                Manage the distribution of automated reports and alerting.

              </Text>
            </div>
            <div className="col-12">
              {this.getSelectedForm()}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ChangeRequest;

import React from "react";
import "./Filter.scss";
import PropTypes from "prop-types";
import { Icon } from "../Icon";

const Filter = (props) => {
  const {
    setFilter,
    placeholder = "Filter By Name",
    value,
    noIcon = false,
    height,
    background = "",
  } = props;

  return (
    <div className="filter input-group">
      {!noIcon && (
        <div className="input-group-prepend" style={{ height: height }}>
          <span className="input-group-text">
            <Icon name="Search" color="#868ab1" />
          </span>
        </div>
      )}
      <input
        type="text"
        className="form-control"
        value={value}
        placeholder={placeholder}
        onChange={(e) => setFilter(e.target.value)}
        style={{ height: height, background: background }}
      />
    </div>
  );
};

export default Filter;

Filter.propTypes = {
  setFilter: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  background: PropTypes.string,
  value: PropTypes.string,
  noIcon: PropTypes.bool,
};

import { EventEmitter } from "events";
import Dispatcher from "../dispatcher";
import Constants from "../constants";
import GeneralUtils from "../utils/GeneralUtils";
import ChartUtils from "../utils/ChartUtils";

import moment from "moment";
import _ from "lodash";

const CHANGE = "REPORTS_CHANGE";
const SAVED_CHANGE = "REPORTS_SAVED_CHANGE";

let _available_reports = [];
let _significant_energy_users_report = [];
let _supplyTemperatures = [];
let _returnTemperatures = [];
let _zoneSetpoints = [];
let _energy_benchmark_report = {};

let _electric_profile = [];
let _gas_profile = [];
let _water_profile = [];
let _dayVsNightElectric = [];
let _dayVsNightGas = [];
let _dayVsNightWater = [];
let _meterBreakdownGas = [];
let _meterBreakdownElectric = [];
let _meterBreakdownWater = [];

class ReportStore extends EventEmitter {
  constructor() {
    super();
    // Registers action handler with the Dispatcher.
    Dispatcher.register(this._registerToActions.bind(this));
  }

  // Switches over the action's type when an action is dispatched.
  _registerToActions(action) {
    switch (action.actionType) {
      case Constants.SIGNIFICANT_ENERGY_USERS_REPORT_FETCHED:
        this._storeSignificantEnergyUsersReportData(action.payload);
        break;
      case Constants.ZONE_PROFILES_FETCHED:
        this._storeZoneProfiles(action.payload);
        break;
      case Constants.ZONE_SETPOINT_HISTORY_FETCHED:
        this._storeZoneSetpoints(action.payload);
        break;
      case Constants.ENERGY_BENCHMARK_REPORT_FETCHED:
        this._storeEnergyBenchmarkReport(action.payload);
        break;
      case Constants.AVAILABLE_REPORTS_FETCHED:
        this._storeAvailableReports(action.payload);
        break;
      case Constants.REPORT_SAVED:
        this._saveReport(action.payload);
        break;
      default:
        break;
    }
  }

  // Hooks a React component's callback to the CHANGED event.
  addChangeListener(callback) {
    this.on(CHANGE, callback);
  }

  // Removes the listener from the CHANGED event.
  removeChangeListener(callback) {
    this.removeListener(CHANGE, callback);
  }

  addSaveChangeListener(callback) {
    this.on(SAVED_CHANGE, callback);
  }

  // Removes the listener from the CHANGED event.
  removeSaveChangeListener(callback) {
    this.removeListener(SAVED_CHANGE, callback);
  }

  clear() {
    _available_reports = [];
    _significant_energy_users_report = [];
    _supplyTemperatures = [];
    _returnTemperatures = [];
    _zoneSetpoints = [];
    _energy_benchmark_report = {};
    _electric_profile = [];
    _gas_profile = [];
    _water_profile = [];
    _dayVsNightElectric = [];
    _dayVsNightGas = [];
    _dayVsNightWater = [];
    _meterBreakdownGas = [];
    _meterBreakdownElectric = [];
    _meterBreakdownWater = [];
  }

  _storeAvailableReports(json) {
    if (json != null) {
      _available_reports = json;
      this.emit(CHANGE);
    }
  }

  _saveReport() {
    this.emit(SAVED_CHANGE);
  }

  _storeSignificantEnergyUsersReportData(json) {
    if (json != null) {
      _significant_energy_users_report = json;
      this.emit(CHANGE);
    }
  }

  _storeEnergyBenchmarkReport(json) {
    _electric_profile = [];
    _gas_profile = [];
    _water_profile = [];
    _dayVsNightElectric = [];
    _dayVsNightGas = [];
    _dayVsNightWater = [];
    _meterBreakdownGas = [];
    _meterBreakdownElectric = [];
    _meterBreakdownWater = [];
    _energy_benchmark_report = json;

    let gas_consumption = ChartUtils.getColumnConsumptionSeries(
      "Actual",
      Constants.RED,
      true,
      true
    );
    let prev_gas_consumption = ChartUtils.getColumnConsumptionSeries(
      "Average",
      Constants.RED_TRANSPARENT,
      true,
      true
    );

    let electric_consumption = ChartUtils.getColumnConsumptionSeries(
      "Actual",
      Constants.BLUE,
      true,
      true
    );
    let prev_electric_consumption = ChartUtils.getColumnConsumptionSeries(
      "Average",
      Constants.BLUE_TRANSPARENT,
      true,
      true
    );

    let water_consumption = ChartUtils.getColumnConsumptionSeries(
      "Actual",
      Constants.GREEN,
      true,
      true
    );
    let prev_water_consumption = ChartUtils.getColumnConsumptionSeries(
      "Average",
      Constants.GREEN_TRANSPARENT,
      true,
      true
    );

    if (json.gas_consumptions) {
      json.gas_consumptions.forEach(function (consumption) {
        let date = moment(consumption.timestamp);
        gas_consumption.data.push([
          date.valueOf(),
          GeneralUtils.roundNumber(consumption.actual_consumption, 1),
        ]);
        prev_gas_consumption.data.push([
          date.valueOf(),
          GeneralUtils.roundNumber(consumption.avg_actual_consumption, 1),
        ]);
      });
    }

    if (json.electric_consumptions) {
      json.electric_consumptions.forEach(function (consumption) {
        let date = moment(consumption.timestamp);
        electric_consumption.data.push([
          date.valueOf(),
          GeneralUtils.roundNumber(consumption.actual_consumption, 1),
        ]);
        prev_electric_consumption.data.push([
          date.valueOf(),
          GeneralUtils.roundNumber(consumption.avg_actual_consumption, 1),
        ]);
      });
    }

    if (json.water_consumptions) {
      json.water_consumptions.forEach(function (consumption) {
        let date = moment(consumption.timestamp);
        water_consumption.data.push([
          date.valueOf(),
          GeneralUtils.roundNumber(consumption.actual_consumption, 1),
        ]);
        prev_water_consumption.data.push([
          date.valueOf(),
          GeneralUtils.roundNumber(consumption.avg_actual_consumption, 1),
        ]);
      });
    }

    _electric_profile = [electric_consumption, prev_electric_consumption];
    _gas_profile = [gas_consumption, prev_gas_consumption];
    _water_profile = [water_consumption, prev_water_consumption];
    _dayVsNightElectric = [
      {
        name: "Operating Vs Non Operating Hours - Electic",
        ts_start: moment(json.ts_start).unix(),
        ts_end: moment(json.ts_end).unix(),
        colorByPoint: true,
        data: [],
      },
    ];

    _dayVsNightGas = [
      {
        name: "Operating Vs Non Operating Hours - Gas",
        ts_start: moment(json.ts_start).unix(),
        ts_end: moment(json.ts_end).unix(),
        colorByPoint: true,
        data: [],
      },
    ];

    _dayVsNightWater = [
      {
        name: "Operating Vs Non Operating Hours - Water",
        ts_start: moment(json.ts_start).unix(),
        ts_end: moment(json.ts_end).unix(),
        colorByPoint: true,
        data: [],
      },
    ];

    if (json.day_night) {
      _dayVsNightElectric[0].data.push({
        name: "Operating",
        amount: json.day_night.electric_day_total,
        y: json.day_night.electric_day_percent,
        color: Constants.BLUE,
      });

      _dayVsNightElectric[0].data.push({
        name: "Non Operating",
        color: Constants.BLUE_TRANSPARENT,
        amount: json.day_night.electric_night_total,
        y: json.day_night.electric_night_percent,
      });

      _dayVsNightGas[0].data.push({
        name: "Operating",
        amount: json.day_night.gas_day_total,
        y: json.day_night.gas_day_percent,
        color: Constants.RED,
      });

      _dayVsNightGas[0].data.push({
        name: "Non Operating",
        color: Constants.RED_TRANSPARENT,
        amount: json.day_night.gas_night_total,
        y: json.day_night.gas_night_percent,
      });

      _dayVsNightWater[0].data.push({
        name: "Operating",
        amount: json.day_night.water_day_total,
        y: json.day_night.water_day_percent,
        color: Constants.GREEN,
      });

      _dayVsNightWater[0].data.push({
        name: "Non Operating",
        color: Constants.GREEN_TRANSPARENT,
        amount: json.day_night.water_night_total,
        y: json.day_night.water_night_percent,
      });
    }

    _meterBreakdownElectric = [
      {
        name: "Meter Breakdown - Electric",
        ts_start: moment(json.ts_start).unix(),
        ts_end: moment(json.ts_end).unix(),
        colorByPoint: true,
        data: [],
      },
    ];

    if (json.all_electric_meter_total) {
      let elec_meters = _.orderBy(
        json.all_electric_meter_total,
        "total_kwh",
        "desc"
      );
      for (let i = 0; i < elec_meters.length; i++) {
        let meter_consumption = elec_meters[i].total_kwh;
        let percentage = Math.round(
          (meter_consumption / json.electric_kwh) * 100
        );
        _meterBreakdownElectric[0].data.push({
          name: elec_meters[i].name,
          amount: meter_consumption,
          y: percentage,
        });
      }
    }

    _meterBreakdownGas = [
      {
        name: "Meter Breakdown - Gas",
        ts_start: moment(json.ts_start).unix(),
        ts_end: moment(json.ts_end).unix(),
        colorByPoint: true,
        data: [],
      },
    ];

    if (json.all_gas_meter_total) {
      let gas_meters = _.orderBy(json.all_gas_meter_total, "total_kwh", "desc");
      for (let i = 0; i < gas_meters.length; i++) {
        let meter_consumption = gas_meters[i].total_kwh;
        let percentage = Math.round((meter_consumption / json.gas_kwh) * 100);
        _meterBreakdownGas[0].data.push({
          name: gas_meters[i].name,
          amount: meter_consumption,
          y: percentage,
        });
      }
    }

    _meterBreakdownWater = [
      {
        name: "Meter Breakdown - Water",
        ts_start: moment(json.ts_start).unix(),
        ts_end: moment(json.ts_end).unix(),
        colorByPoint: true,
        data: [],
      },
    ];

    if (json.all_water_meter_total) {
      let water_meters = _.orderBy(
        json.all_water_meter_total,
        "total_kwh",
        "desc"
      );
      for (let i = 0; i < water_meters.length; i++) {
        let meter_consumption = water_meters[i].total_kwh;
        let percentage = Math.round(
          (meter_consumption / json.water_total) * 100
        );
        _meterBreakdownWater[0].data.push({
          name: water_meters[i].name,
          amount: meter_consumption,
          y: percentage,
        });
      }
    }

    this.emit(CHANGE);
  }

  _storeZoneSetpoints(json) {
    let upperLimitValue = 23;
    let lowerLimitValue = 18.9;
    _zoneSetpoints = [];
    let setpoint = {
      name: "Daily Setpoint Value",
      type: "line",
      lineWidth: 3,
      color: "#4caf50",
      marker: {
        enabled: true,
        symbol: "circle",
        radius: 4,
      },
      data: [],
      zones: [
        {
          value: lowerLimitValue,
          color: "#14a0c1",
          fillColor: "#14a0c1",
        },
        {
          value: upperLimitValue,
          color: "#4caf50",
          fillColor: "#4caf50",
        },
        {
          color: "#e53935",
          fillColor: "#e53935",
        },
      ],
      events: {
        legendItemClick: function (e) {
          e.preventDefault();
        },
      },
    };
    let upperLimit = {
      name: "Upper Recommended",
      type: "line",
      dashStyle: "Dash",
      shadow: false,
      lineWidth: 1,
      color: "#01CB99",
      marker: {
        enabled: false,
      },
      data: [],
      events: {
        legendItemClick: function (e) {
          e.preventDefault();
        },
      },
    };
    let lowerLimit = {
      name: "Lower Recommended",
      type: "line",
      dashStyle: "Dash",
      shadow: false,
      lineWidth: 1,
      color: "#01CB99",
      marker: {
        enabled: false,
      },
      data: [],
      events: {
        legendItemClick: function (e) {
          e.preventDefault();
        },
      },
    };
    for (const [ts, val] of Object.entries(json)) {
      if (ts != null && val != null) {
        let timestamp = new Date(ts).getTime();
        let value = Math.round(val * 10) / 10;
        let color = "#4caf50";
        if (value < lowerLimitValue) {
          color = "#14a0c1";
        }
        if (value > upperLimitValue) {
          color = "#e53935";
        }
        setpoint.data.push({ x: timestamp, y: value, color: color });
        lowerLimit.data.push([timestamp, lowerLimitValue + 0.1]);
        upperLimit.data.push([timestamp, upperLimitValue]);
      }
    }

    _zoneSetpoints.push(setpoint);
    _zoneSetpoints.push(upperLimit);
    _zoneSetpoints.push(lowerLimit);

    this.emit(CHANGE);
  }

  _storeZoneProfiles(zone_dto) {
    _supplyTemperatures = [];
    _returnTemperatures = [];

    for (const [asset_id, chart_data] of Object.entries(zone_dto.zone_charts)) {
      let id = Number.parseInt(asset_id);
      let asset = _.find(zone_dto.assets, { asset_id: id });
      let value_data = _.find(chart_data, { property_code: "SupT" });
      if (value_data && value_data.chart_data_tems) {
        let descStr = " (" + id + ")";
        if (asset && asset.name) {
          descStr = " (" + asset.name + ")";
        }
        let seriesObj = {
          name: "Supply Temp" + descStr,
          type: "line",
          marker: {
            enabled: true,
            symbol: "circle",
            radius: 1,
          },
          data: [],
          events: {
            legendItemClick: function (e) {
              e.preventDefault();
            },
          },
        };
        for (let i = 0; i < value_data.chart_data_tems.length; i++) {
          seriesObj.data.push([
            moment(value_data.chart_data_tems[i].timestamp).valueOf(),
            value_data.chart_data_tems[i].value,
          ]);
        }
        _supplyTemperatures.push(seriesObj);
      }
    }

    for (const [asset_id, chart_data] of Object.entries(zone_dto.zone_charts)) {
      let id = Number.parseInt(asset_id);
      let asset = _.find(zone_dto.assets, { asset_id: id });
      let value_data = _.find(chart_data, { property_code: "RetT" });
      if (value_data && value_data.chart_data_tems) {
        let descStr = " (" + id + ")";
        if (asset && asset.name) {
          descStr = " (" + asset.name + ")";
        }
        let seriesObj = {
          name: "Return Temp" + descStr,
          type: "line",
          marker: {
            enabled: true,
            symbol: "circle",
            radius: 1,
          },
          data: [],
          events: {
            legendItemClick: function (e) {
              e.preventDefault();
            },
          },
        };
        for (let i = 0; i < value_data.chart_data_tems.length; i++) {
          seriesObj.data.push([
            moment(value_data.chart_data_tems[i].timestamp).valueOf(),
            value_data.chart_data_tems[i].value,
          ]);
        }
        _returnTemperatures.push(seriesObj);
      }
    }
    this.emit(CHANGE);
  }

  getSupplyTempData() {
    return _supplyTemperatures;
  }

  getReturnTempData() {
    return _returnTemperatures;
  }

  getZoneSetpointData() {
    return _zoneSetpoints;
  }

  getEnergyBenchmarkReport() {
    return _energy_benchmark_report;
  }

  getElectricProfileData() {
    return _electric_profile;
  }

  getGasProfileData() {
    return _gas_profile;
  }

  getWaterProfileData() {
    return _water_profile;
  }

  getElectricDayNightData() {
    return _dayVsNightElectric;
  }

  getGasDayNightData() {
    return _dayVsNightGas;
  }

  getWaterDayNightData() {
    return _dayVsNightWater;
  }

  getElectricBreakdownData() {
    return _meterBreakdownElectric;
  }

  getGasBreakdownData() {
    return _meterBreakdownGas;
  }

  getWaterBreakdownData() {
    return _meterBreakdownWater;
  }

  getAvailableReports() {
    return _available_reports;
  }

  getSignificantEnergyUsersReportData() {
    return _significant_energy_users_report;
  }
}

const reportStore = new ReportStore();
export default reportStore;

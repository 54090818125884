import React, { Component } from "react";
import "./ManageZones.scss";
import ZonesActions from "../../actions/zonesActions";
import ZonesStore from "../../stores/zonesStore";
import OrganisationActions from "../../actions/organisationActions";
import OrganisationStore from "../../stores/organisationStore";
import UserStore from "../../stores/userStore";
import GeneralUtils from "../../utils/GeneralUtils";
import { SearchSelect } from "../../components/SearchSelect";
import { DocumentTitle } from "../../components/DocumentTitle";
import { ModifyZonePanel } from "./ModifyZonePanel";
import _ from "lodash";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Button, Typography } from 'antd';
import { Icon } from '../../components/Icon';
import { LogoSpinner } from "../../components/LogoSpinner";

const { Title, Text } = Typography;

class ManageZones extends Component {
  constructor(props) {
    super(props);
    this.state = {
      new_zone: {
        name: "",
        location: "",
        criticality: "low",
        description: "",
        category: "wellness",
        capacity: 0,
        size: 0,
        type: "",
        tenant_id: null,
        // targets
        temp_target_lower: 21,
        temp_target_upper: 23,

        co2_target_lower: 800,
        co2_target_upper: 2000,

        pm25_target_lower: 10,
        pm25_target_upper: 25,

        humd_target_lower: 40,
        humd_target_upper: 65,

        lux_target_lower: 300,
        lux_target_upper: 1000,
      },
      zones: ZonesStore.getZones(),
      buildings: OrganisationStore.getBuildings(),
      zone_creation: false,
      selected_building: null,
      loading: true,
    };
    this._onOrganisationChange = this._onOrganisationChange.bind(this);
    this._onZonesChange = this._onZonesChange.bind(this);
    this._setTargetBuilding = this._setTargetBuilding.bind(this);
    this._changeZoneValues = this._changeZoneValues.bind(this);
    this._validateZoneInput = this._validateZoneInput.bind(this);
    this._saveZone = this._saveZone.bind(this);
    this._updateZone = this._updateZone.bind(this);
    this._deleteZone = this._deleteZone.bind(this);
    this._toggleZoneCreation = this._toggleZoneCreation.bind(this);
    this._onZoneOperation = this._onZoneOperation.bind(this);
  }
  _onOrganisationChange() {
    this.setState({
      buildings: OrganisationStore.getBuildings(),
      loading: false,
    });
  }
  _onZonesChange() {
    this.setState({
      zones: ZonesStore.getZones(),
      selected_building: this.state.selected_building,
      loading: false
    });
  }

  _onZoneOperation() {
    if (this.state.zone_creation || this.state.zone_deletion)
      ZonesActions.getZones(this.state.selected_building.building_id);
    this._toggleZoneCreation(false);
  }

  UNSAFE_componentWillMount() {
    OrganisationStore.addChangeListener(this._onOrganisationChange);
    ZonesStore.addChangeListener(this._onZonesChange);
    ZonesStore.addZoneOperationListener(this._onZoneOperation);
  }

  componentDidMount() {
    let current_user = UserStore.getUser();
    if (UserStore.isSuper()) {
      OrganisationActions.getOrganisations(true);
    } else if (current_user && current_user.fk_organisation_id) {
      OrganisationActions.getOrganisation(current_user.fk_organisation_id);
    }
  }
  componentWillUnmount() {
    OrganisationStore.removeChangeListener(this._onOrganisationChange);
    ZonesStore.removeChangeListener(this._onZonesChange);
    ZonesStore.removeZoneOperationListener(this._onZoneOperation);

    ZonesStore.clear();
  }
  _setTargetBuilding(selected_building) {
    ZonesActions.getZones(selected_building.building_id);

    this.setState({
      selected_building: selected_building,
      loading: true,
      new_zone: {
        ...this.state.new_zone,
        building_id: selected_building.building_id,
      },
    });
  }
  noRowsAvailable(numRows) {
    let rows = [];
    for (let i = 0; i < numRows; i++) {
      rows.push(<td key={i} className="valign-middle"></td>);
    }
    return (
      <tr>
        <td className="valign-middle" colSpan={4}>
          <div className="d-flex align-items-center">
            <div>
              <div className="tx-inverse">
                {this.state.selected_building == null
                  ? "No zones available, please select a target building first."
                  : "No zones available in the selected building. Please contact support to create new zones."}
              </div>
            </div>
          </div>
        </td>
        {rows}
      </tr>
    );
  }
  _validateZoneInput(zone) {
    let validBuilding = parseInt(zone.building_id) > 0;
    let validName = GeneralUtils.stringNotEmpty(zone.name);
    let validLocation = GeneralUtils.stringNotEmpty(zone.location);
    return validBuilding && validName && validLocation;
  }

  _changeZoneValues(zone, target_property, event) {
    let zones = this.state.zones;
    let new_zone = this.state.new_zone;
    const stringProperties = [
      "name",
      "description",
      "location",
      "type",
      "criticality",
    ];
    let value = stringProperties.includes(target_property)
      ? event.target.value
      : +event.target.value;
    let editExisting = false;

    for (let i = 0; i < zones.length; i++) {
      if (zones[i].zone_id === zone.zone_id) {
        zones[i][target_property] = value;
        editExisting = true;
        break;
      }
    }
    if (!editExisting) {
      new_zone[target_property] = value;
    }

    this.setState({ zones: zones, new_zone: new_zone });
  }

  _saveZone(zone) {
    if (this._validateZoneInput(zone)) {
      ZonesActions.saveZone(zone);
    } else {
      toast("Invalid Input, Please check fields and try again", {
        type: toast.TYPE.WARNING,
        autoClose: 3000,
        preventDuplicated: true,
      });
    }
  }

  _updateZone(zone) {
    if (this._validateZoneInput(zone) && zone.zone_id) {
      const updated_zone = {
        name: zone.name,
        zone_id: zone.zone_id,
        location: zone.location,
        criticality: zone.criticality,
        description: zone.description,
        capacity: zone.capacity,
        size: zone.size,
        category: zone.category,
        type: zone.type,

        // targets
        temp_target_lower: zone.temp_target_lower,
        temp_target_upper: zone.temp_target_upper,

        co2_target_lower: zone.co2_target_lower,
        co2_target_upper: zone.co2_target_upper,

        pm25_target_lower: zone.pm25_target_lower,
        pm25_target_upper: zone.pm25_target_upper,

        humd_target_lower: zone.humd_target_lower,
        humd_target_upper: zone.humd_target_upper,

        lux_target_lower: zone.lux_target_lower,
        lux_target_upper: zone.lux_target_upper,
      };
      ZonesActions.updateZone(updated_zone);
    } else {
      toast("Invalid Input, Please check fields and try again", {
        type: toast.TYPE.WARNING,
        autoClose: 3000,
        preventDuplicated: true,
      });
    }
  }

  _deleteZone(zone_id) {
    if (window.confirm("Are you sure you wish to delete this zone?")) {
      this.setState({ zone_deletion: true });
      ZonesActions.deleteZone(zone_id);
    }
  }

  _toggleZoneCreation(target_state = null) {
    if (this.state.zone_creation || target_state === false) {
      this.setState({
        zone_deletion: false,
        zone_creation: false,
        new_zone: {
          building_id: this.state.selected_building.building_id,
          name: "",
          location: "",
          criticality: "low",
          description: "",
          category: "wellness",
          capacity: 0,
          size: 0,
          type: "",
          // targets
          temp_target_lower: 21,
          temp_target_upper: 23,

          co2_target_lower: 800,
          co2_target_upper: 2000,

          pm25_target_lower: 10,
          pm25_target_upper: 25,

          humd_target_lower: 40,
          humd_target_upper: 65,

          lux_target_lower: 300,
          lux_target_upper: 1000,
        },
      });
    } else {
      this.setState({ zone_creation: true });
    }
  }

  getZoneTable() {
    let zoneRows = this.noRowsAvailable(3);
    let newZoneRow = null;
    let zones = _.orderBy(this.state.zones, "name", "asc");
    let buildingsFormattedArray = this.state.buildings.map((building) => {
      return {
        ...building,
        name: building.name + ` (building id: ${building.building_id})`,
      };
    });
    let button = (
      <Button
        className="button green"
        icon={<Icon name="AddCircleFilled" color={'#fff'} size={18} />}
        size="small"
        onClick={this._toggleZoneCreation}
        style={{ height: "38px" }}
      >Add Zone</Button>
    );
    if (this.state.zone_creation) {
      button = (
        <Button
          className="button red"
          icon={<Icon name="Close" color={'#fff'} size={18} />}
          size="small"
          onClick={this._toggleZoneCreation}
          style={{ height: "38px" }}
        >Cancel</Button>
      );
    }

    if (this.state.zone_creation) {
      newZoneRow = (
        <ModifyZonePanel
          building={this.state.selected_building}
          key={"new_zone"}
          changeZoneValues={this._changeZoneValues}
          zone={this.state.new_zone}
          updateZone={this._updateZone}
          saveZone={this._saveZone}
          cancelZone={this._toggleZoneCreation}
        />
      );
    }

    if (zones.length > 0) {
      zoneRows = zones.reverse().map((zone) => {
        if (zone.zone_id) {
          return (
            <ModifyZonePanel
              key={zone.zone_id}
              changeZoneValues={this._changeZoneValues}
              deleteZone={this._deleteZone}
              zone={zone}
              building={this.state.selected_building}
              updateZone={this._updateZone}
              saveZone={this._saveZone}
            />
          );
        } else {
          return null;
        }
      });
    }

    return (
      <div>
        <div
          className="br-pagetitle pd-x-0 pd-b-0 mg-b-10"
          style={{ width: "100%" }}
        >
          <span className="col-7 col-lg-5 pd-l-0">
            <SearchSelect
              limit={100}
              options={buildingsFormattedArray}
              placeholder={"Select Building"}
              defaultValue={this.state.selected_building}
              actionOnSelectedOption={this._setTargetBuilding}
            />
          </span>
          <div className="col-5 pd-0">
            {this.state.selected_building && button}
          </div>
        </div>

        <div className="card bd-0 shadow-base mg-t-5 overflow-auto">
          <table className="table mg-b-0 table-contact">
            <thead>
              <tr>
                <th className="wd-20p tx-mont tx-medium">
                  Name<span style={{ color: "red" }}>*</span>
                </th>
                <th className="wd-20p tx-10-force tx-mont tx-medium">
                  Description<span style={{ color: "red" }}>*</span>
                </th>
                <th className="wd-20p tx-10-force tx-mont tx-medium">
                  Location<span style={{ color: "red" }}>*</span>
                </th>
                <th className="wd-10p tx-10-force tx-mont tx-medium">
                  Criticality
                </th>
                <th className="wd-10p tx-10-force tx-mont tx-medium">
                  Type<span style={{ color: "red" }}>*</span>
                </th>
                <th className="wd-10p tx-10-force tx-mont tx-medium">
                  Capacity (People)
                </th>
                <th className="wd-10p tx-10-force tx-mont tx-medium">
                  Size (sqm)
                </th>
              </tr>
            </thead>
            <tbody>
              {newZoneRow}
              {zoneRows}
            </tbody>
          </table>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="br-mainpanel br-profile-page floorplan-background">
        <DocumentTitle title="Manage Zones" />
        <LogoSpinner loading={this.state.loading} />
        <div className="br-container">
          <div className="row">
            <div className="col-12 mg-t-30">
              <Title level={3}>Manage Zones</Title>
              <Text type="secondary">
                Create, edit and delete zones within buildings.
              </Text>
            </div>
            <div className="col-12 mg-t-10">{this.getZoneTable()}</div>
          </div>
        </div>
      </div>
    );
  }
}

export default ManageZones;

import ActionTypes from "../constants";
import GeneralUtils from "../utils/GeneralUtils";

/**
 * Actions related to exploring meters. This class interfaces with the API
 * to retrieve buildings, meters, and meter chart data, dispatching actions to update the application state.
 *
 * @class
 * @example
 * const metersExplorerActions = new MetersExplorerActions();
 * metersExplorerActions.getBuildings();
 */
class MetersExplorerActions {
  /**
   * Fetches the list of allowed buildings from the API and dispatches an action to store this data.
   *
   * @returns {Promise} - A promise that resolves with the fetched data.
   */
  getBuildings() {
    return GeneralUtils.get({
      url: "building/allowed",
      actionType: ActionTypes.METERS_EXPLORER_BUILDINGS_FETCHED,
    });
  }

  /**
   * Fetches the list of meters from the API and dispatches an action to store this data.
   *
   * @returns {Promise} - A promise that resolves with the fetched data.
   */
  getMeters() {
    return GeneralUtils.get({
      url: "meter/",
      actionType: ActionTypes.METERS_EXPLORER_METERS_FETCHED,
    });
  }
  /**
   * Fetches the meter chart data from the API and dispatches an action to store this data.
   *
   * @param {Object} payload - The payload object containing the necessary data for the API call.
   * @param {string} meter_id - The ID of the main meter to fetch data for.
   * @param {boolean} isComparison - Whether the data is for a comparison chart.
   * @returns {Promise} - A promise that resolves with the fetched data.
   */
  getMeterChartData(payload, meter_id, isComparison = false) {
    return GeneralUtils.post({
      url: "explorer/chart/data",
      object: payload,
      actionType: isComparison
        ? ActionTypes.METERS_EXPLORER_COMPARISON_CHART_DATA_FETCHED
        : ActionTypes.METERS_EXPLORER_CHART_DATA_FETCHED,
      modifyResponse: (response) => {
        return { response: response, isComparison: isComparison, isDataExplorerCall: true, main_meter_id: meter_id };
      }
    });
  }
}

const metersExplorerActions = new MetersExplorerActions();
export default metersExplorerActions;

import React, { Component } from "react";
import Highcharts from "highcharts";
import HC_exporting from "highcharts/modules/exporting";
import HC_export_data from "highcharts/modules/export-data";
import HC_more from "highcharts/highcharts-more";
import moment from "moment";
import "./HighChart.scss";
import ChartUtils from "../../utils/ChartUtils";
import GeneralUtils from "../../utils/GeneralUtils";

class HighChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataType: this.props.dataType,
      loading: false,
    };
    this.drawChart = this.drawChart.bind(this);
  }
  componentDidMount() {
    if (
      this.props.series[0] &&
      this.props.series[0].data &&
      this.props.series[0].data.length > 0
    ) {
      this.drawChart();
    }
  }
  componentDidUpdate() {
    if (
      this.props.series[0] &&
      this.props.series[0].data &&
      this.props.series[0].data.length > 0
    ) {
      this.drawChart();
    }
  }
  componentWillUnmount() {
    if (this.chart) {
      this.chart.destroy();
    }
  }
  shouldComponentUpdate(nextProps) {
    if (
      nextProps.interval &&
      this.props.interval &&
      nextProps.interval !== this.props.interval
    ) {
      return true;
    }
    if (
      nextProps.dataType &&
      this.props.dataType &&
      nextProps.dataType !== this.props.dataType
    ) {
      return true;
    }

    if (
      nextProps.meter_id &&
      this.props.meter_id &&
      nextProps.meter_id !== this.props.meter_id
    ) {
      return true;
    }

    if (this.props.series && nextProps.series) {
      if (
        JSON.stringify(this.props.series) !== JSON.stringify(nextProps.series)
      ) {
        return true;
      }
    }

    if (this.props.alwaysRedraw) {
      return true;
    }

    return false;
  }
  render() {
    if (ChartUtils.checkHasData(this.props.series)) {
      return (
        <div
          id={this.props.chart_Tag}
          style={{ height: "100%", width: "100%" }}
        />
      );
    } else {
      return (
        <div
          className="d-flex align-items-center justify-content-center"
          style={{ marginTop: "auto", marginBottom: "auto", height: "100%" }}
        >
          <div className="tx-center">
            <span className="tx-12" style={{ opacity: "0.6" }}>
              <img
                alt="no sensor"
                style={{
                  maxHeight: "120px",
                  maxWidth: "120px",
                  width: "100%",
                  height: "100%",
                }}
                src="/img/graphics/sensors-icon.png"
              />
              <p style={{ opacity: "0.6" }}>No Data Available</p>
            </span>
          </div>
        </div>
      );
    }
  }
  drawChart() {
    HC_exporting(Highcharts);
    HC_export_data(Highcharts);
    HC_more(Highcharts);

    if (ChartUtils.checkHasData(this.props.series)) {
      let dataType = this.props.dataType;
      let tooltip = {
        shared: true,
        crosshairs: true,
        valueDecimals: 2,
        valueSuffix: dataType,
        xDateFormat: "%a, %b %e : %H:%M",
      };

      if (this.props.customTooltip) {
        tooltip = {
          headerFormat: "<b>{series.name}</b><br>",
          pointFormat: "{point.x:%Y-%m-%d %H:%M}<br>{point.y} " + dataType,
        };
      }
      let xAxisLabels = {
        style: {
          color: "#343a40",
          fontSize: "9px",
        },
      };

      let yAxisLabels = {
        style: {
          color: "#343a40",
          fontSize: "9px",
        },
        formatter: function () {
          return Math.abs(this.value) + dataType;
        },
      };

      if (window.innerWidth <= 900) {
        yAxisLabels.padding = "0px";
        yAxisLabels.x = -5;
      }

      if (this.props.hideXAxisLabels) {
        xAxisLabels = {
          enabled: false,
        };
      }

      if (this.props.hideYAxisLabels) {
        yAxisLabels = {
          enabled: false,
        };
      }

      let seriesObj = {
        turboThreshold: 0,
      };

      if (this.props.disabledHoverHide) {
        seriesObj.states = {
          inactive: {
            opacity: 1,
          },
        };
      }
      if (this.props.no_gap) {
        seriesObj.groupPadding = 0;
        seriesObj.pointPadding = 0;
        seriesObj.borderWidth = 0.2;
      }

      if (this.props.group_gap_only) {
        seriesObj.groupPadding = 0.2;
        seriesObj.pointPadding = 0;
        seriesObj.borderWidth = 1;
      }

      let yAxis = {
        title: {
          text: null,
        },
        opposite: false,
        height: "100%",
        offset: 0,
        lineWidth: 2,
        gridLineDashStyle: "longdash",
        gridLineWidth: 1,
        gridLineColor: "#e6e6e6",
        minorLineWidth: 1,
        startOnTick: false,
        endOnTick: false,
        showFirstLabel: true,
        showLastLabel: true,
        labels: yAxisLabels,
      };

      if (this.props.plot_lines && this.props.plot_lines.length > 0) {
        yAxis.plotLines = this.props.plot_lines;
      }

      if (this.props.min !== "undefined") {
        yAxis.min = this.props.min;
      }

      if (this.props.max !== "undefined") {
        yAxis.max = this.props.max;
      }

      if (this.props.softMin !== "undefined") {
        yAxis.softMin = this.props.softMin;
      }

      if (this.props.softMax !== "undefined") {
        yAxis.softMax = this.props.softMax;
      }

      let plotBands = [];
      if (this.props.plotBands) {
        plotBands = this.props.plotBands;
      }

      let legend = {
        enabled: true,
        align: "center",
        verticalAlign: "bottom",
        itemStyle: {
          color: "#343a40",
          fontSize: "9px",
          fontWeight: "bold",
        },
      };

      if (
        typeof this.props.showLegend !== "undefined" &&
        this.props.showLegend === 0
      ) {
        legend = {
          enabled: false,
        };
      }

      let xAxis = {
        gridLineDashStyle: "longdash",
        type: "datetime",
        gridLineWidth: 1,
        gridLineColor: "#e6e6e6",
        minorLineWidth: 1,
        startOnTick: false,
        endOnTick: false,
        labels: xAxisLabels,
        plotBands: plotBands,
        units: [
          ["millisecond", [1, 2, 5, 10, 20, 25, 50, 100, 200, 500]],
          ["second", [1, 2, 5, 10, 15, 30]],
          ["minute", [1, 2, 5, 10, 15, 30]],
          ["hour", [1, 3, 5, 7, 9, 11, 13, 15, 17, 19, 21, 23]],
          ["day", [1]],
          ["week", [1]],
          ["month", [1, 3, 6]],
          ["year", null],
        ],
      };

      if (this.props.xAxisMin) {
        xAxis.min = this.props.xAxisMin;
      }

      if (this.props.xAxisMax) {
        xAxis.max = this.props.xAxisMax;
      }

      if (this.props.showFullDayOnXAxis) {
        xAxis.dateTimeLabelFormats = {
          day: "%a %d, %b",
        };
      }

      let columnSeriesOptions = {
        softThreshold: false,
      };

      if (this.props.disableColumnAnimation) {
        columnSeriesOptions.animation = {
          duration: 0,
        };
      }

      if (this.props.showLabelsOnColumns) {
        columnSeriesOptions.dataLabels = {
          enabled: true,
          inside: true,
        };
      }

      if (this.props.hideGridLines) {
        xAxis.gridLineWidth = 0;
        xAxis.minorLineWidth = 0;
        yAxis.gridLineWidth = 0;
        yAxis.minorLineWidth = 0;
        yAxis.lineColor = "transparent";
      }

      if (this.props.stacking) {
        columnSeriesOptions.stacking = "normal";
      }

      let lineSeriesOptions = {
        dataLabels: {
          enabled: false,
        },
        softThreshold: false,
      };

      if (this.props.step === 1) {
        lineSeriesOptions.step = "center";
      }

      if (this.props.dualAxis) {
        tooltip.valueSuffix = "";
        let secondaryLabelText = this.props.secondaryAxisLabelText;
        let secondaryAxis = {
          ...yAxis,
        };

        let secondaryAxisLabel = {
          ...yAxisLabels,
          formatter: function () {
            return Math.abs(this.value) + secondaryLabelText;
          },
        };
        secondaryAxis.labels = secondaryAxisLabel;
        secondaryAxis.opposite = true;
        yAxis = [yAxis, secondaryAxis];
      }

      if (this.props.dualTimeAxis) {
        let secondaryAxis = {
          ...xAxis,
        };
        xAxis = [xAxis, secondaryAxis];
      }

      let options = {
        chart: {
          type: this.props.main_type,
          zoomType: "x",
        },
        rangeSelector: {
          enabled: false,
        },
        navigator: {
          enabled: false,
        },
        scrollbar: {
          enabled: false,
        },
        legend: legend,
        credits: {
          enabled: false,
        },
        title: {
          text: null,
        },
        xAxis: xAxis,
        yAxis: yAxis,
        tooltip: tooltip,
        plotOptions: {
          series: seriesObj,
          line: lineSeriesOptions,
          column: columnSeriesOptions,
          area: {
            stacking: "normal",
            step: "right",
            softThreshold: false,
          },
        },
        series: this.props.series,
        navigation: {
          buttonOptions: {
            verticalAlign: "bottom",
            y: 0,
            x: 0,
            theme: {
              style: {
                color: "#17A2B8",
                textDecoration: "underline",
              },
            },
          },
        },
        exporting: {
          enabled: this.props.exporting,
          filename: `OPNBuildings - data export`,
          csv: {
            dateFormat: "%Y-%m-%dT%H:%M:%S.%LZ",
          },
          buttons: {
            contextButton: {
              enabled: false,
            },
            exportButton: {
              text: "Export",
              // Use only the download related menu items from the default
              // context button
              menuItems: [
                "downloadCSV",
                "downloadPNG",
                {
                  text: "Download PNG image with data",
                  onclick: function () {
                    // Derive timeframe from the first set of data
                    const start_date = moment(
                      this.series[0].processedXData[0]
                    ).format("DD MMM YYYY, HH:MM");
                    const end_date = moment(
                      this.series[0][this.series[0].processedXData.length - 1]
                    ).format("DD MMM YYYY, HH:MM");

                    // Attach it as subtitle
                    let subtitleText = `${start_date} - ${end_date}`;

                    // Add suffix like ' kWh' at the end
                    let suffix = "";
                    if (dataType) {
                      suffix = dataType;
                    }

                    // Iterate over series and add the line break and line with total consumption or average based on the suffix
                    this.series.forEach((e, index) => {
                      if (index > 5) return null;
                      let total_value = e.processedYData.reduce(
                        (accumulator, currentValue) =>
                          accumulator + currentValue
                      );
                      let value = GeneralUtils.getFormattedNumberWithUnit(
                        total_value,
                        suffix,
                        0
                      );

                      if (suffix.includes("ppm")) {
                        let average_value =
                          e.processedYData.reduce(
                            (accumulator, currentValue) =>
                              accumulator + currentValue
                          ) / e.processedYData.length;
                        value = `Average: ${GeneralUtils.getFormattedNumberWithUnit(
                          average_value,
                          suffix,
                          0
                        )}`;
                      }

                      if (suffix.includes("°C") || suffix.includes("%")) {
                        let average_value =
                          e.processedYData.reduce(
                            (accumulator, currentValue) =>
                              accumulator + currentValue
                          ) / e.processedYData.length;
                        value = `Average: ${GeneralUtils.getFormattedNumberWithUnit(
                          average_value,
                          suffix,
                          1
                        )}`;
                      }

                      subtitleText =
                        subtitleText +
                        `<br/><div style="text-align: center; font-size: 12px; margin: auto; width: 100%">${e.name} - ${value}</div>`;
                    });

                    // Export the chart with title (name of the document) and subtitle (timeframe, series names and aggregated value)
                    this.exportChart(null, {
                      title: {
                        text: document.title,
                      },
                      subtitle: {
                        text: subtitleText,
                      },
                      chart: {
                        height: "auto",
                      },
                    });
                  },
                },
                "downloadPDF",
              ],
            },
          },
        },
      };

      if (this.props.options) {
        options = {
          ...options,
          ...this.props.options,
        };
      }

      this.chart = Highcharts.chart(this.props.chart_Tag, options);
    }
  }
}
export default HighChart;

import ActionTypes from "../constants";
import GeneralUtils from "../utils/GeneralUtils";

/**
 * Actions related to fetching and managing alarms overview data. This class interfaces with the API
 * to retrieve and manipulate alarm data, dispatching actions to update the application state.
 *
 * @class
 * @example
 * const alarmsOverviewActions = new AlarmsOverviewActions();
 * alarmsOverviewActions.getAlarmsOverview();
 */
class AlarmsOverviewActions {
  /**
   * Fetches an overview of all alarms from the API and dispatches an action to store this data.
   *
   * @returns {Promise} - A promise that resolves with the fetched data.
   */
  getAlarmsOverview() {
    return GeneralUtils.get({
      url: "alarms",
      actionType: ActionTypes.ALARMS_OVERVIEW_FETCH,
    });
  }

  /**
   * Fetches the details of a specific alarm by its ID from the API and dispatches an action to store this data.
   *
   * @param {string} alarm_id - The unique identifier of the alarm to be fetched.
   * @returns {Promise} - A promise that resolves with the fetched data.
   */
  getAlarm(alarm_id) {
    return GeneralUtils.get({
      url: `alarms/${alarm_id}`,
      actionType: ActionTypes.ALARMS_OVERVIEW_ALARM_FETCH,
    });
  }

  /**
   * Updates a specific alarm in the API and dispatches an action to store the updated data.
   *
   * @param {string} alarm_id - The unique identifier of the alarm to be updated.
   * @param {Object} alarm_obj - The alarm object containing updated data.
   * @returns {Promise} - A promise that resolves with the response of the update operation.
   */
  updateAlarm(alarm_id, alarm_obj) {
    return GeneralUtils.put({
      url: `alarms/${alarm_id}`,
      object: alarm_obj,
      actionType: ActionTypes.ALARMS_OVERVIEW_ALARM_UPDATED,
      successToast: "Alarm successfully updated.",
      failToast: "Alarm update failed.",
    });
  }

  /**
   * Toggles the status of a specific alarm in the API and dispatches an action to store the updated data.
   *
   * @param {string} alarm_id - The unique identifier of the alarm to be toggled.
   * @param {Object} alarm_obj - The alarm object containing the status to be updated.
   * @returns {Promise} - A promise that resolves with the response of the toggle operation.
   */
  toggleAlarmStatus(alarm_id, alarm_obj) {
    return GeneralUtils.put({
      url: `alarms/${alarm_id}/status`,
      object: alarm_obj,
      actionType: ActionTypes.ALARMS_OVERVIEW_ALARM_UPDATED,
      successToast: "Alarm successfully updated.",
      failToast: "Alarm update failed.",
    });
  }

  /**
   * Saves a new alarm to the API and dispatches an action to store the new alarm data.
   *
   * @param {Object} alarm - The alarm object to be saved.
   * @returns {Promise} - A promise that resolves with the response of the save operation.
   */
  saveNewAlarm(alarm) {
    return GeneralUtils.post({
      url: "alarms",
      object: alarm,
      actionType: ActionTypes.ALARMS_OVERVIEW_ALARM_SAVED,
      successToast: "Alarm successfully saved.",
      failToast: "Alarm creation failed.",
    });
  }

  /**
   * Deletes a specific alarm by its ID from the API and dispatches an action to remove this data from the state.
   *
   * @param {string} alarm_id - The unique identifier of the alarm to be deleted.
   * @returns {Promise} - A promise that resolves with the response of the delete operation.
   */
  deleteAlarm(alarm_id) {
    return GeneralUtils.delete({
      url: `alarms/${alarm_id}`,
      actionType: ActionTypes.ALARMS_OVERVIEW_ALARM_DELETED,
      successToast: "Alarm successfully deleted.",
      failToast: "Alarm deletion failed.",
    });
  }
}

const alarmsOverviewActions = new AlarmsOverviewActions();
export default alarmsOverviewActions;

import { EventEmitter } from 'events';
import Dispatcher from '../dispatcher';
import ActionTypes from '../constants';
import _ from 'lodash';

const BREACHES_FETCHED = 'BREACHES_FETCHED';

let _breaches_by_buildings = [];
let _breaches_by_estates = [];

class BreachesStore extends EventEmitter {

    constructor() {
        super();
        // Registers action handler with the Dispatcher.
        Dispatcher.register(this._registerToActions.bind(this));
    }

    // Switches over the action's type when an action is dispatched.
    _registerToActions(action) {
        switch (action.actionType) {
            case ActionTypes.BUILDINGS_WITH_BREACHES_FETCHED:
                this._storeBreaches(action.payload);
                break;
            default:
                break;
        }
    }

    // Hooks a React component's callback to the BREACHES_FETCHED event.
    addFetchListener(callback) {
        this.on(BREACHES_FETCHED, callback);
    }

    // Removes the listener from the BREACHES_FETCHED event.
    removeFetchListener(callback) {
        this.removeListener(BREACHES_FETCHED, callback);
    }

    clear() {
        _breaches_by_buildings = [];
        _breaches_by_estates = [];
    }

    _storeBreaches(json) {
        _breaches_by_buildings = json;

        let estates = [];
        estates = json.map(building => {
          return {
            fk_estate_id: building.fk_estate_id,
            estate_name: building.estate_name,
            buildings: json
            .map(element => {
              if (element.estate_name === building.estate_name) {
                return element;
              }
              return null;
            })
            .filter(element => element)
          }
        })
    
        _breaches_by_estates = _.uniqBy(estates, 'estate_name');

        this.emit(BREACHES_FETCHED);
    }

    // Getters

    getBreachesByBuildings() {
        return _breaches_by_buildings;
    }

    getBreachesByEstates() {
        return _breaches_by_estates;
    }

}

const breachesStore = new BreachesStore();
export default breachesStore;

import React, { Component } from "react";
import { Link } from "react-router-dom";
import UserStore from "../../stores/userStore";
import GeneralStore from "../../stores/generalStore";
import GeneralActions from "../../actions/generalActions";
import { TimeframePicker } from "../../components/TimeframePicker";
import { UserMenu } from "../../components/UserMenu";
import { Icon } from "../../components/Icon";
import styles from "./Header.module.scss";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: UserStore.getUser(),
      ts_start: GeneralStore.getStartDate(),
      ts_end: GeneralStore.getEndDate(),
      granularity: GeneralStore.getGranularity(),
    };
    this._onChange = this._onChange.bind(this);
    this._selectDate = this._selectDate.bind(this);
  }
  _onChange() {
    if (this.state.ts_start !== GeneralStore.getStartDate() || this.state.ts_end !== GeneralStore.getEndDate()) {
      this.setState({
        user: UserStore.getUser(),
        ts_start: GeneralStore.getStartDate(),
        ts_end: GeneralStore.getEndDate(),
        granularity: GeneralStore.getGranularity()
      });
    }

    this.setState({
      timeframe_disabled: false
    })
  }
  UNSAFE_componentWillMount() {
    GeneralStore.addChangeListener(this._onChange);
  }
  componentWillUnmount() {
    GeneralStore.removeChangeListener(this._onChange);
  }
  _selectDate(ts_start, ts_end, granularity) {
    this.setState({
      timeframe_disabled: true,
    }, () => GeneralActions.setDates(ts_start, ts_end, granularity))
  }
  componentDidMount() {
    // Close panel in mobile view when selecting an option
    document.querySelectorAll(".br-sideleft .br-menu-link:not(.menu-item-group)").forEach(element => {
      element.addEventListener("click", function (e) {
        if (document.body.classList.contains("show-left")) {
          document.body.classList.remove("show-left");
        }
      });
    });

    // Expand the left sidebar in mobile view
    document.getElementById("btnLeftMenuMobile").addEventListener("click", function (e) {
      e.stopPropagation();
      document.body.classList.add("show-left");
      e.preventDefault();
    });

    // This will hide sidebar when it's clicked outside of it
    document.addEventListener("click", function (e) {
      e.stopPropagation();

      // closing left sidebar
      if (document.body.classList.contains("show-left")) {
        let targ = e.target.closest(".br-sideleft");
        if (!targ) {
          document.body.classList.remove("show-left");
        }
      }
    });

    document.addEventListener("touchstart", function (e) {
      e.stopPropagation();

      // closing left sidebar
      if (document.body.classList.contains("show-left")) {
        let targ = e.target.closest(".br-sideleft");
        if (!targ) {
          document.body.classList.remove("show-left");
        }
      }
    });
  }

  getSubscriptionStatus() {
    let icon, className, message;
    const status = UserStore.getSubscriptionStatus();
    if (status === null || status === "ACTIVE") {
      return null;
    }

    if (status === "TRIAL") {
      icon = "Clock";
      className = "trial";
      message =
        "This is a trial account and the access to it will expire after 30 days of first login.";
    } else if (status === "TRIAL_EXPIRED") {
      icon = "Clock";
      className = "trial-expired";
      message =
        "Your trial period has expired and access will be revoked soon.";
    } else if (status === "EXPIRED") {
      icon = "Warning";
      className = "expired";
      message = "Your subscription has expired.";
    } else if (status === "OVERDUE") {
      icon = "Warning";
      className = "overdue";
      message =
        "Your account is overdue on some/all elements of your subscription, please contact OPNBuildings to resolve.";
    } else {
      return null;
    }

    return (
      <React.Fragment>
        <div className={`navicon-left-subscription ${className}`}>
          <div className="icon-wrapper">
            <Icon name={icon} size={24} color='#fff' />
          </div>
          <span className="message">{message}</span>
        </div>
      </React.Fragment>
    );
  }
  render() {
    let dateNavigator = null;

    if (this.props.location && this.props.location.pathname) {
      if (this.props.dateSelector) {
        dateNavigator = (
          <div className={styles.timeframeContainer}>
            <TimeframePicker
              start={this.state.ts_start}
              end={this.state.ts_end}
              defaultGranularity={this.state.granularity}
              onChange={this._selectDate}
              granularities={['custom', 'day', 'week', 'month', 'year']}
              header
              unix
              disabled={this.state.timeframe_disabled}
            />
          </div>
        );
      }
    }

    return (
      <div>
        <div className="br-header">
          <div className="br-header-left">
            <div className="navicon-left hidden-lg-up">
              <Link id="btnLeftMenuMobile" to="#">
                <Icon
                  name="Menu"
                  title="Hide Sidebar"
                  size={25}
                />
              </Link>
            </div>
            {this.getSubscriptionStatus()}
          </div>
          <div className="br-header-center">{dateNavigator}</div>
          <div className="br-header-right">
            <UserMenu user={this.state.user} />
          </div>
        </div>
      </div>
    );
  }
}

export default Header;

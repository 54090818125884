import React, { Component } from "react";
import { SearchSelect } from "../../../components/SearchSelect";
import { Icon } from "../../../components/Icon";
import "./ModifyMappingPanel.scss";

class ModifyMappingPanel extends Component {
  getMappingRow(mapping) {
    const is_sensor_or_counter =
      this.props.selected_data_source_type === "SENSOR" ||
      this.props.selected_data_source_type === "COUNTER";
    const selected_property = this.props.all_properties.filter(
      (property) => property.property_id === mapping.property_id
    )[0];
    const selected_property_string =
      selected_property &&
      `${selected_property.name} (${selected_property.property_id})`;

    let status = (
      <span
        style={{
          color: "orange",
          cursor: "pointer",
          float: "right",
          width: "30px",
        }}
        className="btn btn-icon pd-2"
      >
        <Icon
          name='Circle'
          color='orange'
          size={16}
          style={{ verticalAlign: "text-top", marginRight: "5px" }}
        />
      </span>
    );

    if (this.props.duplicated_mapping) {
      status = (
        <span
          style={{
            color: "red",
            cursor: "pointer",
            float: "right",
            width: "30px",
          }}
          className="btn btn-icon pd-2"
        >
          <Icon
            name='Refresh'
            color='red'
            size={16}
            style={{ verticalAlign: "sub", marginRight: "5px" }}
          />
        </span>
      );
    } else if (this.props.validated_mapping) {
      status = (
        <span
          style={{
            color: "green",
            cursor: "pointer",
            float: "right",
            width: "30px",
          }}
          className="btn btn-icon pd-2"
        >
          <Icon
            name='RadioOn'
            color='green'
            size={16}
            style={{ verticalAlign: "text-top", marginRight: "5px" }}
          />
        </span>
      );
    } else if (this.props.saved_mapping) {
      status = (
        <span
          style={{
            color: "green",
            cursor: "pointer",
            float: "right",
            width: "30px",
          }}
          className="btn btn-icon pd-2"
        >
          <Icon name="CheckCircleOutline" color="#868ba1" />
        </span>
      );
    } else if (mapping.mapping_id) {
      status = (
        <span
          style={{
            color: "gray",
            cursor: "pointer",
            float: "right",
            width: "30px",
          }}
          className="btn btn-icon pd-2"
        >
          <Icon name="CheckCircleOutline" color="#868ba1" />
        </span>
      );
    }

    const all_data_sources = [];
    for (let data_source in this.props.data_sources[
      this.props.selected_entity_type
    ]) {
      all_data_sources.push(
        ...this.props.data_sources[this.props.selected_entity_type][data_source]
      );
    }
    const data_source_object =
      all_data_sources.length > 0 &&
      all_data_sources.filter(
        (source) => source.data_source_id === mapping.data_source_id
      )[0];
    let data_source;
    if (data_source_object === false) {
      data_source = (
        <span>{`Data Source (id: ${mapping.data_source_id})`}</span>
      );
    } else if (this.props.selected_data_source_type === null) {
      data_source = (
        <span
          onClick={() => this.props.setDataSource(data_source_object)}
          style={{ cursor: "pointer", textDecoration: "underline" }}
        >
          {data_source_object && data_source_object.name}
        </span>
      );
    } else {
      data_source = (
        <span>{data_source_object && data_source_object.name}</span>
      );
    }

    return (
      <tr>
        {/* PROPERTY */}
        <td style={{ verticalAlign: "baseline" }}>
          <select
            style={{ height: "25px" }}
            disabled={mapping.mapping_id}
            value={mapping.property_id}
            className="inline-single-input"
            onChange={this.props.changeMappingValues.bind(
              this,
              mapping,
              "property_id"
            )}
          >
            <option value={0}>
              {this.props.selected_data_source_type
                ? "Select Property"
                : selected_property_string}
            </option>
            {this.props.available_properties.map((property) => {
              return (
                <option
                  title={property.title}
                  key={property.property_id}
                  value={property.property_id}
                >{`${property.name} (${property.property_id})`}</option>
              );
            })}
          </select>
        </td>
        {/* DATA SOURCE */}
        <td style={{ verticalAlign: "baseline", textAlign: "center" }}>
          {data_source}
        </td>
        {/* POINT/NAME */}
        {this.props.selected_data_source_type === "APPLIANCE" ? (
          <td style={{ verticalAlign: "baseline" }}>
            <SearchSelect
              limit={100}
              options={this.props.appliance_unique_points}
              isDisabled={mapping.mapping_id}
              defaultValue={mapping}
              placeholder={"Select Point"}
              actionOnSelectedOption={this.props.changeMappingApplianceValues.bind(
                this,
                mapping
              )}
            />
          </td>
        ) : (
          <td style={{ verticalAlign: "baseline" }}>
            <input
              disabled={mapping.mapping_id || is_sensor_or_counter}
              placeholder="Name"
              title={mapping.name}
              type="text"
              className="inline-single-input"
              defaultValue={mapping.name}
              onChange={this.props.changeMappingValues.bind(
                this,
                mapping,
                "name"
              )}
            />
          </td>
        )}
        {/* INSTANCE */}
        <td style={{ verticalAlign: "baseline" }}>
          <input
            disabled
            placeholder="Instance Number"
            type="number"
            className="inline-single-input"
            value={mapping.instance_number}
          />
        </td>
        {/* DESCRIPTION */}
        <td style={{ verticalAlign: "baseline" }}>
          <input
            disabled
            placeholder="Description"
            type="text"
            className="inline-single-input"
            defaultValue={mapping.description}
            title={mapping.description}
            onChange={this.props.changeMappingValues.bind(
              this,
              mapping,
              "description"
            )}
          />
        </td>
        {/* STATUS AND BUTTON */}
        <td style={{ verticalAlign: "baseline" }}>
          <div style={{ justifyContent: "flex-end", display: "flex" }}>
            {status}
            {mapping.mapping_id ? (
              <span
                onClick={() => this.props.deleteMapping(mapping.mapping_id)}
                style={{ cursor: "pointer", width: "30px" }}
                className="btn btn-icon btn-danger pd-2"
              >
                <Icon
                  name='Delete'
                  size={16}
                  style={{ verticalAlign: "text-top" }}
                />
              </span>
            ) : (
              <span
                onClick={() => this.props.cancelNewMapping(mapping)}
                style={{
                  cursor: "pointer",
                  width: "30px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                className="btn btn-icon btn-warning"
              >
                <Icon name='Cross' size={16} style={{ verticalAlign: 'text-top' }} />
              </span>
            )}
          </div>
        </td>
      </tr>
    );
  }

  getNewMappingRowWithoutSelectedDataSource(mapping) {
    let available_data_sources = [];
    let available_properties = this.props.available_properties;
    let selected_data_source_type = null;

    // Gathering Data Sources
    for (let data_source in this.props.data_sources[
      this.props.selected_entity_type
    ]) {
      available_data_sources.push(
        ...this.props.data_sources[this.props.selected_entity_type][data_source]
      );
    }

    // Adjusting available properties/data sources basing on the information present
    if (mapping.data_source_id > 0 && mapping.property_id > 0) {
      available_properties = available_properties.filter(
        (prop) => prop.property_id === mapping.property_id
      );
      available_data_sources = available_data_sources.filter(
        (ds) => ds.data_source_id === mapping.data_source_id
      );
      selected_data_source_type = available_data_sources.filter(
        (source) => source.data_source_id === mapping.data_source_id
      )[0].type;
    } else if (mapping.data_source_id > 0) {
      selected_data_source_type = available_data_sources.filter(
        (source) => source.data_source_id === mapping.data_source_id
      )[0].type;
    } else if (mapping.property_id > 0) {
      const properties = this.props.available_properties;
      let compatible_data_sources_ids = properties.filter(
        (prop) => prop.property_id === mapping.property_id
      )[0].found_in_data_sources;
      available_data_sources = available_data_sources.filter((ds) =>
        compatible_data_sources_ids.includes(ds.data_source_id)
      );
    }

    const is_sensor_or_counter =
      selected_data_source_type === "SENSOR" ||
      selected_data_source_type === "COUNTER";
    let status = (
      <span
        style={{
          color: "orange",
          cursor: "pointer",
          float: "right",
          width: "30px",
        }}
        className="btn btn-icon pd-2"
      >
        <Icon
          name='Circle'
          color='orange'
          size={16}
          style={{ verticalAlign: "text-top", marginRight: "5px" }}
        />
      </span>
    );
    if (this.props.duplicated_mapping) {
      status = (
        <span
          style={{
            color: "red",
            cursor: "pointer",
            float: "right",
            width: "30px",
          }}
          className="btn btn-icon pd-2"
        >
          <Icon
            name='Refresh'
            color='red'
            size={16}
            style={{ verticalAlign: "sub", marginRight: "5px" }}
          />
        </span>
      );
    } else if (this.props.validated_mapping) {
      status = (
        <span
          style={{
            color: "green",
            cursor: "pointer",
            float: "right",
            width: "30px",
          }}
          className="btn btn-icon pd-2"
        >
          <Icon
            name='RadioOn'
            color='green'
            size={16}
            style={{ verticalAlign: "text-top", marginRight: "5px" }}
          />
        </span>
      );
    }

    return (
      <tr>
        {/* PROPERTY */}
        <td style={{ verticalAlign: "baseline" }}>
          <select
            style={{ height: "25px" }}
            disabled={mapping.mapping_id}
            value={mapping.property_id}
            className="inline-single-input"
            onChange={this.props.changeMappingValues.bind(
              this,
              mapping,
              "property_id"
            )}
          >
            <option value={0}>{"Select Property"}</option>
            {available_properties.map((property) => {
              return (
                <option
                  title={property.title}
                  key={property.property_id}
                  value={property.property_id}
                >{`${property.name} (${property.property_id})`}</option>
              );
            })}
          </select>
        </td>
        {/* DATA SOURCE */}
        <td style={{ verticalAlign: "baseline", textAlign: "center" }}>
          <select
            style={{ height: "25px" }}
            value={mapping.data_source_id ? mapping.data_source_id : 0}
            disabled={mapping.property_id === 0 || mapping.locked_data_source}
            className="inline-single-input"
            onChange={this.props.changeMappingValues.bind(
              this,
              mapping,
              "data_source_id"
            )}
          >
            <option value={0}>{"Select Data Source"}</option>
            {available_data_sources.map((ds) => {
              return (
                <option
                  title={ds.name}
                  key={ds.data_source_id}
                  value={ds.data_source_id}
                >{`${ds.name} (${ds.data_source_id})`}</option>
              );
            })}
          </select>
        </td>
        {/* NAME */}
        {selected_data_source_type === "APPLIANCE" ? (
          <td style={{ verticalAlign: "baseline" }}>
            <SearchSelect
              limit={100}
              options={this.props.appliance_unique_points}
              isDisabled={mapping.mapping_id}
              defaultValue={mapping}
              placeholder={"Select Point"}
              actionOnSelectedOption={this.props.changeMappingApplianceValues.bind(
                this,
                mapping
              )}
            />
          </td>
        ) : (
          <td style={{ verticalAlign: "baseline" }}>
            <input
              disabled={!selected_data_source_type || is_sensor_or_counter}
              placeholder="Name"
              type="text"
              className="inline-single-input"
              defaultValue={mapping.name}
              title={mapping.name}
              onChange={this.props.changeMappingValues.bind(
                this,
                mapping,
                "name"
              )}
            />
          </td>
        )}
        {/* INSTANCE */}
        <td style={{ verticalAlign: "baseline" }}>
          <input
            disabled
            placeholder="Instance Number"
            type="number"
            className="inline-single-input"
            value={mapping.instance_number}
          />
        </td>
        {/* DESCRIPTION */}
        <td style={{ verticalAlign: "baseline" }}>
          <input
            disabled
            placeholder="Description"
            type="text"
            className="inline-single-input"
            defaultValue={mapping.description}
            title={mapping.description}
            onChange={this.props.changeMappingValues.bind(
              this,
              mapping,
              "description"
            )}
          />
        </td>
        {/* STATUS AND BUTTON */}
        <td style={{ verticalAlign: "baseline" }}>
          <div style={{ justifyContent: "flex-end", display: "flex" }}>
            {status}
            {mapping.mapping_id ? (
              <span
                onClick={() => this.props.deleteMapping(mapping.mapping_id)}
                style={{ cursor: "pointer", width: "30px" }}
                className="btn btn-icon btn-danger pd-2"
              >
                <Icon
                  name='Delete'
                  size={16}
                  style={{ verticalAlign: "text-top" }}
                />
              </span>
            ) : (
              <span
                onClick={() => this.props.cancelNewMapping(mapping)}
                style={{
                  cursor: "pointer",
                  width: "30px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                className="btn btn-icon btn-warning"
              >
                <Icon name='Cross' size={16} style={{ verticalAlign: 'text-top' }} />
              </span>
            )}
          </div>
        </td>
      </tr>
    );
  }

  render() {
    const mapping = this.props.mapping;
    const is_new_mapping_without_data_source =
      this.props.selected_data_source_type === null && !mapping.mapping_id;

    return is_new_mapping_without_data_source
      ? this.getNewMappingRowWithoutSelectedDataSource(mapping)
      : this.getMappingRow(mapping);
  }
}

export default ModifyMappingPanel;

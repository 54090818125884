import React, { useState, useEffect } from 'react';
import { Button, Row, Col, Tabs } from 'antd';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import classNames from 'classnames';
import LogoSpinner from '../../../../components/LogoSpinner/LogoSpinner';
import styles from './EnergyPerformanceCharts.module.scss';
import energyReviewActions from '../../../../actions/energyReviewActions';
import Timeframe from '../../../../utils/Timeframe';
import moment from 'moment';
import GeneralUtils from '../../../../utils/GeneralUtils';

// Constants
const UPPER_CHART_HEIGHT = 300;
const BOTTOM_CHART_HEIGHT = 630;
const POSITIVE_COLOR = '#fbb5b3';  // red - worse performance
const NEGATIVE_COLOR = '#c2e5db';  // green - better performance
const CONSUMPTION_PRIMARY_COLOR = '#87ceeb';   // orange for annualised consumption
const CONSUMPTION_SECONDARY_COLOR = '#ffa07a'; // blue for weekly consumption

const getLastSunday = () => {
  const now = moment();
  return now.subtract(now.day(), 'days');
};

const sharedOptions = {
  credits: {
    enabled: false,
  },
  xAxis: {
    type: 'datetime',
    gridLineDashStyle: "longdash",
    gridLineWidth: 1,
    gridLineColor: "#e6e6e6",
    minorLineWidth: 1,
    startOnTick: false,
    endOnTick: false,
    labels: {
      format: '{value:%b %Y}',
      style: {
        color: "#343a40",
        fontSize: "9px",
      },
    },
    dateTimeLabelFormats: {
      day: '%b %e',
      week: '%b %e',
      month: '%b %e',
      year: '%Y'
    }
  },
  yAxis: {
    title: {
      text: null,
    },
    opposite: false,
    height: "100%",
    offset: 0,
    lineWidth: 2,
    gridLineDashStyle: "longdash",
    gridLineWidth: 1,
    gridLineColor: "#e6e6e6",
    minorLineWidth: 1,
    startOnTick: false,
    endOnTick: false,
    showFirstLabel: true,
    showLastLabel: true,
    labels: {
      style: {
        color: "#343a40",
        fontSize: "9px",
      },

    },
  },
  plotOptions: {
    series: {
      turboThreshold: 0,
      marker: {
        enabled: false,
      },
      groupPadding: 0,
      pointPadding: 0,
      borderWidth: 0.2,
      pointPlacement: "on",
    },
    line: {
      dataLabels: {
        enabled: false,
      },
      softThreshold: false,
      series: {
        marker: {
          enabled: false,
        },
      },
    },
    column: {
      softThreshold: false,
    },
  },
  exporting: {
    enabled: true,
    buttons: {
      contextButton: {
        enabled: true,
        align: 'right',
        verticalAlign: 'top',
        symbol: 'menu',
        x: 0,
        menuItems: [
          "viewFullscreen",
          "printChart",
          "downloadPNG",
          "downloadPDF",
          "downloadSVG",
          "downloadCSV",
          "downloadXLS"]
      }
    },
  }
};

const EnergyPerformanceCharts = ({ meter }) => {
  const [displayMode, setDisplayMode] = useState('absolute');
  const [timeframe, setTimeframe] = useState('ytd');

  // Loading states
  const [performanceLoading, setPerformanceLoading] = useState(false);
  const [consumptionLoading, setConsumptionLoading] = useState(false);

  // Separate states for each chart
  const [performanceData, setPerformanceData] = useState({
    absolute: [],
    relative: []
  });

  const [cusumData, setCusumData] = useState({
    absolute: [],
    relative: []
  });

  const [consumptionData, setConsumptionData] = useState({
    weekly: [],
    annualised: []
  });

  // Fetch performance data
  useEffect(() => {
    const fetchPerformanceData = async () => {
      if (!meter?.energy_review_meter_id) return;

      const endDate = getLastSunday();
      let start;

      // Calculate start date based on timeframe
      switch (timeframe) {
        case 'ytd':
          start = endDate.clone().startOf('year');
          break;
        case '6m':
          start = endDate.clone().subtract(6, 'months');
          break;
        case '12m':
          start = endDate.clone().subtract(12, 'months');
          break;
        case '24m':
          start = endDate.clone().subtract(24, 'months');
          break;
        default:
          start = endDate.clone().startOf('year');
      }

      const timeframeObj = new Timeframe(start, endDate);

      setPerformanceLoading(true);
      try {
        const response = await energyReviewActions.getPerformanceChartData(
          meter.energy_review_meter_id,
          timeframeObj
        );

        const absoluteData = [];
        const relativeData = [];

        const absoluteCusum = [];
        const relativeCusum = [];

        // Checking for valid data points
        const validDataPoints = response.actual.filter((point, index) => {
          const expectedPoint = response.expected.find(ep => ep.timestamp === point.timestamp);
          return expectedPoint && expectedPoint.value !== null;
        });

        // Sort data points by timestamp to ensure chronological order
        validDataPoints.sort((a, b) => moment(a.timestamp).valueOf() - moment(b.timestamp).valueOf());

        let totalActual = 0;
        let totalExpected = 0;
        let absoluteCusumValue = 0;  // Running sum for absolute differences

        validDataPoints.forEach((actualPoint) => {
          const expectedPoint = response.expected.find(ep => ep.timestamp === actualPoint.timestamp);

          if (expectedPoint && expectedPoint.value !== null) {
            const timestamp = moment(actualPoint.timestamp).valueOf();

            // Calculate absolute and relative differences for first chart
            const absoluteDiff = actualPoint.value - expectedPoint.value;
            const relativeDiff = (absoluteDiff / expectedPoint.value) * 100;

            // Add to performance data arrays (first chart)
            absoluteData.push({
              x: timestamp,
              y: absoluteDiff
            });
            relativeData.push({
              x: timestamp,
              y: relativeDiff
            });

            // Calculate CUSUM values for second chart
            absoluteCusumValue += absoluteDiff;
            totalActual += actualPoint.value;
            totalExpected += expectedPoint.value;
            const relativeCusumValue = ((totalActual - totalExpected) / totalExpected) * 100;

            // Add to CUSUM arrays (second chart)
            absoluteCusum.push({
              x: timestamp,
              y: absoluteCusumValue
            });
            relativeCusum.push({
              x: timestamp,
              y: relativeCusumValue
            });
          }
        });

        setPerformanceData({
          absolute: absoluteData,
          relative: relativeData
        });

        setCusumData({
          absolute: absoluteCusum,
          relative: relativeCusum
        });

      } catch (error) {
        console.error('Error fetching performance data:', error.code, error.type);
      } finally {
        setPerformanceLoading(false);
      }
    };

    fetchPerformanceData();
  }, [meter?.energy_review_meter_id, timeframe]);

  // Second useEffect for consumption data
  useEffect(() => {
    const fetchMeterData = async () => {
      if (!meter?.energy_review_meter_id) return;

      setConsumptionLoading(true);
      try {
        const endDate = getLastSunday();
        let start;

        // Calculate start date based on timeframe
        switch (timeframe) {
          case 'ytd':
            start = endDate.clone().startOf('year');
            break;
          case '6m':
            start = endDate.clone().subtract(6, 'months');
            break;
          case '12m':
            start = endDate.clone().subtract(12, 'months');
            break;
          case '24m':
            start = endDate.clone().subtract(24, 'months');
            break;
          default:
            start = endDate.clone().startOf('year');
        }

        const timeframeObj = new Timeframe(start, endDate);

        const consumption_over_time = await energyReviewActions.getAnnualisedConsumptionChartData(
          meter.energy_review_meter_id,
          timeframeObj
        );

        const weekly_consumption = consumption_over_time.find(data => data?.property?.name === 'Energy kWh');
        const annualised_consumption = consumption_over_time.find(data => data?.property?.name === 'Energy kWh Rolling Total');

        // Process and set chart data
        setConsumptionData(prevData => ({
          weekly: weekly_consumption?.data?.map(point => ({
            x: moment(point.timestamp).valueOf(),
            y: point.value
          })) || [],
          annualised: annualised_consumption?.data?.map(point => ({
            x: moment(point.timestamp).valueOf(),
            y: point.value
          })) || []
        }));

      } catch (error) {
        console.error('Error fetching meter chart data:', error.code, error.type);
      } finally {
        setConsumptionLoading(false);
      }
    };

    fetchMeterData();
  }, [meter?.energy_review_meter_id, timeframe]);

  const getPerformanceChartOptions = (displayMode) => ({
    ...sharedOptions,
    chart: {
      type: 'areaspline',
      height: UPPER_CHART_HEIGHT,
    },
    title: {
      text: `Performance Change ${displayMode === 'relative' ? '(%)' : '(kWh)'}`
    },
    yAxis: {
      ...sharedOptions.yAxis,
      title: {
        text: displayMode === 'relative' ? 'Performance Change (%)' : 'Performance Change (kWh)',
        style: {
          color: '#343a40',
          fontSize: '12px'
        }
      },
      labels: {
        formatter: function () {
          return GeneralUtils.getFormattedNumberWithUnit(
            this.value,
            displayMode === 'relative' ? '%' : ' kWh'
          );
        }
      }
    },
    legend: {
      enabled: false,
      align: "center",
      verticalAlign: "bottom",
      itemStyle: {
        color: "#343a40",
        fontSize: "9px",
        fontWeight: "bold",
      },
    },
    plotOptions: {
      areaspline: {
        negativeFillColor: NEGATIVE_COLOR,
        fillColor: POSITIVE_COLOR,
        negativeColor: NEGATIVE_COLOR,
        color: POSITIVE_COLOR,
        lineWidth: 1,
        threshold: 0,
        states: {
          hover: {
            lineWidth: 1
          }
        },
        marker: {
          enabled: false,
          symbol: 'circle',
          radius: 2,
          states: {
            hover: {
              enabled: true
            }
          }
        }
      }
    },
    series: [{
      name: 'Performance',
      data: performanceData ? performanceData[displayMode] : []
    }],
    tooltip: {
      shared: true,
      xDateFormat: '%b %e, %Y',
      headerFormat: '<b>{point.key}</b><br/>',
      pointFormatter: function () {
        return `${this.series.name}: ${GeneralUtils.getFormattedNumberWithUnit(
          this.y,
          displayMode === 'relative' ? '%' : ' kWh',
          displayMode === 'relative' ? 1 : 0
        )}<br/>`;
      }
    },
  });

  const getCusumChartOptions = (displayMode) => ({
    ...sharedOptions,
    chart: {
      type: 'areaspline',
      height: UPPER_CHART_HEIGHT,
    },
    title: {
      text: `CUSUM Performance ${displayMode === 'relative' ? '(%)' : '(kWh)'}`
    },
    yAxis: {
      ...sharedOptions.yAxis,
      title: {
        text: displayMode === 'relative' ? 'Cumulative Change (%)' : 'Cumulative Change (kWh)',
        style: {
          color: '#343a40',
          fontSize: '12px'
        }
      },
      labels: {
        formatter: function () {
          return GeneralUtils.getFormattedNumberWithUnit(
            this.value,
            displayMode === 'relative' ? '%' : ' kWh'
          );
        }
      }
    },
    legend: {
      enabled: false,
      align: "center",
      verticalAlign: "bottom",
      itemStyle: {
        color: "#343a40",
        fontSize: "9px",
        fontWeight: "bold",
      },
    },
    plotOptions: {
      areaspline: {
        negativeFillColor: NEGATIVE_COLOR,
        fillColor: POSITIVE_COLOR,
        negativeColor: NEGATIVE_COLOR,
        color: POSITIVE_COLOR,
        lineWidth: 1,
        threshold: 0,
        states: {
          hover: {
            lineWidth: 1
          }
        },
        marker: {
          enabled: false,
          symbol: 'circle',
          radius: 2,
          states: {
            hover: {
              enabled: true
            }
          }
        }
      }
    },
    series: [{
      name: 'CUSUM',
      data: cusumData ? cusumData[displayMode] : []
    }],
    tooltip: {
      shared: true,
      xDateFormat: '%b %e, %Y',
      headerFormat: '<b>{point.key}</b><br/>',
      pointFormatter: function () {
        return `${this.series.name}: ${GeneralUtils.getFormattedNumberWithUnit(
          this.y,
          displayMode === 'relative' ? '%' : ' kWh',
          displayMode === 'relative' ? 1 : 0
        )}<br/>`;
      }
    },
  });

  const getAnnualisedConsumptionChartOptions = () => ({
    ...sharedOptions,
    chart: {
      type: 'mixed',
      height: BOTTOM_CHART_HEIGHT,
    },
    title: { text: 'Weekly & Annualised Consumption' },
    yAxis: [
      {
        // Weekly consumption y-axis
        min: 0,
        title: {
          text: 'Weekly Consumption (kWh)',
          style: {
            color: '#343a40',
            fontSize: '12px'
          }
        },
        labels: {
          formatter: function () {
            return GeneralUtils.getFormattedNumberWithUnit(this.value, ' kWh');
          }
        }
      },
      {
        // Annualised consumption y-axis
        min: 0,
        title: {
          text: 'Annualised Consumption (kWh)',
          style: {
            color: '#343a40',
            fontSize: '12px'
          }
        },
        opposite: true,
        labels: {
          formatter: function () {
            return GeneralUtils.getFormattedNumberWithUnit(this.value, ' kWh');
          }
        }
      }
    ],
    series: [
      {
        name: 'Weekly Consumption',
        type: 'column',
        yAxis: 0,
        color: CONSUMPTION_PRIMARY_COLOR,
        data: consumptionData ? consumptionData.weekly : []
      },
      {
        name: 'Annualised Consumption',
        type: 'spline',
        yAxis: 1,
        color: CONSUMPTION_SECONDARY_COLOR,
        data: consumptionData ? consumptionData.annualised : [],
        marker: {
          enabled: false
        },
        lineWidth: 4
      }
    ],
    tooltip: {
      shared: true,
      xDateFormat: '%b %e, %Y',
      headerFormat: '<b>{point.key}</b><br/>',
      pointFormatter: function () {
        return `${this.series.name}: ${GeneralUtils.getFormattedNumberWithUnit(this.y, ' kWh')}<br/>`;
      }
    },
  });

  return (
    <div className="chart-modal-content">
      <LogoSpinner loading={performanceLoading || consumptionLoading} />
      <Tabs defaultActiveKey="performance">
        <Tabs.TabPane tab="Performance" key="performance">
          <Row gutter={[30, 30]}>
            <Col span={24}>
              <Row justify="space-between" style={{ marginTop: 10, marginBottom: 10 }} gutter={[16, 16]}>
                <Col className={styles.buttonWrapper}>
                  <Button
                    size="small"
                    onClick={() => setDisplayMode('absolute')}
                    type={displayMode === 'absolute' ? 'primary' : 'text'}
                    className={classNames({
                      [styles.activeButton]: displayMode === 'absolute',
                      [styles.inactiveButton]: displayMode !== 'absolute',
                    })}
                  >
                    Absolute (kWh)
                  </Button>
                  <Button
                    size="small"
                    onClick={() => setDisplayMode('relative')}
                    type={displayMode === 'relative' ? 'primary' : 'text'}
                    className={classNames({
                      [styles.activeButton]: displayMode === 'relative',
                      [styles.inactiveButton]: displayMode !== 'relative',
                    })}
                  >
                    Relative (%)
                  </Button>
                </Col>

                <Col className={styles.buttonWrapper}>
                  <Button
                    size="small"
                    onClick={() => setTimeframe('ytd')}
                    type={timeframe === 'ytd' ? 'primary' : 'text'}
                    className={classNames({
                      [styles.activeButton]: timeframe === 'ytd',
                      [styles.inactiveButton]: timeframe !== 'ytd',
                    })}
                  >
                    Year to Date
                  </Button>
                  <Button
                    size="small"
                    onClick={() => setTimeframe('6m')}
                    type={timeframe === '6m' ? 'primary' : 'text'}
                    className={classNames({
                      [styles.activeButton]: timeframe === '6m',
                      [styles.inactiveButton]: timeframe !== '6m',
                    })}
                  >
                    Last 6 Months
                  </Button>
                  <Button
                    size="small"
                    onClick={() => setTimeframe('12m')}
                    type={timeframe === '12m' ? 'primary' : 'text'}
                    className={classNames({
                      [styles.activeButton]: timeframe === '12m',
                      [styles.inactiveButton]: timeframe !== '12m',
                    })}
                  >
                    Last 12 Months
                  </Button>
                  <Button
                    size="small"
                    onClick={() => setTimeframe('24m')}
                    type={timeframe === '24m' ? 'primary' : 'text'}
                    className={classNames({
                      [styles.activeButton]: timeframe === '24m',
                      [styles.inactiveButton]: timeframe !== '24m',
                    })}
                  >
                    Last 24 Months
                  </Button>
                </Col>
              </Row>
            </Col>

            <Col span={24}>
              <HighchartsReact
                highcharts={Highcharts}
                options={getPerformanceChartOptions(displayMode)}
              />
            </Col>
            <Col span={24}>
              <HighchartsReact
                highcharts={Highcharts}
                options={getCusumChartOptions(displayMode)}
              />
            </Col>
          </Row>
        </Tabs.TabPane>

        <Tabs.TabPane tab="Consumption" key="consumption">
          <Row gutter={[30, 30]}>
            <Col span={24}>
              <Row justify="end" style={{ marginTop: 10, marginBottom: 10 }}>
                <Col className={styles.buttonWrapper}>
                  <Button
                    size="small"
                    onClick={() => setTimeframe('ytd')}
                    type={timeframe === 'ytd' ? 'primary' : 'text'}
                    className={classNames({
                      [styles.activeButton]: timeframe === 'ytd',
                      [styles.inactiveButton]: timeframe !== 'ytd',
                    })}
                  >
                    Year to Date
                  </Button>
                  <Button
                    size="small"
                    onClick={() => setTimeframe('6m')}
                    type={timeframe === '6m' ? 'primary' : 'text'}
                    className={classNames({
                      [styles.activeButton]: timeframe === '6m',
                      [styles.inactiveButton]: timeframe !== '6m',
                    })}
                  >
                    Last 6 Months
                  </Button>
                  <Button
                    size="small"
                    onClick={() => setTimeframe('12m')}
                    type={timeframe === '12m' ? 'primary' : 'text'}
                    className={classNames({
                      [styles.activeButton]: timeframe === '12m',
                      [styles.inactiveButton]: timeframe !== '12m',
                    })}
                  >
                    Last 12 Months
                  </Button>
                  <Button
                    size="small"
                    onClick={() => setTimeframe('24m')}
                    type={timeframe === '24m' ? 'primary' : 'text'}
                    className={classNames({
                      [styles.activeButton]: timeframe === '24m',
                      [styles.inactiveButton]: timeframe !== '24m',
                    })}
                  >
                    Last 24 Months
                  </Button>
                </Col>
              </Row>
            </Col>

            <Col span={24}>
              <HighchartsReact
                highcharts={Highcharts}
                options={getAnnualisedConsumptionChartOptions()}
              />
            </Col>
          </Row>
        </Tabs.TabPane>
      </Tabs>
    </div>
  );
};

export default EnergyPerformanceCharts;

import { EventEmitter } from "events";
import Dispatcher from "../dispatcher";
import ActionTypes from "../constants";

const APPLIANCE_POINTS_FETCHED = "APPLIANCE_POINTS_FETCHED";

let _appliance_points = [];

class ApplianceStore extends EventEmitter {
  constructor() {
    super();
    // Registers action handler with the Dispatcher.
    Dispatcher.register(this._registerToActions.bind(this));
  }

  // Switches over the action's type when an action is dispatched.
  _registerToActions(action) {
    switch (action.actionType) {
      case ActionTypes.APPLIANCE_POINTS_FETCHED:
        this._storeAppliancePoints(action.payload);
        break;
      default:
        break;
    }
  }

  // Hooks a React component's callback to the CHANGED event.
  addAppliancePointsListener(callback) {
    this.on(APPLIANCE_POINTS_FETCHED, callback);
  }

  // Removes the listener from the CHANGED event.
  removeAppliancePointsListener(callback) {
    this.removeListener(APPLIANCE_POINTS_FETCHED, callback);
  }

  clear() {
    _appliance_points = [];
  }

  _storeAppliancePoints(json) {
    _appliance_points = json;
    this.emit(APPLIANCE_POINTS_FETCHED);
  }

  getAppliancePoints() {
    return _appliance_points;
  }
}

const applianceStore = new ApplianceStore();
export default applianceStore;

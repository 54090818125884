import React, { useState, useEffect } from 'react';
import { Prompt } from 'react-router-dom';

const useUnsavedChangesWarning = (
    message = 'All unsaved changes will be lost, proceed?'
) => {
    const [isDirty, setDirty] = useState(false);

    useEffect(() => {
        window.onbeforeunload = isDirty
            ? () => isDirty && message
            : null;

        return () => {
            window.onbeforeunload = null;
        };
    }, [isDirty, message]);

    const routerPrompt = <Prompt when={isDirty} message={message} />;
    const onDirty = () => setDirty(true);
    const onPristine = () => setDirty(false);

    return { routerPrompt, onDirty, onPristine };
};

export default useUnsavedChangesWarning;
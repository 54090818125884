import GeneralUtils from "./GeneralUtils";

class ComponentUtils {

    /**
     * Generates an array of objects containing `value` and `label` fields, sorted by label.
     *
     * @param {Array} values - An array of input values to be converted into value/label pairs.
     * @param {Function} displayTextFunction - A function that takes a value and returns its corresponding label.
     *                                         If the function returns `null`, a default conversion is applied.
     *
     * @returns {Array} - An array of objects, each containing:
     *   - `value`: The original input value.
     *   - `label`: The corresponding human-readable label, either from `displayTextFunction` or the default.
     *              The objects are sorted alphabetically by the `label` field.
     *
     * Example Input/Output:
     * Input:
     *   values = ["NATURAL_GAS", "ELECTRIC", "PROPANE", "SOLAR"];
     *   displayTextFunction = (value) => value === "NATURAL_GAS" ? "Natural Gas" : null;
     * Output:
     *   [
     *     { value: "ELECTRIC", label: "Electric" },
     *     { value: "NATURAL_GAS", label: "Natural Gas" },
     *     { value: "PROPANE", label: "Propane" },
     *     { value: "SOLAR", label: "Solar" }
     *   ]
     */
    generateLabelsForValues(values, displayTextFunction) {
        const valuesWithLabels = values.map((value) => {
            const label = displayTextFunction 
            ? displayTextFunction(value) ?? GeneralUtils.defaultValueToDisplayText(value)
            : GeneralUtils.defaultValueToDisplayText(value);
        return { value, label };
        });

        const sortedValues = valuesWithLabels.sort((a, b) =>
            a.label.localeCompare(b.label)
        );

        return sortedValues; 
    }

}

const componentUtils = new ComponentUtils();
export default componentUtils;
import ActionTypes from "../constants";
import GeneralUtils from "../utils/GeneralUtils";

/**
 * Actions related to managing sensors. This class interfaces with the API
 * to retrieve, create, update, and delete sensor data, dispatching actions to update the application state.
 *
 * @class
 * @example
 * const sensorActions = new SensorActions();
 * sensorActions.getBuildingSigfoxSensors('building123');
 */
class SensorActions {
  /**
   * Fetches Sigfox sensors for a specific building from the API and dispatches an action to store this data.
   *
   * @param {string} buildingId - The unique identifier of the building.
   * @returns {Promise} - A promise that resolves with the fetched data.
   */
  getBuildingSigfoxSensors(buildingId) {
    return GeneralUtils.get({
      url: `sensor/building/${buildingId}`,
      actionType: ActionTypes.BUILDING_SIGFOX_SENSORS,
      failToast: "Unable to get building sensors, please try again",
    });
  }

  /**
   * Fetches Sigfox device types from the API and dispatches an action to store this data.
   *
   * @param {...string} types - The types of Sigfox devices to fetch.
   * @returns {Promise} - A promise that resolves with the fetched data.
   */
  getSigfoxDeviceTypes(...types) {
    const params = types.map((type, ind) => `${ind > 0 ? "&" : "?"}entityType=${type}`).join("");
    return GeneralUtils.get({
      url: `sensor/type${params}`,
      actionType: ActionTypes.SIGFOX_DEVICE_TYPES,
      failToast: "Unable to get Sigfox device types, please try again",
    });
  }

  /**
   * Saves a new sensor to the API and dispatches an action to store the new sensor data.
   *
   * @param {Object} sensor - The sensor object to be saved.
   * @returns {Promise} - A promise that resolves with the response of the save operation.
   */
  saveSensor(sensor) {
    return GeneralUtils.post({
      url: "sensor",
      object: sensor,
      actionType: ActionTypes.SENSOR_SAVED,
      successToast: `Sensor ${sensor.name} saved successfully`,
      failToast: `Unable to save sensor ${sensor.name} or sensor already created, please try again`,
      modifyResponse: (response) => ({ ...sensor, response: response }),
    });
  }

  /**
   * Updates an existing sensor in the API and dispatches an action to store the updated data.
   *
   * @param {Object} sensor - The sensor object to be updated.
   * @returns {Promise} - A promise that resolves with the response of the update operation.
   */
  updateSensor(sensor) {
    return GeneralUtils.put({
      url: "sensor",
      object: sensor,
      actionType: ActionTypes.SENSOR_UPDATED,
      successToast: `Sensor ${sensor.name} updated successfully`,
      failToast: `Unable to update sensor ${sensor.name}, please try again`,
      modifyResponse: (response) => ({ ...sensor, response: response }),
    });
  }

  /**
   * Deletes a specific sensor by its ID from the API and dispatches an action to remove this data from the state.
   *
   * @param {string} deviceId - The unique identifier of the sensor to be deleted.
   * @returns {Promise} - A promise that resolves with the response of the delete operation.
   */
  deleteSensor(deviceId) {
    return GeneralUtils.delete({
      url: `sensor/${deviceId}`,
      actionType: ActionTypes.SENSOR_DELETED,
      successToast: `Sensor ${deviceId} deleted successfully`,
      failToast: "Unable to delete sensor, please try again",
      modifyResponse: (response) => ({ ...response, deviceId }),
    });
  }
}

const sensorActions = new SensorActions();
export default sensorActions;

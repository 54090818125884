import ActionTypes from "../constants";
import GeneralUtils from "../utils/GeneralUtils";

/**
 * Actions related to fetching the overview of meters. This class interfaces with the API
 * to retrieve data and dispatches actions to update the application state.
 *
 * @class
 * @example
 * const metersOverviewActions = new MetersOverviewActions();
 * metersOverviewActions.getMetersOverview('2020-01-01', '2020-01-31');
 */
class MetersOverviewActions {
  /**
   * Fetches an overview of meters within a specified time range from the API and dispatches an action to store this data.
   *
   * @param {string} [ts_start] - The start timestamp of the data range.
   * @param {string} [ts_end] - The end timestamp of the data range.
   * @returns {Promise} - A promise that resolves with the fetched data.
   */
  getMetersOverview(timeframe) {
    const queryParams = GeneralUtils.toIntervalQueryParameterString(timeframe);

    return GeneralUtils.get({
      url: `v2/meters?${queryParams}`,
      actionType: ActionTypes.METERS_OVERVIEW_FETCH,
      failToast: "Unable to fetch meters overview, please try again",
    });
  }
}

const metersOverviewActions = new MetersOverviewActions();
export default metersOverviewActions;

import ActionTypes from "../constants";
import GeneralUtils from "../utils/GeneralUtils";

/**
 * Actions related to fetching organisation data. This class interfaces with the API
 * to retrieve data and dispatches actions to update the application state.
 *
 * @class
 * @example
 * const organisationActions = new OrganisationActions();
 * organisationActions.getOrganisation('org123');
 */
class OrganisationActions {
  /**
   * Fetches the details of a specific organisation by its ID from the API and dispatches an action to store this data.
   *
   * @param {string} orgId - The unique identifier of the organisation.
   * @returns {Promise} - A promise that resolves with the fetched data.
   */
  getOrganisation(orgId) {
    return GeneralUtils.get({
      url: `organisations/${orgId}`,
      actionType: ActionTypes.ORGANISATION_FETCHED,
      failToast: "Unable to fetch organisation, please try again",
    });
  }

  /**
   * Fetches the list of organisations from the API, optionally including their buildings, and dispatches an action to store this data.
   *
   * @param {string} [showBuildings="false"] - Whether to include buildings in the response.
   * @returns {Promise} - A promise that resolves with the fetched data.
   */
  getOrganisations(showBuildings = false) {
    return GeneralUtils.get({
      url: `organisations/?showBuildings=${showBuildings}`,
      actionType: ActionTypes.ORGANISATIONS_FETCHED,
      failToast: "Unable to fetch organisations, please try again",
    });
  }
}

const organisationActions = new OrganisationActions();
export default organisationActions;

import Highcharts from "highcharts";
import Constants from "../constants";

class ChartUtils {
  getColorForProperty(property_name) {
    let color = Constants.ORANGE;
    if (
      property_name.indexOf("Active") > -1 ||
      property_name.indexOf("Enable") > -1 ||
      property_name.indexOf("Outside") > -1 ||
      property_name === "Hot Water System"
    ) {
      color = Constants.GREEN;
    }
    if (
      property_name.indexOf("Fault") > -1 ||
      property_name.indexOf("Alarm") > -1
    ) {
      color = Constants.RED;
    }
    if (
      property_name.indexOf("CO2") > -1 ||
      property_name.indexOf("Intake") > -1
    ) {
      color = Constants.YELLOW;
    }
    if (property_name.indexOf("Heat") > -1) {
      color = Constants.RED;
    }
    if (
      property_name.indexOf("Cool") > -1 ||
      property_name.indexOf("Gas") > -1
    ) {
      color = Constants.BLUE;
    }
    if (
      property_name.indexOf("Damper") > -1 ||
      property_name.indexOf("Electric") > -1
    ) {
      color = Constants.ORANGE;
    }
    return color;
  }

  getLineConsumptionSeries(
    name,
    color,
    dashStyle,
    width,
    showInLegend,
    includeInDataExport
  ) {
    if (color === null) {
      return {
        includeInDataExport: includeInDataExport,
        name: name,
        type: "spline",
        showInLegend: showInLegend,
        lineWidth: width,
        dashStyle: dashStyle,
        marker: {
          enabled: false,
          symbol: "circle",
          radius: 1,
        },
        data: [],
        events: {
          legendItemClick: function (e) {
            e.preventDefault();
          },
        },
      };
    } else {
      return {
        includeInDataExport: includeInDataExport,
        name: name,
        color: color,
        type: "spline",
        showInLegend: showInLegend,
        lineWidth: width,
        dashStyle: dashStyle,
        marker: {
          enabled: false,
          symbol: "circle",
          radius: 1,
        },
        data: [],
        events: {
          legendItemClick: function (e) {
            e.preventDefault();
          },
        },
      };
    }
  }

  getColumnConsumptionSeries(
    name,
    color,
    showInLegend,
    includeInDataExport,
    yAxis = 0
  ) {
    return {
      includeInDataExport: includeInDataExport,
      name: name,
      type: "column",
      color: color,
      data: [],
      yAxis: yAxis,
      showInLegend: showInLegend,
      events: {
        legendItemClick: function (e) {
          e.preventDefault();
        },
      },
    };
  }

  getScatterSeries(name, color, showInLegend, includeInDataExport, markerSize) {
    return {
      type: "scatter",
      name: name,
      color: color,
      data: [],
      showInLegend: showInLegend,
      includeInDataExport: includeInDataExport,
      events: {
        legendItemClick: function (e) {
          e.preventDefault();
        },
      },
      marker: {
        radius: markerSize,
      },
    };
  }

  getAreaConsumptionSeries(name, color, showInLegend, includeInDataExport) {
    return {
      includeInDataExport: includeInDataExport,
      name: name,
      type: "area",
      color: color,
      data: [],
      step: "left",
      marker: {
        enabled: false,
        symbol: "circle",
        radius: 1,
      },
      showInLegend: showInLegend,
      events: {
        legendItemClick: function (e) {
          e.preventDefault();
        },
      },
    };
  }

  resizeCharts() {
    setTimeout(function () {
      Highcharts.charts.forEach(function (chart) {
        if (chart && chart.renderTo && chart.renderTo.clientHeight) {
          let newWidth = chart.renderTo.clientWidth;
          let newHeight = chart.renderTo.clientHeight;
          chart.update({
            chart: {
              width: newWidth,
              height: newHeight,
            },
          });
        }
      });
    }, 200);
  }
  checkHasData(series) {
    let hasData = false;
    if (series) {
      for (let i = 0; i < series.length; i++) {
        if (series[i].data && series[i].data.length > 0) {
          hasData = true;
          break;
        }
      }
    }
    return hasData;
  }
}

const chartUtils = new ChartUtils();
export default chartUtils;

import React, { Component } from "react";

import "./ManageMappings.scss";

import UserStore from "../../stores/userStore";
import OrganisationActions from "../../actions/organisationActions";
import OrganisationStore from "../../stores/organisationStore";
import MappingActions from "../../actions/mappingActions";
import MappingStore from "../../stores/mappingStore";
import PropertyActions from "../../actions/propertyActions";
import PropertyStore from "../../stores/propertyStore";
import EntityTypeActions from "../../actions/entityTypeActions";
import EntityTypeStore from "../../stores/entityTypeStore";
import DataSourceActions from "../../actions/dataSourceActions";
import DataSourceStore from "../../stores/dataSourceStore";
import ApplianceActions from "../../actions/applianceActions";
import ApplianceStore from "../../stores/applianceStore";

import { SearchSelect } from "../../components/SearchSelect";
import { ModifyMappingPanel } from "./ModifyMappingPanel";
import _ from "lodash";
import { v4 } from "uuid";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import GeneralUtils from "../../utils/GeneralUtils";
import { DocumentTitle } from "../../components/DocumentTitle";
import { Button, Typography } from 'antd';
import { Icon } from '../../components/Icon';

const { Title, Text } = Typography;

class ManageMappings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      buildings: OrganisationStore.getBuildings(),
      selected_building: null,
      entities: [],
      selected_entity: null,
      data_sources: [],
      selected_data_source: null,
      mappings: [],
      new_mappings: [],
      available_properties: [],
      all_properties: [],
      unvalidated_mappings: [],
      validated_mappings: [],
      saved_mappings: [],
      duplicated_mappings: [],
      appliance_points: [],
      filter: "",
    };
    // listener events
    this._onOrganisationChange = this._onOrganisationChange.bind(this);
    this._onEntityTypeFetch = this._onEntityTypeFetch.bind(this);
    this._onDataSourceFetch = this._onDataSourceFetch.bind(this);
    this._onMappingFetch = this._onMappingFetch.bind(this);
    this._onPropertyFetch = this._onPropertyFetch.bind(this);
    this._onGeneratedMapping = this._onGeneratedMapping.bind(this);
    this._onMappingOperation = this._onMappingOperation.bind(this);
    this._onAppliancePointsFetch = this._onAppliancePointsFetch.bind(this);

    // filter setters
    this._setTargetBuilding = this._setTargetBuilding.bind(this);
    this._setTargetEntity = this._setTargetEntity.bind(this);
    this._setTargetDataSource = this._setTargetDataSource.bind(this);

    //helpers and controllers
    this._addNewMapping = this._addNewMapping.bind(this);
    this._cancelNewMapping = this._cancelNewMapping.bind(this);
    this._changeMappingValues = this._changeMappingValues.bind(this);
    this._changeMappingApplianceValues =
      this._changeMappingApplianceValues.bind(this);
    this._validateMapping = this._validateMapping.bind(this);
    this._validateMappingInput = this._validateMappingInput.bind(this);
    this._setSearchInput = this._setSearchInput.bind(this);

    // Operation actions
    this._saveMappings = this._saveMappings.bind(this);
    this._deleteMapping = this._deleteMapping.bind(this);
  }

  UNSAFE_componentWillMount() {
    OrganisationStore.addChangeListener(this._onOrganisationChange);
    EntityTypeStore.addChangeListener(this._onEntityTypeFetch);
    DataSourceStore.addChangeListener(this._onDataSourceFetch);
    MappingStore.addChangeListener(this._onMappingFetch);
    MappingStore.addMappingListener(this._onGeneratedMapping);
    MappingStore.addMappingOperationListener(this._onMappingOperation);
    PropertyStore.addChangeListener(this._onPropertyFetch);
    ApplianceStore.addAppliancePointsListener(this._onAppliancePointsFetch);
  }

  componentWillUnmount() {
    OrganisationStore.removeChangeListener(this._onOrganisationChange);
    EntityTypeStore.removeChangeListener(this._onEntityTypeFetch);
    DataSourceStore.removeChangeListener(this._onDataSourceFetch);
    MappingStore.removeChangeListener(this._onMappingFetch);
    MappingStore.removeMappingListener(this._onGeneratedMapping);
    MappingStore.removeMappingOperationListener(this._onMappingOperation);
    PropertyStore.removeChangeListener(this._onPropertyFetch);
    ApplianceStore.removeAppliancePointsListener(this._onAppliancePointsFetch);
  }

  componentDidMount() {
    let current_user = UserStore.getUser();
    if (UserStore.isSuper()) {
      OrganisationActions.getOrganisations(true);
    } else if (current_user && current_user.fk_organisation_id) {
      OrganisationActions.getOrganisation(current_user.fk_organisation_id);
    }
  }

  _onMappingFetch() {
    const all_mappings = this.state.selected_data_source
      ? MappingStore.getMappingByDataSource()
      : MappingStore.getMappingByEntity();
    const saved_mappings = MappingStore.getSavedBulk();
    const succesfull_instance_numbers = saved_mappings.map(
      (map) => map.instance_number
    );
    const succesfull_property_ids = saved_mappings.map(
      (map) => map.property_id
    );

    const updated_new_mappings = this.state.new_mappings.filter((mapping) => {
      if (mapping.name) {
        return !succesfull_instance_numbers.includes(mapping.instance_number);
      } else {
        return !succesfull_property_ids.includes(mapping.property_id);
      }
    });

    this.setState({
      mappings: all_mappings,
      saved_mappings: saved_mappings,
      new_mappings: updated_new_mappings,
      validated_mappings: [],
      unvalidated_mappings: [],
    });
  }

  _onMappingOperation() {
    if (this.state.selected_data_source) {
      MappingActions.getMappingByDataSource(
        this.state.selected_data_source.data_source_id
      );
    } else if (this.state.selected_entity) {
      MappingActions.getMappingByEntity(
        this.state.selected_entity.entity_type_id
      );
    }
  }

  _onOrganisationChange() {
    this.setState({
      buildings: OrganisationStore.getBuildings(),
    });
  }

  _onEntityTypeFetch() {
    this.setState({
      entities: EntityTypeStore.getEntityTypes(),
    });
  }

  _onDataSourceFetch() {
    this.setState({
      data_sources: DataSourceStore.getDataSourceEntityMap(),
    });
  }

  _onPropertyFetch() {
    this.setState({
      all_properties: PropertyStore.getAllProperties(),
      available_properties: this.state.selected_data_source
        ? PropertyStore.getProperties()
        : PropertyStore.getPropertiesWithCompatibleDataSources(),
    });
  }

  _onGeneratedMapping() {
    this.setState({
      validated_mappings: MappingStore.getGeneratedMapping(),
    });
  }

  _onAppliancePointsFetch() {
    this.setState({
      appliance_points: ApplianceStore.getAppliancePoints(),
    });
  }

  _setTargetBuilding(selected_building) {
    EntityTypeActions.getBuildingEntityTypes(selected_building.building_id);
    PropertyActions.getAllProperties();

    this.setState({
      selected_building: selected_building,
      selected_entity: null,
      selected_data_source: null,
      data_sources: [],
      mappings: [],
      new_mappings: [],
    });
  }

  _setTargetEntity(selected_entity) {
    DataSourceActions.getDataSourceEntityMap(
      this.state.selected_building.building_id
    );
    MappingActions.getMappingByEntity(selected_entity.entity_type_id);

    this.setState({
      selected_entity: selected_entity,
      selected_data_source: null,
      mappings: [],
      new_mappings: [],
    });
  }

  _setTargetDataSource(selected_data_source) {
    this.setState({ available_properties: [], appliance_points: [] });
    if (selected_data_source.type === "APPLIANCE")
      ApplianceActions.getAppliancePoints(selected_data_source.data_source_id);
    PropertyActions.getAvailableProperties(
      this.state.selected_entity.entity_type_id,
      selected_data_source.data_source_id
    );
    MappingActions.getMappingByDataSource(selected_data_source.data_source_id);

    this.setState({
      selected_data_source: selected_data_source,
      mappings: [],
      new_mappings: [],
    });
  }

  noRowsAvailable(numRows) {
    let rows = [];
    for (let i = 0; i < numRows; i++) {
      rows.push(<td key={i} className="valign-middle"></td>);
    }
    return (
      <tr>
        <td className="valign-middle" colSpan={4}>
          <div className="d-flex align-items-center">
            <div>
              <div className="tx-inverse">
                {this.state.selected_building == null
                  ? "No mappings available, please select a target building."
                  : "No mappings available, please select the entity and data source."}
              </div>
            </div>
          </div>
        </td>
        {rows}
      </tr>
    );
  }

  _changeMappingValues(mapping, target_property, event) {
    let mappings = this.state.mappings;
    let new_mappings = [...this.state.new_mappings];

    const string_target_properties = ["name", "description"];
    let value = string_target_properties.includes(target_property)
      ? event.target.value
      : +event.target.value;
    if (mapping.mapping_id) {
      for (let i = 0; i < mappings.length; i++) {
        if (mappings[i].mapping_id === mapping.mapping_id) {
          mappings[i][target_property] = value;
          this.setState({ mappings: mappings });
          break;
        }
      }
    } else {
      for (let i = 0; i < new_mappings.length; i++) {
        if (new_mappings[i].uuid === mapping.uuid) {
          new_mappings[i][target_property] = value;
          this.setState({ new_mappings: new_mappings, validated_mappings: [] });
          break;
        }
      }
    }

    if (target_property === "data_source_id") {
      let available_data_sources = [];
      let data_source_type = null;
      for (let data_source in this.state.data_sources[
        this.state.selected_entity.type
      ]) {
        available_data_sources.push(
          ...this.state.data_sources[this.state.selected_entity.type][
          data_source
          ]
        );
        data_source_type =
          mapping.data_source_id &&
          available_data_sources.filter(
            (ds) => ds.data_source_id === mapping.data_source_id
          )[0].type;
      }
      if (data_source_type === "APPLIANCE")
        ApplianceActions.getAppliancePoints(+event.target.value);
    }
  }

  _changeMappingApplianceValues(mapping, point) {
    let modified_new_mappings = [...this.state.new_mappings];

    for (let i = 0; i < modified_new_mappings.length; i++) {
      if (modified_new_mappings[i].uuid === mapping.uuid) {
        modified_new_mappings[i] = {
          ...modified_new_mappings[i],
          name: point.name,
          description: point.description,
          unit: point.unit,
          instance_number: point.instance_number,
          ext_point_ref: point.ext_point_ref
        };

        this.setState({
          new_mappings: modified_new_mappings,
          validated_mappings: [],
        });
        break;
      }
    }
  }

  _saveMappings() {
    const mappings_bulk = this.state.validated_mappings.map((mapping) => {
      return {
        entity_type_id: mapping.entity_type_id,
        property_id: mapping.property_id,
        data_source_id: mapping.data_source_id,
        instance_number: mapping.instance_number,
        name: mapping.name,
        ext_point_ref: mapping.ext_point_ref,
        description: mapping.description,
        unit: mapping.unit,
        error: mapping.error,
        error_type: mapping.error_type,
        ts_start: mapping.ts_start,
        ts_end: mapping.ts_end,
      };
    });

    MappingActions.saveMappingBulk(mappings_bulk);
  }

  _deleteMapping(mapping_id) {
    if (
      window.confirm(
        "Are you sure you wish to delete this Mapping? All associated links will be destroyed"
      )
    ) {
      MappingActions.deleteMapping(mapping_id);
    }
  }

  _validateMappingInput(mapping) {
    let available_data_sources = [];
    let data_source_type = null;
    for (let data_source in this.state.data_sources[
      this.state.selected_entity.type
    ]) {
      available_data_sources.push(
        ...this.state.data_sources[this.state.selected_entity.type][data_source]
      );
    }
    data_source_type =
      mapping.data_source_id &&
      available_data_sources.filter(
        (ds) => ds.data_source_id === mapping.data_source_id
      )[0].type;

    // no data from mapping nor state
    if (data_source_type === null && this.state.selected_data_source === null) {
      return false;
    }

    if (
      this.state.selected_data_source === null &&
      data_source_type === "APPLIANCE"
    ) {
      // no data source selected + name in mapping = Appliance check
      const nameIsValid = GeneralUtils.stringNotEmpty(mapping.name);
      const unitIsValid = GeneralUtils.stringNotEmpty(mapping.unit);
      const instanceNumberIsValid = mapping.instance_number > -1;
      const propertyIdIsValid = mapping.property_id > 0;
      return (
        nameIsValid && instanceNumberIsValid && propertyIdIsValid && unitIsValid
      );
    } else if (
      this.state.selected_data_source === null &&
      (data_source_type === "SENSOR" || data_source_type === "COUNTER")
    ) {
      // no data source selected + property_id in mapping = sensor and counter check
      const propertyIdIsValid = mapping.property_id > 0;
      return propertyIdIsValid;
    } else if (
      this.state.selected_data_source &&
      this.state.selected_data_source.type === "APPLIANCE"
    ) {
      // data source selected - Appliance check
      const nameIsValid = GeneralUtils.stringNotEmpty(mapping.name);
      const unitIsValid = GeneralUtils.stringNotEmpty(mapping.unit);
      const instanceNumberIsValid = mapping.instance_number > -1;
      const propertyIdIsValid = mapping.property_id > 0;
      return (
        nameIsValid && instanceNumberIsValid && propertyIdIsValid && unitIsValid
      );
    } else if (
      this.state.selected_data_source &&
      (this.state.selected_data_source.type === "SENSOR" ||
        this.state.selected_data_source.type === "COUNTER")
    ) {
      // data source selected - sensor or counter check
      const propertyIdIsValid = mapping.property_id > 0;
      return propertyIdIsValid;
    }
  }

  _validateMapping() {
    this.setState({
      unvalidated_mappings: [],
      validated_mappings: [],
    });

    const new_mappings = this.state.new_mappings;
    const mapped_properties = new_mappings.map((mapping) => {
      return {
        property_id: mapping.property_id,
        instance_number: mapping.instance_number,
        unit: mapping.unit,
        name: mapping.name,
        description: mapping.description,
        data_source_id: mapping.data_source_id,
        uuid: mapping.uuid,
        ext_point_ref: mapping.ext_point_ref
      };
    });

    let all_fields_validated = true;
    mapped_properties.forEach((property) => {
      if (!this._validateMappingInput(property)) {
        all_fields_validated = false;
      }
    });

    // TODO
    // ONLY FOR APPLIANCES?? ASK BRIAN
    // if only for appliances make a check what data source type is it by the mapping.data_source_id

    // filtering duplicates with the function comparing variables
    const unique_mapped_properties = _.uniqWith(
      mapped_properties,
      (prop1, prop2) =>
        prop1.name === prop2.name &&
        prop1.description === prop2.description &&
        prop1.instance_number === prop2.instance_number &&
        prop1.data_source_id === prop2.data_source_id &&
        prop1.property_id === prop2.property_id
    );
    const difference_mapped_properties = _.difference(
      mapped_properties,
      unique_mapped_properties
    );
    if (difference_mapped_properties.length > 0)
      toast("Duplicated entry/entries found", {
        type: toast.TYPE.WARNING,
        autoClose: 3000,
        preventDuplicated: true,
      });

    this.setState({
      unvalidated_mappings: unique_mapped_properties,
      duplicated_mappings: difference_mapped_properties.map((dif) => dif.uuid),
    });

    if (all_fields_validated) {
      const mapping_validation_dto = {
        entity_type_id: new_mappings[0].entity_type_id,
        data_source_id: new_mappings[0].data_source_id,
        mapped_properties: unique_mapped_properties,
      };

      MappingActions.generateMapping(mapping_validation_dto);
    } else {
      toast("Please correct the necessary fields", {
        type: toast.TYPE.WARNING,
        autoClose: 3000,
        preventDuplicated: true,
      });
    }
  }

  _addNewMapping() {
    let all_data_sources_ids = [];
    const available_data_sources =
      this.state.data_sources[this.state.selected_entity.type];
    for (let data_source in available_data_sources) {
      all_data_sources_ids.push(...available_data_sources[data_source]);
    }

    let data_source_id = null;
    let locked_data_source = false;

    if (this.state.selected_data_source) {
      // Data source selected
      data_source_id = this.state.selected_data_source.data_source_id;
    } else if (
      this.state.selected_data_source === null &&
      this.state.new_mappings.length > 0 &&
      this.state.new_mappings[0].data_source_id
    ) {
      // Data source NOT selected but there is already
      data_source_id = this.state.new_mappings[0].data_source_id;
      locked_data_source = true;
    } else {
      data_source_id = null;
    }

    all_data_sources_ids = all_data_sources_ids.map(
      (data_source) => data_source.data_source_id
    );
    PropertyActions.getAvailablePropertiesForEntity(
      this.state.selected_entity.entity_type_id,
      all_data_sources_ids
    );

    const new_mapping = {
      uuid: v4(),
      entity_type_id: this.state.selected_entity.entity_type_id,
      property_id: 0,
      data_source_id: data_source_id,
      instance_number: 0,
      name: "",
      description: "",
      unit: "",
      locked_data_source: locked_data_source,
    };

    if (
      this.state.selected_data_source === null &&
      this.state.new_mappings.length === 0
    ) {
      // no data source selected, first new mapping
      this.setState({
        new_mappings: [new_mapping, ...this.state.new_mappings],
        validated_mappings: [],
      });
    } else if (
      this.state.selected_data_source === null &&
      this.state.new_mappings.length > 0 &&
      data_source_id
    ) {
      // no data source selected, another mapping, locking all previous mappings data sources
      this.setState({
        new_mappings: [
          new_mapping,
          ...this.state.new_mappings.map((el) => {
            return {
              ...el,
              locked_data_source: true,
            };
          }),
        ],
        validated_mappings: [],
      });
    } else if (
      this.state.selected_data_source === null &&
      this.state.new_mappings.length > 0 &&
      !data_source_id
    ) {
      // no data source selected, data source not selected in first new mapping, blocking the user with the creation of new mappings
      toast(
        "Please select a data source in your new mapping before adding more",
        { type: toast.TYPE.WARNING, autoClose: 3000, preventDuplicated: true }
      );
    } else {
      // when data source is selected, proceed as normal
      this.setState({
        new_mappings: [new_mapping, ...this.state.new_mappings],
        validated_mappings: [],
      });
    }
  }

  _cancelNewMapping(mapping) {
    this.setState({
      new_mappings: this.state.new_mappings.filter(
        (el) => el.uuid !== mapping.uuid
      ),
      unvalidated_mappings: this.state.unvalidated_mappings.filter(
        (el) => el.uuid !== mapping.uuid
      ),
      validated_mappings: this.state.duplicated_mappings.includes(mapping.uuid)
        ? this.state.validated_mappings
        : [], // allows for removal of duplicates only, without clearing previous validations
    });
  }

  _setSearchInput(e) {
    this.setState({
      filter: e.target.value.toLowerCase(),
    });
  }

  _getInputFilterAndLegend() {
    return (
      <div className="d-flex justify-content-between flex-column flex-sm-row">
        <div className="col-12 col-sm-6 col-xl-5 my-2">
          <div className="input-group">
            <input
              type="text"
              className="form-control"
              placeholder="Filter By Name, Description, Instance Number and Properties"
              onChange={this._setSearchInput}
              value={this.state.filter}
            />
          </div>
        </div>
        <div className="col-12 col-sm-6 col-xl-5 my-2 align-items-center justify-content-around justify-content-md-end d-flex">
          <div className="btn btn-icon pd-2 mg-r-20 tx-11 hidden-md-down">
            Status:{" "}
          </div>
          <div className="btn btn-icon pd-2 tx-11 mg-l-20 mg-r-10">
            <Icon
              name='Refresh'
              color='red'
              size={16}
              style={{ verticalAlign: "sub", marginRight: "5px" }}
            />
            Duplicated
          </div>
          <div className="btn btn-icon pd-2 tx-11">
            <Icon
              name='Circle'
              color='orange'
              size={16}
              style={{ verticalAlign: "sub", marginRight: "5px" }}
            />
            Invalid
          </div>
          <div className="btn btn-icon pd-2 tx-11 mg-r-20 mg-l-10">
            <Icon
              name='RadioOn'
              color='green'
              size={16}
              style={{ verticalAlign: "sub", marginRight: "5px" }}
            />
            Valid
          </div>
          <div className="btn btn-icon pd-2 tx-11">
            <Icon
              name='CheckCircleOutline'
              color='green'
              size={16}
              style={{ verticalAlign: "sub", marginRight: "5px" }}
            />
            Saved
          </div>
        </div>
      </div>
    );
  }

  getMappingTable() {
    let mappingRows = this.noRowsAvailable(2);
    let newMappingRows = null;
    let mappings = _.orderBy(this.state.mappings, "mapping_id", "desc");
    let new_mappings = this.state.new_mappings;
    let saveButton = (
      <Button
        className="button green"
        icon={<Icon name="AddCircleFilled" color={'#fff'} size={18} />}
        size="small"
        onClick={this._addNewMapping}
        style={{ marginLeft: '20px' }}
        disabled={
          !(this.state.selected_building && this.state.selected_entity) ||
          this.state.selected_entity.type === "BUILDING"
        }
      >Add Mapping</Button>
    );
    let validateMappingButton = (
      <Button
        className="button blue"
        icon={<Icon name="Validated" color={'#fff'} size={18} />}
        size="small"
        onClick={this._validateMapping}
        style={{ marginLeft: '20px' }}
      >Validate Mappings</Button>
    );
    let saveMappingButton = (
      <Button
        className="button green"
        icon={<Icon name="Save" color={'#fff'} size={18} />}
        size="small"
        onClick={this._saveMappings}
        style={{ marginLeft: '20px' }}
      >Save Mappings</Button>
    );

    const floatingButtons = (
      <div className="sensor-button-wrapper">
        {this.state.validated_mappings.length ===
          this.state.unvalidated_mappings.length &&
          this.state.validated_mappings.length > 0 &&
          saveMappingButton}
        {this.state.new_mappings.length > 0 && validateMappingButton}
        {saveButton}
      </div>
    );

    let buildingsFormattedArray = this.state.buildings.map((building) => {
      return {
        ...building,
        name: building.name + ` (ID: ${building.building_id})`,
      };
    });

    let entityFormattedArray = this.state.entities
      .filter((entity) => entity.type !== "BUILDING")
      .map((entity) => {
        return { ...entity, name: entity.name + ` (${entity.type})` };
      });

    let dataSourceFormattedArray = [];
    const type = this.state.selected_entity && this.state.selected_entity.type;
    for (var key in this.state.data_sources[type]) {
      if (this.state.data_sources[type].hasOwnProperty(key)) {
        dataSourceFormattedArray = [
          ...this.state.data_sources[type][key],
          ...dataSourceFormattedArray,
        ];
      }
    }

    buildingsFormattedArray = _.orderBy(buildingsFormattedArray, "name", "asc");
    entityFormattedArray = _.orderBy(entityFormattedArray, "name", "asc");
    dataSourceFormattedArray = _.orderBy(dataSourceFormattedArray, "name", "asc");

    const getValidatedStatus = (mapping) => {
      if (
        this.state.unvalidated_mappings.length ===
        this.state.validated_mappings.length &&
        this.state.unvalidated_mappings.length > 0 &&
        this.state.validated_mappings.length > 0
      ) {
        return true;
      } else {
        return false;
      }
    };

    // NEW MAPPINGS

    if (new_mappings.length > 0) {
      newMappingRows = new_mappings.map((mapping) => {
        return (
          <ModifyMappingPanel
            // passed data
            mapping={mapping}
            key={mapping.uuid}
            available_properties={this.state.available_properties}
            all_properties={this.state.all_properties}
            selected_data_source_type={
              this.state.selected_data_source
                ? this.state.selected_data_source.type
                : null
            }
            selected_entity_type={
              this.state.selected_entity && this.state.selected_entity.type
            }
            appliance_unique_points={this.state.appliance_points}
            data_sources={this.state.data_sources}
            // handlers
            changeMappingValues={this._changeMappingValues}
            changeMappingApplianceValues={this._changeMappingApplianceValues}
            saveMapping={this._saveMappings}
            deleteMapping={this._deleteMapping}
            cancelNewMapping={this._cancelNewMapping}
            //status updates
            duplicated_mapping={this.state.duplicated_mappings.includes(
              mapping.uuid
            )}
            validated_mapping={getValidatedStatus(mapping)}
            saved_mapping={
              this.state.saved_mappings.filter(
                (el) => el.mapping_id === mapping.mapping_id
              ).length > 0
            }
          />
        );
      });
    }

    // EXISTING MAPPINGS

    if (mappings.length > 0) {
      mappingRows = mappings.map((mapping) => {
        const filter = this.state.filter;

        const mappingMatchesDataSourceID = this.state.selected_data_source
          ? mapping.data_source_id ===
          this.state.selected_data_source.data_source_id
          : true;
        const mappingMatchesEntityID =
          mapping.entity_type_id === this.state.selected_entity.entity_type_id;

        const filteredProperties = this.state.available_properties.map(
          (mapping) => {
            if (
              mapping.name.toLowerCase().includes(filter) ||
              mapping.title.toLowerCase().includes(filter) ||
              mapping.code.toLowerCase().includes(filter)
            ) {
              return mapping.property_id;
            }
            return null;
          }
        );

        const filterMatchesName = mapping.name.toLowerCase().includes(filter);
        const filterMatchesDescription = mapping.description
          .toLowerCase()
          .includes(filter);
        const filterMatchesInstanceNumber = mapping.instance_number
          .toString()
          .toLowerCase()
          .includes(filter);
        const filterMatchesPropertyId = mapping.property_id
          .toString()
          .toLowerCase()
          .includes(filter);
        const filterMatchesPropertyValues = filteredProperties.includes(
          mapping.property_id
        );

        if (
          mapping.mapping_id &&
          mappingMatchesEntityID &&
          mappingMatchesDataSourceID &&
          (filterMatchesName ||
            filterMatchesDescription ||
            filterMatchesInstanceNumber ||
            filterMatchesPropertyId ||
            filterMatchesPropertyValues)
        ) {
          return (
            <ModifyMappingPanel
              // passed data
              mapping={mapping}
              key={mapping.mapping_id}
              available_properties={this.state.available_properties}
              all_properties={this.state.all_properties}
              selected_data_source_type={
                this.state.selected_data_source
                  ? this.state.selected_data_source.type
                  : null
              }
              selected_entity_type={
                this.state.selected_entity && this.state.selected_entity.type
              }
              appliance_unique_points={this.state.appliance_points}
              data_sources={this.state.data_sources}
              // handlers
              setDataSource={this._setTargetDataSource}
              saveMapping={this._saveMappings}
              deleteMapping={this._deleteMapping}
              changeMappingValues={this._changeMappingValues}
              changeMappingApplianceValues={this._changeMappingApplianceValues}
              // status
              saved_mapping={
                this.state.saved_mappings.filter(
                  (el) => el.mapping_id === mapping.mapping_id
                ).length > 0
              }
            />
          );
        } else {
          return null;
        }
      });
    }

    const entryRequiredAsterisk = <span style={{ color: "red" }}>*</span>;

    return (
      <div>
        <div
          className="br-pagetitle pd-x-0 pd-b-0 mg-b-10 flex-wrap"
          style={{ width: "100%" }}
        >
          <span className="col-12 d-flex pd-0">
            <span className="col-4 pd-l-0">
              <SearchSelect
                limit={100}
                options={buildingsFormattedArray}
                placeholder={"Select Building"}
                defaultValue={this.state.selected_building}
                actionOnSelectedOption={this._setTargetBuilding}
              />
            </span>
            <span className="col-4 pd-x-0">
              <SearchSelect
                limit={100}
                options={entityFormattedArray}
                placeholder={"Select Entity"}
                defaultValue={this.state.selected_entity}
                actionOnSelectedOption={this._setTargetEntity}
              />
            </span>
            <span className="col-4 pd-r-0">
              <SearchSelect
                limit={100}
                options={dataSourceFormattedArray}
                placeholder={"Select Data Source"}
                defaultValue={this.state.selected_data_source}
                actionOnSelectedOption={this._setTargetDataSource}
              />
            </span>
          </span>
        </div>
        <div className="card bd-0 shadow-base mg-t-5">
          {this._getInputFilterAndLegend()}
          <table className="table mg-b-0 table-contact">
            <thead>
              <tr>
                <th className="wd-25p tx-10-force tx-mont tx-medium tx-center">
                  Property{entryRequiredAsterisk}
                </th>
                <th className="wd-20p tx-10-force tx-mont tx-medium tx-center">
                  Data Source{entryRequiredAsterisk}
                </th>
                <th className="wd-25p tx-10-force tx-mont tx-medium tx-center">
                  Name/Point{entryRequiredAsterisk}
                </th>
                <th className="wd-5p tx-10-force tx-mont tx-medium tx-center">
                  Instance{entryRequiredAsterisk}
                </th>
                <th className="wd-20p tx-10-force tx-mont tx-medium tx-center">
                  Description
                </th>
                <th className="wd-5p tx-10-force tx-mont tx-medium tx-center"></th>
              </tr>
            </thead>
            <tbody>
              {this.state.available_properties.length > 0 && newMappingRows}
              {mappingRows}
            </tbody>
          </table>
          {floatingButtons}
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="br-mainpanel br-profile-page floorplan-background">
        <DocumentTitle title="Manage Mappings" />
        <div className="br-container">
          <div className="row">
            <div className="col-12 mg-t-30">
              <Title level={3}>Update and Create Mappings</Title>
              <Text type="secondary">
                Update and Create new mappings. Select the building, entity and data source to continue.
              </Text>
            </div>
            <div className="col-12">{this.getMappingTable()}</div>
          </div>
        </div>
      </div>
    );
  }
}

export default ManageMappings;

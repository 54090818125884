import ActionTypes from "../constants";
import GeneralUtils from "../utils/GeneralUtils";

/**
 * Actions related to fetching HVAC details. This class interfaces with the API
 * to retrieve asset data and dispatches actions to update the application state.
 *
 * @class
 * @example
 * const hvacDetailsActions = new HvacDetailsActions();
 * hvacDetailsActions.getAsset('asset123');
 */
class HvacDetailsActions {
  /**
   * Fetches the details of a specific asset by its ID from the API and dispatches an action to store this data.
   *
   * @param {string} assetId - The unique identifier of the asset.
   * @returns {Promise} - A promise that resolves with the fetched data.
   */
  getAsset(assetId) {
    return GeneralUtils.get({
      url: `asset/${assetId}`,
      actionType: ActionTypes.HVAC_DETAILS_INFO_FETCHED,
    });
  }

  /**
   * Fetches asset data within a specified timeframe and bucket size from the API
   * and dispatches an action to store this data in the charts.
   *
   * @param {string} assetId - The unique identifier of the asset.
   * @param {Timeframe} timeframe - A Timeframe object containing start and end dates for the data range.
   * @param {string} bucket - The time bucket size for data aggregation (e.g., '15min', 'hour', 'day').
   * @returns {Promise} - A promise that resolves with the fetched data.
   */
  getAssetDetails(assetId, timeframe, bucket) {
    const queryParams = GeneralUtils.toIntervalQueryParameterString(timeframe);

    return GeneralUtils.get({
      url: `v2/assets/${assetId}/data?bucket=${bucket}&${queryParams}`,
      actionType: ActionTypes.HVAC_DETAILS_CHARTS_FETCHED,
    });
  }
}

const hvacDetailsActions = new HvacDetailsActions();
export default hvacDetailsActions;

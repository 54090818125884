import React from "react";
import "./Input.scss";
import PropTypes from "prop-types";

import { Icon } from "../Icon";

const Input = (props) => {
  const {
    label,
    value,
    onChangeHandler,
    isRequired,
    style,
    placeholder,
    prefix,
    suffix,
    iconPrefix,
    iconSuffix,
    paddingLeft,
    paddingRight,
    type = "text",
    disabled,
    min,
  } = props;

  const getPrefix = () => {
    if (prefix) {
      return <div className="prefix">{prefix}</div>;
    } else if (iconPrefix) {
      return (
        <div className="prefix">
          <Icon color='#868ba1' name={iconPrefix} />
        </div>
      );
    }

    return null;
  };

  const getSuffix = () => {
    if (suffix) {
      return <div className="suffix">{suffix}</div>;
    } else if (iconSuffix) {
      return (
        <div className="suffix">
          <Icon color='#868ba1' name={iconSuffix} />
        </div>
      );
    }

    return null;
  };

  return (
    <div className="Input" style={style}>
      {label && (
        <div className="label">
          {label}{" "}
          {isRequired && <span className="field-status">(required)</span>}
        </div>
      )}
      {getPrefix()}

      <input
        className="dedicated-input"
        disabled={disabled}
        placeholder={placeholder}
        value={value}
        type={type}
        min={min}
        onChange={(e) => onChangeHandler(e.target.value)}
        style={{
          paddingLeft: paddingLeft
            ? paddingLeft
            : prefix || iconPrefix
              ? "38px"
              : "11px",
          paddingRight: paddingRight
            ? paddingRight
            : suffix || iconSuffix
              ? "38px"
              : "11px",
        }}
      />
      {getSuffix()}
    </div>
  );
};

export default Input;

Input.propTypes = {
  label: PropTypes.string,
  value: PropTypes.any,
  onChangeHandler: PropTypes.func,
  isRequired: PropTypes.bool,
  style: PropTypes.object,
  placeholder: PropTypes.string,
  prefix: PropTypes.string,
  suffix: PropTypes.string,
  iconPrefix: PropTypes.string,
  iconSuffix: PropTypes.string,
  type: PropTypes.string,
};

import React, { useState, useEffect } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import decode from "jwt-decode";
import Constants from "./constants";
import moment from "moment";
import UserStore from "./stores/userStore";
import { ConfigProvider } from 'antd';
import theme from './scss/antdTheme.js'

import { NotFound } from "./views/NotFound";
import { Maintenance } from "./views/Maintenance";
import { EnergyReportDetail } from "./views/Reports/EnergyReport/EnergyReportDetail";
import { EnergyClassificationReport } from "./views/Reports/EnergyClassificationReport";
import { ManageBuildings } from "./views/ManageBuildings";
import { AddBuilding } from "./views/ManageBuildings/AddBuilding";
import { EditBuilding } from "./views/ManageBuildings/EditBuilding";
import { ManageUsers } from "./views/ManageUsers";
import { InventoryProcessing } from "./views/InventoryProcessing";
import { MappingAndValidation } from "./views/MappingAndValidation";
import { AssessmentExecution } from "./views/AssessmentExecution";
import { ManageCounters } from "./views/ManageCounters";
import { ManageAssets } from "./views/ManageAssets";
import { ManageZones } from "./views/ManageZones";
import { ManageMeters } from "./views/ManageMeters";
import { ManageAppliances } from "./views/ManageAppliances";
import { ManageDoorways } from "./views/ManageDoorways";
import { ManageMappings } from "./views/ManageMappings";
import { ManagePassword } from "./views/ManagePassword";
import { ChangeOrganisation } from "./views/ChangeOrganisation";
import { ManageSensors } from "./views/ManageSensors";
import { ChangeRequest } from "./views/ChangeRequest";
import { InviteActivationLogin } from "./views/InviteActivationLogin";
import { ManagePasswordNoLogin } from "./views/ManagePasswordNoLogin";
import { ManageNotifications } from "./views/ManageNotifications";
import { SensorListing } from "./views/SensorListing";
import { MetersExplorer } from "./views/MetersExplorer";
import { DataExplorer } from "./views/DataExplorer";
import { DataExporter } from "./views/DataExporter";

//Dashboards
import { HealthCheck } from "./views/Dashboards/HealthCheck";
import { SafetyDashboard } from "./views/Dashboards/SafetyDashboard"; // Old
import { OccupancyStatusDashboard } from "./views/Dashboards/OccupancyStatusDashboard"; // New
import { LiveConditionsDashboard } from "./views/Dashboards/LiveConditionsDashboard"; // Old
import { BuildingConditionsDashboard } from "./views/Dashboards/BuildingConditionsDashboard"; // New
import { QRZoneView } from "./views/Dashboards/QRZoneView"; // Old
import { ZoneConditionsDashboard } from "./views/Dashboards/ZoneConditionsDashboard"; // New
import { MeterUsageDashboard } from "./views/Dashboards/MeterUsageDashboard"; // Old
import { MetersUsageDashboard } from "./views/Dashboards/MetersUsageDashboard"; // New
import { EnergyLeaderboardDashboard } from "./views/Dashboards/EnergyLeaderboardDashboard";


// Analysis
import { ConsumptionAnalysis } from "./views/Analysis/ConsumptionAnalysis";
import { BuildingBenchmark } from "./views/Analysis/BuildingBenchmark";
import { EnergyPerformanceReport } from "./views/Analysis/EnergyPerformanceReport";
import { IndoorConditions } from "./views/Analysis/IndoorConditions";


//Overviews
import { Zones } from "./views/Overviews/Zones";
import { Meters } from "./views/Overviews/Meters";
import { Hvac } from "./views/Overviews/Hvac";
import { Dashboards } from "./views/Dashboards";
import { Analysis } from "./views/Analysis";
import { Alarms as Notifications } from "./views/Alarms";

// Details
import { Zone } from "./views/DetailedViews/Zone";
import { Meter } from "./views/DetailedViews/Meter";
import { Asset } from "./views/DetailedViews/Asset";

// Admin & Debug
import { ApplianceLogDebug } from "./views/ApplianceLogDebug";
import { MappedPointDebug } from "./views/MappedPointDebug";
import { MeterDataUpload } from "./views/MeterDataUpload";

import { Login } from "./views/Login";
import { SignificantEnergyUsersReport } from "./views/SignificantEnergyUsersReport";
import { PasswordReset } from "./views/PasswordReset";
import { Header } from "./frame/Header";
import { HeaderPublic } from "./frame/HeaderPublic";
import { Leftpanel } from "./frame/Leftpanel";
import _ from "lodash";

export const PrivateRoute = ({
  dateSelector = false,
  ...props
}) => {
  let isLoggedIn = UserStore.loggedIn();
  if (isLoggedIn) {
    if (props.path === "/health") {
      return (
        <div>
          <HeaderPublic {...props} />
          <Route {...props} />
        </div>
      );
    } else if (props.path.includes("significant-energy-users")) {
      return (
        <div>
          <Route {...props} />
        </div>
      );
    } else {
      return (
        <div>
          <Header
            dateSelector={dateSelector}
            {...props}
          />
          <Leftpanel {...props} />
          <Route {...props} />
        </div>
      );
    }
  } else {
    if (props.location !== null && props.location.pathname !== null) {
      let referrer = props.location.search
        ? props.location.pathname + props.location.search
        : props.location.pathname;
      return (
        <Redirect
          to={{
            pathname: "/login",
            state: { referrer: referrer },
          }}
        />
      );
    } else {
      return (
        <Redirect
          to={{
            pathname: "/login",
            state: { referrer: "/home" },
          }}
        />
      );
    }
  }
};

export const ReportRoute = ({ ...props }) => {
  if (props.path.includes("energy-classification-report")) {
    let token = props.computedMatch.params.token;
    return (
      <div>
        <HeaderPublic {...props} />
        <br />
        <div className="mg-t-80 mg-b-20">
          <EnergyClassificationReport {...props} token={token} />
        </div>
      </div>
    );
  }

  if (
    props.computedMatch &&
    props.computedMatch.params &&
    props.computedMatch.params.token
  ) {
    if (
      props.computedMatch.params.token.indexOf("buiJhbGciOJZI5cC6IkpXVCLD") > -1
    ) {
      try {
        let token = props.computedMatch.params.token;
        let build_id_str = token.split("buiJhbGciOJZI5cC6IkpXVCLD")[1];
        let building_id = build_id_str.substring(0, build_id_str.length - 5);
        return (
          <LiveConditionsDashboard
            {...props}
            building_id={building_id}
            token={token}
          />
        );
      } catch (err) {
        return <Redirect to="/notfound" />;
      }
    } else if (
      props.computedMatch.params.token.indexOf("enrlediJh5bucC6IkpdhsBNO") > -1
    ) {
      try {
        let token = props.computedMatch.params.token;
        let estate_id_str = token.split("enrlediJh5bucC6IkpdhsBNO")[1];
        let estate_id = estate_id_str.substring(0, estate_id_str.length - 5);
        return (
          <EnergyLeaderboardDashboard
            {...props}
            estate_id={estate_id}
            token={token}
          />
        );
      } catch (err) {
        return <Redirect to="/notfound" />;
      }
    } else if (
      props.computedMatch.params.token.indexOf("useiJhJZI5bucC6Ikpdhsage") > -1
    ) {
      try {
        let token = props.computedMatch.params.token;
        let meter_id_str = token.split("useiJhJZI5bucC6Ikpdhsage")[1];
        let meter_id = meter_id_str.substring(0, meter_id_str.length - 5);
        return (
          <MeterUsageDashboard {...props} meter_id={meter_id} token={token} />
        );
      } catch (err) {
        return <Redirect to="/notfound" />;
      }
    } else {
      try {
        let decoded_token = decode(props.computedMatch.params.token);
        if (decoded_token.type === "zone_kpi") {
          try {
            let token = props.computedMatch.params.token;
            return <QRZoneView {...props} token={token} />;
          } catch (err) {
            return <Redirect to="/notfound" />;
          }
        } else {
          let token_contents =
            decoded_token.shareToken && decoded_token.shareToken.length > 0
              ? JSON.parse(decoded_token.shareToken)
              : {};
          if (
            token_contents.verificationCode ===
            Constants.REPORT_VERIFICATION_CODE
          ) {
            let ts_start = moment(token_contents.tsStart).unix();
            let ts_end = moment(token_contents.tsStart).unix();
            if (token_contents.reportType === "energy") {
              return (
                <div>
                  <HeaderPublic {...props} />
                  <br />
                  <div className="mg-t-80 pd-l-35 pd-r-35 pd-b-35">
                    <EnergyReportDetail
                      {...props}
                      ts_start={ts_start}
                      ts_end={ts_end}
                      building_id={token_contents.buildingId}
                      token={props.computedMatch.params.token}
                    />
                  </div>
                </div>
              );
            }
          }
          return <Redirect to="/notfound" />;
        }
      } catch (err) {
        return <Redirect to="/notfound" />;
      }
    }
  }
};

export const AdminRoute = ({ ...props }) => {
  let isLoggedIn = UserStore.loggedIn();
  let isAdmin = UserStore.isAdmin() || UserStore.isSuper();
  if (isLoggedIn && isAdmin) {
    return (
      <div>
        <Header {...props} />
        <Leftpanel {...props} />
        <Route {...props} />
      </div>
    );
  } else {
    return <Redirect to="/home" />;
  }
};

export const SuperRoute = ({ ...props }) => {
  let isLoggedIn = UserStore.loggedIn();
  let isSuper = UserStore.isSuper();
  if (isLoggedIn && isSuper) {
    if (props.path === "/health") {
      return (
        <div>
          <HeaderPublic {...props} />
          <Route {...props} />
        </div>
      );
    } else {
      return (
        <div>
          <Header {...props} />
          <Leftpanel {...props} />
          <Route {...props} />
        </div>
      );
    }
  } else {
    return <Redirect to="/home" />;
  }
};

export const PublicRoute = ({ ...props }) => {
  return (
    <div>
      {props.header && <HeaderPublic {...props} />}
      <Route {...props} />
    </div>
  );
};

export const ConditionalRoute = ({ condition, redirectConditionFunction, componentTrue, componentFalse, ...rest }) => {

  const referrer = rest.location.search
    ? rest.location.pathname + rest.location.search
    : rest.location.pathname;

  if (redirectConditionFunction(rest)) return <Redirect to={{ pathname: "/login", state: { referrer: referrer } }} />


  if (condition) return componentTrue;
  return componentFalse;
}

export const Routes = (props) => {
  const [loggedIn, setLoggedIn] = useState(UserStore.loggedIn());

  const { active, message, countdownActive, timeFormat, timeTillDate } =
    props.maintenanceData;

  useEffect(() => {
    const handleUserStoreChange = () => {
      setLoggedIn(UserStore.loggedIn());
    };

    UserStore.addChangeListener(handleUserStoreChange);
    return () => {
      UserStore.removeChangeListener(handleUserStoreChange);
    };
  }, []);

  if (active) {
    return (
      <Maintenance
        message={message}
        countdownActive={countdownActive}
        timeFormat={timeFormat}
        timeTillDate={timeTillDate}
      />
    );
  }

  return (
    <ConfigProvider theme={theme}>
      <Switch>
        <ReportRoute
          path="/energy-report/:token"
          component={EnergyReportDetail}
        />
        <ReportRoute
          path="/live-utilization-report/:token"
          component={SafetyDashboard}
        />
        <ReportRoute
          path="/live-conditions-report/:token"
          component={LiveConditionsDashboard}
        />
        <ReportRoute path="/qr-dashboard/:token" component={QRZoneView} />
        <ReportRoute
          path="/energy-leaderboard-dashboard/:token"
          component={EnergyLeaderboardDashboard}
        />
        <ReportRoute
          path="/meter-dashboard/:token"
          component={MeterUsageDashboard}
        />
        <ReportRoute
          path="/energy-classification-report/:token"
          component={EnergyClassificationReport}
        />
        <PrivateRoute
          exact
          path="/manage-password"
          component={ManagePassword}
        />
        <AdminRoute
          exact
          path="/manage-notifications"
          component={ManageNotifications}
        />
        <SuperRoute exact path="/sensor-listing" component={SensorListing} />
        <SuperRoute
          exact
          path="/change-organisation"
          component={ChangeOrganisation}
        />
        <AdminRoute exact path="/manage-users" component={ManageUsers} />
        <AdminRoute
          exact
          path="/manage-buildings"
          component={ManageBuildings}
        />
        <AdminRoute
          exact
          path="/manage-buildings/add/"
          component={AddBuilding}
        />
        <AdminRoute
          exact
          path="/manage-buildings/:building_id"
          component={EditBuilding}
        />
        <SuperRoute
          exact
          path="/inventory-processing"
          component={InventoryProcessing}
        />
        <SuperRoute
          exact
          path="/mapping-and-validation"
          component={MappingAndValidation}
        />
        <SuperRoute
          exact
          path="/assessment-execution"
          component={AssessmentExecution}
        />
        <AdminRoute exact path="/manage-zones" component={ManageZones} />
        <SuperRoute exact path="/manage-assets" component={ManageAssets} />
        <SuperRoute exact path="/manage-meters" component={ManageMeters} />
        <SuperRoute exact path="/manage-doorways" component={ManageDoorways} />
        <SuperRoute exact path="/manage-counters" component={ManageCounters} />
        <SuperRoute
          exact
          path="/manage-appliances"
          component={ManageAppliances}
        />
        <SuperRoute exact path="/manage-sensors" component={ManageSensors} />
        <SuperRoute exact path="/manage-mapping" component={ManageMappings} />
        <AdminRoute exact path="/change-request" component={ChangeRequest} />
        <PrivateRoute
          path="/estate-energy-report/building/:building_id"
          component={EnergyReportDetail}
        />
        <PrivateRoute path="/data-exporter" component={DataExporter} />
        <PrivateRoute path="/meters-explorer" component={MetersExplorer} />
        <ConditionalRoute
          path="/data-explorer/:token?"
          condition={loggedIn}
          redirectConditionFunction={prs => {
            return !loggedIn && !_.get(prs, 'computedMatch.params.token')
          }}
          componentTrue={<PrivateRoute path="/data-explorer/:token?" component={DataExplorer} />}
          componentFalse={<PublicRoute header path="/data-explorer/:token?" component={DataExplorer} />}
        />

        <PrivateRoute exact path="/app">
          <Redirect to="/home" />
        </PrivateRoute>
        <PrivateRoute exact path="/dev">
          <Redirect to="/home" />
        </PrivateRoute>
        <PrivateRoute exact path="/">
          <Redirect to="/home" />
        </PrivateRoute>
        <Route exact path="/login" component={Login} />
        <Route exact path="/reset-password" component={PasswordReset} />
        <Route exact path="/notfound" component={NotFound} />
        <Route
          path="/reset-password/:user_id/:token"
          component={ManagePasswordNoLogin}
        />
        <Route
          path="/invite-authentication/:user_id/:token"
          component={InviteActivationLogin}
        />
        <PrivateRoute
          path="/report/significant-energy-users/organisation/:organisation_id/"
          component={SignificantEnergyUsersReport}
        />

        <Route exact path="/login/:refference?" component={Login} />

        <PrivateRoute exact path="/zones" component={Zones} dateSelector />
        <PrivateRoute exact path="/meters" component={Meters} dateSelector />
        <PrivateRoute exact path="/hvac" component={Hvac} />
        <PrivateRoute exact path="/notifications" component={Notifications} />
        <PrivateRoute path="/zones/:id" component={Zone} dateSelector />
        <PrivateRoute path="/meters/:id" component={Meter} dateSelector />
        <PrivateRoute path="/hvac/:id" component={Asset} dateSelector />

        <PrivateRoute exact path="/home" component={Dashboards} />
        <PrivateRoute exact path="/dashboards" component={Dashboards} />
        <PublicRoute
          path="/dashboards/meter-usage/:token"
          component={MetersUsageDashboard}
        />
        <PublicRoute
          path="/dashboards/building-conditions/:token"
          component={BuildingConditionsDashboard}
        />
        <PublicRoute
          path="/dashboards/zone-conditions/:token"
          component={ZoneConditionsDashboard}
        />
        <PublicRoute
          path="/dashboards/occupancy-status/:token"
          component={OccupancyStatusDashboard}
        />

        <PrivateRoute exact path="/analysis" component={Analysis} />

        <ConditionalRoute
          path="/analysis/consumption-analysis/:token?"
          condition={loggedIn}
          redirectConditionFunction={prs => {
            return !loggedIn && !_.get(prs, 'computedMatch.params.token')
          }}
          componentTrue={<PrivateRoute path="/analysis/consumption-analysis/:token?" component={ConsumptionAnalysis} />}
          componentFalse={<PublicRoute header path="/analysis/consumption-analysis/:token?" component={ConsumptionAnalysis} />}
        />

        <ConditionalRoute
          path="/analysis/building-benchmark/:token?"
          condition={loggedIn}
          redirectConditionFunction={prs => {
            return !loggedIn && !_.get(prs, 'computedMatch.params.token')
          }}
          componentTrue={<PrivateRoute path="/analysis/building-benchmark/:token?" component={BuildingBenchmark} />}
          componentFalse={<PublicRoute header path="/analysis/building-benchmark/:token?" component={BuildingBenchmark} />}
        />

        <ConditionalRoute
          path="/analysis/energy-performance-report/:token?"
          condition={loggedIn}
          redirectConditionFunction={prs => {
            return !loggedIn && !_.get(prs, 'computedMatch.params.token')
          }}
          componentTrue={<PrivateRoute path="/analysis/energy-performance-report/:token?" component={EnergyPerformanceReport} />}
          componentFalse={<PublicRoute header path="/analysis/energy-performance-report/:token?" component={EnergyPerformanceReport} />}
        />

        <PrivateRoute
          exact
          path="/analysis/indoor-conditions"
          dateSelector
          component={IndoorConditions}
        />

        <SuperRoute
          path="/appliance-log-debug"
          component={ApplianceLogDebug}
          dateSelector
        />
        <SuperRoute
          path="/mapped-point-debug"
          component={MappedPointDebug}
          dateSelector
        />
        <SuperRoute path="/meter-data-upload" component={MeterDataUpload} />

        <AdminRoute exact path="/health" component={HealthCheck} />

        <Route component={NotFound} />
      </Switch>
    </ConfigProvider >
  );
};


const configTheme = {
    components: {
        Switch: {
            colorPrimary: '#5e656e',
            colorPrimaryHover: '#5e656e',
            colorPrimaryBorder: '#5e656e',
            colorTextTertiary: '#5e656e',
            colorTextQuaternary: '#5e656e',
        },
        DatePicker: {
            colorPrimary: '#1ca289',
        },
        Select: {
            colorTextPlaceholder: '#5e566e'
        },
        Tabs: {
            colorPrimary: '#1ca289',
            colorPrimaryHover: '#1ca289',
            colorPrimaryBorder: '#1ca289',
        },
    }
}

export default configTheme;
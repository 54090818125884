import React, { Component } from "react";
import "./HeaderPublic.scss";
import { Link } from "react-router-dom";
import UserStore from "../../stores/userStore";

class HeaderPublic extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: UserStore.getUser(),
    };
    this._onChange = this._onChange.bind(this);
  }
  _onChange() {
    this.setState({ user: UserStore.getUser() });
  }
  render() {
    let logo = (
      <div className="home-header-logo transition">
        <Link to="/home">
          <img
            alt="OPNBuildings Logo"
            src="/img/opn/OPNLogoWhite.png"
            style={{ width: "100%" }}
          />
        </Link>
      </div>
    );

    return (
      <div id="HeaderPublic">
        <div className="home-header-wrapper transition">
          <div className="home-header-left">{logo}</div>
        </div>
      </div>
    );
  }
}

export default HeaderPublic;

import React from "react";
import styles from "./LogoSpinner.module.scss";

const LogoSpinner = ({ loading }) => {
  if (!loading) return null;

  return (
    <div className={styles["logoSpinner"]}>
      <div className={styles["spinner"]}></div>
    </div>
  );
};

export default LogoSpinner;

import React, { Component } from "react";
import PropTypes from "prop-types";
import AsyncSelect from "react-select/async";
import "./SearchSelect.scss";
import _ from "lodash";

const propTypes = {
  limit: PropTypes.number,
  defaultValue: PropTypes.object,
  actionOnSelectedOption: PropTypes.func,
};

const defaultProps = {
  limit: 9999,
  defaultValue: null,
  actionOnSelectedOption: _.noop,
  associated_callback_value: _.noop,
};

class SearchSelect extends Component {
  static propTypes = propTypes;
  static defaultProps = defaultProps;
  constructor(props) {
    super(props);
    this.state = {
      inputValue: "",
      limit: props.limit,
      selectedOption: this.props.defaultValue,
      actionOnSelectedOption: this.props.actionOnSelectedOption,
      associated_callback_value: this.props.associated_callback_value,
    };
    this.getOptions = this.getOptions.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.noOptionsMessage = this.noOptionsMessage.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  getOptionValue = (option) => option.name; // maps the result 'name' as the 'value'

  getOptionLabel = (option) => option.name; // maps the result 'name' as the 'label'

  handleChange(selectedOption, { action }) {
    // you can use the 'action' to do different things here
    this.setState({
      selectedOption: selectedOption,
    });
    // this is for update action on selectedOption
    // will use the noop defaultProp if the dev didn't define the prop, so no need to conditionally call
    this.state.actionOnSelectedOption(
      selectedOption,
      this.state.associated_callback_value
    );
  }

  async getOptions(inputValue) {
    if (!inputValue) {
      return [];
    }
    return _.take(
      _.filter(this.props.options, function (o) {
        return o.name.toLowerCase().includes(inputValue.toLowerCase());
      }),
      this.props.limit
    );
  }

  // inputValue state is controlled in the Select, so this probably isn't necessary
  // except to maybe validate that it is changing
  handleInputChange(inputValue) {
    this.setState({ inputValue });
    return inputValue;
  }

  noOptionsMessage(inputValue) {
    if (this.props.options.length) return null;
    if (!inputValue) {
      return "No Input Value";
    }
    return "No Results";
  }

  render() {
    const defaultOptions = _.take(this.props.options, this.props.limit);
    let disabled = false;
    let className = "";
    if (this.props.extraHeight) {
      className = "extra_lineheight";
    }
    if (this.props.isDisabled) {
      disabled = true;
      className = className + " select-element-disabled";
    }

    const customStyles = {
      control: (provided) => ({
        ...provided,
        borderRadius: '12px'
      }),
      menu: (provided) => ({
        ...provided,
        borderRadius: '12px'
      })
    };

    return (
      <AsyncSelect
        cacheOptions
        className={className}
        styles={customStyles}
        isDisabled={disabled}
        value={this.props.defaultValue}
        noOptionsMessage={this.noOptionsMessage}
        getOptionValue={this.getOptionValue}
        getOptionLabel={this.getOptionLabel}
        defaultOptions={defaultOptions}
        loadOptions={this.getOptions}
        placeholder={this.props.placeholder}
        onChange={this.handleChange}
      />
    );
  }
}

export default SearchSelect;

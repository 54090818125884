import React from "react";
import PropTypes from "prop-types";
import { ReactComponent as Dashboard } from "../../assets/icons/dashboard.svg";
import { ReactComponent as Gas } from "../../assets/icons/gas-meter.svg";
import { ReactComponent as Zone } from "../../assets/icons/zone.svg";
import { ReactComponent as MeterExplorer } from "../../assets/icons/meter-explorer.svg";
import { ReactComponent as Flash } from "../../assets/icons/meter-explorer.svg";
import { ReactComponent as Hvac } from "../../assets/icons/hvac.svg";
import { ReactComponent as Password } from "../../assets/icons/password.svg";
import { ReactComponent as Logout } from "../../assets/icons/logout.svg";
import { ReactComponent as IndoorConditions } from "../../assets/icons/indoor-conditions.svg";
import { ReactComponent as Alarm } from "../../assets/icons/alarm.svg";
import { ReactComponent as Fan } from "../../assets/icons/fan.svg";
import { ReactComponent as Check } from "../../assets/icons/check.svg";
import { ReactComponent as CheckCircle } from "../../assets/icons/check-circle.svg";
import { ReactComponent as Cross } from "../../assets/icons/cross.svg";
import { ReactComponent as Delete } from "../../assets/icons/delete.svg";
import { ReactComponent as Edit } from "../../assets/icons/edit.svg";
import { ReactComponent as ToggleOn } from "../../assets/icons/toggle-on.svg";
import { ReactComponent as ToggleOff } from "../../assets/icons/toggle-off.svg";
import { ReactComponent as Warning } from "../../assets/icons/warning.svg";
import { ReactComponent as ArrowLeft } from "../../assets/icons/arrow-left.svg";
import { ReactComponent as ArrowRight } from "../../assets/icons/arrow-right.svg";
import { ReactComponent as ArrowUp } from "../../assets/icons/arrow-up.svg";
import { ReactComponent as ArrowDown } from "../../assets/icons/arrow-down.svg";
import { ReactComponent as DataExplorer } from "../../assets/icons/data-explorer.svg";
import { ReactComponent as Close } from "../../assets/icons/close.svg";
import { ReactComponent as Analysis } from "../../assets/icons/analysis.svg";
import { ReactComponent as Euro } from "../../assets/icons/euro.svg";
import { ReactComponent as Co2 } from "../../assets/icons/co2.svg";
import { ReactComponent as AddCircle } from "../../assets/icons/add-circle.svg";
import { ReactComponent as AddCircleFilled } from "../../assets/icons/add-circle-filled.svg";
import { ReactComponent as Collapse } from "../../assets/icons/collapse.svg";
import { ReactComponent as Expand } from "../../assets/icons/expand.svg";
import { ReactComponent as Share } from "../../assets/icons/share.svg";
import { ReactComponent as RemoveSelection } from "../../assets/icons/remove-selection.svg";
import { ReactComponent as Save } from "../../assets/icons/save.svg";
import { ReactComponent as EditSquare } from "../../assets/icons/edit-square.svg";
import { ReactComponent as EditOff } from "../../assets/icons/edit-off.svg";
import { ReactComponent as Info } from "../../assets/icons/info.svg";
import { ReactComponent as Copy } from "../../assets/icons/copy.svg";
import { ReactComponent as Validated } from "../../assets/icons/validated.svg";
import { ReactComponent as Reset } from "../../assets/icons/reset.svg";
import { ReactComponent as Download } from "../../assets/icons/download.svg";
import { ReactComponent as Mail } from "../../assets/icons/mail.svg";
import { ReactComponent as Users } from "../../assets/icons/users.svg";
import { ReactComponent as Globe } from "../../assets/icons/globe.svg";
import { ReactComponent as AddUser } from "../../assets/icons/add-user.svg";
import { ReactComponent as Sensor } from "../../assets/icons/sensor.svg";
import { ReactComponent as Clipboard } from "../../assets/icons/clipboard.svg";
import { ReactComponent as Pulse } from "../../assets/icons/pulse.svg";
import { ReactComponent as Key } from "../../assets/icons/key.svg";
import { ReactComponent as CloudUpload } from "../../assets/icons/cloud-upload.svg";
import { ReactComponent as Construction } from "../../assets/icons/construction.svg";
import { ReactComponent as Calendar } from "../../assets/icons/calendar.svg";
import { ReactComponent as Clock } from "../../assets/icons/clock.svg";
import { ReactComponent as Search } from "../../assets/icons/search.svg";
import { ReactComponent as ReturnRight } from "../../assets/icons/return-right.svg";
import { ReactComponent as RemoveCircle } from "../../assets/icons/remove-circle.svg";
import { ReactComponent as Business } from "../../assets/icons/business.svg";
import { ReactComponent as QrScanner } from "../../assets/icons/qr-scanner.svg";
import { ReactComponent as Grid } from "../../assets/icons/grid.svg";
import { ReactComponent as Map } from "../../assets/icons/map.svg";
import { ReactComponent as Ribbon } from "../../assets/icons/ribbon.svg";
import { ReactComponent as Inventory } from "../../assets/icons/inventory.svg";
import { ReactComponent as Debug } from "../../assets/icons/debug.svg";
import { ReactComponent as Device } from "../../assets/icons/device.svg";
import { ReactComponent as Link } from "../../assets/icons/link.svg";
import { ReactComponent as CheckCircleOutline } from "../../assets/icons/check-circle-outline.svg";
import { ReactComponent as Circle } from "../../assets/icons/circle.svg";
import { ReactComponent as Notification } from "../../assets/icons/notification.svg";
import { ReactComponent as Refresh } from "../../assets/icons/refresh.svg";
import { ReactComponent as RadioOn } from "../../assets/icons/radio-on.svg";
import { ReactComponent as Menu } from "../../assets/icons/menu.svg";
import { ReactComponent as TrendUp } from "../../assets/icons/trend-up.svg";
import { ReactComponent as TrendDown } from "../../assets/icons/trend-down.svg";
import { ReactComponent as Leaf } from "../../assets/icons/leaf.svg";
import { ReactComponent as Thermometer } from "../../assets/icons/thermometer.svg";
import { ReactComponent as Flame } from "../../assets/icons/flame.svg";
import { ReactComponent as Electricity } from "../../assets/icons/electricity.svg";
import { ReactComponent as Water } from "../../assets/icons/water.svg";
import { ReactComponent as Renewable } from "../../assets/icons/renewable.svg";
import { ReactComponent as MailUnread } from "../../assets/icons/mail-unread.svg";
import { ReactComponent as Undo } from "../../assets/icons/undo.svg";
import { ReactComponent as Send } from "../../assets/icons/send.svg";
import { ReactComponent as Eye } from "../../assets/icons/eye.svg";
const Icon = ({ name, size = 20, color = "#fdfdfb", style, onClick, className, ...props}) => {

  const icons = {
    Eye,
    Send,
    Undo,
    MailUnread,
    Renewable,
    Water,
    Electricity,
    Flame,
    Leaf,
    Thermometer,
    TrendUp,
    TrendDown,
    Flash,
    Menu,
    RadioOn,
    Refresh,
    Notification,
    Circle,
    CheckCircleOutline,
    Link,
    Device,
    Debug,
    Inventory,
    Ribbon,
    Map,
    Grid,
    QrScanner,
    Business,
    RemoveCircle,
    ReturnRight,
    Search,
    Clock,
    Calendar,
    Construction,
    CloudUpload,
    Key,
    Pulse,
    Clipboard,
    Sensor,
    AddUser,
    Dashboard,
    Gas,
    Zone,
    MeterExplorer,
    Hvac,
    Password,
    Logout,
    IndoorConditions,
    Alarm,
    Fan,
    Check,
    CheckCircle,
    Cross,
    Delete,
    Edit,
    ToggleOn,
    ToggleOff,
    Warning,
    ArrowLeft,
    ArrowRight,
    ArrowUp,
    ArrowDown,
    DataExplorer,
    Close,
    Analysis,
    Euro,
    Co2,
    AddCircle,
    AddCircleFilled,
    Collapse,
    Expand,
    Share,
    RemoveSelection,
    Save,
    EditSquare,
    EditOff,
    Info,
    Copy,
    Validated,
    Reset,
    Download,
    Mail,
    Users,
    Globe
  };

  const IconComponent = icons[name];

  if (!IconComponent) {
    return "Icon Error, check name/imports";
  }

  return (
    <IconComponent width={size} height={size} fill={color} style={style} onClick={onClick} className={className} {...props} />
  );
};

Icon.propTypes = {
  name: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  size: PropTypes.number,
  color: PropTypes.string,
  style: PropTypes.object,
};

export default Icon;

// Icons are sourced from: https://fonts.google.com/icons?icon.set=Material+Symbols&icon.style=Rounded&icon.platform=web
// Remove svg fill before importing
// Add to import -> const icons -> PropTypes

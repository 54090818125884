const urlManager = {
  getWebSocketUrl: function () {
    if (window.location.host.includes("localhost")) {
      return "wss://b-3e5d3259-f80a-4759-80d2-629ddd10388f-1.mq.eu-west-1.amazonaws.com:61619";
    } else if (window.location.href.includes("dev-ui")) {
      return "wss://b-3e5d3259-f80a-4759-80d2-629ddd10388f-1.mq.eu-west-1.amazonaws.com:61619";
    } else {
      return "wss://mq.opnbuildings.com:61619";
    }
  },
  getAPIUrl: function () {
    if (window.location.host.includes("localhost")) {
      return "http://localhost:8080/";
    } else if (window.location.href.includes("dev-ui")) {
      return "https://dev-apis.opnbuildings.com/webapis/";
    } else {
      return "https://apis-env.opnbuildings.com/webapis/";
    }
  },
  getReceiverUrl: function () {
    if (window.location.host.includes("localhost")) {
      return "http://localhost:8080/";
    } else if (window.location.href.includes("dev-ui")) {
      return "https://dev-receiver.opnbuildings.com/receiver/";
    } else {
      return "https://receiver.opnbuildings.com/receiver/";
    }
  },
  getProcessorUrl: function () {
    if (window.location.host.includes("localhost")) {
      return "http://localhost:8080/";
    } else if (window.location.href.includes("dev-ui")) {
      return "https://dev-processor.opnbuildings.com/processor/";
    } else {
      return "https://processor.opnbuildings.com/processor/";
    }
  },
  getEngineUrl: function () {
    if (window.location.host.includes("localhost")) {
      return "http://localhost:8080/";
    } else if (window.location.href.includes("dev-ui")) {
      return "https://dev-engines.opnbuildings.com/engines/";
    } else {
      return "https://engines.opnbuildings.com/engines/";
    }
  },
  getUIUrl: function () {
    if (window.location.host.includes("localhost")) {
      return "http://localhost:8000/";
    } else if (window.location.href.includes("dev-ui")) {
      return "https://dev-ui.opnbuildings.com/";
    } else {
      return "https://ui.opnbuildings.com/";
    }
  },
  addQuery: function (searchString, query) {
    if (!searchString.includes(query)) {
      searchString = searchString + "&" + query;
    } else {
      searchString = searchString.replace("&" + query, "");
    }
    return searchString;
  },
};

export default urlManager;



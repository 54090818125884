import React from "react";

import "./Asset.scss";
import _ from "lodash";
import Timeframe from "../../../utils/Timeframe";
//components
import { DocumentTitle } from "../../../components/DocumentTitle";
import { Icon } from "../../../components/Icon";
import { Chart } from "../../../components/Chart";
import { LogoSpinner } from "../../../components/LogoSpinner";
import GeneralStore from "../../../stores/generalStore";
import HvacDetailsStore from "../../../stores/hvacDetailsStore";
import HvacDetailsActions from "../../../actions/hvacDetailsActions";
import Constants from "../../../constants";

class Asset extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      asset: null,
      chart_data: [],
      timeframe: new Timeframe(GeneralStore.getStartDate(), GeneralStore.getEndDate()),
      details_loading: true,
      charts_loading: true,
      bucket: Constants.BUCKET_FIFTEEN_MIN,
    };
    this.onHvacDetailsFetch = this.onHvacDetailsFetch.bind(this);
    this.onHvacChartsFetch = this.onHvacChartsFetch.bind(this);
    this.onDateChange = this.onDateChange.bind(this);
  }
  UNSAFE_componentWillMount() {
    HvacDetailsStore.addHvacDetailsFetchListener(this.onHvacDetailsFetch);
    HvacDetailsStore.addHvacChartsFetchListener(this.onHvacChartsFetch);
    GeneralStore.addChangeListener(this.onDateChange);
  }
  componentWillUnmount() {
    HvacDetailsStore.removeHvacDetailsFetchListener(this.onHvacDetailsFetch);
    HvacDetailsStore.removeHvacChartsFetchListener(this.onHvacChartsFetch);
    GeneralStore.removeChangeListener(this.onDateChange);
  }

  componentDidMount() {
    this.fetchHvacDetails();
    this.fetchHvacCharts();
  }

  fetchHvacDetails() {
    const asset_id = _.get(this.props, "match.params.id");

    if (asset_id) {
      this.setState(
        {
          details_loading: true,
        },
        () => HvacDetailsActions.getAsset(asset_id)
      );
    }
  }

  fetchHvacCharts() {
    const asset_id = _.get(this.props, "match.params.id");
    const timeframe = this.state.timeframe;
    const bucket = this.state.bucket;

    if (asset_id && timeframe && bucket) {
      this.setState(
        {
          charts_loading: true,
        },
        () => HvacDetailsActions.getAssetDetails(asset_id, timeframe, bucket)
      );
    }
  }

  onHvacDetailsFetch() {
    const details = HvacDetailsStore.getHvacDetails();
    this.setState({
      asset: details,
      details_loading: false,
    });
  }

  onHvacChartsFetch() {
    const charts = HvacDetailsStore.getHvacCharts();
    this.setState({
      chart_data: charts,
      charts_loading: false,
    });
  }

  onDateChange() {
    const bucket = this.state.bucket;
    const newTimeframe = new Timeframe(GeneralStore.getStartDate(), GeneralStore.getEndDate());
    const daysDifference = newTimeframe.getDaysDifference();

    let newBucket = bucket;
    if (daysDifference > Constants.MAX_DAYS_FOR_DAY_BUCKET) {
      newBucket = Constants.BUCKET_DAY;
    } else if (daysDifference > Constants.MAX_DAYS_FOR_HOUR_BUCKET) {
      newBucket = Constants.BUCKET_HOUR;
    }

    this.setState(
      {
        timeframe: newTimeframe,
        bucket: newBucket,
      },
      () => this.fetchHvacCharts()
    );
  }

  getPageTitle() {
    const asset_name = _.get(this.state, "asset.name");
    return `${asset_name ? asset_name : "Asset Overview"}`;
  }

  getTitle() {
    const asset_name = _.get(this.state, "asset.name");
    const lastLocation = sessionStorage.getItem("assetLastLoc");
    const defaultLocation = "/hvac";
    if (asset_name) {
      return (
        <div
          className="title tx-18 tx-archivo-semibold shadow-base"
          onClick={() => {
            if (lastLocation) {
              this.props.history.push(lastLocation);
            } else {
              this.props.history.push(defaultLocation);
            }
          }}
        >
          <span className="icon-wrapper">
            <Icon name='ArrowLeft' size={40} color='#000' />
          </span>
          {asset_name}
        </div>
      );
    }
    return null;
  }

  isBucketDisabled(bucket) {
    const daysDifference = this.state.timeframe.getDaysDifference();

    return (
      (bucket === Constants.BUCKET_FIFTEEN_MIN &&
        daysDifference > Constants.MAX_DAYS_FOR_HOUR_BUCKET) ||
      (bucket === Constants.BUCKET_HOUR &&
        daysDifference > Constants.MAX_DAYS_FOR_DAY_BUCKET)
    );
  }

  setBucketSize(bucket) {
    this.setState({ bucket }, () => this.fetchHvacCharts());
  }

  getTemperatureProfilesSection() {
    const chart_data = this.state.chart_data.find(
      (c) => c.ref === "temp_chart"
    );

    if (chart_data && chart_data.series.length > 0) {
      const buttons = [
        {
          label: "15 min",
          activeCondition: this.state.bucket === Constants.BUCKET_FIFTEEN_MIN,
          disabledCondition: this.isBucketDisabled(Constants.BUCKET_FIFTEEN_MIN),
          handler: () => this.setBucketSize(Constants.BUCKET_FIFTEEN_MIN),
        },
        {
          label: "Hour",
          activeCondition: this.state.bucket === Constants.BUCKET_HOUR,
          disabledCondition: this.isBucketDisabled(Constants.BUCKET_HOUR),
          handler: () => this.setBucketSize(Constants.BUCKET_HOUR),
        },
        {
          label: "Day",
          activeCondition: this.state.bucket === Constants.BUCKET_DAY,
          disabledCondition: false,
          handler: () => this.setBucketSize(Constants.BUCKET_DAY),
        },
      ];

      return (
        <div className="col-12 mg-t-30">
          <Chart
            title={chart_data.name}
            data={chart_data.series}
            tooltipDecimals={1}
            unit={chart_data.dataType}
            buttons={buttons}
          />
        </div>
      );
    }
  }

  getKwhConsumptionProfiles() {
    const chart_data = this.state.chart_data.find((c) => c.ref === "kwh_chart");

    if (chart_data) {
      return (
        <div className="col-12 mg-t-30">
          <Chart
            title={chart_data.name}
            data={chart_data.series}
            tooltipDecimals={0}
            unit={chart_data.dataType}
          />
        </div>
      );
    }
  }

  getNoDataMessage() {
    const series = this.state.chart_data;
    if (
      series.length === 0 &&
      this.state.charts_loading === false &&
      this.state.details_loading === false
    ) {
      return (
        <div className="col-12 mg-t-30">
          <Chart noData />
        </div>
      );
    }
    return null;
  }

  getOtherCharts() {
    const remaining_charts = this.state.chart_data.filter((c) => {
      return c.ref.includes("level");
    });

    return remaining_charts
      .filter((c) => c.data.length)
      .map((c) => {
        return (
          <div key={c.ref} className="col-12 mg-t-30">
            <Chart
              title={c.name}
              data={[c]}
              tooltipDecimals={0}
              unit={c.dataType}
              height={130}
              exporting={false}
              min={0}
              max={100}
            />
          </div>
        );
      });
  }

  render() {
    return (
      <div
        id="Meter"
        className="br-mainpanel br-profile-page floorplan-background"
        style={{ scrollY: "scroll" }}
      >
        <DocumentTitle title={this.getPageTitle()} />
        <LogoSpinner
          loading={this.state.charts_loading || this.state.details_loading}
        />
        <div className="br-container">
          <div className="header row">
            <div className="col mg-t-30">{this.getTitle()}</div>
          </div>
          <div className="charts row">
            {this.getNoDataMessage()}
            {this.getTemperatureProfilesSection()}
            {this.getKwhConsumptionProfiles()}
            {this.getOtherCharts()}
          </div>
        </div>
      </div>
    );
  }
}

export default Asset;

import React, { Component } from "react";
import MultiSelect from "@khanacademy/react-multi-select";
import "./InviteUserPanel.scss";
import moment from "moment";
import {Icon} from "../../../components/Icon";

class InviteUserPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: this.props.user,
      buildings: this.props.buildings,
      roles: this.props.roles,
      inputValue: "",
    };
  }
  componentWillReceiveProps(nextProps) {
    this.setState({
      user: nextProps.user,
      buildings: nextProps.buildings,
      roles: nextProps.roles,
    });
  }
  getUserTable() {
    let user = this.state.user;
    let emailInput = (
      <input
        placeholder="Email"
        type="text"
        className="inline-single-input"
        defaultValue={user.mail}
        onChange={this.props.changeUserValues.bind(this, user, "mail")}
      />
    );
    if (user.user_id && user.user_id > 0) {
      emailInput = (
        <input
          placeholder="Email"
          type="text"
          className="inline-single-input"
          disabled
          defaultValue={user.mail}
        />
      );
    }
    let buildingOptions = [];
    for (let i = 0; i < this.state.buildings.length; i++) {
      buildingOptions.push({
        label: this.state.buildings[i].name,
        value: this.state.buildings[i].building_id,
      });
    }

    return (
      <tr>
        <td className="valign-middle">
          <input
            placeholder="Name"
            type="text"
            className="inline-single-input"
            defaultValue={user.name}
            onChange={this.props.changeUserValues.bind(this, user, "name")}
          />
        </td>
        <td className="valign-middle">{emailInput}</td>
        <td className="valign-middle">
          <select
            onChange={this.props.changeUserValues.bind(
              this,
              user,
              "fk_role_id"
            )}
            value={user.fk_role_id}
            className="inline-single-input"
          >
            <option value={0} disabled>
              None
            </option>
            {this.state.roles.map(function (role, key) {
              if (role.type === "ADMIN" || role.type === "GENERAL") {
                return (
                  <option key={key} value={role.role_id}>
                    {role.type}
                  </option>
                );
              } else {
                return null;
              }
            })}
          </select>
        </td>
        <td className="valign-middle">
          {this.state.buildings.length !== 0 ? (
            <div className="table-form">
              <MultiSelect
                overrideStrings={{
                  selectSomeItems: "Select Buildings...",
                  allItemsAreSelected: "All Buildings Selected",
                  selectAll: "Select All",
                  search: "Search",
                }}
                options={buildingOptions}
                selected={user.allowed_buildings}
                onSelectedChanged={this.props.changeUserValues.bind(
                  this,
                  user,
                  "allowed_buildings"
                )}
              />
            </div>
          ) : (
            <p
              title={
                "This organisation does not have accessible buildings or the buildings have been disabled."
              }
            >
              No accessible buildings
            </p>
          )}
        </td>
        <td className="valign-middle">
          {user.user_id
            ? moment(user.ts_last_access).format("DD MMM YYYY - HH:mm")
            : "Not Available"}
        </td>
        <td className="valign-middle tx-center">
          {this.props.user.user_id && this.props.user.status === 'new' && (
            <span
              title='Resend Invitation Email'
              onClick={this.props.resendInvitation.bind(this, user)}
              style={{ cursor: "pointer" }}
              className="btn btn-icon btn-info tx-18 pd-5 mg-r-2 align-items-center justify-content-center"
            >
              <Icon name='MailUnread' color='#fff' />
            </span>
          )}
          <span
            onClick={this.props.saveUser.bind(this, user)}
            style={{ cursor: "pointer" }}
            className="btn btn-icon btn-success tx-18 pd-5 mg-r-2"
          >
            <Icon name='Save' color='#fff' />
          </span>
          {this.props.user.user_id ? (
            <span
              onClick={this.props.deleteUser.bind(this, user)}
              style={{ cursor: "pointer" }}
              className="btn btn-icon btn-danger tx-18 pd-5"
            >
              <Icon name='Delete' color='#fff' />
            </span>
          ) : (
            <span
              onClick={this.props.toggleUserCallback.bind(
                this,
                this.props.user
              )}
              style={{ cursor: "pointer" }}
              className="btn btn-icon btn-warning tx-18 pd-5"
            >
            <Icon name="Cross" color='#fff' />
            </span>
          )}
        </td>
      </tr>
    );
  }
  render() {
    return this.getUserTable();
  }
}

export default InviteUserPanel;

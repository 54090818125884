const COLOR_PALETTE = {
    grades: {
      A: '#4b947e',
      B: '#98d8c5',
      C: '#efc5a8',
      D: '#f8817d',
    },
    energyTypes: {
      electricity: '#19a5b0',
      thermal: '#f4a261',
    },
    improvements: {
      savings: '#4b947e',  
      remaining: '#d9d9d9'
    },
    gray: '#d9d9d9'
  };

  export default COLOR_PALETTE;
import React, { Component } from "react";
import "./IndoorConditions.scss";
import _ from "lodash";
import moment from "moment";
import GeneralUtils from "../../../utils/GeneralUtils";

import BreachesActions from "../../../actions/breachesActions";
import BreachesStore from "../../../stores/breachesStore";
import GeneralStore from "../../../stores/generalStore";
import GeneralActions from "../../../actions/generalActions";
import { LogoSpinner } from "../../../components/LogoSpinner";
import { Modal } from "../../../components/Modal";
import { DocumentTitle } from "../../../components/DocumentTitle";
import { QRForEstate } from "../../../components/QRForEstate";
import { Icon } from "../../../components/Icon";

class IndoorConditions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      modalOpen: false,
      live_view: false,
      ts_start: GeneralStore.getStartDate(),
      ts_end: GeneralStore.getEndDate(),
      sortBy: null,
      sortDirection: "asc",
      modalData: {},
      modalType: null,
      buildings: BreachesStore.getBreachesByBuildings(),
      estates: BreachesStore.getBreachesByEstates(),
      filter: "",
    };
    this._onDateChange = this._onDateChange.bind(this);
    this._changeSorting = this._changeSorting.bind(this);
    this._zoneClick = this._zoneClick.bind(this);
    this._onBreachesFetch = this._onBreachesFetch.bind(this);
    this._generateCSV = this._generateCSV.bind(this);
    this._downloadCSV = this._downloadCSV.bind(this);
    this._turnOffModal = this._turnOffModal.bind(this);

    this.getBuildingBreachTable = this.getBuildingBreachTable.bind(this);
  }

  _onBreachesFetch() {
    this.setState({
      buildings: BreachesStore.getBreachesByBuildings(),
      estates: BreachesStore.getBreachesByEstates(),
      loading: false,
    });
  }

  _onDateChange() {
    const start_of_day = moment().startOf("day").unix();
    if (GeneralStore.getStartDate() <= start_of_day) {
      this.setState({
        ts_start: GeneralStore.getStartDate(),
        ts_end: GeneralStore.getEndDate(),
        live_view: false,
        loading: true,
      });
    } else {
      this.setState({
        ts_start: GeneralStore.getStartDate(),
        ts_end: GeneralStore.getEndDate(),
        live_view: true,
        loading: true,
      });
    }
  }

  UNSAFE_componentWillMount() {
    BreachesStore.addFetchListener(this._onBreachesFetch);
    GeneralStore.addChangeListener(this._onDateChange);
  }

  componentWillUnmount() {
    const now = moment().unix();
    const start_of_day = moment().startOf("day").unix();

    if (this.state.live_view === true) {
      GeneralActions.setDates(start_of_day, now);
    }
    BreachesStore.removeFetchListener(this._onBreachesFetch);
    GeneralStore.removeChangeListener(this._onDateChange);
  }

  componentWillUpdate() {
    let ts_start = GeneralStore.getStartDate();
    let ts_end = GeneralStore.getEndDate();
    if (this.state.ts_start !== ts_start || this.state.ts_end !== ts_end) {
      BreachesActions.getBuildingsWithBreaches(ts_start, ts_end);
    }
  }

  componentDidMount() {
    let ts_start = GeneralStore.getStartDate();
    let ts_end = GeneralStore.getEndDate();
    BreachesActions.getBuildingsWithBreaches(ts_start, ts_end);
  }

  _zoneClick(event, zone_id) {
    event.stopPropagation();
    sessionStorage.setItem("zoneLastLoc", this.props.location.pathname);
    this.props.history.push(`/zones/${zone_id}`);
  }

  _changeSorting(e, sortBy) {
    e.stopPropagation();

    if (this.state.sortBy !== sortBy) {
      this.setState({ sortBy: sortBy, sortDirection: "asc" });
    } else if (
      this.state.sortBy === sortBy &&
      this.state.sortDirection === "asc"
    ) {
      this.setState({ sortBy: sortBy, sortDirection: "desc" });
    } else if (
      this.state.sortBy === sortBy &&
      this.state.sortDirection === "desc"
    ) {
      this.setState({ sortBy: sortBy, sortDirection: "asc" });
    }
  }
  getSortingIcon(column) {
    if (this.state.sortBy === column) {
      if (this.state.sortDirection === "asc") {
        return (
          <Icon name='ArrowUp' size={20} color='#000' style={{ verticalAlign: 'top', float: 'right', position: 'absolute' }} />
        );
      } else {
        return (
          <Icon name='ArrowDown' size={20} color='#000' style={{ verticalAlign: 'top', float: 'right', position: 'absolute' }} />
        );
      }
    }
  }

  getTotalBreaches() {
    let total_breaches = 0;
    this.state.estates.forEach((estate) => {
      estate.buildings.forEach((building) => {
        building.zones.forEach((zone) => {
          if (zone.co2_breaches > 0 && zone.co2_breaches !== null)
            total_breaches++;
          if (zone.temperature_breaches > 0 && zone.co2_breaches !== null)
            total_breaches++;
          if (zone.humidity_breaches > 0 && zone.co2_breaches !== null)
            total_breaches++;
        });
      });
    });

    return total_breaches;
  }

  getBuildingBreachTableHeader(kpi) {
    return (
      <thead className="thead-light thead-fixed">
        <tr>
          <th
            onClick={(e) => this._changeSorting(e, kpi + "_name")}
            className="wd-20p"
          >
            Zone Name{this.getSortingIcon(kpi + "_name")}
          </th>
          <th
            onClick={(e) => this._changeSorting(e, kpi + "_breaches")}
            className="wd-10p tx-center"
          >
            Breaches{this.getSortingIcon(kpi + "_breaches")}
          </th>
          <th
            onClick={(e) =>
              this._changeSorting(e, kpi + "_breach_duration_mins")
            }
            className="wd-10p tx-center"
          >
            Duration{this.getSortingIcon(kpi + "_breach_duration_mins")}
          </th>
          <th
            onClick={(e) => this._changeSorting(e, kpi + "_average")}
            className="wd-10p tx-center"
          >
            Average{this.getSortingIcon(kpi + "_average")}
          </th>
          <th
            onClick={(e) => this._changeSorting(e, kpi + "_min")}
            className="wd-10p tx-center"
          >
            Min{this.getSortingIcon(kpi + "_min")}
          </th>
          <th
            onClick={(e) => this._changeSorting(e, kpi + "_max")}
            className="wd-10p tx-center"
          >
            Max{this.getSortingIcon(kpi + "_max")}
          </th>
        </tr>
      </thead>
    );
  }

  getKPIBreachTableHeader(kpi) {
    return (
      <thead className="thead-light thead-fixed">
        <tr>
          <th
            onClick={(e) => this._changeSorting(e, "name")}
            className="wd-20p"
          >
            Zone Name{this.getSortingIcon("name")}
          </th>
          <th
            onClick={(e) => this._changeSorting(e, "fk_building_id")}
            className="wd-15p tx-center"
          >
            Building Name{this.getSortingIcon("fk_building_id")}
          </th>
          <th
            onClick={(e) => this._changeSorting(e, kpi + "_breaches")}
            className="wd-10p tx-center"
          >
            Breaches{this.getSortingIcon(kpi + "_breaches")}
          </th>
          <th
            onClick={(e) =>
              this._changeSorting(e, kpi + "_breach_duration_mins")
            }
            className="wd-10p tx-center"
          >
            Duration{this.getSortingIcon(kpi + "_breach_duration_mins")}
          </th>
          <th
            onClick={(e) => this._changeSorting(e, kpi + "_average")}
            className="wd-10p tx-center"
          >
            Average{this.getSortingIcon(kpi + "_average")}
          </th>
          <th
            onClick={(e) => this._changeSorting(e, kpi + "_min")}
            className="wd-10p tx-center"
          >
            Min{this.getSortingIcon(kpi + "_min")}
          </th>
          <th
            onClick={(e) => this._changeSorting(e, kpi + "_max")}
            className="wd-10p tx-center"
          >
            Max{this.getSortingIcon(kpi + "_max")}
          </th>
        </tr>
      </thead>
    );
  }

  getBuildingBreachTable(building, kpi = null) {
    const zones = building.zones;
    let temperature_breaches = [];
    let co2_breaches = [];
    let humidity_breaches = [];

    zones.forEach((zone) => {
      if (zone.temperature_breaches > 0)
        temperature_breaches = [...temperature_breaches, zone];
      if (zone.co2_breaches > 0) co2_breaches = [...co2_breaches, zone];
      if (zone.humidity_breaches > 0)
        humidity_breaches = [...humidity_breaches, zone];
    });

    // adding names to specify KPI with sorting
    co2_breaches = co2_breaches.map((zone) => {
      return { ...zone, co2_name: zone.name };
    });
    temperature_breaches = temperature_breaches.map((zone) => {
      return { ...zone, temperature_name: zone.name };
    });
    humidity_breaches = humidity_breaches.map((zone) => {
      return { ...zone, humidity_name: zone.name };
    });

    if (this.state.sortBy && this.state.sortBy.includes("co2")) {
      co2_breaches = _.orderBy(
        co2_breaches,
        this.state.sortBy,
        this.state.sortDirection
      );
    } else if (this.state.sortBy && this.state.sortBy.includes("temperature")) {
      temperature_breaches = _.orderBy(
        temperature_breaches,
        this.state.sortBy,
        this.state.sortDirection
      );
    } else if (this.state.sortBy && this.state.sortBy.includes("humidity")) {
      humidity_breaches = _.orderBy(
        humidity_breaches,
        this.state.sortBy,
        this.state.sortDirection
      );
    }

    const show_temperature_breaches =
      (kpi === null && temperature_breaches.length > 0) ||
      kpi === "temperature";
    const show_co2_breaches =
      (kpi === null && co2_breaches.length > 0) || kpi === "co2";
    const show_humidity_breaches =
      (kpi === null && humidity_breaches.length > 0) || kpi === "humidity";
    const no_breaches =
      !show_temperature_breaches &&
      !show_co2_breaches &&
      !show_humidity_breaches;

    let target_table = "all";
    if (kpi !== null && kpi === "temperature") {
      target_table = "building-temperature-breaches-table";
    } else if (kpi !== null && kpi === "humidity") {
      target_table = "building-humidity-breaches-table";
    } else if (kpi !== null && kpi === "humidity") {
      target_table = "building-co2-breaches-table";
    }

    return (
      <div>
        <div className="d-flex justify-content-between">
          <div>
            <h3 className="tx-inverse tx-roboto tx-normal mg-b-10">
              {building.name}
            </h3>
            <p className="pd-b-0 mg-b-0">
              Data for{" "}
              {moment(this.state.ts_start * 1000).format("MMM Do YY, hh:mm a")}{" "}
              - {moment(this.state.ts_end * 1000).format("MMM Do YY, hh:mm a")}
            </p>
            <button
              onClick={(e) => this._downloadCSV(target_table, building.name)}
              className="btn btn-outline-info pd-5 tx-12 mg-t-5 mg-b-5"
            >
              Download Table To CSV
            </button>
          </div>
          <Icon name='Close' size={30} color='#868ba1' onClick={this._turnOffModal} style={{ cursor: 'pointer' }} />
        </div>
        <hr />
        {show_co2_breaches && (
          <div className="building-breach-table-wrapper table-responsive">
            <h5 className="tx-inverse tx-roboto tx-normal mg-b-5">
              CO2 Breaches{" "}
            </h5>
            <table className="table mg-b-20" id="building-co2-breaches-table">
              {this.getBuildingBreachTableHeader("co2")}
              <tbody>
                {co2_breaches.length === 0 && (
                  <tr key={"no zones"}>
                    <td colSpan="5" className="valign-middle tx-inverse">
                      No breaches found
                    </td>
                  </tr>
                )}
                {co2_breaches.map((zone) => {
                  const number_of_breaches =
                    GeneralUtils.getFormattedNumberWithUnit(zone.co2_breaches);
                  const total_duration_of_breaches =
                    GeneralUtils.getHoursFromMinutes(
                      zone.co2_breach_duration_mins
                    );
                  const average_value = GeneralUtils.getFormattedNumberWithUnit(
                    zone.co2_average,
                    " ppm"
                  );
                  const min_value = GeneralUtils.getFormattedNumberWithUnit(
                    zone.co2_min,
                    " ppm"
                  );
                  const max_value = GeneralUtils.getFormattedNumberWithUnit(
                    zone.co2_max,
                    " ppm"
                  );

                  return (
                    <tr
                      key={zone.zone_id}
                      className="hover-row"
                      onClick={(e) => this._zoneClick(e, zone.zone_id)}
                    >
                      <td className="valign-middle tx-inverse ">
                        {zone.co2_name}
                      </td>
                      <td className="valign-middle tx-inverse tx-center">
                        {number_of_breaches}
                      </td>
                      <td className="valign-middle tx-inverse tx-center">
                        {total_duration_of_breaches}
                      </td>
                      <td className="valign-middle tx-inverse tx-center">
                        {average_value}
                      </td>
                      <td className="valign-middle tx-inverse tx-center">
                        {min_value}
                      </td>
                      <td className="valign-middle tx-inverse tx-center">
                        {max_value}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
        {show_temperature_breaches && (
          <div className="building-breach-table-wrapper table-responsive">
            <h5 className="tx-inverse tx-roboto tx-normal mg-b-5">
              Temperature Breaches{" "}
            </h5>
            <table
              className="table mg-b-20"
              id="building-temperature-breaches-table"
            >
              {this.getBuildingBreachTableHeader("temperature")}
              <tbody>
                {temperature_breaches.length === 0 && (
                  <tr key={"no zones"}>
                    <td colSpan="5" className="valign-middle tx-inverse">
                      No breaches found
                    </td>
                  </tr>
                )}
                {temperature_breaches.map((zone) => {
                  const number_of_breaches =
                    GeneralUtils.getFormattedNumberWithUnit(
                      zone.temperature_breaches
                    );
                  const total_duration_of_breaches =
                    GeneralUtils.getHoursFromMinutes(
                      zone.temperature_breach_duration_mins
                    );
                  const average_value = GeneralUtils.getFormattedNumberWithUnit(
                    zone.temperature_average,
                    "°C",
                    1
                  );
                  const min_value = GeneralUtils.getFormattedNumberWithUnit(
                    zone.temperature_min,
                    "°C",
                    1
                  );
                  const max_value = GeneralUtils.getFormattedNumberWithUnit(
                    zone.temperature_max,
                    "°C",
                    1
                  );
                  return (
                    <tr
                      key={zone.zone_id}
                      className="hover-row"
                      onClick={(e) => this._zoneClick(e, zone.zone_id)}
                    >
                      <td className="valign-middle tx-inverse ">
                        {zone.temperature_name}
                      </td>
                      <td className="valign-middle tx-inverse tx-center">
                        {number_of_breaches}
                      </td>
                      <td className="valign-middle tx-inverse tx-center">
                        {total_duration_of_breaches}
                      </td>
                      <td className="valign-middle tx-inverse tx-center">
                        {average_value}
                      </td>
                      <td className="valign-middle tx-inverse tx-center">
                        {min_value}
                      </td>
                      <td className="valign-middle tx-inverse tx-center">
                        {max_value}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
        {show_humidity_breaches && (
          <div className="building-breach-table-wrapper table-responsive">
            <h5 className="tx-inverse tx-roboto tx-normal mg-b-5">
              Humidity Breaches{" "}
            </h5>
            <table
              className="table mg-b-20"
              id="building-humidity-breaches-table"
            >
              {this.getBuildingBreachTableHeader("humidity")}
              <tbody>
                {humidity_breaches.length === 0 && (
                  <tr key={"no zones"}>
                    <td colSpan="5" className="valign-middle tx-inverse">
                      No breaches found
                    </td>
                  </tr>
                )}
                {humidity_breaches.map((zone) => {
                  const number_of_breaches =
                    GeneralUtils.getFormattedNumberWithUnit(
                      zone.humidity_breaches
                    );
                  const total_duration_of_breaches =
                    GeneralUtils.getFormattedNumberWithUnit(
                      zone.humidity_breach_duration_mins,
                      " mins"
                    );
                  const average_value = GeneralUtils.getFormattedNumberWithUnit(
                    zone.humidity_average,
                    "%",
                    1
                  );
                  const min_value = GeneralUtils.getFormattedNumberWithUnit(
                    zone.humidity_min,
                    "%",
                    1
                  );
                  const max_value = GeneralUtils.getFormattedNumberWithUnit(
                    zone.humidity_max,
                    "%",
                    1
                  );

                  return (
                    <tr
                      key={zone.zone_id}
                      className="hover-row"
                      onClick={(e) => this._zoneClick(e, zone.zone_id)}
                    >
                      <td className="valign-middle tx-inverse ">
                        {zone.humidity_name}
                      </td>
                      <td className="valign-middle tx-inverse tx-center">
                        {number_of_breaches}
                      </td>
                      <td className="valign-middle tx-inverse tx-center">
                        {total_duration_of_breaches}
                      </td>
                      <td className="valign-middle tx-inverse tx-center">
                        {average_value}
                      </td>
                      <td className="valign-middle tx-inverse tx-center">
                        {min_value}
                      </td>
                      <td className="valign-middle tx-inverse tx-center">
                        {max_value}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
        {no_breaches && <p>No breaches found</p>}
      </div>
    );
  }

  viewKPIBreaches(event, zones, kpi, estate) {
    event.stopPropagation();

    this.setState({
      modalOpen: true,
      modalType: "KPI",
      modalData: {
        zones: zones,
        kpi: kpi,
        estate: estate,
      },
    });
  }

  viewBuildingBreaches(event, building, kpi) {
    event.stopPropagation();

    this.setState({
      modalOpen: true,
      modalType: "building",
      modalData: {
        building: building,
        kpi: kpi,
      },
    });
  }

  _generateCSV(target, file_name) {
    if (document.getElementById(target) !== null) {
      var html = document.getElementById(target);
      let csv = [];
      let rows = html.querySelectorAll("table tr");

      for (let i = 0; i < rows.length; i++) {
        let row = [],
          cols = rows[i].querySelectorAll("td, th");

        for (let j = 0; j < cols.length; j++) row.push(cols[j].innerText);

        csv.push(row.join(";"));
      }
      let csvFile;
      let downloadLink;

      // CSV FILE
      csvFile = new Blob([csv.join("\n")], { type: "text/csv" });
      downloadLink = document.createElement("a");
      downloadLink.download = file_name;
      downloadLink.href = window.URL.createObjectURL(csvFile);
      downloadLink.style.display = "none";
      document.body.appendChild(downloadLink);
      downloadLink.click();
    }
  }

  _downloadCSV(table_target, target_name) {
    if (table_target === "all") {
      this._generateCSV(
        "building-co2-breaches-table",
        "CO2 Breaches For " + target_name
      );
      this._generateCSV(
        "building-temperature-breaches-table",
        "Temperature Breaches For " + target_name
      );
      this._generateCSV(
        "building-humidity-breaches-table",
        "Humidity Breaches For " + target_name
      );
    } else if (table_target === "building-co2-breaches-table") {
      this._generateCSV(table_target, "CO2 Breaches For " + target_name);
    } else if (table_target === "building-temperature-breaches-table") {
      this._generateCSV(
        table_target,
        "Temperature Breaches For " + target_name
      );
    } else if (table_target === "building-humidity-breaches-table") {
      this._generateCSV(table_target, "Humidity Breaches For " + target_name);
    } else {
      this._generateCSV(table_target, target_name);
    }
  }

  getKPIBreachTable(zones, kpi, estate) {
    let buildings = estate.buildings;
    let label, unit, fractionDigits;

    if (kpi === "temperature") {
      label = "Temperature Breaches";
      unit = "°C";
      fractionDigits = 1;
    } else if (kpi === "co2") {
      label = "CO2 Breaches";
      unit = " ppm";
      fractionDigits = 0;
    } else if (kpi === "humidity") {
      label = "Humidity Breaches";
      unit = "%";
      fractionDigits = 1;
    }

    return (
      <div>
        <div className="d-flex justify-content-between">
          <div>
            <h4 className="tx-inverse tx-roboto tx-normal mg-b-10">
              {label} for {estate.estate_name}
            </h4>
            <p className="pd-b-0 mg-b-0">
              Data for{" "}
              {moment(this.state.ts_start * 1000).format("MMM Do YY, hh:mm a")}{" "}
              - {moment(this.state.ts_end * 1000).format("MMM Do YY, hh:mm a")}
            </p>
            <button
              onClick={(e) =>
                this._downloadCSV(
                  "estate-breaches-table",
                  label + " For " + estate.estate_name
                )
              }
              className="btn btn-outline-info pd-5 tx-12 mg-t-5 mg-b-10"
            >
              Download Table To CSV
            </button>
          </div>
          <Icon name='Close' size={30} color='#868ba1' onClick={this._turnOffModal} style={{ cursor: 'pointer' }} />
        </div>
        <table className="table mg-b-0" id="estate-breaches-table">
          {this.getKPIBreachTableHeader(kpi)}
          <tbody>
            {zones.length === 0 && (
              <tr key={"no zones"}>
                <td colSpan="6" className="valign-middle tx-inverse">
                  No breaches found
                </td>
              </tr>
            )}
            {_.orderBy(zones, this.state.sortBy, this.state.sortDirection).map(
              (zone) => {
                const number_of_breaches =
                  GeneralUtils.getFormattedNumberWithUnit(
                    zone[kpi + "_breaches"]
                  );
                const building_name = buildings.filter(
                  (building) => building.building_id === zone.fk_building_id
                )[0].name;
                const total_duration_of_breaches =
                  GeneralUtils.getHoursFromMinutes(
                    zone[kpi + "_breach_duration_mins"]
                  );
                const average_value = GeneralUtils.getFormattedNumberWithUnit(
                  zone[kpi + "_average"],
                  unit,
                  fractionDigits
                );
                const min_value = GeneralUtils.getFormattedNumberWithUnit(
                  zone[kpi + "_min"],
                  unit,
                  fractionDigits
                );
                const max_value = GeneralUtils.getFormattedNumberWithUnit(
                  zone[kpi + "_max"],
                  unit,
                  fractionDigits
                );

                return (
                  <tr
                    key={zone.zone_id}
                    className="hover-row"
                    onClick={(e) => this._zoneClick(e, zone.zone_id)}
                  >
                    <td className="valign-middle tx-inverse ">{zone.name}</td>
                    <td className="valign-middle tx-inverse tx-center">
                      {building_name}
                    </td>
                    <td className="valign-middle tx-inverse tx-center">
                      {number_of_breaches}
                    </td>
                    <td className="valign-middle tx-inverse tx-center">
                      {total_duration_of_breaches}
                    </td>
                    <td className="valign-middle tx-inverse tx-center">
                      {average_value}
                    </td>
                    <td className="valign-middle tx-inverse tx-center">
                      {min_value}
                    </td>
                    <td className="valign-middle tx-inverse tx-center">
                      {max_value}
                    </td>
                  </tr>
                );
              }
            )}
          </tbody>
        </table>
      </div>
    );
  }

  _turnOffModal() {
    this.setState({ modalOpen: false });
  }

  getModal() {
    let element;
    const modalData = this.state.modalData;
    if (this.state.modalOpen === false) return null;

    if (this.state.modalType === "KPI") {
      element = this.getKPIBreachTable(
        modalData.zones,
        modalData.kpi,
        modalData.estate
      );
    } else if (this.state.modalType === "building") {
      element = this.getBuildingBreachTable(modalData.building, modalData.kpi);
    }

    return <Modal toggleOpen={this._turnOffModal}>{element}</Modal>;
  }

  getEstateRows() {
    const estates = this.state.estates;
    const filter = this.state.filter;

    return estates.map((estate) => {
      let zones_with_breaches = estate.buildings.map((building) => {
        return [...building.zones];
      });

      const filterCheck =
        estate.estate_name.toLowerCase().includes(filter.toLowerCase()) ||
        estates.length === 1;
      const buildingNameCheck =
        estate.buildings.filter((building) =>
          building.name.toLowerCase().includes(filter.toLowerCase())
        ).length > 0;

      if (
        zones_with_breaches.length > 0 &&
        zones_with_breaches[0].length > 0 &&
        (filterCheck || buildingNameCheck)
      ) {
        return (
          <div key={estate.estate_name}>
            <div className="row mg-b-20 pd-x-30 pr-md-0 justify-content-center">
              {this.getBreachesCards(estate)}
              {this.getBuildingsCards(estate)}
            </div>
          </div>
        );
      } else if (
        filter &&
        filterCheck === false &&
        buildingNameCheck === false
      ) {
        return null;
      } else {
        return <div key={estate.estate_name}></div>;
      }
    });
  }

  getBreachesCards(estate) {
    const green = "#3e8e7e";
    const red = "#d04648";

    let temperature_breaches = [];
    let co2_breaches = [];
    let humidity_breaches = [];

    let zones_with_breaches = estate.buildings.map((building) => {
      return [...building.zones];
    });

    _.flatten(zones_with_breaches).forEach((zone) => {
      if (zone.temperature_breaches > 0)
        temperature_breaches = [...temperature_breaches, zone];
      if (zone.co2_breaches > 0) co2_breaches = [...co2_breaches, zone];
      if (zone.humidity_breaches > 0)
        humidity_breaches = [...humidity_breaches, zone];
    });

    return (
      <div className="card breaches-cards-wrapper bd-0 shadow-base col-12 col-sm-2 mg-b-10">
        <h5 className="tx-archivo-semibold mg-b-5">{estate.estate_name}</h5>
        <div className="row">
          <div className="col-12 mg-b-0">
            <h6>CO2 Breaches</h6>
            <div
              className="card"
              onClick={(e) =>
                this.viewKPIBreaches(e, co2_breaches, "co2", estate)
              }
              style={{ background: co2_breaches.length > 0 ? red : green }}
            >
              <div className="card-body">
                <h1>{co2_breaches.length}</h1>
                <span>{co2_breaches.length === 1 ? "zone" : "zones"}</span>
                <span className="view-button">View</span>
              </div>
            </div>
          </div>
          <div className="col-12 mg-b-0">
            <h6>Temperature Breaches</h6>
            <div
              onClick={(e) =>
                this.viewKPIBreaches(
                  e,
                  temperature_breaches,
                  "temperature",
                  estate
                )
              }
              className="card"
              style={{
                background: temperature_breaches.length > 0 ? red : green,
              }}
            >
              <div className="card-body">
                <h1>{temperature_breaches.length}</h1>
                <span>
                  {temperature_breaches.length === 1 ? "zone" : "zones"}
                </span>
                <span className="view-button">View</span>
              </div>
            </div>
          </div>
          <div className="col-12 mg-b-0">
            <h6>Humidity Breaches</h6>
            <div
              className="card"
              onClick={(e) =>
                this.viewKPIBreaches(e, humidity_breaches, "humidity", estate)
              }
              style={{ background: humidity_breaches.length > 0 ? red : green }}
            >
              <div className="card-body">
                <h1>{humidity_breaches.length}</h1>
                <span>{humidity_breaches.length === 1 ? "zone" : "zones"}</span>
                <span className="view-button">View</span>
              </div>
            </div>
          </div>
        </div>
        <QRForEstate estate={estate} />
      </div>
    );
  }

  getBuildingsCards(estate) {
    const green = "#3e8e7e";
    const red = "#d04648";

    const tick_icon_small = <Icon name="CheckCircle" color={green} />;
    const warning_icon_small = <Icon name="Warning" color={red} />;

    return (
      <div className="buildings-cards-wrapper col-12 col-sm">
        <div className="row justify-content-start mg-x-0 ml-sm-20 buildings-wrapper">
          {estate.buildings
            .filter((building) =>
              building.name
                .toLowerCase()
                .includes(this.state.filter.toLowerCase())
            )
            .map((building) => {
              let temperature_breaches = [];
              let temperature_breaches_duration = 0;
              let co2_breaches = [];
              let co2_breaches_duration = 0;
              let humidity_breaches = [];
              let humidity_breaches_duration = 0;

              building.zones.forEach((zone) => {
                if (zone.temperature_breaches > 0)
                  temperature_breaches = [...temperature_breaches, zone];
                temperature_breaches_duration +=
                  zone.temperature_breach_duration_mins;

                if (zone.co2_breaches > 0)
                  co2_breaches = [...co2_breaches, zone];
                co2_breaches_duration += zone.co2_breach_duration_mins;

                if (zone.humidity_breaches > 0)
                  humidity_breaches = [...humidity_breaches, zone];
                humidity_breaches_duration +=
                  zone.humidity_breach_duration_mins;
              });

              const hasBreaches =
                [...co2_breaches, ...humidity_breaches, ...temperature_breaches]
                  .length > 0;
              const icon = hasBreaches ? warning_icon_small : tick_icon_small;

              return (
                <div
                  key={building.building_id}
                  className={
                    hasBreaches
                      ? "order-0 col pd-x-15 pr-md-0"
                      : "order-1 col pd-x-15 pr-md-0"
                  }
                >
                  <div
                    onClick={(e) => this.viewBuildingBreaches(e, building)}
                    className="card building-card shadow-base col-12 pd-0"
                  >
                    <div className="card-header justify-content-between align-items-center d-flex">
                      <h5 className="tx-archivo-semibold">{building.name}</h5>
                      {icon}
                    </div>
                    <div className="card-body table-wrapper">
                      <table className="table mg-b-0">
                        <thead>
                          <tr>
                            <th className="wd-40p ">Breaches</th>
                            <th className="wd-30p tx-center">Zones</th>
                            <th className="wd-30p tx-center">Duration</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            className="hover-row"
                            onClick={(e) =>
                              this.viewBuildingBreaches(e, building, "co2")
                            }
                            style={{
                              color: co2_breaches.length > 0 ? red : "#343a40",
                            }}
                          >
                            <td className="valign-middle ">CO2</td>
                            <td className="valign-middle  tx-center">
                              {co2_breaches.length}
                            </td>
                            <td className="valign-middle  tx-center">
                              {GeneralUtils.getHoursFromMinutes(
                                co2_breaches_duration
                              )}
                            </td>
                          </tr>
                          <tr
                            className="hover-row"
                            onClick={(e) =>
                              this.viewBuildingBreaches(
                                e,
                                building,
                                "temperature"
                              )
                            }
                            style={{
                              color:
                                temperature_breaches.length > 0
                                  ? red
                                  : "#343a40",
                            }}
                          >
                            <td className="valign-middle ">Temperature</td>
                            <td className="valign-middle  tx-center">
                              {temperature_breaches.length}
                            </td>
                            <td className="valign-middle  tx-center">
                              {GeneralUtils.getHoursFromMinutes(
                                temperature_breaches_duration
                              )}
                            </td>
                          </tr>
                          <tr
                            className="hover-row"
                            onClick={(e) =>
                              this.viewBuildingBreaches(e, building, "humidity")
                            }
                            style={{
                              color:
                                humidity_breaches.length > 0 ? red : "#343a40",
                            }}
                          >
                            <td className="valign-middle ">Humidity</td>
                            <td className="valign-middle  tx-center">
                              {humidity_breaches.length}
                            </td>
                            <td className="valign-middle  tx-center">
                              {GeneralUtils.getHoursFromMinutes(
                                humidity_breaches_duration
                              )}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              );
            })}
          <div className="col order-2"></div>
          <div className="col order-2"></div>
          <div className="col order-2"></div>
          <div className="col order-2"></div>
          <div className="col order-2"></div>
          <div className="col order-2"></div>
          <div className="col order-2"></div>
          <div className="col order-2"></div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div id="IndoorConditions" className="br-mainpanel floorplan-background">
        <DocumentTitle
          title={"Conditions Overview"}
          notifications={this.getTotalBreaches()}
        />
        <div className="br-container">
          <div className="br-pagetitle mg-b-25 pd-x-0 mg-t-5 wd-100p float-left d-flex flex-column flex-md-row"></div>
          {this.getModal()}
          {this.state.loading ? (
            <LogoSpinner loading={this.state.loading} />
          ) : (
            this.getEstateRows()
          )}
        </div>
      </div>
    );
  }
}

export default IndoorConditions;

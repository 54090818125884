import { EventEmitter } from "events";
import Dispatcher from "../dispatcher";
import ActionTypes from "../constants";

const CHANGE = "COUNTER_CHANGE";
const COUNTER_OPERATION = "COUNTER_OPERATION";

let _counters = [];

class CounterStore extends EventEmitter {
  constructor() {
    super();
    // Registers action handler with the Dispatcher.
    Dispatcher.register(this._registerToActions.bind(this));
  }

  // Switches over the action's type when an action is dispatched.
  _registerToActions(action) {
    switch (action.actionType) {
      case ActionTypes.COUNTERS_FETCHED:
        this._storeCounters(action.payload);
        break;
      case ActionTypes.COUNTER_UPDATED:
        this._updateCounter(action.payload);
        break;
      case ActionTypes.COUNTER_SAVED:
        this._saveCounter(action.payload);
        break;
      case ActionTypes.COUNTER_DELETED:
        this._deleteCounter(action.payload);
        break;
      default:
        break;
    }
  }

  // Hooks a React component's callback to the CHANGED event.
  addChangeListener(callback) {
    this.on(CHANGE, callback);
  }
  addCounterOperationListener(callback) {
    this.on(COUNTER_OPERATION, callback);
  }

  // Removes the listener from the CHANGED event.
  removeChangeListener(callback) {
    this.removeListener(CHANGE, callback);
  }
  removeCounterOperationListener(callback) {
    this.removeListener(COUNTER_OPERATION, callback);
  }

  clear() {
    _counters = [];
  }

  _storeCounters(json) {
    _counters = json;
    this.emit(CHANGE);
  }

  _saveCounter() {
    this.emit(COUNTER_OPERATION);
  }

  _updateCounter(json) {
    if (json.counter_id) {
      for (let i = 0; i < _counters.length; i++) {
        if (_counters[i].counter_id === json.counter_id) {
          _counters[i] = json;
          break;
        }
      }
    }
    this.emit(COUNTER_OPERATION);
  }
  _deleteCounter(json) {
    this.emit(COUNTER_OPERATION);
  }

  // Getters

  getCounters() {
    return _counters;
  }
}

const counterStore = new CounterStore();
export default counterStore;

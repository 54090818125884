import React, { Component } from "react";
import PropTypes from "prop-types";
import { Icon } from "../Icon";
import "./Table.scss";
import _ from "lodash";
import { v4 } from "uuid";

class Table extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sortBy: this.props.sortBy || "",
      sortDirection: this.props.sortDirection || "asc",
      loading: false,
      inputFilter: "",
      tagFilter: [],
    };
    this.getSortIcon = this.getSortIcon.bind(this);
    this.changeSortBy = this.changeSortBy.bind(this);
    this.handleTagClick = this.handleTagClick.bind(this);
    this.getTagFilter = this.getTagFilter.bind(this);
    this.filterData = this.filterData.bind(this);
  }

  componentDidMount() {
    this.initializeFilters();
  }

  componentWillUnmount() {
    this.setFilterSettings();
  }

  initializeFilters() {
    const savedSettings = this.getFilterSettings();
    if (savedSettings) {
      this.setState({
        sortBy: savedSettings.sortBy || this.state.sortBy,
        sortDirection: savedSettings.sortDirection || this.state.sortDirection,
        inputFilter: savedSettings.inputFilter || this.state.inputFilter,
        tagFilter: savedSettings.tagFilter || this.state.tagFilter,
      });
    } else {
      if (this.props.tagFilters && !this.props.multiTagFilter) {
        const default_filter = this.props.tagFilters.find((f) => f.default);
        if (default_filter) {
          this.setState({ tagFilter: [default_filter.label] });
        }
      }
      this.setFilterSettings();
    }
  }

  getFilterSettings() {
    const settings = sessionStorage.getItem(
      `${this.props.id}TableFilterSettings`
    );
    return settings ? JSON.parse(settings) : false;
  }

  setFilterSettings() {
    const filterSettings = {
      sortBy: this.state.sortBy,
      sortDirection: this.state.sortDirection,
      inputFilter: this.state.inputFilter,
      tagFilter: this.state.tagFilter,
    };
    sessionStorage.setItem(
      `${this.props.id}TableFilterSettings`,
      JSON.stringify(filterSettings)
    );
  }

  getSortIcon(column) {
    if (this.state.sortBy === column && this.state.sortDirection === "asc")
      return (
        <Icon
          name="ArrowUp"
          color='#868ba1'
          style={{ marginTop: "2px" }}
        />
      );
    if (this.state.sortBy === column && this.state.sortDirection === "desc")
      return (
        <Icon
          name="ArrowDown"
          color='#868ba1'
          style={{ marginTop: "2px" }}
        />
      );
    return null;
  }

  changeSortBy(sortBy) {
    if (this.state.sortBy !== sortBy) {
      this.setState({ sortBy: sortBy, sortDirection: "asc" });
    } else {
      if (this.state.sortDirection === "asc") {
        this.setState({ sortDirection: "desc" });
      } else {
        this.setState({ sortDirection: "asc" });
      }
    }
  }

  getHeaders() {
    return (
      <thead>
        <tr>
          {this.props.headers.map((hdr) => {
            let classes = "cursor-pointer tx-archivo-semibold";

            if (hdr.sticky) {
              classes += " sticky";
            }
            if (hdr.width) {
              classes = classes + ` wd-${hdr.width}p`;
            }

            return (
              <th
                key={hdr.label}
                className={classes}
                onClick={
                  hdr.unsortable
                    ? undefined
                    : () => this.changeSortBy(hdr.accessor)
                }
              >
                {hdr.label}{" "}
                {hdr.unsortable ? null : this.getSortIcon(hdr.accessor)}
              </th>
            );
          })}
        </tr>
      </thead>
    );
  }

  filterData() {
    const sort_by_value = this.state.sortBy;
    const sort_direction = this.state.sortDirection;
    const input_filter = this.state.inputFilter.toLowerCase();
    const tag_filters = this.state.tagFilter;
    const filterableAccessors = this.props.headers
      .filter((e) => e.filterable)
      .map((f) => f.accessor);

    let data = this.props.data;

    // Input Filtering
    if (filterableAccessors.length) {
      data = data.filter((d) => {
        let match = false;

        filterableAccessors.forEach((g) => {
          const value = _.get(d, g);
          if (value && value.toLowerCase().includes(input_filter)) {
            match = true;
          }
        });

        return match;
      });
    }

    // Tag Filtering for multifiltering
    if (this.props.tagFilters) {
      tag_filters.forEach((tag) => {
        const filter = this.props.tagFilters.find((obj) => obj.label === tag);

        data = data.filter((entry) => {
          return filter.condition(entry);
        });
      });
    }

    // Push nulls to always be at the bottom, no matter the sorting
    const nulls = _.filter(data, [sort_by_value, null]);
    const nonNulls = _.reject(data, [sort_by_value, null]);

    const sortedNonNulls = _.orderBy(
      nonNulls,
      [
        (entity) => {
          const val = _.get(entity, sort_by_value);
          if (typeof val === "string") {
            return val.trim().toLowerCase();
          }
          return val;
        },
      ],
      [sort_direction]
    );

    // Combine sorted non-null values with null values at the bottom and return
    return _.concat(sortedNonNulls, nulls);
  }

  getRows(data) {
    if (data.length === 0) {
      return (
        <tbody>
          <tr>
            <td colSpan={this.props.headers.length}>
              <div className="d-flex align-items-center">
                <div>
                  <div className="tx-inverse">No data available.</div>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      );
    }

    if (this.props.loading) {
      return (
        <tbody>
          {data.map((m, index) => {
            const getPlaceholder = () => (
              <td key={v4()} className="valign-middle">
                <div
                  className="d-flex align-items-center cell-container shimmerBG"
                  style={{ height: "42px", borderRadius: "5px" }}
                ></div>
              </td>
            );

            return (
              <tr key={index}>
                {this.props.headers.map((hdr) => {
                  return getPlaceholder();
                })}
              </tr>
            );
          })}
        </tbody>
      );
    }

    return (
      <tbody>
        {this.filterData().map((entry, index) => {
          let classes = "";
          if (this.props.rowClickHandler) classes = "hover-row cursor-pointer";
          return (
            <tr
              className={classes}
              key={index}
              onClick={
                this.props.rowClickHandler
                  ? (e) => this.props.rowClickHandler(entry)
                  : undefined
              }
            >
              {this.props.headers.map((hdr, index) => {
                let value = _.get(entry, hdr.accessor);

                if (value !== undefined && value !== null && hdr.formatter) {
                  value = hdr.formatter(value);
                }

                if (hdr.customCell) return hdr.customCell(index, entry);

                return this.getDefaultCell(index, value);
              })}
            </tr>
          );
        })}
      </tbody>
    );
  }

  getDefaultCell(key, value) {
    if (value === undefined || value === null) {
      return (
        <td key={key} className="tx-14 not-available">
          N/A
        </td>
      );
    }

    return (
      <td key={key} className="tx-14">
        {value}
      </td>
    );
  }

  getInputFilter() {
    let number_of_entries = this.filterData().length;

    return (
      <div className="filter-and-addnew">
        <input
          className="input-filter"
          type="text"
          value={this.state.inputFilter}
          placeholder={
            number_of_entries
              ? `Search in ${number_of_entries} entries...`
              : "Search..."
          }
          onChange={(e) => this.handleInputFilterChange(e.target.value)}
        />
        {this.props.addNewButton && (
          <div
            className="add-new-button"
            onClick={
              this.props.addNewButton
                ? this.props.addNewButton.clickHandler
                : undefined
            }
          >
            <span className="plus-sign">+</span>
            {this.props.addNewButton.label}
          </div>
        )}
      </div>
    );
  }

  handleInputFilterChange(value) {
    this.setState({ inputFilter: value });
  }

  getTagFilter() {
    if (this.props.tagFilters === undefined) {
      return <div className="tag-filter"></div>;
    }

    return (
      <div className="tag-filter">
        {this.props.tagFilters.map((tag) => {
          let classes = "tag tx-archivo-medium";
          if (this.state.tagFilter.includes(tag.label)) {
            classes += " active";
          }

          return (
            <div
              key={tag.label}
              className={classes}
              onClick={() => this.handleTagClick(tag.label)}
            >
              {tag.label}
            </div>
          );
        })}
      </div>
    );
  }

  handleTagClick(label) {
    const tagFilter = this.state.tagFilter;

    if (this.props.multiTagFilter) {
      if (tagFilter.includes(label) === false) {
        this.setState({
          tagFilter: [...tagFilter, label],
        });
      } else {
        this.setState({
          tagFilter: tagFilter.filter((tag) => tag !== label),
        });
      }
    } else {
      if (tagFilter.includes(label) === false) {
        this.setState({
          tagFilter: [label],
        });
      } else {
        this.setState({
          tagFilter: [],
        });
      }
    }
  }

  render() {
    return (
      <div className="Table" style={{ width: this.props.width }}>
        <div className="filters-container">
          {this.getInputFilter()}
          {this.getTagFilter()}
        </div>
        <div
          className="card bd-0 shadow-base mg-t-10 mg-b-20 wd-100p table-container"
          style={{ maxHeight: this.props.height ? this.props.height : "80vh" }}
        >
          <table className="table mg-b-0 table-contact">
            {this.getHeaders()}
            {this.getRows(this.filterData())}
          </table>
        </div>
      </div>
    );
  }
}

Table.defaultProps = {
  id: "",
  data: [],
  headers: [],
  tagFilters: [],
  height: "80vh",
  width: "100%",
  inputFilter: true,
};

Table.propTypes = {
  id: PropTypes.string.isRequired, // Must be unique across all table components
  data: PropTypes.array, // Array of Objects to power the table
  headers: PropTypes.arrayOf(
    // Headers to power the thead element
    PropTypes.shape({
      label: PropTypes.string.isRequired, // header text
      accessor: PropTypes.string.isRequired, // which value does it access in the object
      width: PropTypes.number, // column width
      filterable: PropTypes.bool, // is this column considered for input filtering
      unsortable: PropTypes.bool, // is this column available for sorting
      formatter: PropTypes.func, // optional function formatting the value
    })
  ),
  tagFilters: PropTypes.arrayOf(
    // tag filters found in the top-right corner
    PropTypes.shape({
      label: PropTypes.string.isRequired, // Label on filtering tag
      condition: PropTypes.func.isRequired, // function that checks how the filter behaves, has to return true to display the row (e.g. return obj.co2 < 2000 for 'High CO2')
      default: PropTypes.bool, // whether the selected tag is default (like 'All' tag)
    })
  ),
  loading: PropTypes.bool, // Condition for displaying spinner
  height: PropTypes.string, // Height of the table container _alone_ has to be string -> '200px' or '70vh'
  width: PropTypes.string, // Width of the table container _alone_ has to be string ->
  sortBy: PropTypes.string, // Default sorting, as accessor can be nested e.g. 'meter.consumption'
  sortDirection: PropTypes.oneOf(["asc", "desc"]), // Default direction, either 'asc' or 'desc'
  multiTagFilter: PropTypes.bool, // Whether you can select several tag filter
  inputFilter: PropTypes.bool, // whether the Input filter is used
  rowClickHandler: PropTypes.func, // handler for row clicking
  addNewButton: PropTypes.shape({
    label: PropTypes.string,
    clickHandler: PropTypes.func,
  }),
};

export default Table;

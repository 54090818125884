import React, { PureComponent } from "react";
import DashboardsActions from "../../../actions/dashboardsActions";
import DashboardsStore from "../../../stores/dashboardsStore";
import moment from "moment";
import _ from "lodash";

import { TickingTime } from "../../../components/TickingTime";
import { DonutChart } from "../../../components/DonutChart";
import { Spinner } from "../../../components/Spinner";
import { DocumentTitle } from "../../../components/DocumentTitle";
import { Link } from "react-router-dom";
import { Websocket } from "../../../components/Websocket";
import { Icon } from "../../../components/Icon";
import GeneralUtils from "../../../utils/GeneralUtils";

class OccupancyStatusDashboardComponent extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      occupancyLevel: null,
      occupancyCount: null,
      zone: {
        threshold_target: 0,
      },
      websocketZoneData: [],
    };
    this._onZoneChange = this._onZoneChange.bind(this);
    this._fetchData = this._fetchData.bind(this);
  }

  _onZoneChange() {
    let zone = DashboardsStore.getOccupancyStatus();

    let occupancyCount = _.get(zone, "current_count");
    let occupancyCapacity = _.get(zone, "threshold_target");
    let occupancyLevel = null;
    if (occupancyCount && occupancyCapacity) {
      occupancyLevel = GeneralUtils.getFormattedNumberWithUnit(
        occupancyCount / occupancyCapacity,
        "%",
        0
      );
    }

    this.setState({
      occupancyCount: occupancyCount,
      occupancyLevel: occupancyLevel,
      zone: zone,
    });
  }
  UNSAFE_componentWillMount() {
    DashboardsStore.addDashboardDataFetchedListener(this._onZoneChange);
  }
  componentWillUnmount() {
    DashboardsStore.clear();
    DashboardsStore.removeDashboardDataFetchedListener(this._onZoneChange);
  }

  componentDidMount() {
    this._fetchData();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.websocketData &&
      prevProps.websocketData !== this.props.websocketData
    ) {
      let updatedState = this.state.websocketZoneData;
      if (
        this.state.websocketZoneData.filter(
          (el) => el.fkZoneId === this.props.websocketData.fkZoneId
        ).length === 0
      ) {
        updatedState = [
          ...this.state.websocketZoneData,
          this.props.websocketData,
        ];
      } else {
        updatedState = this.state.websocketZoneData;
        updatedState = updatedState.filter(
          (el) => el.fkZoneId !== this.props.websocketData.fkZoneId
        );
        updatedState = [...updatedState, this.props.websocketData];
      }
      this.setState({ websocketZoneData: updatedState });
    }
  }

  _fetchData() {
    const token = _.get(this.props, "match.params.token");

    if (token) {
      DashboardsActions.getDataWithToken(token);
    }
  }
  getScreenWidth() {
    let width = 0;
    if (typeof window.innerWidth == "number") {
      //Non-IE
      width = window.innerWidth;
    } else if (
      document.documentElement &&
      (document.documentElement.clientWidth ||
        document.documentElement.clientHeight)
    ) {
      //IE 6+ in 'standards compliant mode'
      width = document.documentElement.clientWidth;
    } else if (
      document.body &&
      (document.body.clientWidth || document.body.clientHeight)
    ) {
      //IE 4 compatible
      width = document.body.clientWidth;
    }
    return width;
  }
  getScreenHeight() {
    let height = 0;
    if (typeof window.innerHeight == "number") {
      //Non-IE
      height = window.innerHeight;
    } else if (
      document.documentElement &&
      (document.documentElement.clientHeight ||
        document.documentElement.clientHeight)
    ) {
      //IE 6+ in 'standards compliant mode'
      height = document.documentElement.clientHeight;
    } else if (
      document.body &&
      (document.body.clientHeight || document.body.clientHeight)
    ) {
      //IE 4 compatible
      height = document.body.clientHeight;
    }
    return height;
  }
  checkIfOffline(zone) {
    if (!zone) return true;
    if (zone.name === "") return true;
    if (this.props.websocketOnline === true) return false;
    if (zone && zone.uptime_stats) {
      const lastvalueDate = new Date(zone.uptime_stats.ts_last_data);
      const endDate = new Date(zone.uptime_stats.ts_end);
      const differenceInMinutes = Math.ceil(
        (endDate - lastvalueDate) / (1000 * 60)
      );
      return differenceInMinutes > 45;
    }
  }
  checkIfInaccurate(zone) {
    if (this.props.websocketOnline) {
      return false;
    }
    if (zone && zone.uptime_stats) {
      return zone.uptime_stats.uptime_percent <= 95;
    }
    return false;
  }
  getLogo(isMobile) {
    let marginBottom = "10vh";
    let rowClass = "row";
    let colClass = "col-12";
    if (isMobile) {
      marginBottom = "1vh";
      rowClass = "row tx-center bg-white pd-b-15";
      colClass = "col-12 tx-center";
    }

    let imgStyle = {
      width: isMobile ? "50vw" : "32vw",
      paddingLeft: isMobile ? "0" : "3vw",
      marginTop: "5vh",
    };

    if (this.state.zone.fk_building_id) {
      // Boole Postgrad Library
      if (this.state.zone.fk_building_id === 7) {
        imgStyle = {
          ...imgStyle,
          width: isMobile ? "70vw" : "32vw",
          marginTop: isMobile ? "15px" : "5vh",
        };
        return (
          <div className={rowClass} style={{ marginBottom: marginBottom }}>
            <div className={colClass}>
              {" "}
              <img
                src="/img/ucc/ucc-logo-1.png"
                alt="UCC Library Logo"
                style={imgStyle}
              />
            </div>
          </div>
        );
        // GMIT
      } else if (this.state.zone.fk_building_id === 44) {
        imgStyle = {
          paddingLeft: "3vw",
          width: isMobile ? "60vw" : "31vw",
          marginTop: isMobile ? "15px" : "4vh",
        };
        return (
          <div className={rowClass} style={{ marginBottom: marginBottom }}>
            <div className={colClass}>
              {" "}
              <img
                src="/img/atu/ATU-Logo-Long.jpeg"
                alt="ATU Logo"
                style={imgStyle}
              />
            </div>
          </div>
        );
        // The Hub, Restaurants Buildings, Main Quad, Kane Building
      } else if ([28, 29, 30, 32].includes(this.state.zone.fk_building_id)) {
        imgStyle = {
          ...imgStyle,
          width: isMobile ? "40vw" : "31vw",
          marginTop: isMobile ? "15px" : "5vh",
        };
        return (
          <div className={rowClass} style={{ marginBottom: marginBottom }}>
            <div className={colClass}>
              {" "}
              <img src="/img/ucc/ucc-logo.svg" alt="UCC Logo" style={imgStyle} />
            </div>
          </div>
        );
      } else {
        return <div style={{ marginBottom: "25px" }}></div>;
      }
    }
  }
  getInternalZones(enterMessage, hasWarning, isIframe = false) {
    let showLegend = false;
    let warningIcon = (
      <Icon name='Warning' color='orange' style={{ verticalAlign: 'text-top' }} />
    );

    if (this.state.zone.sub_zones && this.state.zone.sub_zones.length > 0) {
      let zoneDescriptor;
      let screenHeight = this.getScreenHeight();
      return (
        <div className="col-4" style={{ backgroundColor: "white" }}>
          {this.getLogo(false)}
          {this.state.zone.sub_zones.map((sub_zone, key) => {
            let subzoneWarning = false;
            if (this.checkIfInaccurate(sub_zone)) {
              subzoneWarning = true;
              showLegend = true;
            }
            let current_count = 0;
            if (sub_zone.current_count) {
              current_count = sub_zone.current_count;
            }

            if (
              this.state.websocketZoneData.filter(
                (el) => sub_zone.zone_id === el.fkZoneId
              ).length === 1
            ) {
              const subzoneObject = this.state.websocketZoneData.filter(
                (el) => sub_zone.zone_id === el.fkZoneId
              )[0];
              current_count = subzoneObject.runningCount;
            }

            let occLevel = Math.round(
              (current_count / sub_zone.threshold_target) * 100
            );
            occLevel = occLevel > 100 ? 100 : occLevel;

            if (screenHeight > 400) {
              zoneDescriptor = (
                <h4
                  style={{
                    color: "#000000",
                    fontSize: "2.2vh",
                    fontWeight: "100",
                    paddingLeft: "3vw",
                    marginBottom: "2vh",
                  }}
                >
                  Occupants <strong>{current_count}</strong> / Capacity{" "}
                  <strong>{sub_zone.threshold_target}</strong>{" "}
                  {subzoneWarning && warningIcon}
                </h4>
              );
            }
            return (
              <div key={key} className="row">
                <div className="col-12">
                  <h1
                    style={{
                      color: "#000000",
                      fontSize: isIframe ? "4.4vh" : "4vh",
                      fontWeight: "200",
                      paddingLeft: "3vw",
                      paddingRight: "1vw",
                    }}
                  >
                    {sub_zone.name} ({occLevel}%)
                  </h1>
                  {zoneDescriptor}
                  <hr style={{ marginBottom: "2vh", marginTop: "0vh" }} />
                </div>
              </div>
            );
          })}
          <div
            className="row"
            style={{
              position: "absolute",
              bottom: "8vh",
              left: "3vw",
              right: "2vw",
              textAlign: "left",
            }}
          >
            <div className="col-12">
              <h1
                style={{
                  color: "#606060",
                  fontSize: "6vh",
                  fontWeight: "400",
                  marginBottom: "1vh",
                }}
              >
                <TickingTime />
              </h1>
              {hasWarning || showLegend ? (
                <p
                  style={{
                    color: "#606060",
                    textAlign: "left",
                    fontSize: "2vh",
                    fontWeight: "200",
                  }}
                >
                  <Icon
                    name="Warning"
                    color="orange"
                    size={28}
                    style={{
                      verticalAlign: "sub",
                    }}
                  />{" "}
                  Outage: Numbers may be inaccurate.
                </p>
              ) : null}
              <div className="col-12 pd-0">
                {" "}
                <h1
                  style={{
                    color: "#606060",
                    textAlign: "left",
                    fontSize: "2vh",
                    fontWeight: "200",
                  }}
                >
                  Powered By{" "}
                  <Link
                    to={{ pathname: "https://www.opnbuildings.com" }}
                    target="_blank"
                  >
                    <img
                      style={{
                        width: "200px",
                      }}
                      alt="OPNBuildings Logo"
                      src="/img/opn/OPNLogo.png"
                    />
                  </Link>
                </h1>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="col-4" style={{ backgroundColor: "white" }}>
          {this.getLogo(false)}
          <div className="row">
            <div className="col-12">
              <h1
                style={{
                  color: "#000000",
                  fontSize: isIframe ? "4.4vh" : "4vh",
                  fontWeight: "100",
                  paddingLeft: "3vw",
                  paddingRight: "1vw",
                }}
              >
                <span style={{ fontWeight: "300" }}>CO2 levels</span> (target
                range: 600-800 ppm) and{" "}
                <span style={{ fontWeight: "300" }}>temperatures</span> (target
                range: 18-21°C) are continuously monitored to ensure your
                comfort.
              </h1>
            </div>
          </div>
          <div
            className="row"
            style={{
              position: "absolute",
              bottom: "4.5vh",
              left: "3vw",
              right: "2vw",
              textAlign: "left",
            }}
          >
            <div className="col-12">
              <h1
                style={{
                  color: "#606060",
                  fontSize: "6vh",
                  fontWeight: "400",
                  marginBottom: "1vh",
                }}
              >
                <TickingTime />
              </h1>
              {hasWarning || showLegend ? (
                <h1
                  style={{
                    color: "#606060",
                    textAlign: "left",
                    fontSize: "2vh",
                    fontWeight: "200",
                  }}
                >
                  <Icon
                    name="Warning"
                    color="orange"
                    style={{
                      verticalAlign: "sub",
                      fontSize: "2.8vh",
                    }}
                  />{" "}
                  Outage: Numbers may be inaccurate.
                </h1>
              ) : null}
              <div className="col-12 pd-0">
                {" "}
                <h1
                  style={{
                    color: "#606060",
                    textAlign: "left",
                    fontSize: "2vh",
                    fontWeight: "200",
                  }}
                >
                  Powered By{" "}
                  <Link
                    to={{ pathname: "https://www.opnbuildings.com" }}
                    target="_blank"
                  >
                    <img
                      style={{
                        width: "200px",
                      }}
                      alt="OPNBuildings Logo"
                      src="/img/opn/OPNLogo.png"
                    />
                  </Link>
                </h1>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }

  render() {
    const isOffline = this.checkIfOffline(this.state.zone);
    const isInaccurate = this.checkIfInaccurate(this.state.zone);
    const isIframe = window.location !== window.parent.location; // check if the component is in iframe
    const isMobile =
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
        ? true
        : false; // device detection for mobile view

    const warningIcon = isInaccurate ? (
      <Icon
        name="Warning"
        color="orange"
        style={{
          verticalAlign: "top",
          marginTop: "5px",
          marginLeft: "5px",
        }}
      />
    ) : null;
    const donutWidth = this.getScreenHeight() / 16;

    let primaryColor = "#259f6f";
    let primaryMessage = "Enter";
    let enterMessage = "Please Enter";

    const getOccupancyCount = () => {
      let occupancyCount = this.state.occupancyCount;
      if (
        this.state.websocketZoneData.filter(
          (el) => this.state.zone.zone_id === el.fkZoneId
        ).length === 1
      ) {
        const subzoneObject = this.state.websocketZoneData.filter(
          (el) => this.state.zone.zone_id === el.fkZoneId
        )[0];
        occupancyCount = subzoneObject.runningCount;
      }
      return occupancyCount;
    };

    const getOccupancyLevel = () => {
      let occupancyLevel = this.state.occupancyLevel;
      const thresholdTarget = _.get(this.state, "zone.threshold_target");
      const occupancyCount = getOccupancyCount();
      occupancyLevel = Math.round((occupancyCount / thresholdTarget) * 100);
      return occupancyLevel > 100 ? 100 : occupancyLevel;
    };

    let occupancyPercentage = isMobile ? (
      <div className="d-flex">
        <div
          style={{
            float: "left",
            marginRight: "5vw",
            marginLeft: "0.5vw",
            marginTop: "0.8vh",
          }}
        >
          <DonutChart
            strokeColor={"white"}
            transparentTrack={true}
            hideValue={true}
            trafficInverted={true}
            value={getOccupancyLevel()}
            size={38}
            textSize={8}
            percentSize={2}
          />
        </div>
        {getOccupancyLevel() && (
          <h1 style={{ color: "white", fontSize: "6vh", fontWeight: "300" }}>
            {getOccupancyLevel()}% full
          </h1>
        )}
      </div>
    ) : (
      <div className="col-12 mg-0 pd-0">
        <div
          style={{
            float: "left",
            marginRight: "2.5vw",
            marginLeft: "1.2vw",
            marginTop: "1.8vh",
          }}
        >
          <DonutChart
            strokeColor={"white"}
            transparentTrack={true}
            hideValue={true}
            trafficInverted={true}
            value={getOccupancyLevel()}
            size={donutWidth}
            textSize={8}
            percentSize={0}
          />
        </div>
        {getOccupancyLevel() && (
          <h1 style={{ color: "white", fontSize: "7.5vh", fontWeight: "300" }}>
            {getOccupancyLevel()}% full
          </h1>
        )}
      </div>
    );
    let occupancyPerMax = isMobile ? (
      <div className="d-flex">
        <div
          style={{
            float: "left",
            marginRight: "2.5vw",
            marginLeft: "0.5vw",
            color: "white",
            fontSize: "6vh",
            fontWeight: "300",
          }}
        >
          <Icon name="Users" color="white" size={60} style={{ verticalAlign: 'top' }} />
        </div>
        <h1 style={{ color: "white", fontSize: "6vh", fontWeight: "300" }}>
          {this.state.zone.fk_building_id === 28
            ? getOccupancyCount()
            : getOccupancyCount() + " / " + this.state.zone.threshold_target}
        </h1>
      </div>
    ) : (
      <div className="col-12 mg-0 pd-0">
        <div
          style={{
            float: "left",
            marginRight: "2vw",
            marginLeft: "0.5vw",
            color: "white",
            fontSize: "7.5vh",
            fontWeight: "300",
          }}
        >
          <Icon name="Users" color="white" size={75} style={{ verticalAlign: 'top' }} />
        </div>
        <h1 style={{ color: "white", fontSize: "7.5vh", fontWeight: "300" }}>
          {this.state.zone && this.state.zone.fk_building_id === 28
            ? getOccupancyCount()
            : getOccupancyCount() + " / " + this.state.zone.threshold_target}
          {warningIcon}
        </h1>
      </div>
    );

    if (getOccupancyLevel() > 90) {
      primaryColor = "#ff5722";
      primaryMessage = "Almost Full";
      enterMessage = "Limited space";
    }
    if (getOccupancyLevel() >= 99) {
      primaryColor = "#dc2e21";
      primaryMessage = "Wait";
      enterMessage = "Almost at capacity";
    }
    if (isOffline) {
      primaryColor = "#7e7e7e";
      primaryMessage = "Offline";
      enterMessage = "Sensor Offline";

      occupancyPercentage = isMobile ? (
        <div className="d-flex align-items-center">
          <div
            style={{
              marginTop: "0.8vh",
              color: "white",
              marginRight: "20px",
            }}
          >
            <Icon name="Clock" color="white" size={60} />
          </div>
          <h1 style={{ color: "white", fontSize: "3vh", fontWeight: "300" }}>
            Last online:{" "}
            {this.state.zone.uptime_stats &&
              moment(this.state.zone.uptime_stats.ts_last_data).format(
                "h:mm:ss A"
              )}
          </h1>
        </div>
      ) : (
        <div className="col-12 mg-5 pd-0 d-flex aling-items-center">
          <div
            className="d-flex align-items-center"
            style={{
              float: "left",
              marginRight: "2vw",
              marginLeft: "0.5vw",
              color: "white",
              fontWeight: "300",
            }}
          >
            <Icon name="Clock" color="white" size={75} />
          </div>
          <h1
            className="d-flex align-items-center"
            style={{ color: "white", fontSize: "4.5vh", fontWeight: "300" }}
          >
            Last online:{" "}
            {this.state.zone.uptime_stats &&
              moment(this.state.zone.uptime_stats.ts_last_data).format(
                "h:mm:ss A"
              )}
          </h1>
        </div>
      );

      occupancyPerMax = isMobile ? (
        <div className="d-flex align-items-center">
          <div
            style={{
              color: "white",
              fontWeight: "300",
              marginRight: "20px",
            }}
          >
            <Icon name="Users" color="white" size={60} style={{ verticalAlign: 'top' }} />
          </div>
          <h1 style={{ color: "white", fontSize: "3vh", fontWeight: "300" }}>
            Last count:{" "}
            {this.state.zone.fk_building_id === 28
              ? getOccupancyCount()
              : getOccupancyCount() + " / " + this.state.zone.threshold_target}
          </h1>
        </div>
      ) : (
        <div className="col-12 mg-5 pd-0 d-flex aling-items-center">
          <div
            className="d-flex align-items-center"
            style={{
              float: "left",
              marginRight: "2vw",
              marginLeft: "0.5vw",
              color: "white",
              fontWeight: "300",
            }}
          >
            <Icon name="Users" color="white" size={75} style={{ verticalAlign: 'top' }} />
          </div>
          <h1
            className="d-flex align-items-center"
            style={{ color: "white", fontSize: "4.5vh", fontWeight: "300" }}
          >
            Last count:{" "}
            {this.state.zone.fk_building_id === 28
              ? getOccupancyCount()
              : getOccupancyCount() + " / " + this.state.zone.threshold_target}
          </h1>
        </div>
      );
    }

    // No data returned yet
    if (!_.get(this.state, "zone.name")) return <Spinner />;

    // No occupancy count returned
    if (!_.get(this.state, "occupancyCount"))
      return (
        <div
          style={{
            width: "100vw",
            height: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            gap: "20px",
          }}
        >
          <Link
            to={{ pathname: "https://www.opnbuildings.com" }}
            target="_blank"
          >
            <img
              style={{
                width: "500px",
              }}
              alt="OPNBuildings Logo"
              src="/img/opn/OPNLogo.png"
            />
          </Link>
          <div
            style={{
              textAlign: "center",
            }}
          >
            <h2>No occupancy data found for:</h2>
            <Link
              to={{
                pathname: "/zones/" + _.get(this.state, "zone.zone_id"),
              }}
              target="_blank"
            >
              <h1>{_.get(this.state, "zone.name")}</h1>
            </Link>
          </div>
        </div>
      );

    if (isIframe) {
      return (
        <div className="row no-gutters ht-100v wd-100v">
          <div
            className="col-8"
            style={{ backgroundColor: primaryColor, paddingLeft: "20vh" }}
          >
            <div className="row">
              <div className="col-12 mg-0 pd-0">
                <h1
                  style={{
                    color: "white",
                    fontSize: "10vh",
                    fontWeight: "400",
                    marginTop: "5vh",
                  }}
                >
                  {this.state.zone.name}
                </h1>
              </div>
            </div>
            <div className="row" style={{ marginTop: "0vh" }}>
              <div className="col-12 mg-0 pd-0">
                <h1
                  style={{
                    color: "white",
                    fontSize: "28vh",
                    fontWeight: "400",
                    marginTop: "2vh",
                    letterSpacing: "-1vh",
                    marginLeft: "-1vw",
                  }}
                >
                  {primaryMessage}
                </h1>
              </div>
            </div>
            <div
              className="row"
              style={{ position: "fixed", bottom: "8vh", width: "100%" }}
            >
              {occupancyPercentage}
              {occupancyPerMax}
            </div>
          </div>
          {this.getInternalZones(enterMessage, isInaccurate, isIframe)}
        </div>
      );
    } else if (isMobile) {
      return (
        <div className="row no-gutters ht-100v wd-100v">
          <DocumentTitle
            title={this.state.zone.name}
            notifications={getOccupancyLevel() + "%"}
          />
          <div
            className="col-12 align-items-center justify-content-center"
            style={{ backgroundColor: primaryColor, overflowX: "hidden" }}
          >
            {this.getLogo(true)}
            <div className="row">
              <div className="col-12">
                <h1
                  style={{
                    color: "white",
                    textAlign: "center",
                    fontSize: "15vw",
                    fontWeight: "200",
                    marginTop: "4vh",
                  }}
                >
                  {this.state.zone.name}
                </h1>
                <h1
                  style={{
                    color: "white",
                    textAlign: "center",
                    fontSize: "4vw",
                    fontWeight: "100",
                    marginTop: "0vh",
                    marginBottom: "10vh",
                  }}
                >
                  {this.state.zone.description}
                </h1>
              </div>
            </div>
            <div
              className="row"
              style={{
                marginTop: "2vh",
                textAlign: "center",
                paddingRight: "1vw",
              }}
            >
              <div className="col-12">
                <h1
                  style={{
                    color: "white",
                    fontSize: "26vw",
                    letterSpacing: isOffline ? "3px" : null,
                  }}
                >
                  {primaryMessage}
                </h1>
                {isInaccurate && (
                  <div>
                    <Icon
                      name="Warning"
                      color="orange"
                      style={{ fontSize: "25vw" }}
                    />
                    <h1
                      style={{
                        color: "white",
                        fontSize: "25px",
                        margin: "10px",
                        fontWeight: "400",
                      }}
                    >
                      Due to outages the counts may be inaccurate.
                    </h1>
                  </div>
                )}
              </div>
            </div>
            <div
              className="row"
              style={{
                marginTop: "2vh",
                textAlign: "center",
                flexDirection: "column",
              }}
            >
              <div className="" style={{ margin: "auto" }}>
                {occupancyPercentage}
              </div>
              <div className="" style={{ margin: "auto" }}>
                {occupancyPerMax}
              </div>
            </div>
            <div className="row" style={{ marginTop: "6vh" }}>
              <div className="col-12">
                {" "}
                <h1
                  style={{
                    color: "white",
                    textAlign: "center",
                    fontSize: "4vh",
                    fontWeight: "400",
                    marginBottom: "10px",
                  }}
                >
                  <TickingTime />
                </h1>
              </div>
              <div className="col-12">
                {" "}
                <h1
                  style={{
                    color: "white",
                    textAlign: "center",
                    fontSize: "2vh",
                    fontWeight: "200",
                  }}
                >
                  Powered By{" "}
                  <Link
                    to={{ pathname: "https://www.opnbuildings.com" }}
                    target="_blank"
                  >
                    <strong>OPN</strong>Spaces{" "}
                  </Link>
                </h1>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="row no-gutters ht-100v wd-100v">
          <DocumentTitle
            title={this.state.zone.name}
            notifications={getOccupancyLevel() + "%"}
          />
          <div
            className="col-8"
            style={{ backgroundColor: primaryColor, paddingLeft: "8vw" }}
          >
            <div className="row">
              <div className="col-12 mg-0 pd-0">
                <h1
                  style={{
                    color: "white",
                    fontSize: "9vh",
                    fontWeight: "400",
                    marginTop: "5vh",
                  }}
                >
                  {this.state.zone.name}
                </h1>
                <h4
                  style={{
                    color: "white",
                    fontSize: "3.5vh",
                    fontWeight: "100",
                    marginTop: "0vh",
                  }}
                >
                  {this.state.zone.description}
                </h4>
              </div>
            </div>
            <div className="row" style={{ marginTop: "0vh" }}>
              <div className="col-12 mg-0 pd-0">
                <h1
                  style={{
                    color: "white",
                    fontSize: "17vw",
                    fontWeight: "400",
                    marginTop: "2vh",
                  }}
                >
                  {primaryMessage}
                </h1>
              </div>
            </div>
            <div
              className="row"
              style={{ position: "fixed", bottom: "8vh", width: "100%" }}
            >
              {occupancyPercentage}
              {occupancyPerMax}
            </div>
          </div>
          {this.getInternalZones(enterMessage, isInaccurate)}
        </div>
      );
    }
  }
}

class OccupancyStatusDashboard extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      liveZone: {
        zone_id: null,
        fk_organisation_id: null,
        fk_building_id: null,
        conn_token: null,
        conn_id: null,
      },
      subscriptions: [],
    };
    this.onFetch = this.onFetch.bind(this);
  }

  UNSAFE_componentWillMount() {
    DashboardsStore.addDashboardDataFetchedListener(this.onFetch);
  }

  componentDidMount() {
    const token = _.get(this.props, "match.params.token");
    if (token) DashboardsActions.getDataWithToken(token);
  }

  componentWillUnmount() {
    DashboardsStore.clear();
    DashboardsStore.removeDashboardDataFetchedListener(this.onFetch);
  }

  onFetch() {
    const data = DashboardsStore.getOccupancyStatus();

    let subscriptions = [];
    if (data.zone_id && data.fk_building_id && data.fk_organisation_id) {
      subscriptions = [
        `/topic/organisation.${data.fk_organisation_id}.building.${data.fk_building_id}.zone.${data.zone_id}.utilization`,
      ];
      if (data.sub_zones.length > 0) {
        data.sub_zones.forEach((subzone) => {
          subscriptions = [
            ...subscriptions,
            `/topic/organisation.${subzone.fk_organisation_id}.building.${subzone.fk_building_id}.zone.${subzone.zone_id}.utilization`,
          ];
        });
      }
    }

    this.setState({ subscriptions: subscriptions, liveZone: data });
  }

  render() {
    return (
      <Websocket
        destination={this.state.subscriptions}
        login={this.state.liveZone.conn_id}
        password={this.state.liveZone.conn_token}
        children={<OccupancyStatusDashboardComponent {...this.props} />}
      />
    );
  }
}

export default OccupancyStatusDashboard;
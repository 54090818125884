import ActionTypes from "../constants";
import GeneralUtils from "../utils/GeneralUtils";

/**
 * Actions related to automated assessments, including inventory processing, mapping and validation,
 * and energy performance classification reporting. This class interfaces with the API to perform various
 * actions, dispatching actions to update the application state.
 *
 * @class
 * @example
 * const automatedAssessmentsActions = new AutomatedAssessmentsActions();
 * automatedAssessmentsActions.getInventoryDocument('document123', 'estate456');
 */
class AutomatedAssessmentsActions {
  /**
   * Fetches an inventory document for a specific estate from the API and dispatches an action to store this data.
   *
   * @param {string} documentId - The unique identifier of the document.
   * @param {string} estateId - The unique identifier of the estate.
   * @returns {Promise} - A promise that resolves with the fetched data.
   */
  getInventoryDocument(documentId, estateId) {
    return GeneralUtils.get({
      url: `sheet/onboard/${documentId}/estate/${estateId}`,
      actionType: ActionTypes.INVENTORY_FETCHED,
      failToast: "Unable to find the document for this estate.",
    });
  }

  /**
   * Fetches the building mapping for a specific building from the API and dispatches an action to store this data.
   *
   * @param {string} buildingId - The unique identifier of the building.
   * @returns {Promise} - A promise that resolves with the fetched data.
   */

  getBuildingMapping(buildingId) {
    return GeneralUtils.get({
      url: `v2/mappings/building/${buildingId}`,
      actionType: ActionTypes.AUTOMATION_MAPPING_FETCHED,
      failToast: "Unable to retrieve the mapping for this building.",
    });
  }

  /**
   * Fetches the unique points for a specific building from the API and dispatches an action to store this data.
   *
   * @param {string} buildingId - The unique identifier of the building.
   * @returns {Promise} - A promise that resolves with the fetched data.
   */
  getBuildingUniquePoints(buildingId) {
    return GeneralUtils.get({
      url: `admin/building/${buildingId}/unique-points`,
      actionType: ActionTypes.AUTOMATION_UNIQUE_POINTS_FETCHED,
      failToast: "Unable to fetch Building's unique points",
    });
  }

  /**
   * Fetches an energy performance report using a token from the API and dispatches an action to store this data.
   *
   * @param {string} token - The token for accessing the report.
   * @returns {Promise} - A promise that resolves with the fetched data.
   */
  getEnergyPerformanceReportWithToken(token) {
    return GeneralUtils.get({
      url: `assessment/token/${token}`,
      actionType: ActionTypes.ASSESSMENT_REPORT_FETCHED,
      failToast: "Unable to retrieve the report.",
    });
  }

  /**
   * Fetches an energy performance report using an assessment ID from the API and dispatches an action to store this data.
   *
   * @param {string} id - The unique identifier of the assessment.
   * @returns {Promise} - A promise that resolves with the fetched data.
   */
  getEnergyPerformanceReportWithAssessmentId(id) {
    return GeneralUtils.get({
      url: `assessment/${id}`,
      actionType: ActionTypes.ASSESSMENT_REPORT_FETCHED,
      failToast: "Unable to retrieve the report.",
    });
  }

  /**
   * Fetches the chart data for an energy performance report using a token and category from the API and dispatches an action to store this data.
   *
   * @param {string} token - The token for accessing the report.
   * @param {string} category - The category of the chart data.
   * @returns {Promise} - A promise that resolves with the fetched data.
   */
  getEnergyPerformanceReportChartData(token, category) {
    return GeneralUtils.get({
      url: `assessment/token/${token}/category/${category}/charts`,
      actionType: ActionTypes.ASSESSMENT_REPORT_CHART_DATA_FETCHED,
      failToast: "Unable to retrieve the chart data.",
    });
  }

  /**
   * Shares an assessment report via email using a token from the API and dispatches an action to store this data.
   *
   * @param {string} email - The email address to share the report with.
   * @param {string} token - The token for accessing the report.
   * @returns {Promise} - A promise that resolves with the fetched data.
   */
  shareAssessment(email, token) {
    return GeneralUtils.get({
      url: `assessment/token/share/${token}?email=${email}`,
      actionType: ActionTypes.ASSESSMENT_REPORT_SHARED,
      failToast: "Unable to share the assessment.",
    });
  }

  /**
   * Saves a mapping to the API and dispatches an action to store this data.
   *
   * @param {Object} mapping - The mapping object to be saved.
   * @returns {Promise} - A promise that resolves with the response of the save operation.
   */
  saveMapping(mapping) {
    return GeneralUtils.post({
      url: "v2/mappings",
      object: mapping,
      actionType: ActionTypes.AUTOMATION_MAPPING_SAVED,
      successToast: "Mapping saved successfully",
      failToast: "Unable to save mapping, please try again",
    });
  }

  /**
   * Executes an automatic assessment for a building within a specified time range and dispatches an action to store this data.
   *
   * @param {string} buildingId - The unique identifier of the building.
   * @param {string} tsStart - The start timestamp of the assessment.
   * @param {string} tsEnd - The end timestamp of the assessment.
   * @param {string[]} emailRecipients - The list of email recipients for the assessment results.
   * @param {Object} overrides - The overrides for the assessment.
   * @param {number} heatingKwh - The heating consumption in kWh.
   * @param {number} coolingKwh - The cooling consumption in kWh.
   * @param {number} ventilationKwh - The ventilation consumption in kWh.
   * @param {number} hotWaterKwh - The hot water consumption in kWh.
   * @returns {Promise} - A promise that resolves with the response of the execution operation.
   */
  executeAutomaticAssessment(
    buildingId,
    tsStart,
    tsEnd,
    emailRecipients,
    overrides,
    heatingKwh,
    coolingKwh,
    ventilationKwh,
    hotWaterKwh
  ) {
    const postObject = {
      building_id: buildingId,
      ts_start: tsStart,
      ts_end: tsEnd,
      recipients: emailRecipients,
      overrides: overrides,
      current_heating_consumption: heatingKwh,
      current_cooling_consumption: coolingKwh,
      current_ventilation_consumption: ventilationKwh,
      current_hot_water_consumption: hotWaterKwh,
    };

    return GeneralUtils.post({
      url: `assessment`,
      object: postObject,
      actionType: ActionTypes.AUTOMATIC_ASSESSMENT_STARTED,
      successToast: "Assessment started, you will receive an email as soon as we're done!",
      failToast: "Unable to start the Assessment, please try again",
    });
  }
}

const automatedAssessmentsActions = new AutomatedAssessmentsActions();
export default automatedAssessmentsActions;

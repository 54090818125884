import React, { Component } from "react";
import Highcharts from "highcharts";
import HC_exporting from "highcharts/modules/exporting";
import HC_export_data from "highcharts/modules/export-data";
import "./PieChart.scss";

class PieChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      chart_Tag: "_" + Math.random().toString(36).substr(2, 9),
    };
    this.drawChart = this.drawChart.bind(this);
  }
  componentDidMount() {
    if (
      this.props.series[0] &&
      this.props.series[0].data &&
      this.props.series[0].data.length > 0
    ) {
      this.drawChart();
    }
  }
  componentDidUpdate() {
    if (
      this.props.series[0] &&
      this.props.series[0].data &&
      this.props.series[0].data.length > 0
    ) {
      this.drawChart();
    }
  }
  componentWillUnmount() {
    if (this.chart) {
      this.chart.destroy();
    }
  }
  shouldComponentUpdate(nextProps) {
    if (this.props.series && nextProps.series) {
      if (
        JSON.stringify(this.props.series) !== JSON.stringify(nextProps.series)
      ) {
        return true;
      }
    }
    return false;
  }
  render() {
    if (
      this.props.series[0] &&
      this.props.series[0].data &&
      this.props.series[0].data.length > 0
    ) {
      let centerText = null;
      if (this.props.centerText) {
        centerText = (
          <div
            style={{
              position: "absolute",
              width: "100%",
              height: "100%",
              top: 0,
              fontSize: this.props.centerTextSize
                ? this.props.centerTextSize + "px"
                : "30px",
              color: this.props.centerTextColor
                ? this.props.centerTextColor
                : "black",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {this.props.centerText}
          </div>
        );
      }

      if (this.props.centerTextElement) {
        centerText = (
          <div
            style={{
              position: "absolute",
              width: "100%",
              height: "100%",
              top: 0,
              fontSize: this.props.centerTextSize
                ? this.props.centerTextSize + "px"
                : "30px",
              color: this.props.centerTextColor
                ? this.props.centerTextColor
                : "black",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {this.props.centerTextElement}
          </div>
        );
      }

      return (
        <div className="row" style={{ marginTop: "5px" }}>
          <div className="col-12 pd-l-0 pd-r-0" style={{ width: "100%" }}>
            {centerText}
            <div id={this.state.chart_Tag} style={{ width: "100%" }} />
          </div>
        </div>
      );
    } else {
      return (
        <div
          className="d-flex align-items-center justify-content-center"
          style={{ height: "327px" }}
        >
          <div className="wd-lg-70p wd-xl-50p tx-center pd-x-40">
            <h5 className="tx-xs-24 tx-normal tx-info mg-b-30 lh-5">
              No Data Available.
            </h5>
          </div>
        </div>
      );
    }
  }
  drawChart() {
    if (
      this.props.series[0] &&
      this.props.series[0].data &&
      this.props.series[0].data.length > 0
    ) {
      let unit = "kWh";
      if (this.props.unit) {
        unit = this.props.unit;
      }
      let pieFormat;
      let pointFormat =
        "{point.name}<br/> Percent: <b>{point.percentage:.1f} %</b> <br/> Value: {point.amount:.0f} " +
        unit;

      if (this.props.unitInFront) {
        pointFormat = `{point.name}<br/> Percent: <b>{point.percentage:.1f} %</b> <br/> Value: ${unit}{point.amount:.0f}`;
      }

      if (this.props.compareMeters) {
        pointFormat =
          "<b>{point.name}</b><br/> Percent: <b>{point.percentage:.1f} %</b> <br/> Consumption: {point.amount:.0f} " +
          unit +
          "<br/> Emissions: {point.emissions:.0f} kgCO2e <br/> Cost: €{point.cost:.0f}";
      }

      if (this.props.valuesNotPercent) {
        pointFormat =
          "{point.name}<br/> No. of " +
          this.props.entity_type +
          ": {point.amount} <br/>  Percent: <b>{point.percentage:.1f} %</b>";
        pieFormat = {
          borderWidth: this.props.borderWidth,
          allowPointSelect: true,
          cursor: "pointer",
          dataLabels: {
            enabled: true,
            format: "{point.amount} " + this.props.entity_type,
            distance: -30,
            color: "white",
          },
        };
      } else {
        pieFormat = {
          borderWidth: this.props.borderWidth,
          allowPointSelect: true,
          cursor: "pointer",
          dataLabels: {
            enabled: true,
            format: "{point.percentage:.0f}%",
            distance: -30,
            color: "white",
          },
        };
      }

      if (typeof this.props.primaryColor !== "undefined") {
        let colors = [],
          base = this.props.primaryColor,
          i;
        for (i = 0; i < 10; i += 1) {
          colors.push(
            Highcharts.color(base)
              .brighten((i - 4) / 10)
              .get()
          );
        }
        pieFormat.colors = colors;
      }

      if (this.props.showLegend) {
        pieFormat.showInLegend = true;
      }
      if (this.props.innerSize) {
        pieFormat.innerSize = this.props.innerSize;
      }
      HC_exporting(Highcharts);
      HC_export_data(Highcharts);

      const options = {
        chart: {
          plotBackgroundColor: null,
          plotBorderWidth: null,
          plotShadow: false,
          type: "pie",
          height: this.props.height,
          backgroundColor: this.props.backgroundColor || "#FFF",
        },
        credits: {
          enabled: false,
        },
        title: {
          text: null,
        },
        tooltip: {
          headerFormat: "",
          pointFormat: pointFormat,
        },
        plotOptions: {
          pie: pieFormat,
        },
        series: this.props.series,
        navigation: {
          buttonOptions: {
            verticalAlign: "bottom",
            y: 0,
            x: 10,
            theme: {
              style: {
                color: "#17A2B8",
                textDecoration: "underline",
              },
            },
          },
        },
        exporting: {
          enabled: this.props.exporting,
          buttons: {
            contextButton: {
              enabled: false,
            },
            exportButton: {
              text: "Export",
              // Use only the download related menu items from the default
              // context button
              menuItems: ["downloadCSV", "downloadPNG", "downloadPDF"],
            },
          },
        },
        ...this.props.options,
      };

      this.chart = Highcharts.chart(this.state.chart_Tag, options);
    }
  }
}
export default PieChart;

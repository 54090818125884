import ActionTypes from "../constants";
import GeneralUtils from "../utils/GeneralUtils";

/**
 * Actions related to fetching data source entity maps. This class interfaces with the API
 * to retrieve data and dispatches actions to update the application state.
 *
 * @class
 * @example
 * const dataSourceActions = new DataSourceActions();
 * dataSourceActions.getDataSourceEntityMap('building123');
 */
class DataSourceActions {
  /**
   * Fetches the data source entity map for a specific building from the API and dispatches an action to store this data.
   *
   * @param {string} buildingId - The unique identifier of the building.
   * @returns {Promise} - A promise that resolves with the fetched data.
   */
  getDataSourceEntityMap(buildingId) {
    return GeneralUtils.get({
      url: `data-source/building/${buildingId}/entity/map`,
      actionType: ActionTypes.DATA_SOURCE_ENTITY_MAP_FETCHED,
      failToast: "Unable to get data source entity map, please try again",
    });
  }
}

const dataSourceActions = new DataSourceActions();
export default dataSourceActions;

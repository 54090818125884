import { EventEmitter } from "events";
import Dispatcher from "../dispatcher";
import Constants from "../constants";
import _ from "lodash";
import moment from "moment";
import GeneralUtils from "../utils/GeneralUtils";
import ChartUtils from "../utils/ChartUtils";

const HVAC_DETAILS_INFO_FETCHED = Constants.HVAC_DETAILS_INFO_FETCHED;
const HVAC_DETAILS_CHARTS_FETCHED = Constants.HVAC_DETAILS_CHARTS_FETCHED;

let _hvac_details = {};
let _hvac_charts = {
  temperature: [],
  levels: [],
};

class HvacDetailsStore extends EventEmitter {
  constructor() {
    super();
    // Registers action handler with the Dispatcher.
    Dispatcher.register(this._registerToActions.bind(this));
  }
  // // Switches over the action's type when an action is dispatched.
  _registerToActions(action) {
    switch (action.actionType) {
      case HVAC_DETAILS_INFO_FETCHED:
        this._storeHvacDetails(action.payload);
        break;
      case HVAC_DETAILS_CHARTS_FETCHED:
        this._storeHvacCharts(action.payload);
        break;
      default:
        break;
    }
  }
  // // Hooks a React component's callback to the CHANGED event.
  addHvacDetailsFetchListener(callback) {
    this.on(HVAC_DETAILS_INFO_FETCHED, callback);
  } 
  removeHvacDetailsFetchListener(callback) {
    this.removeListener(HVAC_DETAILS_INFO_FETCHED, callback);
  }
  addHvacChartsFetchListener(callback) {
    this.on(HVAC_DETAILS_CHARTS_FETCHED, callback);
  }
  removeHvacChartsFetchListener(callback) {
    this.removeListener(HVAC_DETAILS_CHARTS_FETCHED, callback);
  }

  clear() {
    _hvac_details = {};
    _hvac_charts = {};
  }

  _storeHvacDetails(json) {
    _hvac_details = json;
    this.emit(HVAC_DETAILS_INFO_FETCHED);
  }

  _storeHvacCharts(json) {
    _hvac_charts = [];

    let _temperature_chart = {
      name: "Temperature Profiles",
      series: [],
      dataType: "°C",
      ref: "temp_chart",
      type: "line",
    };

    let _velocity_chart = {
      name: "Velocity Profiles",
      series: [],
      dataType: "m3/s",
      ref: "vel_chart",
      type: "line",
    };

    let _consumption_chart = {
      dataType: " kWh",
      ref: "kwh_chart",
      type: "line",
      name: "Energy Consumption",
      series: [],
    };

    const response = _.orderBy(json, "property.name", "desc");

    response
      .filter((item) => item.property && item.data)
      .forEach((item, i) => {
        // Check the format of points
        let data = item.data.map((dataItem) => {
          if (
            ["Status", "Enable", "Active"].some((keyword) =>
              item.property.name.includes(keyword)
            )
          ) {
            if (dataItem.value > 0) {
              dataItem.value = 100;
            }
          } else if (
            item.property.name.includes("Velocity") &&
            dataItem.value > 50
          ) {
            dataItem.value /= 1000;
          }
          return [
            moment(dataItem.timestamp).valueOf(),
            GeneralUtils.roundNumber(dataItem.value, 2),
          ];
        });

        let label = item.property.name;

        // check where to assign the serie
        let chartToUpdate = null;
        if (item.property.data_type === "tempC") {
          chartToUpdate = _temperature_chart.series;
        } else if (item.property.data_type.includes("kwh")) {
          label = `${item.entity_type.name} - ${item.property.name}`;
          chartToUpdate = _consumption_chart.series;
        } else if (
          ["SupVl", "RetVl", "SupVlSP", "RetVlSP"].includes(item.property.code)
        ) {
          chartToUpdate = _velocity_chart.series;
        } else {
          // Series not assigned to temperature/velocity/consumption charts are out straight to _hvac_charts to display as independent charts
          chartToUpdate = _hvac_charts;
        }

        let seriesData = data.map((dataItem) => ({
          x: dataItem[0],
          y: dataItem[1],
        }));

        // check if the column or line
        let serie;

        if (
          item.property.data_type === "level" ||
          item.property.data_type === "percent"
        ) {
          serie = ChartUtils.getColumnConsumptionSeries(
            label,
            ChartUtils.getColorForProperty(response[i].property.name),
            true,
            true
          );
        } else {
          serie = ChartUtils.getLineConsumptionSeries(
            label,
            null,
            Constants.SOLID,
            2,
            true,
            true
          );
        }

        if (serie) {
          serie.description = item.mapping ? item.mapping.description : "";
          serie.data = seriesData;
          serie.dataType = item.property.data_type === "tempC" ? "" : "%";
          serie.ref =
            (item.property.data_type === "tempC" ? "other" : "level") + i;
        }

        chartToUpdate.push(serie);
      });

    // Check if the charts have the
    if (_velocity_chart.series.length) _hvac_charts.unshift(_velocity_chart);
    if (_temperature_chart.series.length)
      _hvac_charts.unshift(_temperature_chart);
    if (_consumption_chart.series.length)
      _hvac_charts.unshift(_consumption_chart);

    this.emit(HVAC_DETAILS_CHARTS_FETCHED);
  }

  getHvacDetails() {
    return _hvac_details;
  }
  getHvacCharts() {
    return _hvac_charts;
  }
}

const hvacDetailsStore = new HvacDetailsStore();
export default hvacDetailsStore;

import React, { Component } from 'react';
import './ChangeOrganisation.scss';
import UserActions from '../../actions/userActions';
import UserStore from '../../stores/userStore';
import OrganisationActions from '../../actions/organisationActions';
import OrganisationStore from '../../stores/organisationStore';

import { DocumentTitle } from '../../components/DocumentTitle'
import { SearchSelect } from '../../components/SearchSelect'

import { Typography } from "antd";
import { LogoSpinner } from '../../components/LogoSpinner';
const { Title, Text } = Typography;


class ChangeOrganisation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: UserStore.getUser(),
      orgs: OrganisationStore.getOrganisations(),
      selectedOrganisation: null,
      loading: true
    };
    this.onUserChange = this.onUserChange.bind(this);
    this.onOrganisationChange = this.onOrganisationChange.bind(this);
    this.updateUser = this.updateUser.bind(this);
    this.selectOrganisation = this.selectOrganisation.bind(this);
  }

  UNSAFE_componentWillMount() {
    OrganisationStore.addChangeListener(this.onOrganisationChange);
    UserStore.addChangeListener(this.onUserChange);
  }

  componentDidMount() {
    if (UserStore.isSuper()) {
      OrganisationActions.getOrganisations(false);
    }
  }
  componentWillUnmount() {
    OrganisationStore.removeChangeListener(this.onOrganisationChange);
    UserStore.removeChangeListener(this.onUserChange);
  }


  updateUser() {
    if (this.state.selectedOrganisation != null) {
      UserActions.updateUserOrganisation(this.state.user.user_id, this.state.selectedOrganisation.organisation_id);
    }
  }
  selectOrganisation(selectedOrg) {
    this.setState({ selectedOrganisation: selectedOrg })
  }
  onOrganisationChange() {
    this.setState({
      orgs: OrganisationStore.getOrganisations(), selectedOrganisation: null, loading: false
    })
  }
  onUserChange() {
    this.props.history.push("/login");
  }

  render() {
    let orgs = [];
    let current_org = "N/A";
    if (this.state.orgs && this.state.orgs.length > 0) {
      orgs = this.state.orgs.map(org => {
        if (org.organisation_id === this.state.user.fk_organisation_id) {
          current_org = org.name;
        }
        return { ...org, name: org.name + ` (org id: ${org.organisation_id})` }
      })
    }

    return (
      <div className="br-mainpanel br-profile-page floorplan-background">
        <DocumentTitle title='Change Organisation' />
        <LogoSpinner loading={this.state.loading} />
        <div className="br-container">
          <div className="row mg-y-30">
            <div className="col-12">
              <Title level={3}>Change Organisation</Title>
              <Text type='secondary'>
                Update the organisation your account belongs to (SuperAdmins Only).
              </Text>
            </div>
            <div className="col-12">
              <div className="form-group">
                <label className="form-control-label">Current Organisation:</label>
                <input type="text" disabled className="form-control" defaultValue={current_org} placeholder="Current Organisation" />
              </div>
              <div className="form-group">
                <label className="form-control-label">Select New Organisation:</label>
                <SearchSelect limit={100} options={orgs} placeholder={"Select Organisation"} defaultValue={this.state.selectedOrganisation} actionOnSelectedOption={this.selectOrganisation} />
              </div>
              <button type="submit" className="btn btn-info btn-block" onClick={this.updateUser}>Update</button>
            </div>
          </div>
        </div>

      </div>
    );
  }
}

export default ChangeOrganisation;

import React from "react";
import "./MultiSelectDropdown.scss";
import PropTypes from "prop-types";
import MultiSelect from "@khanacademy/react-multi-select";

const MultiSelectDropdown = (props) => {
  const {
    label,
    value,
    onChangeHandler,
    isRequired,
    style,
    options,
    disabled,
  } = props;

  return (
    <div
      className={`MultiSelectDropdown ${disabled ? "disabled" : ""}`}
      style={style}
    >
      {label && (
        <div className="label">
          {label}{" "}
          {isRequired && <span className="field-status">(required)</span>}
        </div>
      )}
      <MultiSelect
        disabled={disabled}
        overrideStrings={{
          selectSomeItems: "Select Items...",
          allItemsAreSelected: "All Items Selected",
          selectAll: "Select All",
          search: "Search",
        }}
        options={options}
        selected={value}
        onSelectedChanged={onChangeHandler}
      />
    </div>
  );
};

export default MultiSelectDropdown;

MultiSelectDropdown.propTypes = {
  label: PropTypes.string,
  // value: PropTypes.array,
  onChangeHandler: PropTypes.func,
  isRequired: PropTypes.bool,
  style: PropTypes.object,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.any.isRequired,
    })
  ),
};

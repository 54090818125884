import React, {useState} from 'react';
import './DisclaimerModal.scss'

const DisclaimerModal = (props) => {

  const {localStorageKey = window.location.pathname, text, title, children, style} = props;

  const [disclaimerRead, setDisclaimerRead] = useState(window.localStorage.getItem(localStorageKey));

  const setLocalStorage = (localStorageKey) => {
    window.localStorage.setItem(localStorageKey, true)
    setDisclaimerRead(true)
  }

  const getModalContents = () => {
    if (children) {
      return <div className="modal-content">
        {children}
      </div>
    } 

    if (text || title) {
      return <div className="modal-content">
        <div className='title'>{title}</div>
        <div className='text'>{text}</div>
      </div>
    }
  }

  const disclaimer = <div id='DisclaimerModal' >
    <div className="disclaimer-card" style={style}>
      {getModalContents()}
      <div className="disclaimer-buttons">
        <button onClick={() => setLocalStorage(localStorageKey)} className='okay-button btn btn-info'>I understand</button>
      </div>
    </div>
  </div>

  return disclaimerRead ? null : disclaimer;
};

export default DisclaimerModal;
import React from 'react';
import _ from 'lodash';
import { Card } from 'antd';
import { Typography } from 'antd';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import COLOR_PALETTE from './ColorPalette';

const { Title } = Typography;

const ColumnChartCard = ({ data, type }) => {
  const current = _.get(data, 'current');

  const thermalValue = _.get(current, `thermal.${type}`, 0);
  const electricityValue = _.get(current, `electricity.${type}`, 0);

  if (thermalValue === 0 && electricityValue === 0) {
    return null;
  }

  const getUnitAndTitle = () => {
    switch (type) {
      case 'consumption':
        return { unit: 'kWh', title: 'Energy' };
      case 'emissions':
        return { unit: 'kgCO2e', title: 'Emissions' };
      case 'cost':
        return { unit: getCurrencySymbol(data.user_data.currency), title: 'Cost' };
      default:
        return { unit: '', title: '' };
    }
  };

  const getCurrencySymbol = (currency) => {
    switch (currency) {
      case 'EUR':
        return '€';
      case 'GBP':
        return '£';
      default:
        return currency;
    }
  };

  const { unit, title } = getUnitAndTitle();

  const options = {
    chart: {
      type: 'column',
      height: 250
    },
    title: {
      text: null
    },
    xAxis: {
      categories: [title]
    },
    yAxis: {
      min: 0,
      title: {
        text: unit
      },
      stackLabels: {
        enabled: false
      }
    },
    credits: {
      enabled: false
    },
    legend: {
      enabled: false // Remove the legend from the chart
    },
    tooltip: {
      formatter: function () {
        const energyAdj = this.series.name === 'Thermal' ? 'thermal energy ' : 'electricity ';
        let additionalInfo = '';
        let formattedValue = '';
        let formattedTotal = '';

        if (type === 'cost') {
          formattedValue = `${unit}${Highcharts.numberFormat(this.y, 0, ',')}`;
          formattedTotal = `${unit}${Highcharts.numberFormat(this.point.total, 0, ',')}`;
          const consumption = this.series.name === 'Thermal' ? current.thermal.consumption : current.electricity.consumption;
          const costPerUnit = consumption > 0 ? this.y / consumption : 0;
          additionalInfo = `<br/><div>Cost per Unit: ${unit}${Highcharts.numberFormat(costPerUnit, 4)}/kWh</div>`;
        } else {
          formattedValue = `${Highcharts.numberFormat(this.y, 0, ',')} ${unit}`;
          formattedTotal = `${Highcharts.numberFormat(this.point.total, 0, ',')} ${unit}`;
        }

        return `<div style="color: ${this.point.color};"><b>${this.series.name}</b></div><br/>` +
          `<div><b>${Highcharts.numberFormat(this.point.percentage, 1)}%</b> of ${title.toLowerCase()} comes from ` +
          `${energyAdj}(${formattedValue} / ${formattedTotal})</div>${additionalInfo}`;
      }
    },
    plotOptions: {
      column: {
        stacking: 'normal',
        dataLabels: {
          enabled: true,
          format: '{point.y:,.0f}'
        }
      }
    },
    colors: [COLOR_PALETTE.energyTypes.thermal, COLOR_PALETTE.energyTypes.electricity],
    series: [{
      name: 'Thermal',
      data: [current.thermal[type]]
    }, {
      name: 'Electricity',
      data: [current.electricity[type]]
    }]
  };

  return (
    <Card bordered={false} style={{ height: '100%' }}>
      <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
        <Title level={5} style={{ marginBottom: '16px' }}>{title}</Title>
        <div style={{ flex: 1 }}>
          <HighchartsReact
            highcharts={Highcharts}
            options={options}
          />
        </div>
      </div>
    </Card>
  );
};

export default ColumnChartCard;

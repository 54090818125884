import React, { useState, useEffect } from "react";
import QRCode from "qrcode.react";
import _ from "lodash";
import JSZip from "jszip";
import "./QRForEstate.scss";
import QrActions from "../../actions/qrActions";
import QrStore from "../../stores/qrStore";
import UrlManager from "../../utils/UrlManager";
import { Icon } from "../Icon";

const QRForEstate = (props) => {
  const [isDisabled, setDisabled] = useState(false);
  const [tokens, setTokens] = useState([]);
  const [map, setMap] = useState([]);
  const [availableButtonStates] = useState({
    initial_state: "Generate QR Codes",
    fetching: "Loading...",
    generating: "Generating QR...",
    preparing: "Preparing File...",
    complete: "Download Complete",
    no_zones_found: "No Zones Found",
  });
  const [buttonState, setButtonState] = useState(
    availableButtonStates.initial_state
  );

  const clickHandler = () => {
    if (buttonState === availableButtonStates.initial_state) {
      getZoneTokens();
    } else {
      prepareForDownload();
    }
  };

  const getZoneTokens = () => {
    setButtonState(availableButtonStates.fetching);
    setDisabled(true);
    let all_zones = props.estate.buildings.map((building) => building.zones);
    all_zones = _.flatten(all_zones);
    if (all_zones.length === 0) {
      setButtonState(availableButtonStates.no_zones_found);
      setDisabled(true);
    }
    QrActions.getTokensInBulk(all_zones);
  };

  // Attach listeners waiting for token generation (API call)
  useEffect(() => {
    if (buttonState === availableButtonStates.fetching)
      QrStore.addTokensBulkFetchListener(_onTokensBulkFetch);
    return () => {
      QrStore.removeTokensBulkFetchListener(_onTokensBulkFetch);
    };
  });

  useEffect(() => {
    if (buttonState === availableButtonStates.generating) {
      prepareForDownload();
    }
  });

  // Set them in state
  const _onTokensBulkFetch = () => {
    if (buttonState === availableButtonStates.fetching) {
      const imgSrc = "/img/opn/qrlogo.png";
      const zones_with_tokens = QrStore._getTokensBulk();
      const map = zones_with_tokens.map((zone, index) => {
        return (
          <div key={zone.zone_id} style={{ display: "none" }}>
            <QRCode
              id={zone.zone_id + "-lg"}
              value={generateLink(zone.token)}
              level={"M"}
              size={600}
              fgColor={"#0b2f41"}
              imageSettings={{
                src: imgSrc,
                x: null,
                y: null,
                height: 162,
                width: 162,
                excavate: true,
              }}
            />
            <QRCode
              id={zone.zone_id}
              value={generateLink(zone.token)}
              level={"M"}
              size={300}
              fgColor={"#0b2f41"}
              imageSettings={{
                src: imgSrc,
                x: null,
                y: null,
                height: 81,
                width: 81,
                excavate: true,
              }}
            />
          </div>
        );
      });

      setTokens(zones_with_tokens);
      setMap(map);
      setButtonState(availableButtonStates.generating);
    }
  };

  // // Change QR code to png, append it to DOM and download it
  const prepareForDownload = () => {
    setButtonState(availableButtonStates.preparing);
    setDisabled(true);

    let zip = new JSZip();
    const buildings = props.estate.buildings;

    setTimeout(() => {
      tokens.forEach((zone, ind) => {
        const title = zone.name;
        const buildingName = buildings.filter(
          (building) => building.building_id === zone.fk_building_id
        )[0].name;
        const subtitle = "Building - " + buildingName;

        // if there's any subtitle add extra space
        const subtitleSpace = subtitle ? 27 : 0;

        // Taking blank hidden canvas in DOM and resizing it
        const canvas = document.getElementById("BlankCanvas");
        canvas.width = 620;
        canvas.height = 730 + subtitleSpace;
        const ctx = canvas.getContext("2d");
        ctx.fillStyle = "white";
        ctx.fillRect(0, 0, canvas.width, canvas.height);

        // Taking QRcode and drawing it on the canvas
        let picture;
        let poweredBy = document.getElementById("OPNpng");

        if (window.devicePixelRatio > 1) {
          picture = document.getElementById(zone.zone_id);
        } else {
          picture = document.getElementById(zone.zone_id + "-lg");
        }
        ctx.drawImage(picture, 10, 80 + subtitleSpace);

        // preparing title and drawing it on the canvas
        if (title.length > 30) {
          ctx.font = "32px Fira Sans, Roboto, Ariel";
        } else {
          ctx.font = "42px Fira Sans, Roboto, Ariel";
        }
        ctx.fillStyle = "#093b50";
        ctx.textAlign = "center";
        ctx.fillText(title, 310, 45);

        // adding subtitle if present
        if (subtitle) {
          ctx.font = "28px Fira Sans, Roboto, Arial";
          ctx.fillStyle = "#426A81";
          ctx.textAlign = "center";
          ctx.fillText(subtitle, 310, 85);
        }

        // adding 'Powered by OPNbuildings'
        ctx.drawImage(poweredBy, 440, 683 + subtitleSpace, 167, 34);
        ctx.font = "16px Fira Sans, Roboto, Arial";
        ctx.fillStyle = "#426A81";
        ctx.fillText("Powered by ", 397, 708 + subtitleSpace);

        function getBase64String(dataURL) {
          var idx = dataURL.indexOf("base64,") + "base64,".length;
          return dataURL.substring(idx);
        }

        const png = canvas.toDataURL("image/png");
        const baseString = getBase64String(png);

        //replacing '/' in names to avoid creating new folders
        zip.file(
          `${buildingName}/${zone.name.replace("/", "|")}.png`,
          baseString,
          { base64: true, createFolders: true }
        );
        if (ind === tokens.length - 1) {
          setDisabled(false);
          setButtonState(availableButtonStates.complete);
          setTimeout(() => {
            setButtonState(availableButtonStates.initial_state);
          }, 5000);
        }
      });

      zip.generateAsync({ type: "blob" }).then(function (content) {
        content = URL.createObjectURL(content);
        let name = `${props.estate.estate_name}.zip`;
        downloadFile(content, name);
      });
    }, 100);
  };

  // Create a link from token and get UI Url based on the environment
  const generateLink = (token) => {
    const url = UrlManager.getUIUrl() + "qr-dashboard/" + token;
    return url;
  };

  const downloadFile = (href, name) => {
    const link = document.createElement("a");
    link.href = href;
    link.download = name;
    link.style.display = "none";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div id="QRForEstate">
      <canvas style={{ display: "none" }} id={"BlankCanvas"} />
      <button
        disabled={isDisabled}
        className="btn btn-outline-info"
        onClick={clickHandler}
      >
        <Icon name='QrScanner' color='#17a2b8' style={{ marginRight: '5px' }} />
        {buttonState}
      </button>
      <img alt="OPNBuildings" id="OPNpng" src="/img/opn/OPNLogo.png" />
      {map}
    </div>
  );
};

export default QRForEstate;

import React, { Component } from "react";
import "./MappedPointDebug.scss";
import AdminActions from "../../actions/adminActions";
import AdminStore from "../../stores/adminStore";
import BuildingActions from "../../actions/buildingActions";
import BuildingStore from "../../stores/buildingStore";
import GeneralStore from "../../stores/generalStore";
import { LogoSpinner } from "../../components/LogoSpinner";
import { HighChart } from "../../components/HighChart";
import { SearchSelect } from "../../components/SearchSelect";
import { DocumentTitle } from "../../components/DocumentTitle";
import { Icon } from "../../components/Icon";
import { Table, Button } from 'antd';

class MappedPointDebug extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ts_start: GeneralStore.getStartDate(),
      ts_end: GeneralStore.getEndDate(),

      selected_mapping: null,
      selected_source: null,
      selected_source_type: null,
      selected_building: null,
      available_buildings: BuildingStore.getBuildings(),
      data_source_mappings: AdminStore.getDataSourceMappings(),
      available_appliances: AdminStore.getAvailableAppliances(),
      available_counters: AdminStore.getAvailableCounters(),
      available_sensors: AdminStore.getAvailableSensors(),
      available_types: [
        {
          name: "All Data Source Types",
          value: "All",
        },
        {
          name: "Sensors",
          value: "Sensor",
        },
        {
          name: "Appliances",
          value: "Appliance",
        },
        {
          name: "Counters",
          value: "Counter",
        },
      ],
      search_input: "",
      loading: true,
    };
    this._selectBuilding = this._selectBuilding.bind(this);
    this._onDateChange = this._onDateChange.bind(this);
    this._onDataChange = this._onDataChange.bind(this);
    this.onFilterInput = this.onFilterInput.bind(this);
    this._selectSource = this._selectSource.bind(this);
    this._selectSourceType = this._selectSourceType.bind(this);
    this._selectMapping = this._selectMapping.bind(this);
    this._clearAllFilters = this._clearAllFilters.bind(this);
  }

  _onDataChange() {

    this.setState({
      data_source_mappings: AdminStore.getDataSourceMappings(),
      available_counters: AdminStore.getAvailableCounters(),
      available_sensors: AdminStore.getAvailableSensors(),
      available_appliances: AdminStore.getAvailableAppliances(),
      chart_data: AdminStore.getMappedPointValues(),
      loading: false,
    });
  }

  UNSAFE_componentWillMount() {
    GeneralStore.addChangeListener(this._onDateChange);
    AdminStore.addChangeListener(this._onDataChange);
  }

  componentWillUnmount() {
    AdminStore.clear();
    GeneralStore.removeChangeListener(this._onDateChange);
    AdminStore.removeChangeListener(this._onDataChange);
  }

  componentDidMount() {
    this.fetchBuildings();
  }

  async fetchBuildings() {
    const buildings = await BuildingActions.getBuildings();
    this.setState({
      available_buildings: buildings,
      loading: false,
    });
  }

  _selectBuilding(selected_building) {
    this.setState(
      {
        selected_building,
        selected_source: null,
        selected_source_type: null,
        selected_mapping: null,
        chart_data: [],
        search_input: "",
        loading: true
      },
      this._fetchData(selected_building.building_id)
    );
  }

  _fetchData(building_id) {
    AdminActions.getDataSourceMappingsByBuildingId(building_id);
    AdminActions.getBuildingDatasources(building_id);
  }
  _onDateChange() {
    const ts_start = GeneralStore.getStartDate();
    const ts_end = GeneralStore.getEndDate();

    this.setState(
      {
        ts_start,
        ts_end,
        loading: true,
      },
      () => {
        if (this.state.selected_mapping != null) {
          AdminActions.getMappedPointValues(
            this.state.selected_mapping.mapping_id,
            ts_start,
            ts_end
          );
        }
      }
    );
  }

  _selectSourceType(selected_source_type) {
    this.setState({ selected_source_type: selected_source_type });
  }

  getSourceOptions() {
    let options = [
      { name: "All Data Sources", value: "All" },
      ...this.state.available_counters,
      ...this.state.available_sensors,
      ...this.state.available_appliances
    ];

    if (this.state.selected_source_type && this.state.selected_source_type.value !== "All") {
      if (this.state.selected_source_type.value === "Appliance") {
        options = [{ name: "All Data Sources", value: "All" }, ...this.state.available_appliances];
      }
      if (this.state.selected_source_type.value === "Sensor") {
        options = [{ name: "All Data Sources", value: "All" }, ...this.state.available_sensors];
      }
      if (this.state.selected_source_type.value === "Counter") {
        options = [{ name: "All Data Sources", value: "All" }, ...this.state.available_counters];
      }
    }
    return options;
  }


  _selectSource(selected_source) {
    this.setState({ selected_source: selected_source });
  }

  onFilterInput(event) {
    this.setState({ search_input: event.target.value });
  }

  getFilteredMappings() {
    let displayMappings = this.state.data_source_mappings;
    const searchValue = this.state.search_input.toLowerCase();

    if (this.state.search_input) {
      displayMappings = displayMappings.filter(obj =>
        obj.name.toLowerCase().includes(searchValue) ||
        obj.description.toLowerCase().includes(searchValue) ||
        this.getDataSourceName(obj.fk_data_source_id).toLowerCase().includes(searchValue) ||
        `${obj.entity_type.entity_type} - ${obj.entity_type.name}`.toLowerCase().includes(searchValue) ||
        obj.unit.toLowerCase().includes(searchValue)
      );
    }

    if (this.state.selected_source && this.state.selected_source.value !== "All") {
      displayMappings = displayMappings.filter(obj =>
        obj.fk_data_source_id === this.state.selected_source.value
      );
    }

    if (this.state.selected_source_type && this.state.selected_source_type.value !== "All") {
      let dataSources;

      if (this.state.selected_source_type.value === "Appliance") {
        dataSources = this.state.available_appliances;
      }
      if (this.state.selected_source_type.value === "Sensor") {
        dataSources = this.state.available_sensors;
      }
      if (this.state.selected_source_type.value === "Counter") {
        dataSources = this.state.available_counters;
      }

      displayMappings = displayMappings.filter(obj =>
        dataSources.map(ds => ds.value).includes(obj.fk_data_source_id)
      );
    }

    return displayMappings;
  }

  getPointTable() {
    const columns = [
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        sorter: (a, b) => a.name.localeCompare(b.name),
        defaultSortOrder: 'ascend',
        width: '20%'
      },
      {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
        sorter: (a, b) => a.description.localeCompare(b.description),
        width: '25%'
      },
      {
        title: 'Data Source',
        key: 'dataSource',
        render: (text, record) => this.getDataSourceName(record.fk_data_source_id),
        sorter: (a, b) => this.getDataSourceName(a.fk_data_source_id).localeCompare(this.getDataSourceName(b.fk_data_source_id)),
        width: '20%'
      },
      {
        title: 'Mapped To',
        key: 'mappedTo',
        render: (text, record) => `${record.entity_type.entity_type} - ${record.entity_type.name}`,
        sorter: (a, b) => `${a.entity_type.entity_type} - ${a.entity_type.name}`.localeCompare(`${b.entity_type.entity_type} - ${b.entity_type.name}`),
        width: '25%'
      },
      {
        title: 'Unit',
        dataIndex: 'unit',
        key: 'unit',
        sorter: (a, b) => a.unit.localeCompare(b.unit),
        width: '10%'
      },
    ];

    return (
      <Table
        columns={columns}
        dataSource={this.getFilteredMappings()}
        rowKey="mapping_id"
        onRow={(record) => ({
          onClick: () => this._selectMapping(record),
        })}
        pagination={false}
        scroll={{
          x: 900,
          y: 280,
        }}
        locale={{
          emptyText: 'No mappings available.'
        }}
      />
    );
  }

  _selectMapping(mapping) {
    this.setState({ selected_mapping: mapping, loading: true }, () => AdminActions.getMappedPointValues(
      mapping.mapping_id,
      this.state.ts_start,
      this.state.ts_end
    ));
  }

  getPointChart() {
    const selected_mapping = this.state.selected_mapping;
    const loading = this.state.loading;
    let chart_data = this.state.chart_data;
    let chart_id = "DebugChart1";
    let title = "Select A Point";

    if (selected_mapping && chart_data && chart_data.length > 0) {
      title = chart_data[0].name;
      return (
        <div className="row mg-t-20">
          <div className="col-12 col-sm-12">
            <div className="card bd-0 shadow-base" style={{ overflow: 'hidden' }}>
              {this.getChartHeaderContent(title)}
              <div
                className="card-body bd pd-b-0"
                style={{ backgroundColor: "#fff", height: "200px" }}
              >
                <HighChart
                  main_type="line"
                  exporting={true}
                  series={chart_data}
                  dataType={""}
                  chart_Tag={chart_id}
                />
              </div>
            </div>
          </div>
        </div>
      );
    } else if (!loading && selected_mapping && chart_data && chart_data.length === 0) {
      title = `${this.state.selected_mapping.name} - ${this.state.selected_mapping.description}`;

      return <div className="row mg-t-20 mg-b-20">
        <div className="col-12 col-sm-12">
          <div className="card bd-0 shadow-base" style={{ overflow: 'hidden' }}>
            {this.getChartHeaderContent('No data received for ' + title)}
          </div>
        </div>
      </div>
    } else {
      return null;
    }
  }

  getChartHeaderContent(title) {
    return (
      <div className="card-header bg-transparent pd-x-25 pd-y-15 bd d-flex justify-content-between align-items-center">
        <div style={{ float: "left" }}>
          <h6
            className="card-title tx-uppercase tx-12 mg-b-0"
            style={{ float: "left" }}
          >
            {title}
          </h6>
        </div>
      </div>
    );
  }

  _clearAllFilters() {
    this.setState({
      search_input: "",
      selected_source: null,
      selected_source_type: null,
    });
  }

  isAnyFilterSelected = () => {
    return !!this.state.search_input ||
      !!this.state.selected_source ||
      !!this.state.selected_source_type;
  }

  getDataSourceName(dataSourceId) {
    const allDataSources = [
      ...this.state.available_appliances,
      ...this.state.available_sensors,
      ...this.state.available_counters
    ];
    const dataSource = allDataSources.find(ds => ds.value === dataSourceId);
    return dataSource ? dataSource.name : '-';
  }

  render() {
    const list_shown = !!this.state.selected_building;

    return (
      <div
        className="br-mainpanel br-profile-body floorplan-background"
        id="MappedPointDebug"
      >
        <DocumentTitle title={"Mapped Point Debug"} />
        <LogoSpinner loading={this.state.loading} />
        <div className="br-container">
          <div className="row">
            <div className="col mg-t-15">
              <div className="card pd-20 shadow-base bd-0 mg-t-10">
                <div className="row">
                  <div className="col-12 col-sm-12">
                    <div className="row pd-t-6">
                      <div className="col-12 col-sm-4">
                        <SearchSelect
                          limit={999}
                          options={this.state.available_buildings}
                          placeholder={"Select Building..."}
                          defaultValue={this.state.selected_building}
                          actionOnSelectedOption={this._selectBuilding}
                        />
                      </div>
                      {this.isAnyFilterSelected() && (
                        <div className="col-12 col-sm-4 d-flex align-items-center">
                          <Button onClick={this._clearAllFilters} type="primary" danger>
                            Clear All Filters
                          </Button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                {list_shown ? (
                  <div className="row mg-b-5">
                    <div className="col-12 col-sm-12">
                      <hr />
                      <div className="bd-0 mg-t-10">
                        <div
                          className="bd-0 pd-b-0 pd-t-0"
                          style={{ backgroundColor: "#fff" }}
                        >
                          <div className="row mg-t-10 filters">
                            <div
                              className="col-12 col-sm-6 col-md-4"
                            >
                              <div className="input-group">
                                <div className="input-group-prepend">
                                  <span className="input-group-text">
                                    <Icon name='Search' color='#868ba1' />
                                  </span>
                                </div>
                                <input
                                  value={this.state.search_input}
                                  type="text"
                                  className="form-control"
                                  placeholder="Input Filter"
                                  onChange={this.onFilterInput}
                                />
                              </div>
                            </div>
                            <div className="col-12 col-sm-6 col-md-4">
                              <SearchSelect
                                limit={3}
                                options={this.state.available_types}
                                placeholder={"Data Source Type filter"}
                                defaultValue={this.state.selected_source_type}
                                actionOnSelectedOption={this._selectSourceType}
                              />
                            </div>
                            <div className="col-12 col-sm-6 col-md-4">
                              <SearchSelect
                                limit={999}
                                options={this.getSourceOptions()}
                                placeholder={"Data Source filter"}
                                defaultValue={this.state.selected_source}
                                actionOnSelectedOption={this._selectSource}
                              />
                            </div>
                          </div>
                          <div className="row mg-t-10 mg-b-5">
                            <div className="col-12">
                              {this.getPointTable()}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="row mg-t-10">
                    <div className="col-12 col-sm-12 col-md-12 mg-b-15">
                      <div className="card bd-0 shadow-base">
                        <div
                          className="card-body bd pd-b-0 pd-t-0"
                          style={{
                            backgroundColor: "#fff",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            padding: "60px 0",
                            borderRadius: '12px'
                          }}
                        >
                          <h4 className="tx-bold mg-b-20 tx-center">
                            Mapped Point Debug
                          </h4>
                          <p className="mg-x-20 tx-center">
                            <Icon name='Construction' color='#868ba1' size={60} />
                          </p>
                          <h4 className="tx-default tx-center">
                            Please select the building and data source to filter
                            points from.
                          </h4>
                          <p className="tx-center">
                            Once all selections are made then the available data
                            points will populate.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          {this.getPointChart()}
        </div>
      </div>
    );
  }
}

export default MappedPointDebug;

import React, { Component } from 'react';
import './ManagePassword.scss';
import UserActions from '../../actions/userActions';
import UserStore from '../../stores/userStore';

import {PasswordChecker} from '../../components/PasswordChecker'
import {DocumentTitle} from '../../components/DocumentTitle'

class ManagePassword extends Component {
  constructor(props) {
    super(props);
    this.state= {
      user: UserStore.getUser(),
      password: '',
      confirmPassword:'',
      passwordValidated: false
    };
    this.onChangePassword = this.onChangePassword.bind(this);
    this.onChangeConfirmPassword = this.onChangeConfirmPassword.bind(this);
    this.updatePassword = this.updatePassword.bind(this);
    this.setPasswordValidation = this.setPasswordValidation.bind(this);
  }
  updatePassword(event) {
    event.preventDefault();
    if (this.state.passwordValidated) {
      UserActions.changePassword(this.state.user, this.state.password);
      this.setState({password:'', confirmPassword:''})
    }
  }
  onChangePassword(event){
    this.setState({password:event.target.value})
  }
  onChangeConfirmPassword(event){
    this.setState({confirmPassword:event.target.value})
  }
  setPasswordValidation(boolean) {
    this.setState({passwordValidated: boolean})
  }

  render() {
    return (
      <div className="br-mainpanel pd-15">
        <DocumentTitle title='Manage Password'/>
        <div className="br-pagetitle mg-b-0">
          <div>
            <h4>Manage Password</h4>
            <p className="mg-b-0">Update your account password using the form below.</p>
          </div>
        </div>
        <div>
          <div className="col-12 col-md-6 col-xl-4">
            <form onSubmit={this.updatePassword}>
              <div className="form-group">
                <input type="password" className="form-control" value={this.state.password} placeholder="Enter your new password" onChange={this.onChangePassword} />
              </div>
              <div className="form-group">
                <input type="password" className="form-control" value={this.state.confirmPassword} placeholder="Confirm your new password" onChange={this.onChangeConfirmPassword} />
                <PasswordChecker password={this.state.password} confirmPassword={this.state.confirmPassword} setPasswordValidation={this.setPasswordValidation}/>
              </div>
              <button type="submit" className="btn btn-info btn-block" disabled={this.state.passwordValidated === false} onSubmit={this.updatePassword} onClick={this.updatePassword}>Update</button>
            </form>
          </div>
        </div>

      </div>
    );
  }
}

export default ManagePassword;

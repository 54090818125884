import { EventEmitter } from 'events';
import Dispatcher from '../dispatcher';
import ActionTypes from '../constants';

const CHANGE = 'AVAILABLE_PROPERTIES_CHANGE';

let _all_properties = [];
let _available_properties = [];
let _properties_with_compatible_data_sources = [];

class PropertyStore extends EventEmitter {

    constructor() {
        super();
        // Registers action handler with the Dispatcher.
        Dispatcher.register(this._registerToActions.bind(this));
    }

    // Switches over the action's type when an action is dispatched.
    _registerToActions(action) {
        switch (action.actionType) {
            case ActionTypes.AVAILABLE_PROPERTIES_FETCHED:
                this._storeProperties(action.payload);
                break;
            case ActionTypes.ALL_PROPERTIES_FETCHED:
                this._storeAllProperties(action.payload);
                break;
            case ActionTypes.AVAILABLE_PROPERTIES_FOR_ENTITY_FETCHED:
                this._storePropertiesWithCompatibleDataSources(action.payload);
                break;
            default:
                break;
        }
    }

    // Hooks a React component's callback to the CHANGED event.
    addChangeListener(callback) {
        this.on(CHANGE, callback);
    }

    // Removes the listener from the CHANGED event.
    removeChangeListener(callback) {
        this.removeListener(CHANGE, callback);
    }

    clear() {
        _all_properties = [];
        _available_properties = [];
        _properties_with_compatible_data_sources = [];
    }

    _storeProperties(json) {
        _available_properties = json;
        this.emit(CHANGE);
    }
    _storeAllProperties(json) {
        _all_properties = json;
        this.emit(CHANGE);
    }
    _storePropertiesWithCompatibleDataSources(json) {
        _properties_with_compatible_data_sources = json;
        this.emit(CHANGE);
    }

    // Getters

    getProperties() {
        return _available_properties;
    }

    getAllProperties() {
        return _all_properties;
    }

    getPropertiesWithCompatibleDataSources() {
        return _properties_with_compatible_data_sources;
    }

}

const propertyStore = new PropertyStore();
export default propertyStore;
